import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData,} from "framework/src/Utilities";
import { getNavigationMessage } from "../../../components/src/Utils";
import React from "react";

// Customizable Area End

export const configJSON = require("./config");
export const frameworkConfigJSON = require("../../../framework/src/config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  
  userType: string;
  logInToken: string;
  subscriptionData:any,
  qrcodeholder: any;
  token: string;
  result: any;
  isBusinessJoining: boolean;
  scanningError: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentSuccessFullController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getQRApiCallId: any
  getProfileDetailsApiId: any;

  subscriptionApiId:any
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      
      loading: false,
      userType: "",
      logInToken: "",
      subscriptionData:null,
      qrcodeholder: "",
      token: "",
      result: null,
      isBusinessJoining: false,
      scanningError: "",

      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const otpConfResp = await getStorageData('otpConfRes', true);
    const logInResp = await getStorageData('logInRes', true);
    this.setStateAfterLogIn(logInResp);
    this.stateSetAfterSignup(otpConfResp);
    this.checkAuth(logInResp, otpConfResp);
    this.SubscriptionCall()
    // Customizable Area End
  }

  checkAuth = (logInRes: any, otpConfRes: any) => {
    if(!logInRes && !otpConfRes){
      this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
      window.localStorage.clear();
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.subscriptionApiId){
        this.handlegetData(responseJson)
      }
      
    }

    // Customizable Area End
  }

  // Customizable Area Start
  setStateAfterLogIn = (logInResp: any) => {
    if (logInResp) {
      this.setState({
        logInToken: logInResp.logInToken
      })
    }
  }

  stateSetAfterSignup = (otpConfResp: any) => {
    if (otpConfResp) {
      this.setState({ logInToken: otpConfResp.token });
    }
  }
  
  SubscriptionCall= ()=>{
    let id = localStorage.getItem("sId")
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.subscriptionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SubsDetails}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handlegetData = (response:any) =>{
    this.setState({subscriptionData:response.data.attributes})
  }
  goToViewMySubscriptionPage=()=>{
    this.send(getNavigationMessage("Subscriptionbilling2", this.props));
  }
  goBackToLandingPage = ()=>{
    this.send(getNavigationMessage("LandingPage", this.props));
  }

  // Customizable Area End
}
