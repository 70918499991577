import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

import { loadStripe } from '@stripe/stripe-js';
import { getNavigationMessage } from "../../../components/src/Utils";

const verificationStatusMockRes = {
    "message": "success"
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    stripe: any,
    isVerificationSuccessfull: boolean,
    isVerificationFailed: boolean,
    logInToken: string,
    loading:boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class VerifyIdentityController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    stripeIdentityVerificationCallId: string = "";
    stripeIdentityVerificationStatusCallId: string = "";
    verificationInterval: any = null;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            stripe: null,
            isVerificationSuccessfull: false,
            isVerificationFailed: false,
            logInToken: "",
            loading:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if(responseJson){
                this.setState({loading:false})
            }
            if (apiRequestCallId === this.stripeIdentityVerificationCallId) {
                this.handleStripeIdentityApiRes(responseJson);
            }
            if (apiRequestCallId === this.stripeIdentityVerificationStatusCallId) {
                this.handleStripeIdentityStatusApiRes(verificationStatusMockRes);
            }
            runEngine.debugLog("Message Recived", message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const stripeInstance = await loadStripe(configJSON.stripePublishableKey);
        this.setState({ stripe: stripeInstance });
        const logInRes = await getStorageData('logInRes', true);
        const otpConfRes = await getStorageData('otpConfRes', true);

        this.setStateAfterLogIn(logInRes);
        this.stateSetAfterSignup(otpConfRes);
        
        const userLoggedIn = await getStorageData("userLoggedIn", true);
        this.checkUserLoggedIn(userLoggedIn, logInRes, otpConfRes);
    }

    checkUserLoggedIn = (user: any, logInRes: any, otpConfRes: any) => {
        if(user){
          this.send(getNavigationMessage("LandingPage", this.props));
        }else if(!logInRes && !otpConfRes){
            window.localStorage.clear();
            this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
        }
      }

    setStateAfterLogIn = (logInRes: any) => {
        if (logInRes) {
            this.setState({
                logInToken: logInRes.logInToken
            })
        }
    }
    stateSetAfterSignup = (otpConfRes: any) => {
        if (otpConfRes) {
            this.setState({ logInToken: otpConfRes.token });
        }
    }
    handleContinueVerified = () => {
        this.send(getNavigationMessage("StripeSetup", this.props));
    };

    handleVerifyIdentity = async () => {
        if (!this.state.stripe) {
            return;
        }
        this.verifyStripeIdentityApiCall();
    };

    handleStripeIdentityApiRes = async (responseJson: any) => {
        const { url, id } = await responseJson.verification_session;
        const { errors } = await responseJson;

        if (url) {
            window.location.href = url;
        }
        if (errors) {
            this.send(getNavigationMessage("VerifyIdentityFailed", this.props));
        }
    }

    verifyStripeIdentityApiCall = async () => {
        const header = {
            "Content-Type": "application/json",
            token: this.state.logInToken
        };
        this.setState({loading:true})
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.stripeIdentityVerificationCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.stripeVerifyIdentityEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getIdentityVerificationStatus = (session_id: string) => {
        const header = {
            "Content-Type": "application/json",
            token: this.state.logInToken
        };
        this.setState({loading:true})
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.stripeIdentityVerificationStatusCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.stripeVerifyIdentityEndpoint}/${session_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleStripeIdentityStatusApiRes = (response: any) => {

        if (response) {
            const { message } = response;
        }
    }

    // Customizable Area End
}