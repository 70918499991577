// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

// import InfoPage from '../../blocks/info-page/src/InfoPageBlock.web'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth.web";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import VisualAnalyticsWeb from "../../blocks/visualanalytics/src/VisualAnalytics.web";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Splitpayments2 from "../../blocks/splitpayments2/src/Splitpayments2";
import FormApprovalWorkflow from "../../blocks/formapprovalworkflow/src/FormApprovalWorkflow";
// import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import QrCodes from "../../blocks/qrcodes/src/QrCodes.web";
import QrSubscriptionList from "../../blocks/qrcodes/src/QrSubscriptionList.web";
import PaymentSuccessfull from "../../blocks/qrcodes/src/PaymentSuccessfull.web";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2.web";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordLinkExpired from "../../blocks/forgot-password/src/ForgotPasswordLinkExpired.web";
import ForgotPasswordReset from "../../blocks/forgot-password/src/ForgotPasswordReset";
import ForgotPasswordCred from "../../blocks/forgot-password/src/ForgotPasswordCred";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Customform4 from "../../blocks/customform4/src/Customform4.web";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import VerifyIdentity from "../../blocks/stripepayments/src/VerifyIdentity.web";
import VerifyIdentitySuccessfull from "../../blocks/stripepayments/src/VerifyIdentitySuccessfull.web";
import VerifyIdentityFailed from "../../blocks/stripepayments/src/VerifyIdentityFailed.web";
import StripeSetup from "../../blocks/stripepayments/src/StripeSetup.web";
import StripeSetupSuccessfull from "../../blocks/stripepayments/src/StripeSetupSuccessfull.web";
import StripeSetupFailed from "../../blocks/stripepayments/src/StripeSetupFailed.web";
import FreeTrialFailed from "../../blocks/stripepayments/src/FreeTrialFailed.web";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Cfqrcodegenerator25 from "../../blocks/cfqrcodegenerator25/src/Cfqrcodegenerator25";
import PrivacyPolicies from '../../blocks/termsconditions/src/PrivacyPolicies.web';
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import RefundPolicy from "../../blocks/termsconditions/src/RefundPolicy.web"
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import DashboardWeb from "../../components/src/Dashboard.web";
import { UserProvider } from "../../components/src/UserProvider";
import  CustomisableUserProfiles from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web'
import  SetupBusiness from '../../blocks/customisableuserprofiles/src/SetupBusiness.web'
import  SubscribersList from '../../blocks/customisableuserprofiles/src/SubscribersList.web'
import CreateBranch from '../../blocks/customisableuserprofiles/src/CreateBranch.web'
import BusinessBranches from '../../blocks/customisableuserprofiles/src/BusinessBranches.web';
import CreateNewBusiness from '../../blocks/customisableuserprofiles/src/CreateNewBusiness.web';
import ManageBranch from "../../blocks/customisableuserprofiles/src/ManageBranch.web";
import DeactivateAccount from "../../blocks/settings2/src/DeactivateAccount.web";
import MemberShip from "../../blocks/customform4/src/MemberShip.web";
import BusinessSubscriptions from "../../blocks/subscriptionbilling2/src/BusinessSubscriptions.web";
import ChangeNewPassword from "../../blocks/settings2/src/ChangeNewPassword.web"
import "./App.css"


const urlParams = new URLSearchParams(window.location.search);
const fullURL = window.location.href
const token = urlParams.get('token');
localStorage.setItem("forgotToken", token);
console.log("lcoal",localStorage.getItem("isLogin"))
if(token){
  localStorage.setItem("forgotToken", token);
}

const routeMap = {
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  BusinessBranches: {
    component: BusinessBranches,
    path: "/BusinessBranches",
    layoutType: 'dashboard'
  },
  ManageBranch:{
    component: ManageBranch,
    path: "/ManageBranch",
    layoutType: 'registration'
  },
  CameraAccess: {
    component: CameraAccess,
    path: "/CameraAccess"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth",
    layoutType: 'registration'
  },
  SubscribersList: {
    component: SubscribersList,
    path: "/SubscribersList",
    layoutType: 'dashboard'
  },
  SetupBusiness: {
    component: SetupBusiness,
    path: "/SetupBusiness",
    layoutType: 'registration'
  },
  CreateNewBusiness: {
    component: CreateNewBusiness,
    path: "/CreateNewBusiness",
    layoutType: 'registration'
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: "/SaveAsPdf"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  VisualAnalyticsWeb:{
    component:VisualAnalyticsWeb,
    path:"/VisualAnalyticsWeb",
    layoutType: 'dashboard'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Splitpayments2: {
    component: Splitpayments2,
    path: "/Splitpayments2"
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: "/FormApprovalWorkflow"
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: "/Subscriptionbilling2",
    layoutType: 'dashboard'
  },
  QrCodes: {
    component: QrCodes,
    path: "/ScanCode",
    layoutType: 'registration'
  },
  QrSubscriptionList:{
    component:QrSubscriptionList,
    path:"/QrSubscription",
    layoutType:'registration'
  },
  BusinessSubscriptions:{
    component:BusinessSubscriptions,
    path:"/BusinessSubscriptions",
    layoutType:'registration'
  },
  PaymentSuccessfull:{
    component:PaymentSuccessfull,
    path:"/sucessfull",
    layoutType:"registration"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  CreateBranch: {
    component: CreateBranch,
    path: "/CreateBranch",
    layoutType: 'dashboard'
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock",
    layoutType: 'registration'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword",
    layoutType: 'registration'
  },
  ForgotPasswordReset: {
    component: ForgotPasswordReset,
    path: "/ForgotPasswordReset",
    layoutType: 'registration'
  },
  ForgotPasswordCred : {
    component: ForgotPasswordCred,
    path: `/SetNewPassword`,
    layoutType: 'registration'
  },
  ForgotPasswordLinkExpired : {
    component: ForgotPasswordLinkExpired,
    path: `/ForgotPasswordLinkExpired`,
    layoutType: 'registration'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP",
    layoutType: 'registration'
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Customform4: {
    component: Customform4,
    path: "/Customform4",
    layoutType: 'registration'
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments",
  },
  VerifyIdentity: {
    component: VerifyIdentity,
    path: "/VerifyIdentity",
    layoutType: 'registration'
  },
  VerifyIdentitySuccessfull: {
    component: VerifyIdentitySuccessfull,
    path: "/VerifyIdentitySuccessfull",
    layoutType: 'registration'
  },
  VerifyIdentityFailed: {
    component: VerifyIdentityFailed,
    path: "/VerifyIdentityFailed",
    layoutType: 'registration'
  },
  StripeSetup: {
    component: StripeSetup,
    path: "/StripeSetup",
    layoutType: 'registration'
  },
  StripeSetupSuccessfull: {
    component: StripeSetupSuccessfull,
    path: "/StripeSetupSuccessfull",
    layoutType: 'registration'
  },
  StripeSetupFailed: {
    component: StripeSetupFailed,
    path: "/StripeSetupFailed",
    layoutType: 'registration'
  },
  FreeTrialFailed: {
    component: FreeTrialFailed,
    path: "/FreeTrialFailed",
    layoutType: 'registration'
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  Cfqrcodegenerator25: {
    component: Cfqrcodegenerator25,
    path: "/Cfqrcodegenerator25"
  },
  PrivacyPolicies:{
    component: PrivacyPolicies,
    path: "/PrivacyPolicies",
    layoutType: 'registration'

  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions",
    layoutType: 'registration'
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  RefundPolicy:{
    component: RefundPolicy,
    path: "/RefundPolicy",
    layoutType: 'dashboard'

  },
  ChangeNewPassword:{
    component:ChangeNewPassword,
    path:'/changeNewPassword',
    layoutType:'dashboard'
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage",
    layoutType: 'dashboard'
  },
  DeactivateAccount: {
    component: DeactivateAccount,
    path: "/DeactivateAccount",
    layoutType: 'dashboard'
  },
  Membership : {
    component:MemberShip,
    path:"/Membership",
    layoutType:'dashboard'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration",
    layoutType: 'registration'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true,
    layoutType: 'registration'
  },
  UserProfile: {
    component: CustomisableUserProfiles,
    path: '/UserProfile',
    layoutType: 'registration'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const defaultTheme = createTheme({
      typography: {
        fontFamily: 'Inter, sans-serif',
      },
      components: {
        muitooltip: {
          styleoverrides: {
            tooltip: {
              maxWidth: 'none'
            }
          }
        }
      },
      palette: {
        type: 'light',
        primary: { main: '#ffffffff' },
        secondary: { main: '#111111ff' },
        error: { main: '#ff0000' },
        text: {
          primary: '#111111ff',
        },
        background: {
          default: '#111111ff',
        },
      },
      overrides: {
        MuiInputBase: {
          input: {
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '&[type="number"]': {
              MozAppearance: 'textfield',
            },
          },
        },
        MuiPaper: {
          root: {
            color: '#ffffff',
            backgroundColor: '#000000'
          },
        },
        MuiPopover: {
          root: {
            color: '#ffffff',
            backgroundColor: '#000000'
          },
          paper: {
            border: '2px solid rgba(0, 0, 0, 0.23)',
            marginTop: 8,
            borderRadius: 12,
            boxShadow: 'none'
          }
        },
        MuiButton: {
          root: {
            '&:hover': {
              backgroundColor: 'inherit', // Remove the background color on hover
              boxShadow: 'none', // Remove the box shadow on hover
            },
          },
        },
        MuiBox: {
          root: {
            color: '#ffffffff'
          },
        },
        typography: {
          fontFamily: 'Inter, sans-serif',
        },
      },
    });



    return (
      <MuiThemeProvider theme={defaultTheme}>
        <UserProvider>
          <View>
            {WebRoutesGenerator({ routeMap })}
            <ModalContainer />
          </View>
        </UserProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
