Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getProfileDetailsApiEndPoint = "account_block/specific_account";
exports.getBranchesDetailsApiEndPoint = "bx_block_branch/branches?business_id=";
exports.getCountDownDateApiEndPoint = "bx_block_stripe_integration/count_down"
exports.httpGetApiMethod = "GET";
exports.baseUrl = "https://goqr-369823-ruby.b369823.dev.eastus.az.svc.builder.cafe"
// Customizable Area End