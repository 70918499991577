export const logo = require("../assets/94310361Q7fnU7Z1o8hsFjlAbtiIT.png");
export const Fb = require("../assets/9436841Q7fnU7Z1o8hsFjlAbtiIT.png");
export const Insta = require("../assets/9436851Q7fnU7Z1o8hsFjlAbtiIT.png");
export const In = require("../assets/9436861Q7fnU7Z1o8hsFjlAbtiIT.png");
export const LogoDark = require("../assets/9436811Q7fnU7Z1o8hsFjlAbtiIT.png");
export const SuccessIcon = require("../assets/transparent-check-mark-icon.png");
export const profile = require("../assets/icons8-test-account-30.png");
export const logoutImage = require("../assets/image_logout.png");
export const profileImage = require("../assets/image_profile.png");
export const logoutIcon = require("../assets/image_logout.png");
export const flagImage = require("../assets/Flag.png");
export const goQrLogo = require("../assets/9436811Q7fnU7Z1o8hsFjlAbtiIT.png");
export const googleWallet = require("../assets/google-wallet-icon.png")
export const appleWallet = require("../assets/applewallet.png")