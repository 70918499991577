import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
// Customizable Area End

import StripeSetupController, {
  Props,
} from "./StripeSetupController";

export default class StripeSetup extends StripeSetupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { isStripeSetupFailed, isStripeSetupSuccessfull } = this.state;
    return (
      <ThemeProvider theme={theme}>
        {this.state.loading && <SpinnerLoader/>}
        <Container style={{ maxWidth: '100%', padding: '0 6%', fontSize: 'clamp(10px, 16px, 2vw)' }}>
          <Box sx={{ marginBottom: '50px' }}>
            <Typography style={webStyle.verifyIdentityLabel}>
              Connect to Stripe
            </Typography>
            {!isStripeSetupFailed && !isStripeSetupSuccessfull &&
              <Box sx={webStyle.identityVerifyWrapper}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box sx={webStyle.profileCardsLogoBoxStyle}>
                    <CardsIconTilt></CardsIconTilt>
                    <CardsIconFlat></CardsIconFlat>
                  </Box>
                </Box>
                <Box>
                  <TextLabelSecond>
                    Go QR uses Stripe to get you paid quickly and keep your personal and payment information secure. Thousands of companies around the world trust Stripe to process payments for their users. Set up a Stripe account to get paid with Go QR.
                  </TextLabelSecond>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 'clamp(20px, 60px, 5vw)' }}>
                  <MuiStyledButton
                    variant="contained"
                    onClick={this.stripeSetupApiCall}
                    data-test-id = "stripeSetupBtn"
                  >
                    Setup Stripe
                  </MuiStyledButton>
                </Box>
              </Box>
            }
          </Box>

        </Container>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start

const theme = createTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        width: "100%",
      },
    },
  },
});

const MuiStyledButton = styled(Button)({
  width: "225px",
  height: "56px",
  display: "flex",
  padding: "1px",
  background: "#000",
  fontFamily: "Inter",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #F4CD46",
  borderRadius: "50px",
  fontSize: "1em",
  fontWeight: 600,
  color: "#fff",
  letterSpacing: "-0.408px",
  textTransform: "capitalize",
  "&:hover": {
    background: "#F4CD46",
    color: "#000",
  },
  '@media (max-width: 600px)': {
    width: "151px",
    height: "41px",
  }
});

const CardsIconTilt = styled(Box)({
  width: '100px',
  height: '60px',
  background: 'transparent',
  border: '8px solid #fff',
  borderRadius: '10px',
  position: 'relative',
  rotate: '-45deg',
  "&:after": {
    position: "absolute",
    content: "close-quote",
    top: "12px",
    left: 0,
    right: 0,
    width: "100%",
    height: '11px',
    background: "#fff"
  }
});

const CardsIconFlat = styled(Box)({
  width: '100px',
  height: '60px',
  background: 'transparent',
  border: '8px solid #fff',
  borderRadius: '10px',
  position: 'absolute',
  top: '75px',
  left: '78px',
  "&:after": {
    position: "absolute",
    content: "close-quote",
    // top: 0,
    // left: 0,
    right: '10px',
    bottom: '12px',
    width: "50%",
    height: '8px',
    background: "#fff"
  }
});

const TextLabelSecond = styled(Typography) ({
  color: '#FFF',
  fontFamily: 'Inter',
  fontSize: '1.5em',
  fontWeight: 400,
  letterSpacing: '-0.408px',
  textAlign: 'center' ,
  });

const webStyle = {
  identityVerifyWrapper: {
    display: "flex",
    width: '100%',
    flexDirection: 'column',
    gap: 'clamp(20px, 50px, 5vw)'
  },
  verifyIdentityLabel: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '2em',
    fontWeight: 700,
    letterSpacing: '-0.408px',
    marginBottom:'clamp(20px, 50px, 5vw)'
  },
  profileCardsLogoBoxStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '195px',
    height: '172px',
    position: 'relative'
  },
  profileCardsIconsStyle: {
    position: 'absolute' as 'absolute',
    color: '#fff'
  },
  profileLogoBoxStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '195px',
    height: '172px',
  },

  
 
  cardsIconTilt: {
    width: '100px',
    height: '60px',
    background: 'transparent',
    border: '2px solid #fff',
    borderRadius: '8px',
    position: 'relative',
    "&:after": {
      position: "absolute",
      content: "",
      top: "10px",
      left: 0,
      right: 0,
      width: "100px",
      height: '5px',
      background: "#fff"
    }
  },
};
// Customizable Area End