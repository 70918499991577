import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  Modal,
  CardMedia,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
export const configJSON = require("./config");
// Customizable Area End
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomeButton from "../../../components/src/CustomeButton.web";
// Customizable Area Start
const MainContainer = styled(Box)({
  background: "#000",
  padding: '20px 3rem'
});

const BranchesMainContainer = styled(Box)({
  
});

const BranchesHeader = styled(Box)({
  display: "flex",
  width: "100%",
  margin: "auto",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "30px",
  flexWrap: 'wrap',
});

const BranchesHeaderText = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "2em",
  fontWeight: 700,
  // lineHeight: "22px",
  letterSpacing: "-0.407px",
  textAign: "left",
  color: "#fff",
  '@media (max-width: 450px)': {
    marginBottom: '15px'
  }
});

const CreateNewBranchButton = {
  height: "50px",
  width: "225px",
  border: "1.5px solid #fff",
  borderRadius: "53px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "22px",
  letterSpacing: "-0.407px",
  textAlign: "center",
  textTransform: "none",
  color: "#fff",
  '@media (max-width: 450px)': {
    height: "45px",
    width: "180px",
  }
};

const BranchListMainCotainer = styled(Box)({
  width: "90%",
  background: "#000",
  gap: "10px",
  display: "flex",
  flexWrap: "wrap",
  overflow: "auto",
  margin: 'auto',
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    width: "0px",
  },
  '@media (max-width: 1000px)': {
    justifyContent: "center"
  },
  '@media (max-width: 500px)': {
    width: "100%",
  }
});

const BranchMainBox = styled(Box)({
  height: "250px",
  width: "375px",
  border: "1.5px solid #4E4C4C",
  padding: '20px',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: 'border-box',
  "&:hover": {
    border: "1.5px solid #F4CD46",
  },
  "@media(max-width: 950px)": {
    height: "230px",
    width: "350px",
  },
  "@media(max-width: 750px)": {
    height: "210px",
    width: "320px",
  },
  "@media(max-width: 650px)": {
    height: "200px",
    width: "300px",
  }
});

const BranchInnerBox = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: '20px',
});

const BranchInnerBoxButtonMainBox = styled(Box)({
  height: "30px",
  width: "93%",
  display: "flex",
  justifyContent: "space-around",
  '@media (max-width: 1200px)': {
    height: "35px",
    width: "90%",
  },
  '@media (max-width: 450px)': {
    height: "55px",
    width: "90%",
    alignItems: "flex-End"
  }
});

const ManageButton = {
  height: "32px",
  width: "90px",
  border: "1.5px solid #fff",
  borderRadius: "53px",
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "22px",
  textAlign: "center",
  color: "#fff",
  textTransform: "none",
 
  '@media (max-width: 1200px)': {
    width: "90px",
  },
  '@media (max-width: 450px)': {
    width: "135px",
    height: "40px"
  },
  '@media (max-width: 380px)': {
    height: "35px",
    width: "105px",
  }
};


const DownloadPDFButton = styled(Button)({
  height: "40px",
  width: "150px",
  border: "1.5px solid #fff",
  borderRadius: "53px",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "22px",
  letterSpacing: "-0.40799999237060547px",
  textAlign: "center",
  color: "#fff",
  "&:hover": {
    border: "1.5px solid #F4CD46",
  },
  textTransform: "none",
  '@media (max-width: 1200px)': {
    width: "90px",
  },
  '@media (max-width: 450px)': {
    width: "130px",
  },
  '@media (max-width: 380px)': {
    height: "35px",
    width: "105px",
  },
});

const ModalQRmodal = styled(Modal)({
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
});

const ModalQRInnerBox = styled(Box)({
  height: "400px",
  width: "400px",
  background: "#212226",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: 'center'
});

const ModalQRHeaderBox = styled(Box)({
  display: "flex",
  width: "100%",
  height: "40px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px"
});

const BranchNameModalTextStyleModal = styled(Typography)({
  color: "#fff",
  textAlign: "center",
  marginLeft: "50px"
});

const QrImage = styled(CardMedia)({
  height: "260px",
  width: "260px"
});

// Customizable Area End

// Customizable Area Start
import BusinessBranchController, {
  BranchListResponseData,
  Props,
} from "./BusinessBranchController";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
// Customizable Area End

export default class BusinessBranches extends BusinessBranchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  branchBox = () => {
    return (
      <Box style={{fontSize: 'clamp(10px, 16px, 2vw)'}}>
       {this.renderLoader()}
        <BranchesHeader>
          <BranchesHeaderText>
          <ArrowBackIcon onClick={this.landingPage} style={{ position:'relative',top:'5px',fontSize:'1em', color: 'white', marginRight: '8px',cursor:'pointer' }} />
            {configJSON.branchesText}
          </BranchesHeaderText>
          {this.state.isBusinessOwner && 
            <CustomeButton data-test-id="create-branch-id" onclicked={this.goToCreateBranch} text = {configJSON.createNewBranchText} primary={false} style = {CreateNewBranchButton} customeWidht = "225px" />
          }
        </BranchesHeader>
        <BranchListMainCotainer>
          {this.joinedbranchListData()}
          
        </BranchListMainCotainer>
      </Box>
    )
  };

  branchList = () => {
    return (
      this.state.branchList.map((item: BranchListResponseData) => {
        return (
          <BranchMainBox>
            <BranchInnerBox>
              <Typography style={webStyles.branchNameTitleText}>
                {item.branch.data.attributes.branch.branch_name.slice(0, 10)}
              </Typography>
              <Typography style={webStyles.branchNameTotalUserText}>
                {configJSON.totalUsersText} {item.total_users}
              </Typography>
              <BranchInnerBoxButtonMainBox>
                
                <CustomeButton style={ManageButton} text = "Manage" data-test-id='manage-button-id' primary={false}  onclicked={() => this.handleManageTouch(item)} />
                
                {this.state.qrCodeImage && this.modalQR()}
              </BranchInnerBoxButtonMainBox>
            </BranchInnerBox>
          </BranchMainBox>
        )
      })
    )
  };

  joinedbranchListData = () => {
    return (
      this.state.joinedBranchList?.map((item: any) => {
          return (
            <BranchMainBox>
            <BranchInnerBox>
              <Typography style={webStyles.branchNameTitleText}>
                {item[0]?.name.slice(0, 10)}
              </Typography>
              <Typography style={webStyles.branchNameTotalUserText}>
                {configJSON.totalUsersText} {item[2]?.total_users}
              </Typography>
              <BranchInnerBoxButtonMainBox>
  

                <CustomeButton style={ManageButton} text = "Manage" data-test-id='manage-button-id' primary={false}  onclicked={() => this.handleManageClick(item[0])} />
                {this.state.qrCodeImage && this.modalQR()}
              </BranchInnerBoxButtonMainBox>
            </BranchInnerBox>
          </BranchMainBox>
          )
        })
    )
  };

  modalQR = () => {
    return (
      <ModalQRmodal
        disableAutoFocus
        disableEnforceFocus
        open={this.state.qrCodeImage} >
        <ModalQRInnerBox>
          <ModalQRHeaderBox>
            <Typography></Typography>
            <BranchNameModalTextStyleModal>
              {configJSON.branchQRTitleText} - {this.state.itemForImg.branch.data.attributes.branch.branch_name}
            </BranchNameModalTextStyleModal>
            <Button data-test-id='qr-close-button-id' onClick={this.handleModalStateClose}>
              <CloseIcon style={webStyles.closeIconStyle} />
            </Button>
          </ModalQRHeaderBox>
          <QrImage
            image={this.state.itemForImg.branch.data.attributes.qr_code_url} />
          <DownloadPDFButton data-test-id="handlePDF" onClick={()=>this.handleDownloadPDF(this.state.itemForImg.branch.data.attributes.qr_pdf_url)}>
            <a style={webStyles.hyperlinkStyle}
              href={this.state.itemForImg.branch.data.attributes.qr_pdf_url} 
              download="QR.pdf">
              {configJSON.downloadPDFText}
            </a>
          </DownloadPDFButton>
        </ModalQRInnerBox>
      </ModalQRmodal>
    )
  }

  renderLoader=()=>{
    if(this.state.loading)
    {
      return <SpinnerLoader/>
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainContainer>
        {this.branchBox()}
      </MainContainer>
    )
    // Customizable Area End
  };
}

// Customizable Area Start
const webStyles = {
  branchBoxTitleText: {
    fontFamily: "Inter",
    fontSize: "1em",
    fontWeight: 700,
    // lineHeight: "22px",
    letterSpacing: "-0.408px",
    textAign: "left",
    color: "#fff",
    '@media (max-width: 1000px)': {
      color: "green",
    },
  },

  branchNameTitleText: {
    fontFamily: "Inter",
    fontSize: "1.4em",
    fontWeight: 600,
    // lineHeight: "32px",
    letterSpacing: "-0.408px",
    color: "#fff",
    '@media (max-width: 1200px)': {
      // fontSize: "22px",
      // fontWeight: 200,
    },
  },

  branchNameTotalUserText: {
    fontFamily: "Inter",
    fontSize: "1.2em",
    fontWeight: 500,
    // lineHeight: "32px",
    letterSpacing: "-0.408px",
    color: "#fff",
    '@media (max-width: 1200px)': {
      // fontSize: "22px",
      // fontWeight: 200,
    },
  },

  closeIconStyle: {
    color: "white"
  },

  hyperlinkStyle: {
    textDecoration: "none",
    color: "white"
  }

}
// Customizable Area End