import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CustomeButton from "../../../components/src/CustomeButton.web";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
const SubmitButton = styled(Button)({
  display: "flex",
  width: "225px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  border: "1px solid #fff",
  borderRadius: "70px",
  fontSize: "1em",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "capitalize",
  marginTop:'15px',
  "&:hover": {
    border: "1px solid #F4CD46",
    color: "#000",
    backgroundColor:'#F4CD46'
  }
});
const submitButton = {
  display: "flex",
  width: "225px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  // border: "1px solid #fff",
  borderRadius: "70px",
  fontSize: "1em",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "capitalize",
  marginTop:'15px',
}

const StyledButton ={
  display: "flex", width: "180px",
  height: "56px", padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "transparent",
  borderRadius: "50px",
  fontSize: "1rem",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "capitalize",
  
};

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    },
    overrides: {
        MuiDialog: {
            'paperWidthSm': {
                maxWidth: '694px',
                background: '#212226',
                minHeight: '220px'
            },
        },
        MuiBackdrop: {
            'root': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }
        }
    }
});
// Customizable Area End

import DeactivateAccountController, {
  Props,
} from "./DeactivateAccountController";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
import UserContext from "../../../components/src/UserProvider";

export default class DeactivateAccount extends DeactivateAccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    DeactivateAccountController.contextType = UserContext
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {
          this.state.isSubscriber && (
            <>
            <Container maxWidth="xl">
            <Container maxWidth="lg" style={{fontSize: 'clamp(10px, 16px, 2vw)'}}>
            <Box style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '1rem' }}>
              <ArrowBackIcon onClick={this.goBack} style={{ color: '#fff', cursor: 'pointer', fontSize: '2em' }} data-test-id="arrow-back-icon-btn" />
              <Typography style={{...webStyle.headingStyleCreateBtn}}>Deactivate Account</Typography>
            </Box>
               {
                this.state.isDeactivated ?
                <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '1rem' }}>
                  <div></div>
                  <div>
                  <Container maxWidth="lg" >
                   <div>
                  <p style={webStyle.strongdescription}>Your account is pending deactivation</p>
                </div>
                <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} md={12}>
                  <p style={{...webStyle.lightdescription, marginTop:'20px'}}>Your account will be closed 15 days after your last active subscription ends. Any personal data will be processed in line with our GDPR policy.</p>
                </Grid>
                </Grid>
                </div>
                <Box style={{display:'flex' , alignItems:'center', gap:'10px',marginTop:'35px'}}>
                  <SubmitButton onClick={this.onLogout}>Okay</SubmitButton>
                </Box>
                </Container>
                  </div>
                </div>
               
                :
                <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '1rem' }}>
                  <div></div>
                  <div>
                  <Container maxWidth="lg">
                <div>
                  <p style={webStyle.strongdescription}>You will no longer be charged. All your active subscriptions will stop at the end of your current billing period. This action cannot be undone</p>
                </div>
                <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={11} md={11}>
                  <p style={{...webStyle.lightdescription, marginTop:'20px'}}>Your account will be closed 15 days after your last active subscription ends. Any personal data will be processed in line with our GDPR policy.</p>
                </Grid>
                </Grid>
                </div>
                <Box style={{display:'flex' , alignItems:'center', gap:'15px', marginTop:'35px', flexWrap:'wrap'}}>
      
                  <CustomeButton onclicked = {this.goToLandingPage} text="Keep Account" style={submitButton} primary={false}/>
                  <CustomeButton onclicked = {()=>{this.openCancelSubscriptionDialog()}} text="Deactivate Account" style={submitButton} primary={true}/>
    
                </Box>
                </Container>
                  </div>
                </div>
               }
               
            </Container>

            <Dialog open={this.state.isCancel} aria-labelledby="form-dialog-title">
            {this.state.loading && <SpinnerLoader/>}
                    <Box style={{ padding: '35px' }}>
                        <DialogTitle style={{padding: 0}}>
                            <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
                                 <ErrorOutlineIcon style={{ color: 'red', marginRight: '10px', fontSize: '1em' }} />
                                
                                 Deactivate Account
                            </Typography>
                        </DialogTitle>
                        <DialogContent style={{padding: 0}}>
                            <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                            <div>
                              <p style={webStyle.dialoglightdescription}>We’re Sorry to See You Go </p>
                              <p style={webStyle.strongdescription}>You will no longer be charged. All your active subscriptions will stop at the end of your current billing period. This action cannot be undone</p>
                            </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center', gap: '24px', marginBottom: '12px', flexWrap: 'wrap'}}>
                          <CustomeButton style={StyledButton} onclicked={this.closeCancelSubscriptionDialog} text="Cancel" primary={false}/>
                            
                          <CustomeButton style={StyledButton} onclicked={()=>this.deactivateAccountApi()} text="Confirm" primary={true}/>
                              
                        </DialogActions>
                    </Box>
                </Dialog>
        </Container>
            </>
          )
        }


        {
          !this.state.isSubscriber &&
          (<>
            <Container maxWidth="xl">
              <Container maxWidth="lg" style={{fontSize: 'clamp(10px, 16px, 2vw)'}}>
                <Box style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '1rem' }}>
                  <ArrowBackIcon onClick={this.goBack} style={{ color: '#fff', cursor: 'pointer', fontSize: '2em' }} data-test-id="arrow-back-icon-btn" />
                  <Typography style={{ ...webStyle.headingStyleCreateBtn }}>Deactivate Account</Typography>
                </Box>
                <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '1rem' }}>
                  <div></div>
                  <div>
                    <Container maxWidth="lg">
                      <div>
                        <p style={webStyle.strongdescription}>We’re sorry to see you go
                        </p>
                      </div>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={11} md={11}>
                            <p style={{ ...webStyle.lightdescription, marginTop: '20px', fontWeight: 500 }}>Are you sure you want to deactivate your account? This will remove your access permanently. This action can't be undone.</p>
                          </Grid>
                        </Grid>
                      </div>
                      <Box style={{ display: 'flex', alignItems: 'center', gap: '15px', marginTop: '35px', flexWrap: 'wrap' }}>

                        <CustomeButton onclicked={this.goToLandingPage} text="Keep Account" style={submitButton} primary={false} />
                        <CustomeButton onclicked={this.openCancelSubscriptionDialog} text="Deactivate Account" style={submitButton} primary={true} />

                      </Box>
                    </Container>
                  </div>
                </div>


              </Container>

              <Dialog aria-labelledby="form-dialog-title" open={this.state.isCancel} >
                {this.state.loading && <SpinnerLoader />}
                <Box style={{ padding: '35px' }}>
                  <DialogTitle>
                    <Typography style={webStyle.dialogTitleStyle} id="form-dialog-title" >
                      <ErrorOutlineIcon style={{ color: 'red', fontSize: 'clamp(22px, 32px, 5vw)', marginRight: '10px' }} />
                      Deactivate Account
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                      <div>
                        <p style={webStyle.dialoglightdescription}>We’re Sorry to See You Go </p>
                        <p style={{ ...webStyle.strongdescription, fontWeight: 500 }}>Are you sure you want to deactivate your account? This Can not be undone.</p>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: 'center', gap: '24px', marginBottom: '12px' }}>
                    <CustomeButton onclicked={this.closeCancelSubscriptionDialog} style={StyledButton} text="Cancel" primary={false} />

                    <CustomeButton onclicked={this.deactivateAccountForNonBusinessUser} id="deactive" style={StyledButton} text="Confirm" primary={true} />

                  </DialogActions>
                </Box>
              </Dialog>

              <Dialog open={this.state.confirmed} aria-labelledby="form-dialog-title">
                <Typography id="form-dialog-title" style={{ ...webStyle.dialogTitleStyle, paddingBottom: '40px', paddingTop: '50px' }}>
                  <CheckCircleOutlineIcon style={{ color: '#008000', marginRight: '10px', fontSize: 'clamp(22px, 32px, 5vw)' }} />
                  Deactivated Successfully
                </Typography>
                <DialogContent style={{ paddingBottom: '90px', paddingRight: '30x', paddingLeft: '30px' }}>
                  <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300, whiteSpace: 'pre-wrap', width: '580px' }}>
                    Your accounts has been deactivated successfully.
                  </DialogContentText>
                </DialogContent>

              </Dialog>
            </Container>

          </>)
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  strongdescription:{
    fontWeight:700,
    color:'white' as 'white',
    fontSize:'1em',
  },
  lightdescription:{
    fontWeight:300,
    color:'white' as 'white',
    fontSize:'1.25em'
  },
  dialoglightdescription:{
    fontWeight:300,
    color:'white' as 'white',
    fontSize:'1em'
  },
  headingStyleCreateBtn: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '2em',
    fontWeight: 600,
    letterSpacing: '-0.72px',
    position: 'relative' as 'relative',
  },
  dialogTitleStyle: {
    fontSize: 'clamp(22px, 32px, 5vw)',
    fontWeight: 600 as 600,
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.408px',
    color: '#fff',
    textAlign: 'center' as 'center',
    // padding: '5px 10px',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center'
},
dialogContentStyle: {
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    fontSize: 'clamp(16px, 24px, 4vw)',
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center'
},
};
// Customizable Area End
