import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IRefundPolicy {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAdminUser: boolean;
  accountId: number;
  isPrivacyPolicyAccepted: boolean;
  isLoading: boolean;
  title: string,
  loading:boolean,
  desription: string,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class RefundPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRefundPolicyID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      isAdminUser: true,
      isPrivacyPolicyAccepted: false,
      isLoading: false,
      title: "",
      loading:false,
      desription: ''

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getRefundPolicy()
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
  

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRefundPolicyID.length > 0 &&
      apiRequestCallId === this.getRefundPolicyID
    ) {
      console.log(responseJson[0].description)
      this.setState({
        desription: responseJson[0].description, loading:false
      })
      this.setState({loading:false})
    }
    // Customizable Area End
  }

  getRefundPolicy = async () => {
    let id = 1
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    this.setState({loading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRefundPolicyID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_refund_policy/refund_policy_index'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoading: true });
  };

  formatRefundDescriptionList() {
  const lines = this?.state?.desription?.split('\n');
  let htmlContent = '';
  let listCounter = 1; // Initialize counter for manual numbering
  let isListOpen = false;
  let listType = ''; // Track the current list type ('ol' for ordered, 'ul' for unordered)

  const openList = (type:any) => {
    if (!isListOpen || listType !== type) {
      closeList(); // Close the previous list if of a different type
      htmlContent += `<${type}>`;
      isListOpen = true;
      listType = type;
      listCounter = 1; // Reset counter when a new list starts
    }
  };

  const closeList = () => {
    if (isListOpen) {
      htmlContent += `</${listType}>`;
      isListOpen = false;
      listType = '';
    }
  };

  lines?.forEach((line) => {
    const trimmedLine = line.trim();
    if (trimmedLine) {
      if (trimmedLine.match(/^\d+\./)) { // Ordered list item
        openList('ol');
        // Manually insert the number if you want to control numbering directly
        htmlContent += `<li>${listCounter}. ${trimmedLine.substring(trimmedLine.indexOf(' ') + 1)}</li>`;
        listCounter++; // Increment counter for next item
      } else if (trimmedLine.match(/^•/)) { // Unordered list item, but with manual numbering
        openList('ul');
        // Insert manual numbering before the bullet point's text
        htmlContent += `<li>${listCounter}. ${trimmedLine.substring(1).trim()}</li>`;
        listCounter++; // Increment counter for next item
      } else {
        closeList(); // Close any open list before adding non-list content
        if (trimmedLine.startsWith('Refund Policy')) {
          htmlContent += `<h1>${trimmedLine}</h1>`;
        } else if (trimmedLine.startsWith('Standard Refund Policy') || trimmedLine.startsWith('Defective or Damaged Items') || trimmedLine.startsWith('No-Return or Final Sale Items') || trimmedLine.startsWith('Return Shipping Costs') || trimmedLine.startsWith('Refund Processing Time') || trimmedLine.startsWith('Electronic and Digital Products')) {
          htmlContent += `<h2>${trimmedLine}</h2>`;
        } else {
          htmlContent += `<p>${trimmedLine}</p>`;
        }
      }
    }
  });

  // Close any open list at the end
  closeList();

  return htmlContent;
  }

  goBcak=()=>{
    history.back()
  }
  
  

  // Customizable Area End

}
