import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import "./customisableUserProfile.css"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CustomeButton from "../../../components/src/CustomeButton.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiDialog: {
      'paperWidthSm': {
        maxWidth: '694px',
        padding: '50px 0',
        background: '#212226'
      },
    },
    MuiBackdrop: {
      'root': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }
    },
    MuiTableContainer: {
      'root': {
        scrollbarWidth: 'none'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        fontFamily: 'inter',
        padding: '8px',
        backgroundColor: '#212226',
        opacity: 1,
        maxWidth: 300,
      },
      arrow: {
        color: '#212226',
      }
    },
  }
});

const StyledButton = styled(Button)({
  width: '120px',
    height: '34.405px',
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '0.8em',
    fontWeight: 500,
    letterSpacing: '-0.408px',
    backgroundColor: '#393939',
    borderRadius: '5px',
    textTransform: 'capitalize' as 'capitalize',
    position: 'absolute' as const,
    top: '-5px',
    left: '34px',
    padding: '0',
    '@media (max-width: 450px)': {
      width: "100px",
      height: '32px'
    },
    '@media (max-width: 950px)': {
      left: 'unset',
      right: '34px'
    },
})

// Customizable Area End

import SubscribersListController, {
  Props, configJSON,
} from "./SubscribersListController";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";

export default class SubscribersList extends SubscribersListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.loading && <SpinnerLoader />}
        <Container id="subscribers-list-container-main" style={{ marginBottom: '150px', maxWidth: '100%', padding: '0 6%', fontSize: 'clamp(10px, 16px, 2vw)' }}>
          {!this.state.openSubscriberDetail &&
            <>
              <Box style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: 'clamp(15px, 30px, 4vw)' }}>
                <ArrowBackIcon onClick={this.toTheLandingPage} style={webStyle.arrowBackIconStyle} data-test-id="arrow-back-icon-btn" />
                <Typography style={webStyle.headingStyle}>{configJSON.subscriberText}</Typography>
              </Box>
              <Box className="subscriber-list">
                <TableContainer style={{ display: 'flex', justifyContent: 'center', maxHeight: '100%' }}>
                  <Table stickyHeader size="small" aria-label="sticky table" className="subscribers-table" style={{ fontSize: 'inherit', width: '80%' }}>
                    <TableHead className="subscribers-table-head" style={{ borderRadius: '3px' }}>
                      <TableRow>
                        <TableCell className="table-cell" style={webStyle.tableHeadCellStyle}>{configJSON.subscriberText}</TableCell>
                        <TableCell className="table-cell" style={webStyle.tableHeadCellStyle}>{configJSON.planText}</TableCell>
                        <TableCell className="table-cell" style={webStyle.tableHeadCellStyle}>{configJSON.statusText}</TableCell>
                        <TableCell className="table-cell" style={webStyle.tableHeadCellStyle}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="subscribers-table-body">
                      {this.state.subscribersData.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={4} className="table-cell" style={{ textAlign: 'center', color: 'white' }}>
                            {configJSON.noSubscriberFoundText}
                          </TableCell>
                        </TableRow>
                      ) : (
                        this.state.subscribersData.map((row: any, index: number) => {
                          const { account_details, subscription, user_subscription_details } = row;
                          return (
                            <TableRow key={user_subscription_details.id}>
                              <TableCell className="table-cell" scope="row" style={webStyle.tableBodyCellStyle}>
                                {account_details.subscriber_name}
                              </TableCell>
                              <TableCell className="table-cell" style={webStyle.tableBodyCellStyle}>{subscription.name}</TableCell>
                              <TableCell className="table-cell" style={webStyle.tableBodyCellStyle}>{(user_subscription_details.is_paused || user_subscription_details.is_cancle) ? "Inactive" : "Active"}</TableCell>
                              <TableCell className="table-cell view-details-icon-row" style={{ ...webStyle.tableBodyCellStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="moreVertIconBox" style={{ width: '30px', position: "relative" }}>
                                  <MoreVertIcon />
                                  <StyledButton
                                    className="view-subscriber-details-btn"
                                    onClick={() => this.handleViewSubscriberDetails(row)}
                                    id="viewBtn"
                                  >
                                    View Details
                                  </StyledButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          }
          {this.state.openSubscriberDetail &&
            <>
              <Box style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: 'clamp(15px, 48px, 4vw)' }}>
                <ArrowBackIcon onClick={this.handleBackToSubscribersList} style={webStyle.arrowBackIconStyle} data-test-id="arrow-back-icon-btn" />
                <Typography style={webStyle.headingStyle}>{configJSON.subscriberDetailsText}</Typography>
              </Box>
              <Box className="subscriber-details-container-main">
                <Box className="subscriber-profile-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>
                  <Box className='subscriber-profile-photo' style={{ width: 'clamp(80px, 140px, 10vw)', height: 'clamp(80px, 140px, 10vw)', borderRadius: '50%', overflow: 'hidden' }}>
                    {this.state.subscriberProfileDetails?.account_details.photo ?
                      <img src={this.state.subscriberProfileDetails?.account_details.photo} alt="" style={{ width: '100%', height: '100%', }} /> :
                      <AccountCircleIcon style={{ width: '100%', height: '100%', color: '#fff' }} />
                    }
                  </Box>
                  <Box className='subscriber-profile-name'>
                    <Typography style={{ color: '#fff', fontSize: '2.5em', fontWeight: 600, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px', textTransform: 'capitalize' }}>{this.state.subscriberProfileDetails?.account_details.subscriber_name}</Typography>
                  </Box>
                  <Box className='subscriber-profile-type'>
                    <Typography style={{ color: '#fff', fontSize: '2em', fontWeight: 600, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px', textTransform: 'capitalize' }}>{this.state.subscriberProfileDetails?.subscription.name}</Typography>
                  </Box>
                  <Box className='subscriber-profile-plan'>
                    <Typography style={{ color: '#fff', fontSize: '3.2em', fontWeight: 700, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px' }}>{`£${this.state.subscriberProfileDetails?.subscription.price}/`}<span style={{ fontSize: '0.8em', fontWeight: 200, color: '#fff' }}>month</span></Typography>
                  </Box>
                </Box>
                <Box className="subscriber-description-container" sx={webStyle.subscriberDescriptionContainerStyle}>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>{configJSON.subscriptionDetailsText}</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>
                      {`${this.state.subscriberProfileDetails?.subscription.number_of_units} ${this.state.subscriberProfileDetails?.subscription.name_of_units} per ${this.state.subscriberProfileDetails?.subscription.per}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>{configJSON.durationText}</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>
                      {this.state.subscriberProfileDetails?.subscription.duration == 0 ?
                        'Unlimited' :
                        `${this.state.subscriberProfileDetails?.subscription.duration} months`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>{configJSON.pausesAllowedText}</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>{this.state.subscriberProfileDetails?.user_subscription_details.pause_remaining}</Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>{configJSON.validBranchesText}</Typography>
                    <Tooltip title={this.getBranchNameList(this.state.subscriberProfileDetails?.subscription.branch_name)}
                      data-test-id="branch-name-tooltip"
                      arrow
                      PopperProps={{
                        popperOptions: {
                          modifiers: {
                            offset: {
                              offset: '20, -15',
                            },
                          },
                        },
                      }}
                      disableFocusListener
                    >
                      <Typography style={{ ...webStyle.descHeadingValueStyle, cursor: 'pointer' }} data-test-id="valid-branch-data">
                        {this.state.subscriberProfileDetails?.subscription.valid_branches}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>{configJSON.purchaseDateText}</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>{this.formatDate(this.state.subscriberProfileDetails?.user_subscription_details.created_at)}</Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>{configJSON.nextBillingText}</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>{this.formatDate(this.state.subscriberProfileDetails?.user_subscription_details.next_billing)}</Typography>
                  </Box>
                  <Box>
                    <Typography style={webStyle.descHeadingStyle}>{configJSON.unitsRemainingForPeriodText}</Typography>
                    <Typography style={webStyle.descHeadingValueStyle}>{this.state.subscriberProfileDetails?.user_subscription_details.unites_remaining}</Typography>
                  </Box>
                </Box>
                <Box className="subscriber-transaction-history-container" sx={webStyle.transactionHistoryContainerStyle}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography style={{ color: '#fff', fontSize: '1.7em', fontWeight: 600, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.72px' }}
                    >
                      {configJSON.transactionHistoryText}
                    </Typography>
                    {this.state.expandTransactionHistory ?
                      <ArrowDropUpIcon style={{ color: '#fff', fontSize: '2.2em', cursor: 'pointer', }} onClick={this.handleTranscationHistoryExpand} data-test-id="arrow-drop-up-icon" /> :
                      <ArrowDropDownIcon style={{ color: '#fff', fontSize: '2.2em', cursor: 'pointer', }} onClick={this.handleTranscationHistoryExpand} data-test-id="arrow-drop-down-icon" />}
                  </Box>
                  <Box className="transaction-history-table-box">
                    <TableContainer style={{ display: 'flex', justifyContent: 'center', maxHeight: '450px' }}>
                      <Table stickyHeader size="small" aria-label="sticky table" className="transaction-history-table" style={{ fontSize: 'inherit' }}>
                        <TableHead className="transaction-history-table-head" style={{ borderRadius: '3px' }}>
                          <TableRow>
                            <TableCell className="table-cell" style={webStyle.tableHeadCellStyle}>{configJSON.transactionText}</TableCell >
                            <TableCell className="table-cell" style={webStyle.tableHeadCellStyle}>{configJSON.dateText}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="transaction-history-table-body">
                          {this.state.transactionHis.map((row: any, index: number) => {
                            if (!this.state.expandTransactionHistory && index >= 3) {
                              return null;
                            }
                            return (
                              <TableRow key={index}>
                                <TableCell className="table-cell" scope="row" style={webStyle.tableBodyCellStyle}>
                                  {`${row.transaction_type}`}
                                </TableCell>
                                <TableCell className="table-cell" style={webStyle.tableBodyCellStyle}>{this.convertDateFormat(row.date)}</TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
                <Box className="subscriber-buttons-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', gap: '20px', flexWrap: 'wrap' }}>

                  {this.butttonRender(this.state.subscriberProfileDetails)}

                </Box>
              </Box>
            </>
          }
          <Dialog open={this.state.openCancelSubscriptionDialog} aria-labelledby="form-dialog-title">
            <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
              <ErrorOutlineIcon style={{ color: 'red', marginRight: '10px', fontSize: '32px' }} />
           {configJSON.cancelSubscriptionText}
            </Typography>
            <DialogContent>
              <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                {configJSON.dialogContentText1} {this.formatDate(this.state.subscriberProfileDetails?.user_subscription_details.next_billing)}. {configJSON.dialogContentText2}
              </DialogContentText>
              <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 500 }}>
                {configJSON.dialogContentText3}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', gap: '24px', flexWrap: 'wrap' }}>

              <CustomeButton text="Cancel" onclicked={this.handleCancelDialogCancelBtn} data-test-id="cancel-dialog-cancel-btn" primary={false} style={{ ...webStyle.dialogButtonStyle, color: '#fff' }} />

              <CustomeButton text="Confirm" onclicked={this.handleCancelDialogConfirmBtn} data-test-id="cancel-dialog-confirm-btn" primary={true} style={{ ...webStyle.dialogButtonStyle, color: '#fff' }} />


            </DialogActions>
          </Dialog>

          <Dialog open={this.state.openPauseSubscriptionDialog} aria-labelledby="form-dialog-title">
            <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
              <ErrorOutlineIcon style={{ color: 'red', marginRight: '10px', fontSize: '32px' }} />
              {configJSON.pauseSubscriptionText}
            </Typography>
            <DialogContent>
              <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                {configJSON.dialogContentText4}{this.formatDate(this.state.subscriberProfileDetails?.user_subscription_details.next_billing)} {configJSON.dialogContentText5}
              </DialogContentText>
              <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 500 }}>
                {configJSON.dialogContentText6}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', gap: '24px', flexWrap: 'wrap' }}>

              <CustomeButton text="Cancel" onclicked={this.handlePauseDialogCancelBtn} data-test-id="pause-dialog-cancel-btn" primary={false} style={{ ...webStyle.dialogButtonStyle, color: '#fff' }} />

              <CustomeButton text="Confirm" onclicked={this.handlePauseDialogConfirmBtn} data-test-id="pause-dialog-confirm-btn" primary={true} style={{ ...webStyle.dialogButtonStyle, color: '#fff' }} />


            </DialogActions>
          </Dialog>

          <Dialog open={this.state.isSubscriptionPaused} aria-labelledby="form-dialog-title">
            <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
              <CheckCircleOutlineIcon style={{ color: '#008000', marginRight: '10px', fontSize: '32px' }} />
             {configJSON.pausedSuccessfullyText}
            </Typography>
            <DialogContent>
              <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
               {configJSON.dialogContentText7} {this.formatDate(this.state.subscriberProfileDetails?.user_subscription_details.next_billing)}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', gap: '24px' }}>
              <Button onClick={this.handleConfirmationDialogCloseBtn} color="primary" style={{ ...webStyle.dialogButtonStyle, background: '#F4CD46' }} data-test-id="pause-confirm-dialog-close-btn">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.errorDialog} aria-labelledby="form-dialog-title">
            <Typography id="form-dialog-title" style={{ ...webStyle.dialogTitleStyle, fontSize: "25px", marginTop: '30px', marginLeft: '50px', marginRight: '50px' }}>
              <ErrorOutlineIcon style={{ color: 'red', marginRight: '10px', fontSize: '32px' }} />
             {configJSON.noPausesAllowedRemainingText}
            </Typography>
            <DialogContent>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', gap: '24px', marginTop: '30px' }}>
              <CustomeButton data-test-id="pause-confirm-dialog-close-btn" text="Close" primary={true} onclicked={this.handleConfirmationDialogCloseBtn} style={{ ...webStyle.dialogButtonStyle, color: 'white' }} />
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.isSubscriptionCanceled} aria-labelledby="form-dialog-title">
            <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
              <CheckCircleOutlineIcon style={{ color: '#008000', marginRight: '10px', fontSize: '32px' }} />
            {configJSON.cancelledSuccessfullyText}
            </Typography>
            <DialogContent>
              <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                {configJSON.dialogContentText8}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', gap: '24px' }}>
              <Button onClick={this.handleConfirmationDialogCloseBtn} color="primary" style={{ ...webStyle.dialogButtonStyle, background: '#F4CD46' }} data-test-id="cancel-confirm-dialog-close-btn">
               {configJSON.dialogActionText}
              </Button>
            </DialogActions>
          </Dialog>

        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  headingStyle: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '2em',
    fontWeight: 600,
    letterSpacing: '-0.408px',
  },
  arrowBackIconStyle: {
    color: '#FFF',
    cursor: 'pointer',
    fontSize: '2.2em'
  },
  descHeadingStyle: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.5em',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
    textTransform: 'capitalize' as 'capitalize',
  },
  descHeadingValueStyle: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.5em',
    fontWeight: 200,
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center'
  },
  subscriberDescriptionContainerStyle: {
    display: 'flex',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItem: 'center',
    gap: '2rem 4rem',
    flexWrap: 'wrap' as 'wrap',
    padding: { md: "20px 8%", lg: "20px 14%", xl: "20px 18%", },
    marginBottom: '15px'
  },
  transactionHistoryContainerStyle: {
    display: 'flex',
    backgroundColor: 'transparent',
    gap: '2rem',
    padding: { md: "20px 8%", lg: "20px 14%", xl: "20px 20%", },
    flexDirection: 'column' as 'column'
  },
  buttonCommonStyle: {
    width: '269px',
    height: '56px',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    textTransform: 'capitalize' as 'capitalize',
    color: '#fff',
    background: '#000'
  },
  dialogTitleStyle: {
    fontSize: 'clamp(22px, 32px, 5vw)',
    fontWeight: 600 as 600,
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.408px',
    color: '#fff',
    textAlign: 'center' as 'center',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center'
  },
  dialogContentStyle: {
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    fontSize: 'clamp(16px, 24px, 4vw)',
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center'
  },
  dialogButtonStyle: {
    width: '180px',
    height: '56px',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    color: '#000',
    textTransform: 'capitalize' as 'capitalize'
  },
  tableHeadCellStyle: {
    color: "#FFF",
    fontFamily: "Inter, sans-serif",
    fontSize: "1em",
    fontWeight: 500,
    letterSpacing: "-0.408px",
    borderBottom: 0,
    textAlign: "center" as const,
    padding: "13px 24px",
    background: "#212226",
    textTransform: "capitalize" as const,
  },
  tableBodyCellStyle: {
    color: "#FFF",
    fontFamily: "Inter, sans-serif",
    fontSize: "1.2em",
    fontWeight: 200,
    letterSpacing: "-0.408px",
    border: 0,
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
  }
};
// Customizable Area End
