import React from "react"
import { Container, Box, Button, Typography } from "@material-ui/core"
import {
    createStyles,
    withStyles,
    Theme,
  } from "@material-ui/core/styles";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
export const configJSON = require("./config");
import ForgotPasswordController, {
    Props,
  } from "./ForgotPasswordController";

  export const Styles = (theme: Theme) =>
    createStyles({ 
      checkEmailTitle: {
        fontSize: 'clamp(24px, 3vw, 32px)', 
        fontWeight: 700,
        marginTop: '24px',
        marginBottom: '90px',
        '@media (max-width: 601px)': {
          marginBottom: '14px',
        }
      },
      subTitle: {
        fontSize: 'clamp(16px, 3vw, 20px)', 
        marginBottom: '95px', 
        letterSpacing: '-0.41px',
        '@media (max-width: 601px)': {
          marginBottom: '54px',
        }
      },
      resetValidationPass : {
        background: '#F4CD46' as any,
        '&:hover': {
          backgroundColor: '#F4CD46',
      }
      },
      resetdefaultBg : {
        background: '#FFFFFF' as any,
        '&:hover': {
          backgroundColor: '#FFFFFF',
      }
      },
      confirmPasswordText: {
        margin: "95px 0 136px 0",
        fontSize: "clamp(16px, 3vw, 20px)",
        '@media (max-width: 601px)': {
          margin: "20px 0 40px 0",
        }
      },
      confirmPasswordBtn: {
          width: "225px",
          fontStyle: "normal",
          lineHeight: "22px",
          height: "56px",
          color: "#111",
          letterSpacing: "-0.408px",
          borderRadius: "53px",
          textAlign: "center",
          fontSize: "16px",
          textTransform: "capitalize",
          "&:hover": {
            background: "#F4CD46"
          },
          '@media (max-width: 601px)': {
            width: '100%',
            background: '#000000',
            color: '#FFF',
            border: '1px solid #FFF',
            "&:hover": {
              background: '#000000',
              border: '1px solid #F4CD46',
            },
          }
      },
      receiveEmailText: {
        fontSize: 'clamp(14px, 3vw, 16px)',
        marginTop: '20px',
        whiteSpace: 'nowrap',
        '@media (max-width: 601px)': {
          display: 'flex',
          justifyContent: 'center'
        }
      }
    })

export class ForgotPasswordReset extends ForgotPasswordController {
    constructor(props: Props){
        super(props)
    }
    render(){
        const { classes } = this.props;
        return (
          <>
          
          {this.state.loading && <SpinnerLoader/>}
           <Container maxWidth="md" style={{
              backgroundColor: 'black',
              maxWidth: "854px"
            }}>
              
            {localStorage.getItem("User_Reset") == "true"  
              ? 
                <Container style={{ 
                  color: "white",
                  backgroundColor: "black",
                  maxWidth: '100vw',
                  maxHeight: '100vh',
                  padding: '0 8px',
                  display:'flex',
                  justifyContent:'center'
                }} maxWidth="md">
                  <Box>
                    <Typography 
                      data-test-id={"ResendbButton"}
                      className = {classes.checkEmailTitle}
                    >
                      Check your email
                    </Typography>
                    <Typography className = {classes.subTitle}>
                      A password reset link has been sent to  
                      <span style={{textDecoration: "underline", color: "white" }}>
                        {` ${localStorage.getItem("User_Email1")}`}
                      </span> 
                      . Please follow the instructions within the email to continue. 
                    </Typography>
                    <Button
                      onClick={ this.handleResendButton }
                      data-test-id={"forgotRestTestID"}
                      className = {`${classes.confirmPasswordBtn} ${!this.state.forgotpasswordFieldSatisfied ? classes.resetValidationPass : classes.resetdefaultBg}` }
                    >
                      Resend
                    </Button>
                      <div className = {classes.receiveEmailText}>
                        Didn’t receive email? 
                        <span style={{textDecoration: "underline", fontWeight: "bold"}} onClick={this.handleTryEmail}>
                            Try another email address
                          </span>
                        </div>
                  </Box>
                </Container> 
              :
                <div style={{ color : 'white' }}> 
                  <h2 className={classes.checkEmailTitle}>Password Successfully Reset</h2>
                  <div className = {classes.confirmPasswordText}>Congratulations! Your password has been successfully reset</div>
                  <div>
                    <Button 
                      onClick={ this.toLandingPage }
                      className = {`${classes.confirmPasswordBtn} ${!this.state.forgotpasswordFieldSatisfied ? classes.resetValidationPass : classes.resetdefaultBg}` }
                    >
                      Go To Homepage
                    </Button>
                  </div>
                </div>
            }
            </Container>
            </>
        )
    }
}
export default withStyles(Styles, { withTheme: true }) (ForgotPasswordReset)