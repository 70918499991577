import React from "react";
import { Link } from "react-router-dom"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { profile, logoutImage } from "./assets";
import { BlockComponent } from "framework/src/BlockComponent";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { getStorageData } from "../../framework/src/Utilities";
import { Button } from "@material-ui/core";
export interface Props {
  navigation: any;
  id: string;
  onLogout: any,
  userProfilePhoto: any
  userName: string
  email: string
  handleChangePassword: any
}

export interface S {
  // Customizable Area Start
  isActive: boolean;
  isOpen: boolean;
  items: { name: string; to: string; onClick?: any }[];
  isSubscriber: boolean,
  isJoinedMember: boolean,

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

class Dropdown extends BlockComponent<
  Props,
  S,
  SS
> {
  dropdownRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      isActive: false,
      isOpen: false,
      items: [
        { name: "Membership", to: "/Membership" },
        { name: "Change Password", to: "", onClick: this.props.handleChangePassword },
        { name: "Deactivate Account", to: "/DeactivateAccount" },
        { name: "Terms and Conditions", to: "/TermsConditions" },
        { name: "Privacy Policy", to: "/PrivacyPolicies" },
        { name: "Refund Policy", to: "/RefundPolicy" }
      ],
      isSubscriber: false,
      isJoinedMember: false,
    }
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const isSubscriber = await getStorageData('isSubscriber');
    this.setUserType(isSubscriber);
    const isJoinedMemberPresent = await getStorageData('isBusinessJoined');
    console.log("raka", isJoinedMemberPresent)
    this.stateSetAfterJoinedBusiness(isJoinedMemberPresent) 
  }

  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    let imgElementByAlt = document.querySelector('img[alt="Profile Image"]');

    if (this.dropdownRef.current
      && event.target !== imgElementByAlt
      && !this.dropdownRef.current.contains(event.target as Node)
    ) {
      this.setState({ isActive: false });
    }
  };
  setUserType = (isSubscriber: string) => {
    if (isSubscriber === "true") {
      this.setState({ isSubscriber: true });
    }
  }
  stateSetAfterJoinedBusiness = (joinedMember: any) => {
    this.setState({ isJoinedMember: joinedMember });
  }

  onClick = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  handleLogout = () => {
    const { onLogout } = this.props;
    onLogout();
    this.onClick();
  }

  toggleOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const { isActive } = this.state;
    const { userProfilePhoto, email, userName } = this.props;

    return (
      <div className="container">
        <style>{`
          .menu-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .menu-trigger {
            background: #fff;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
            border: none;
            vertical-align: middle;
            transition: box-shadow 0.4s ease;
            margin-left: auto;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            padding: 0;
          }

          .menu-trigger:hover {
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
          }

          .menu-trigger span {
            font-weight: 700;
            vertical-align: middle;
            font-size: 14px;
            margin: 0 10px;
          }

          .menu-trigger img {
            border-radius: 90px;
          }

          .menu {
            background: rgba(141, 141, 141);
            position: absolute;
            top: 60px;
            right: 0;
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
            opacity: 0;
            visibility: hidden;
            transform: translateY(-20px);
            transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
            height: auto; 
            display: flex; 
            flex-direction: column;
            width: 280px;
            border-radius: 20px;
            z-index: 9999; 
            color: #FFF;
          }

          .menu a {
            text-decoration: none !important;
            color: rgba(255, 255, 255, 0.4);
            font-size: 12px;
            font-weight: 400;
          }

          .menu.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
          .menu.inactive{
            backgroundcolor:'red'
          }

          .menu li p {
            text-decoration: none;
            color: #fff;
            padding: 3px 20px;
            display: block;
          }

          .profileLink {
            min-height: 54px;
          }

          .profile {
            border-bottom: 1px solid black;
            padding: 10px 20px 10px 16px;
            color: #FFF;
            align-content: center;
            align-items: center;
            display: flex;
            justify-content: space-between;
          }

          .profileContent {
            display: flex;
            justify-content: space-between;
          }

          .profileName {
            display: flex; 
            flex-direction: column;
            padding-left: 8px;
            max-width: 188px;
            overflow: hidden;
          }

          .userName {
            letter-spacing: -0.34px, 
            textTransform: capitalize as capitalize,
            lineHeight: 1.2;
            margin: 0;
            color: #fff;
            font-size: 14;
            font-weight: 500;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .userEmail {
            color: rgba(255, 255, 255, 0.4);
            margin: 0;
            font-size: 12;
            font-weight: 400;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .setings {
            display: flex;
            justify-content: space-between;
            font-size: 14;
            font-weight: 500;
            list-style: none;
            padding: 12px 20px 10px 24px;
            margin: 0;
            transition: max-height 0.2s ease-out;
            overflow: hidden;
            max-height: 60px;
            cursor: pointer;
          }

          .setings text {
            pading-left: 12px;
          }
          
          .setingsList.open {
            max-height: 188px;
            margin: -6px 0 16px 18px;
          }

          .setingsHeader {
            color: #FFF;
            display: flex;
            justify-content: space-between;
            align-items: center;     
          }

          .setingsList {
            display: flex;
            flex-direction: column;
            flex-grow: 1; 
            line-height: 21px;
            margin-left: 16px;
            list-style: none;
          }

          .setings a {
            color: rgba(255, 255, 255, 0.4);
            text-decoration: none !important;
            font-size: 12;
            font-weight: 400;
            cursor: pointer;
          }

          .setings text {
            padding-left: 12px;
          }

          .logout {
            box-sizing: border-box;
            height: 54px;
            border-top: 1px solid black;
            padding: 16px 16px 16px 28px;
            flex-grow: 1;
            cursor: pointer;
            display: flex;
            align-items: center;
          }

          .logout p {
            margin: 0;
          }

          .logout text {
            margin-left: 12px;
          }
        `}</style>
        <div className="menu-container">
          <button onClick={this.onClick} className="menu-trigger">
            <img src={userProfilePhoto ? userProfilePhoto : profile} alt="Profile Image" width='100%' height='100%'></img>
          </button>
          <nav ref={this.dropdownRef} className={`menu ${isActive ? "active" : "inactive"}`}>
            <Link
              to="/UserProfile"
              className="profileLink"
              onClick={() => { }}
            >
              <div className="profile">
                <div className="profileContent">
                  <img
                    src={userProfilePhoto ? userProfilePhoto : profile}
                    alt="Profile Image"
                    width={32}
                    height={32}
                    style={{ borderRadius: '50%' }}
                  />
                  <div className="profileName">
                    <p className="userName">{userName || "User"}</p>
                    <p className="userEmail">{email || "No user"}</p>
                  </div>
                </div>
                <ArrowRightAltIcon style={{ fontSize: '28px' }} />
              </div>
            </Link>
            <div
              onClick={this.toggleOpen}
              className="setings"
            >
              <div className="setingsHeader">
                <SettingsOutlinedIcon style={{ width: "20px", height: "20px" }} />
                <text>Settings</text>
              </div>
              {this.state.isOpen ? <ArrowDropUpIcon style={{ width: "28px", height: "28px" }} /> : <ArrowDropDownIcon style={{ width: "28px", height: "28px" }} />}

            </div>
            {this.state.isOpen && (
              <ul className={`setingsList ${this.state.isOpen ? "open" : ""}`}>
                {/* {this.state.items.map((item, index) => (
                  
                  item.name === "Membership" && this.state.isSubscriber  ?
                    null :
                    <li key={index}>
                      <Link
                        to={item.to}
                        style={{ cursor: "pointer" }}
                        onClick={() => { }}
                      >
                        {item.name}
                      </Link>
                    </li>
                ))} */}
                <>
                  {(this.state.isSubscriber || localStorage.getItem("BusinessOwner") != "true") ? null :
                    <li key={1}>
                      <Link
                        to="/Membership"
                        style={{ cursor: "pointer" }}
                        onClick={() => { }}
                      >
                        Membership
                      </Link>
                    </li>}
                  <li key={2}>
                    <Link
                    to="/ChangeNewPassword"
                      style={{
                        cursor: "pointer",
                        color: "rgba(255, 255, 255, 0.4)",
                        fontSize: "12px",
                        fontWeight: 400,
                        textTransform: "capitalize",
                        padding: 0
                      }}
                    >
                      Change Password
                    </Link>
                  </li>
                 { (localStorage.getItem("BusinessOwner") != "true") &&  <li key={3}>
                    <Link
                      to="/DeactivateAccount"
                      style={{ cursor: "pointer" }}
                      onClick={() => { }}
                    >
                      Deactivate Account
                    </Link>
                  </li>}
                  <li key={4}>
                    <Link
                      to="/TermsConditions"
                      style={{ cursor: "pointer" }}
                      onClick={() => { }}
                    >
                      Terms and Conditions
                    </Link>
                  </li>
                  <li key={5}>
                    <Link
                      to="/PrivacyPolicies"
                      style={{ cursor: "pointer" }}
                      onClick={() => { }}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li key={6}>
                    <Link
                      to="/RefundPolicy"
                      style={{ cursor: "pointer" }}
                      onClick={() => { }}
                    >
                      Refund Policy
                    </Link>
                  </li>
                </>
              </ul>
            )}
            <div className="logout" onClick={this.handleLogout}>
              <img src={logoutImage} alt="" width="16px" height="16px" />
              <text>
                Log Out
              </text>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Dropdown;