import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    enablePasswordField: boolean;
    confirmenablePasswordField: boolean;
    forgotpasswordFieldSatisfied: boolean;
    Forgotpassword: string,
    handleReset: any,
    confirmForgotpassword: string,
    isForgotValidLength: boolean;
    hasForgotUppercase: boolean,
    hasForgotLowerCase: boolean,
    hasForgotNumber: boolean,
    hasForgotSymbol: boolean,
    iSForgotValidPassword: boolean,
    errMessage:string,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordCredController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    resendpassword: any;
    linkVerificationApiId: string = "";

    //Properties from config
    imgPasswordInVisible: any = imgPasswordInVisible;
    imgPasswordVisible: any = imgPasswordVisible;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        this.state = {
            loading: false,
            enablePasswordField: true,
            confirmenablePasswordField: true,
            isForgotValidLength: false,
            hasForgotUppercase: false,
            hasForgotLowerCase: false,
            hasForgotNumber: false,
            hasForgotSymbol: false,
            iSForgotValidPassword: false,
            forgotpasswordFieldSatisfied: true,
            Forgotpassword: "",
            handleReset: true,
            confirmForgotpassword: "",
            errMessage:"",
        };

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const token = await getStorageData('forgotToken');
        this.checkLinkVerification(token);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.resendpassword) {
                this.resetPasswordApiRes(responseJson);
            }
            if (apiRequestCallId === this.linkVerificationApiId) {
                if (responseJson) {
                    this.linkVerificationApiRes(responseJson);
                }
            }
            runEngine.debugLog("Message Recived", message);
        }
        // Customizable Area End 
    }

    // Customizable Area Start

    checkLinkVerification = (token: string) => {
        this.setState({ loading: true });
        if (token) {

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.linkVerificationApiId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `bx_block_forgot_password/validate_token?token=${token}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "GET"
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    resetPasswordApiRes = (responseJson: any) => {
        this.setState({loading: false});
        if (responseJson?.data?.attributes) {
            this.props.navigation.navigate('ForgotPasswordReset');
        }
        else if (responseJson?.errors[0]?.otp) {
            this.showAlert('Error', responseJson?.errors[0]?.otp)
            
        }
        else {
            const errorMessage = responseJson?.errors[0]?.token;
            if (errorMessage) {
                // this.showAlert('Error', errorMessage)
                this.setState({ forgotpasswordFieldSatisfied: false, errMessage:"Password does not meet requirements" });

            }
        }
    }

    linkVerificationApiRes = (responseJson: any) => {
        const { error } = responseJson;
        if (error) {
            this.props.navigation.navigate('ForgotPasswordLinkExpired');
            this.setState({ loading: true });
        }
        if(!error){
            this.setState({ loading: false });
        }
    }

    confirmforgothandleClickShowPassword = () => {
        this.setState({
            confirmenablePasswordField: !this.state.confirmenablePasswordField,
        });
    };

    forgothandleClickShowPassword = () => {
        this.setState({ enablePasswordField: !this.state.enablePasswordField});
    };

    setConfirmforgotPassword = (text: string) => {
        this.setState({
            confirmForgotpassword: text,
            forgotpasswordFieldSatisfied: true
        });
        this.validatePassword(text)
    };

    setforgotPassword = (text: string) => {
        this.setState({
            Forgotpassword: text,
            forgotpasswordFieldSatisfied: true
        });
        this.validatePassword(text)
    };

    updatedPassword = () => {
        this.setState({ handleReset: false })

        if (this.state.Forgotpassword.trim() === '' && this.state.confirmForgotpassword.trim() === '') {
            this.setState({ forgotpasswordFieldSatisfied: false, errMessage:"Please enter Password !" });
            return
        }
        if (this.state.Forgotpassword !== this.state.confirmForgotpassword) {
            this.setState({ forgotpasswordFieldSatisfied: false, errMessage:"Password does not match" });
        } else {
            const changedPassword = () => {
                localStorage.setItem("User_Reset", "false");
                let forgotToken = localStorage.getItem("forgotToken");
                this.setState({loading: true});
                const header = {
                    "Content-Type": "application/json",
                    token: forgotToken
                };
                const data = {
                    token: forgotToken,
                    new_password: this.state.confirmForgotpassword,
                };
                const httpBody = { data: data };
                const requestMessage = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                );
                this.resendpassword = requestMessage.messageId;

                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    "bx_block_forgot_password/passwords"
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    "POST"
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    JSON.stringify(httpBody)
                );
                
                runEngine.sendMessage(requestMessage.id, requestMessage);

            }
            changedPassword();
        }
    }

    validatePassword = (password: string) => {
        const regexUppercase = /[A-Z]/;
        const regexLength = /.{8,}/;
        const regexNumber = /\d/;
        const regexLowerCase = /[a-z]/;
        const regexSymbol = /[!@#$%^&*(),.?":{}|<>]/;

        this.setState({
            hasForgotUppercase: regexUppercase.test(password),
            isForgotValidLength: regexLength.test(password),
            hasForgotNumber: regexNumber.test(password),
            hasForgotLowerCase: regexLowerCase.test(password),
            hasForgotSymbol: regexSymbol.test(password),
        });

        if (this.state.isForgotValidLength && this.state.hasForgotLowerCase && this.state.hasForgotUppercase && this.state.hasForgotNumber && this.state.hasForgotSymbol && !this.isStringNullOrBlank(this.state.Forgotpassword)) {
            this.setState({ iSForgotValidPassword: true })
        }
        else {
            this.setState({ iSForgotValidPassword: false })
        }
    };

    isStringNullOrBlank(str: string) {
        return str === null || str.length === 0;
    }


    // Customizable Area End
}