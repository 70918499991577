import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { getNavigationMessage, sendAPIRequest } from "../../../components/src/Utils";
import { ThemeProvider, createTheme } from "@material-ui/core";
import React from "react";

// Customizable Area End

export const configJSON = require("./config");
export const frameworkConfigJSON = require("../../../framework/src/config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  qrcodeholder: any;
  token: string;
  result: any;
  isBusinessJoining: boolean;
  scanningError: string;
  loading: boolean;
  userType: string;
  logInToken: string;
  isJoiningBusinessSuccessPopup: boolean;
  isJoiningBusinessFailedPopup: boolean;
  successMessage: string;
  subsciptionDatas: any;
  bussinessData: any;
  joinBusinessId: number;
  userData: any,
  subscriptionProfileData: any
  openDetails: boolean,
  branchName: any;
  redeemed: boolean,
  viewDetails: boolean,
  expandHistoryTable: boolean,
  redeemedData: any,
  transactionHistoryDatas: any,
  viewDetailsData: any,
  redeemedId: any,
  openBuySubscriptionErrorDialog: boolean,
  selectedBranchId: number,
  openRedeemDialog: boolean,
  expandTransactionHistory: boolean
  userSubscriptionId: number,
  redeemDialogTitle: string,
  redeemDialogMessage: string,
  buySubscriptionErrorMsg: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QrCodesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getQRApiCallId: any;
  getProfileDetailsApiId: any;
  joinBusinessApiId: any;
  subscriptionApiId: any;
  buyNowSubscriptionId: any;
  checkValidSubscription: any;
  redeemedApi: any;
  viewDetailsApi: any;
  showSubscriptionApiId: any;

  transactionHistoryData = [
    {
      "id": 264,
      "transaction_type": "Subscription purchased",
      "date": "2024-04-10T12:16:48.129Z",
      "user_subscription_id": 335,
      "created_at": "2024-04-10T12:16:48.146Z",
      "updated_at": "2024-04-10T12:16:48.146Z",
      "payment_status": null
    },
    {
      "id": 265,
      "transaction_type": "Subscription updated",
      "date": "2024-04-10T12:17:50.525Z",
      "user_subscription_id": 335,
      "created_at": "2024-04-10T12:17:50.526Z",
      "updated_at": "2024-04-10T12:17:50.526Z",
      "payment_status": "failed"
    },
    {
      "id": 266,
      "transaction_type": "Subscription updated",
      "date": "2024-04-10T12:18:03.182Z",
      "user_subscription_id": 335,
      "created_at": "2024-04-10T12:18:03.184Z",
      "updated_at": "2024-04-10T12:18:03.184Z",
      "payment_status": "sucess"
    },
    {
      "id": 267,
      "transaction_type": "Subscription redeemed",
      "date": "2024-04-10T12:25:13.305Z",
      "user_subscription_id": 335,
      "created_at": "2024-04-10T12:25:13.306Z",
      "updated_at": "2024-04-10T12:25:13.306Z",
      "payment_status": null
    }
  ];
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SelectedBranch),
      getName(MessageEnum.SelectedBranchData),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      qrcodeholder: "",
      token: "",
      result: null,
      isBusinessJoining: false,
      scanningError: "",
      loading: false,
      userType: "",
      logInToken: "",
      isJoiningBusinessSuccessPopup: false,
      isJoiningBusinessFailedPopup: false,
      successMessage: "",
      subsciptionDatas: null,
      bussinessData: null,
      userData: null,
      joinBusinessId: 0,
      subscriptionProfileData: null,
      openDetails: false,
      branchName: [],
      redeemed: false,
      viewDetails: false,
      expandHistoryTable: false,
      redeemedData: {},
      transactionHistoryDatas: [],
      redeemedId: '',
      viewDetailsData: {},
      openBuySubscriptionErrorDialog: false,
      selectedBranchId: 0,
      openRedeemDialog: false,
      expandTransactionHistory: false,
      userSubscriptionId: 0,
      redeemDialogTitle: "",
      redeemDialogMessage: "",
      buySubscriptionErrorMsg: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const logInResp = await getStorageData('logInRes', true);
    const otpConfResp = await getStorageData('otpConfRes', true);
    const subscriptionToBuy = await getStorageData('subscription_to_buy', true);
    const isJoiningBusiness = await getStorageData('isJoiningBusiness', true);
    this.setJoiningState(isJoiningBusiness);

    // this.SubscriptionCalls(602)

    this.setTokenInStorage(token, logInResp, otpConfResp);
    this.isSubscriptionId(subscriptionToBuy);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  requestQRCode = (token: any) => {
    const data = {
      random: "email_account"
    };

    const httpBody = {
      data: data
    };

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQRApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.requestQRCode(token);
    }

    if (getName(MessageEnum.SelectedBranch) === message.id) {
      const branchDetails = message.getData(getName(MessageEnum.SelectedBranchData));
      this.setState({ selectedBranchId: branchDetails[0].id })
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getQRApiCallId) {
        this.handleQRApiRes(responseJson);
      }
      if (apiRequestCallId === this.getProfileDetailsApiId) {
        this.handleUserProfileApiRes(responseJson);
      }
      if (apiRequestCallId === this.joinBusinessApiId) {
        this.handleJoinBusinessApiRes(responseJson);
      }
      if (apiRequestCallId === this.subscriptionApiId) {
        this.handleSubs(responseJson)
      }
      if (apiRequestCallId === this.buyNowSubscriptionId) {
        this.handleBuySubscriptionRes(responseJson);
      }
      if (apiRequestCallId === this.checkValidSubscription) {
        this.checkValidSubscriptionApiRes(responseJson);
      }
      if (apiRequestCallId === this.redeemedApi) {
        this.handleRedeemApiRes(responseJson);
      }
      if (apiRequestCallId === this.viewDetailsApi) {
        this.handleViewDetailsApiRes(responseJson);
      }
      if (apiRequestCallId === this.showSubscriptionApiId) {
        this.handleShowSubscriptionApiRes(responseJson);
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const subscriptioninfo = message.getData(
        getName(MessageEnum.NavigationQrSubscriptionData)
      );
      this.setState({ subsciptionDatas: subscriptioninfo })
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const subscriptioninfo = message.getData(
        getName(MessageEnum.NavigationQrSubscriptionData2)
      );
      this.setState({ bussinessData: subscriptioninfo })
    }

    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.joinBusinessId !== this.state.joinBusinessId) {
      this.joinBusinessApiCall(this.state.joinBusinessId);
    }
  }

  setTokenInStorage = (token: any, logInRes: any, otpConfRes: any) => {

    if (!logInRes && !otpConfRes && !token) {
      window.localStorage.clear();
      this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
    }

    if (token) {
      const urlParams = new URLSearchParams(window.location.search);
      const business_id = urlParams.get('business_id');
      const logRes = { logInToken: token };
      const otpRes = { token: token }

      localStorage.setItem('otpConfRes', JSON.stringify(otpRes));

      localStorage.setItem('logInRes', JSON.stringify(logRes));
      this.setState({ logInToken: token });
      if (business_id) {
        this.fetchProfileData();
        removeStorageData("isProfileCreated");
        setStorageData("userLoggedIn", true);
        setStorageData("isLogin", true);
        this.SubscriptionCalls(+business_id);
      }
    } else if (logInRes || otpConfRes) {
      this.stateSetAfterSignup(otpConfRes);
      this.setStateAfterLogIn(logInRes);
      this.fetchProfileData();
    }
  }

  setJoiningState = (isTrue: any) => {
    this.setState({ isBusinessJoining: isTrue });
  }

  isSubscriptionId = (id: number) => {
    if (id) {
      this.getSubscriptionDetailsApiCall(id);
    }
  }

  handleQRApiRes = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      let data = responseJson.data.attributes.qr_code;
      data = data
        .replace("https://localhost:3000", frameworkConfigJSON.baseURL)
        .replace("http://localhost:3000", frameworkConfigJSON.baseURL);
      this.setState({ qrcodeholder: data });
      runEngine.debugLog("qrcodeholder", this.state.qrcodeholder);
    }
  }

  setStateAfterLogIn = (logInResp: any) => {
    if (logInResp) {
      this.setState({
        logInToken: logInResp.logInToken
      })
    }
  }

  stateSetAfterSignup = (otpConfResp: any) => {
    if (otpConfResp) {
      this.setState({ logInToken: otpConfResp.token });
    }
  }

  handleScanResult(data: any) {
    const { userType } = this.state;

    if (userType === "subscriber") {
      this.handleSubscriberResult(data)
    } else {
      this.handleBusinessScanResult(data);
    }
  }
  handleScanError(err: any) {
    this.setState({ scanningError: err?.name });

  }

  handleUserProfileApiRes = (response: any) => {
    this.setState({ loading: false });
    const { attributes } = response.data;
    this.setState({ userType: attributes?.role_name, userData: attributes });
  }

  fetchProfileData = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getProfileDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileDetailsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkValidSubscriptionCall = async (data: any) => {

    const { text } = data;
    let text_new = text.slice(1, -1);

    const pairs = text_new.split(',');

    let accountId = null;
    pairs.forEach((data: { split: (arg0: string) => [any, any]; }) => {
      const [key, value] = data.split(':');
      if (key.trim() === 'account_id') {
        accountId = parseInt(value.trim());
      }
    });
    if (accountId) {
      this.setState({ loading: true })

      const header = {
        token: this.state.logInToken,
        "Content-Type": "application/json",
      };

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.checkValidSubscription = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/check_valid_subscription?branch_ids=${this.state.selectedBranchId}&subscriber_account_id=${accountId}`,
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({ isJoiningBusinessFailedPopup: true })
    }
  }

  checkJoiningBusiness = async (data: any) => {
    const { text } = data;

    let textStr = text?.split("/");
    let businessQrDetails = textStr[textStr?.length - 1];
    let businessId = parseInt(businessQrDetails);
    this.setState({ result: businessId });
    if (isNaN(businessId)) {
      this.setState({ isJoiningBusinessFailedPopup: true, });
    } else {
      this.setState({ joinBusinessId: businessId });
    }
  }

  handleBusinessScanResult = async (data: any) => {
    if (data && this.state.isBusinessJoining) {
      this.checkJoiningBusiness(data)
    } else {
      this.checkValidSubscriptionCall(data);
    }
  }

  redeemedSubscribersQR = async (id: any) => {
    this.setState({ loading: true });
    if (id) {

      const header = {
        token: this.state.logInToken,
        "Content-Type": "application/json",
      };

      const bodyData = { id: id }

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.redeemedApi = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_custom_user_subs/redeem_subscription",
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPutMethod);
      runEngine.sendMessage(requestMessage.id, requestMessage);


    }
  }
  ViewDetailsGet = (id: any) => {
    this.setState({ loading: true });
    if (id) {

      const header = {
        token: this.state.logInToken,
        "Content-Type": "application/json",
      };

      const viewDetailsRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.viewDetailsApi = viewDetailsRequestMessage.messageId;

      viewDetailsRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_custom_user_subs/view_details?user_subscription_id=${id}`,
      );

      viewDetailsRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
      viewDetailsRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
      runEngine.sendMessage(viewDetailsRequestMessage.id, viewDetailsRequestMessage);
    }
  }
  handleSubs = (response: any) => {
    if (response) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationQrSubscriptionMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.NavigationQrSubscriptionData), response.subscriptions)
      msg.addData(getName(MessageEnum.NavigationQrSubscriptionData2), response.business)
      this.send(msg);
    }
  }

  handleSubscriberResult = async (data: any) => {
    if (data) {
      const { text } = data;
      let textStr = text?.split("/");
      let businessQrDetails = +textStr[textStr?.length - 1]
      this.setState({ result: businessQrDetails });
      if (businessQrDetails) {
        this.SubscriptionCalls(businessQrDetails);
      } else {
        this.setState({ isJoiningBusinessFailedPopup: true, });
      }
    }
  }

  SubscriptionCalls = (id: number) => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.subscriptionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subscriptionApiEndPoint}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleBuySubscriptionRes = (response: any) => {
    this.setState({ loading: false });
    if (!response.errors) {
      removeStorageData('subscription_to_buy');
      window.location.href = response.data?.url
    } else {
      const { errors } = response;
      this.setState({ openBuySubscriptionErrorDialog: true, buySubscriptionErrorMsg: errors });
    }
  }

  buyNowSubscriptionApi = () => {
    const originUrl = window.location.origin
    this.setState({ loading: true });
    const bodyData = {
      "id": this.state.subscriptionProfileData.id,
      "success_url": `${originUrl}/sucessfull`,
      "cancel_url": `${originUrl}/ScanCode`
    }
    this.buyNowSubscriptionId = sendAPIRequest(
      `bx_block_stripe_integration/subscriber_subscription_payment?id=${this.state.subscriptionProfileData.id}`,
      {
        method: configJSON.httpPostMethod,
        body: bodyData,
        headers: {
          "Content-Type": "application/json",
          "token": this.state.logInToken
        }
      },
    );
    localStorage.setItem('sId', this.state.subscriptionProfileData.id)
  }

  handleBuysubscriptionErrorDialogBtn = () => {
    this.setState({ openBuySubscriptionErrorDialog: false });
  }

  joinBusinessApiCall = (id: number) => {

    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };

    const body = {
      id: id
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.joinBusinessApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.joinBusinessApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleJoinBusinessApiRes = (responseJson: any) => {
    this.setState({ loading: false });
    if (!responseJson.errors) {
      const { meta } = responseJson;
      this.setState({ isJoiningBusinessSuccessPopup: true, successMessage: meta });
    } else {
      const { errors } = responseJson;
    }

  }

  handleGoToHomepage = () => {
    setStorageData("isBusinessJoined", true);
    setStorageData("isProfileCreated", true);
    setStorageData("userLoggedIn", true);
    removeStorageData("isJoiningBusiness");
    this.send(getNavigationMessage("LandingPage", this.props));
  }
  handleGoToQrPage = () => {
    this.send(getNavigationMessage("QrCodes", this.props));
  }

  handleFailedDialogCancelBtn = () => {
    if (this.state.isBusinessJoining) {
      this.setState({ isJoiningBusinessFailedPopup: false });
      removeStorageData("isJoiningBusiness");
      this.send(getNavigationMessage("SetupBusiness", this.props));
    } else {
      this.setState({ isJoiningBusinessFailedPopup: false });
      this.send(getNavigationMessage("LandingPage", this.props));
    }
  }

  handleFailedDialogRetryBtn = () => {
    this.setState({ isJoiningBusinessFailedPopup: false })
  }

  scannerHeading = () => {
    if (this.state.isBusinessJoining) {
      return "Scan a QR code to join business"
    }
  }

  HeadingWrapper = ({ children }: { children: React.ReactNode }) => {
    const gridtheme = createTheme({
      overrides: {
        MuiContainer: {
          'maxWidthLg': {
            '@media (max-width: 1500px)': {
              maxWidth: '1320px',
            },
          },
        }
      }
    });

    return <ThemeProvider theme={gridtheme} children={children}></ThemeProvider>
  }
  viewDetailsOpening = (list: any) => {
    const a = JSON.parse(list.branch_name)
    this.setState({ branchName: a, openDetails: true, subscriptionProfileData: list });
  }

  handleBackToSubscriptionList = () => {
    this.setState({ openDetails: false })
  }
  goBcak = () => {
    this.send(getNavigationMessage("LandingPage", this.props));
  }

  handleRedeemApiRes = (responseJson: any) => {
    this.setState({ loading: false })
    if (responseJson?.message === "Successfully redeemed") {
      this.setState({ redeemedData: responseJson, redeemed: true, })
    } else if (responseJson.errors === "All units redeemed") {
      this.setState({
        openRedeemDialog: true,
        redeemDialogMessage: "This subscriber has no units remaining.",
        redeemDialogTitle: "All units redeemed"
      })
    }
  }

  checkValidSubscriptionApiRes = (responseJson: any) => {
    this.setState({ loading: false })
    if (!responseJson.errors) {
      if (responseJson.length !== 0) {
        const { id } = responseJson[0];
        this.setState({ userSubscriptionId: id });
        this.redeemedSubscribersQR(id)
      } else {
        this.setState({
          openRedeemDialog: true,
          redeemDialogTitle: "No active subscription",
          redeemDialogMessage: "The subscriber has no active subscription with this branch"
        });
      }
    }
  }

  handleCheckDialogOkBtn = () => {
    this.setState({ openRedeemDialog: false });
  }

  handleBackToRedeemScreen = () => {
    this.setState({ redeemed: true, viewDetails: false })
  }

  handleTranscationHistoryExpand = () => {
    this.setState({ expandTransactionHistory: !this.state.expandTransactionHistory });
  }

  handleScanAnotherCodeBtn = () => {
    this.setState({ redeemed: false, viewDetails: false })
  }

  getNextBillingDate = () => {
    const { user_subscription_details } = this.state.viewDetailsData;

    let nextBillingDate = new Date(user_subscription_details?.next_billing);
    let formattedDate = nextBillingDate.getDate() + ' ' + nextBillingDate.toLocaleString('default', { month: 'short' }) + ' ' + nextBillingDate.getFullYear();  // format - 14 Apr 2024
    return formattedDate;
  }

  convertDateFormat = (date: any) => {
    let newDate = new Date(date);

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
    let day = newDate.getDate();
    let month = months[newDate.getMonth()];
    let year = newDate.getFullYear();
    
    let hours = newDate.getHours();
    let minutes: any = newDate.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    
    let formattedDate = `${day} ${month} ${year}, ${hours}:${minutes}${ampm}`;
    return formattedDate;
  }

  getBranchNameList = () => {
    const { subscription } = this.state.viewDetailsData;
    let branch_name_string = subscription?.branch_name;
    let branchArray = JSON.parse(branch_name_string);

    return branchArray.join(",");
  }

  handleViewDetailsBtn = () => {
    const { userSubscriptionId } = this.state;
    this.ViewDetailsGet(userSubscriptionId);
  }

  handleViewDetailsApiRes = (responseJson: any) => {
    this.setState({ loading: false });
    if (!responseJson.errors) {

      if (responseJson?.accounts_with_subscriptions?.transaction_histories && Array.isArray(responseJson?.accounts_with_subscriptions?.transaction_histories)) {
        const sortedTransactions = responseJson?.accounts_with_subscriptions?.transaction_histories.sort((a: any, b: any) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
        this.setState({ transactionHistoryDatas: sortedTransactions });
      }

      this.setState({
        viewDetailsData: responseJson?.accounts_with_subscriptions,
        viewDetails: true,
        redeemed: false
      })
    }
  }

  getSubscriptionDetailsApiCall = (id: any) => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.showSubscriptionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SubsDetails}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShowSubscriptionApiRes = (response: any) => {
    this.setState({ loading: false });
    let data = {}
    if (response.data) {
      const { id, attributes } = response.data;
      data = { id, ...attributes };
      const branchName = JSON.parse(response.data.attributes?.branch_name)
      this.setState({ branchName, openDetails: true, subscriptionProfileData: data });
    }
  }

  returnBusinessName = () => {
    return this.state.bussinessData?.business_name || ""
  }

  returnBusinessLogoUrl = () => {
    return this.state.bussinessData?.photo || ""
  }
  currentDateAndTime = ()=>{
    const now = new Date();
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
          ];
          
          let day = now.getDate();
          let month = months[now.getMonth()];
          let year = now.getFullYear();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
      
        const formattedMinutes = String(minutes).padStart(2, '0');
      
        const formattedTime = `${day} ${month} ${year}, ${formattedHours}:${formattedMinutes}  ${ampm}`;
        
        return formattedTime;
  }

  // Customizable Area End
}
