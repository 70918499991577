import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { getNavigationMessage } from "../../../components/src/Utils";

export interface BranchDetailsInterface {
    branchName: string;
    branchAddress: any;
    totalUsers: number;
}

export interface ListOfBranchResponseData {
    branch: {
        data: {
            type: string;
            id: string;
            attributes: {
                qr_code_url: string;
                account: {
                    first_name: string;
                    account_id: number;
                };
                branch: {
                    branch_users_name: string;
                    branch_name: string;
                    branch_user_permission: {
                        reporting: boolean;
                        manage_subscribers: boolean;
                        manage_business: boolean;
                        manage_subscription: boolean;
                        general_user: boolean;
                        business_owner: boolean;
                        created_at: string;
                        account_id: number;
                        branchUser_id: number;
                        updated_at: string;
                        id: number;
                    };
                    branch_id: number;
                };
            };
        };
    };
    total_users: number;
}

export interface UpdateBrancList {
    errors: null;
    data: {
        id: string;
        type: string;
        attributes: {
            qr_code_url: string;
            account: {
                account_id: number;
                first_name: string;
            };
            branch: {
                branch_id: number;
                branch_name: string;
                branch_users_name: string;
                branch_user_permission: {
                    id: number;
                    reporting: boolean;
                    manage_business: boolean;
                    manage_subscription: boolean;
                    manage_subscribers: boolean;
                    general_user: boolean;
                    business_owner: boolean;
                    account_id: number;
                    branch_user_id: number;
                    created_at: string;
                    updated_at: string;
                }
            }
        }
    }
}

export interface BranchListResponseInterface {
    errors: string;
    branche: {
        data: [
            {
                id: string;
                type: string;
                attributes: {
                    id: string;
                    branch: {
                        branch_id: string;
                        branch_name: string;
                        branch_users_name: string;
                        branch_user_permission: {
                            id: string;
                            reporting: true;
                            manage_business: true;
                            manage_subscription: true;
                            manage_subscribers: true;
                            general_user: true;
                            business_owner: false;
                            account_id: string;
                            branch_user_id: string;
                            created_at: string;
                            updated_at: string
                        }
                    },
                    account: {
                        account_id: string;
                        first_name: string;
                    }
                }
            },
        ]
    },
    Total_users: string;
}

export interface BranchUerlistStateData {
    id: string;
    type: string;
    attributes: {
        id: string;
        branch: {
            branch_id: string;
            branch_name: string;
            branch_users_name: string;
            branch_user_permission: {
                id: string;
                reporting: true;
                manage_business: true;
                manage_subscription: true;
                manage_subscribers: true;
                general_user: true;
                business_owner: false;
                account_id: string;
                branch_user_id: string;
                created_at: string;
                updated_at: string
            }
        },
        account: {
            account_id: string;
            first_name: string;
        }
    }
}

export interface SearchUserApiResponse {
    errors: string;
    account: [
        {
            id: string;
            first_name: string;
            last_name: string;
            full_phone_number: string;
            country_code: null;
            phone_number: null;
            email: string;
            activated: boolean;
            device_id: null;
            unique_auth_id: string;
            password_digest: string;
            created_at: string;
            updated_at: string;
            user_name: null;
            platform: null;
            user_type: null;
            app_language_id: null;
            last_visit_at: null;
            is_blacklisted: boolean;
            suspend_until: null;
            status: string;
            role_id: string;
            stripe_id: null;
            stripe_subscription_id: null;
            stripe_subscription_date: null;
            gender: null;
            date_of_birth: null;
            age: null;
            role_name: string;
            is_email_notification: boolean;
        },
    ]
}
export interface SearchUserListState {
    id: string;
    first_name: string;
    last_name: string;
    full_phone_number: string;
    country_code: null;
    phone_number: null;
    email: string
    activated: boolean;
    device_id: null;
    unique_auth_id: string;
    password_digest: string;
    created_at: string;
    updated_at: string;
    user_name: null;
    platform: null;
    user_type: null;
    app_language_id: null;
    last_visit_at: null;
    is_blacklisted: boolean;
    suspend_until: null;
    status: string;
    role_id: string;
    stripe_id: null;
    stripe_subscription_id: null;
    stripe_subscription_date: null;
    gender: null;
    date_of_birth: null;
    age: null;
    role_name: string;
    is_email_notification: boolean;
}
export interface AddUserApiResponse {
    error: string;
    data: {
        id: string;
        type: string;
        attributes: {
            id: string;
            branch: {
                branch_id: string;
                branch_name: string;
                branch_users_name: string;
                branch_user_permission: {
                    id: string;
                    reporting: boolean;
                    manage_business: boolean;
                    manage_subscription: boolean;
                    manage_subscribers: boolean;
                    general_user: boolean;
                    business_owner: boolean;
                    account_id: string;
                    branch_user_id: string;
                    created_at: string;
                    updated_at: string;
                }
            },
            account: {
                account_id: string;
                first_name: string;
            }
        }
    }
}

export interface UpdateUserPermissionApiResponse {
    errors: string;
    permission: {
        id: string;
        reporting: boolean;
        manage_business: boolean;
        manage_subscription: boolean;
        manage_subscribers: boolean;
        general_user: boolean;
        account_id: string;
        business_owner: boolean;
        branch_user_id: string;
        created_at: string;
        updated_at: string;
    }
}

export interface DeleteUserApiResponse {
    errors: string;
    message: string;
}
export interface AfterLoginResponseData {
    isLogin: boolean;
    logInToken: string;
}
export interface CreateBranchApiResponseData {
    error: string;
    data: {
        id: string;
        type: string;
        attributes: {
            qr_code_url: string;
            qr_pdf_url: string;
            account: {
                account_id: string;
                first_name: string
            };
            branch: {
                branch_id: string;
                branch_name: string;
                branch_users_name: string;
                branch_user_permission: {
                    id: string;
                    reporting: boolean;
                    manage_business: boolean;
                    manage_subscription: boolean;
                    manage_subscribers: boolean;
                    general_user: boolean;
                    business_owner: boolean;
                    account_id: string;
                    branch_user_id: string;
                    created_at: string;
                    updated_at: string;
                }
            }
        }
    }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isEditable: boolean,
    isAdduserModalopen: boolean,
    detailsOfBranch: Array<ListOfBranchResponseData>,
    branchName: string,
    branchAddress: any;
    branchID: any,
    token: string,
    businessID: string,
    pop: boolean,
    anchor: HTMLElement | null,
    userList: Array<BranchUerlistStateData>,
    isDropdown: boolean,
    isReporting: boolean,
    isManageBusiness: boolean,
    isManageSubscription: boolean,
    isManageSubscribers: boolean,
    isGeneralUser: boolean,
    isEditPermissionModal: boolean,
    isAddUser: boolean,
    searchUserList: Array<SearchUserListState>,
    userSelected: string,
    addUserID: string,
    isMakeUserModalOpen: boolean,
    isDeleteModalOpen: boolean,
    tobeBusinessOwner: string,
    tobeDeletedUser: string,
    tobeDeletedUserID: string,
    editUserPermissionName: string,
    searchUserLabelText: string,
    updateUserID: string,
    tobeBusinessOwnerPermissionId: string,
    itemIDtobeMakeEditDelete: string,
    itemNametobeMakeEditDelete: string,
    isCreateBranchActive: boolean;
    isCreateBranchActiveForTitle: boolean;
    isCreateBranchPressed: boolean;
    loading: boolean;
    isBranchNameEmpty: boolean;
    createBranchError: string;
    errorText: any;
    isbranchError: boolean;
    errors: {
        [key: string]: string;
    };
    isValid: boolean;
    errorDialog:boolean;
    screenWidth: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getBranchesUserApiCallId: string = "";
    putBranchNameUpdateApiCallId: string = "";
    putUpdateBusinessOwnerApiCallId: string = "";
    putUpdateUserPermissionApiCallId: string = "";
    deleteBranchUserApiCallId: string = "";
    getSearchUserApiCallId: string = "";
    postAddUserApiCallId: string = "";
    postCreateNewBranchApiCallId: string = "";
    getAllBusinessApiCallId: string = "";

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isEditable: false,
            isAdduserModalopen: false,
            detailsOfBranch: [],
            branchName: "",
            branchAddress: "",
            branchID: null,
            token: "",
            businessID: "",
            pop: false,
            anchor: null,
            userList: [],
            isDropdown: false,
            isReporting: false,
            isManageBusiness: false,
            isManageSubscription: false,
            isManageSubscribers: false,
            isGeneralUser: false,
            isEditPermissionModal: false,
            isAddUser: false,
            searchUserList: [],
            userSelected: "",
            addUserID: "",
            isMakeUserModalOpen: false,
            isDeleteModalOpen: false,
            tobeBusinessOwner: "",
            tobeDeletedUser: "",
            tobeDeletedUserID: "",
            searchUserLabelText: "Search Users",
            editUserPermissionName: "",
            updateUserID: "",
            tobeBusinessOwnerPermissionId: "",
            itemIDtobeMakeEditDelete: "",
            itemNametobeMakeEditDelete: "",
            isCreateBranchActive: false,
            isCreateBranchActiveForTitle: false,
            isCreateBranchPressed: false,
            loading: false,
            isBranchNameEmpty: false,
            createBranchError: "",
            errors: {},
            errorText: "",
            isbranchError: false,
            isValid: false,
            errorDialog:false,
            screenWidth: window.innerWidth
            // Customizable Area End
        };
        // Customizable Area Start
        this.handleResize = this.handleResize.bind(this);
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const logInRes = await getStorageData('logInRes', true);
        const otpConfRes = await getStorageData('otpConfRes', true);
        window.addEventListener('resize', this.handleResize);
        this.setTokenInStorage(token, logInRes, otpConfRes);
        this.getAllBusinessApiList()
        this.getSearchUsers();
    }

    async componentWillUnmount() {
        this.setState({ isCreateBranchActiveForTitle: false })
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({ screenWidth: window.innerWidth });
      }

    afterLogInDataResponse = (logInRes: AfterLoginResponseData) => {
        if (logInRes) {
            this.setState({
                token: logInRes.logInToken
            })
        }
    }

    stateSetAfterSignup = (otpConfRes: any) => {
        if (otpConfRes) {
            this.setState({ token: otpConfRes.token });
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson) {
                this.setState({ loading: false })
            }
            if (apiRequestCallId === this.getAllBusinessApiCallId) {
                this.handleAllBusinessApiRes(responseJson);
                this.setState({ loading: false })
            }
            if (apiRequestCallId === this.putBranchNameUpdateApiCallId) {
                this.putupdateBranchApiResponse(responseJson, errorReponse)
                this.setState({ loading: false })
            }

            if (apiRequestCallId === this.getBranchesUserApiCallId) {
                this.getBranchUserApiResponse(responseJson, errorReponse)
                this.setState({ loading: false })
            }

            if (apiRequestCallId === this.putUpdateBusinessOwnerApiCallId) {
                this.putupdateBusinessOwnerApiResponse(responseJson, errorReponse)
                this.setState({ loading: false })
            }

            if (apiRequestCallId === this.putUpdateUserPermissionApiCallId) {
                this.putupdateUserPermissionApiResponse(responseJson, errorReponse)
                this.setState({ loading: false })
            }

            if (apiRequestCallId === this.deleteBranchUserApiCallId) {
                this.deleteBranchUserApiResponse(responseJson, errorReponse)
                this.setState({ loading: false })
            }

            if (apiRequestCallId === this.getSearchUserApiCallId) {
                this.getSearchUserApiResponse(responseJson, errorReponse)
                this.setState({ loading: false })
            }

            if (apiRequestCallId === this.postAddUserApiCallId) {
                this.postAddUserApiResponse(responseJson, errorReponse)
                this.setState({ loading: false })
            }

            if (apiRequestCallId === this.postCreateNewBranchApiCallId) {
                this.postCreateBranchApiResponse(responseJson, errorReponse)
                this.setState({ loading: false })
            }
        }

        if (getName(MessageEnum.NavigationPayLoadMessage)) {

            if (message.getData(getName(MessageEnum.NavigationManageBranchWithData)) != undefined) {
                const myOrder = await message.getData(getName(MessageEnum.NavigationManageBranchWithData));
                const myOrderIdReceive = await message.getData(getName(MessageEnum.NavigationManageBranchWithBusinessID));
                this.setState({branchName:myOrder.name,branchAddress:myOrder.address,branchID:myOrder.id,businessID:myOrderIdReceive})
                this.getBranchUserData();
                this.setState({ loading: false })
            }

            if (message.getData(getName(MessageEnum.NavigationCreateBranchWithTrueValue)) != undefined) {
                const businessID = await message.getData(getName(MessageEnum.NavigationManageBranchWithBusinessID))
                this.setState({ businessID: businessID, isCreateBranchActive: true, isCreateBranchActiveForTitle: true })
                this.getBranchUserData();
                this.setState({ loading: false })
            }

            if (message.getData(getName(MessageEnum.NavigationCreateBranchWithFalseValue)) != undefined) {
                this.setState({ isCreateBranchActive: false })
                this.setState({ loading: false })
            }
        }
        // Customizable Area End
    }

    putupdateBranchApiResponse = async (responseJson: UpdateBrancList, errorReponse: any) => {
        if (errorReponse) {
            const errorres = errorReponse?.map((ele: any) => ele)
            this.setState({ isbranchError: true, errorText: errorres })
        }
        if (responseJson != null) {
            if (!responseJson.errors) {
                this.putupdateBranchSuccessCallback(responseJson)
            }
        }
    }

    putupdateBranchSuccessCallback = async (responseJson: UpdateBrancList) => {
        this.setState({ isEditable: false })
    }

    getBranchUserApiResponse = async (responseJson: BranchListResponseInterface, errorReponse: string) => {
        if (responseJson != null) {
            if (!responseJson.errors) {
                this.getBranchUserListSuccessCallback(responseJson)
            }
        }
    }

    getBranchUserListSuccessCallback = async (responseJson: BranchListResponseInterface) => {
        this.prepareData(responseJson.branche?.data)
        // this.setState({ userList: responseJson.branche?.data })
    }

    putupdateBusinessOwnerApiResponse = async (responseJson: UpdateBrancList, errorReponse: string) => {
        if (responseJson != null) {
            if (!responseJson.errors) {
                this.putupdateBusinessOwnerSuccessCallback(responseJson)
            }
        }
    }

    prepareData = (response: any) => {
        let tempResponse = [...response]

        const businessOwners = tempResponse.filter((item: any) => item.attributes.branch.branch_user_permission.business_owner);

        const nonBusinessOwners = tempResponse.filter((item: any) => !item.attributes.branch.branch_user_permission.business_owner);


        const tempResponses = businessOwners.concat(nonBusinessOwners);


        this.setState({ userList: tempResponses })

    }

    putupdateBusinessOwnerSuccessCallback = async (responseJson: UpdateBrancList) => {
        this.setState({ isMakeUserModalOpen: false })
        this.getBranchUserData()
    }

    putupdateUserPermissionApiResponse = async (responseJson: UpdateUserPermissionApiResponse, errorReponse: string) => {
        if (responseJson != null) {
            if (!responseJson.errors) {
                this.putupdateUserPermissionSuccessCallback(responseJson)
            }
        }
    }

    putupdateUserPermissionSuccessCallback = async (responseJson: UpdateUserPermissionApiResponse) => {
        this.setState({
            isEditPermissionModal: false,
            isAddUser: false,
            isDropdown: false,
            isReporting: false,
            isManageBusiness: false,
            isManageSubscription: false,
            isManageSubscribers: false,
            isGeneralUser: false,
        })
        this.getBranchUserData()
    }

    deleteBranchUserApiResponse = async (responseJson: DeleteUserApiResponse, errorReponse: string) => {
        if (responseJson != null) {
            if (!responseJson.errors) {
                this.deleteBranchUserSuccessCallback(responseJson)
            }
        }
    }

    deleteBranchUserSuccessCallback = async (responseJson: DeleteUserApiResponse) => {
        this.setState({ isDeleteModalOpen: false })
        this.getBranchUserData();
    }

    getSearchUserApiResponse = async (responseJson: SearchUserApiResponse, errorReponse: string) => {
        if (responseJson != null) {
            if (!responseJson.errors) {
                this.getSearchUserSuccessCallback(responseJson)
            }
        }
    }

    getSearchUserSuccessCallback = async (responseJson: SearchUserApiResponse) => {
        this.setState({ searchUserList: responseJson.account })
    }

    postAddUserApiResponse = async (responseJson: AddUserApiResponse, errorReponse: string) => {
        
        if (responseJson != null) {
            if (!responseJson.error) {
                this.postAddUserSuccessCallback(responseJson)
            }
            else{
                this.setState({errorDialog:true})
            }
        }
    }

    postAddUserSuccessCallback = async (responseJson: AddUserApiResponse) => {
        
        this.setState({
            isEditPermissionModal: false,
            isAddUser: false,
            isDropdown: false,
            searchUserLabelText: "Search Users",
            isReporting: false,
            isManageBusiness: false,
            isManageSubscription: false,
            isManageSubscribers: false,
            isGeneralUser: false,
        })
        this.getBranchUserData()
    }

    postCreateBranchApiResponse = async (responseJson: CreateBranchApiResponseData, errorReponse: string) => {
        if (responseJson != null) {
            if (!responseJson.error) {
                this.postCreateBranchSuccessCallback(responseJson)
            } else if (responseJson.error) {
                this.postCreateBranchErrorCallback(responseJson.error)
            }
            this.setState({ branchID: responseJson?.data?.id })
        }
    }

    postCreateBranchSuccessCallback = async (responseJson: CreateBranchApiResponseData) => {
        this.setState({ isCreateBranchActive: false })
        this.setState({ branchID: responseJson?.data?.id })
        this.getBranchUserData();
    }

    postCreateBranchErrorCallback = async (error: any) => {
        if (error === "Maximum branch count reached. You cannot create more branches.") {
            this.setState({ isCreateBranchActive: false, createBranchError: "A business can have maximum 30 branches" });
        }
        if (error) {
            const errorres = error?.map((ele: any) => ele)
            this.setState({ isbranchError: true, errorText: errorres })
        }

    }

    putUpdateBranch = async () => {
        if (!this.state.branchName && !this.state.branchAddress) {
            this.setState({
                errorText: "Branch Name and Branch Address can't be empty",
                isbranchError: true
            });
        }
        else
            if (!this.state.branchName) {
                this.setState({
                    errorText: "Branch Name can't be empty",
                    isbranchError: true
                });
            } else if (!this.state.branchAddress) {
                this.setState({ errorText: "Branch Address can't be empty", isbranchError: true });
            } else {
                this.setState({
                    errorText: "", isbranchError: false
                });
                if (!this.validBranchName(this.state.branchName)) { this.setState({ isBranchNameEmpty: true, isCreateBranchPressed: true }) }
                else {
                    this.setState({ loading: true })
                    this.putBranchNameUpdateApiCallId = await this.apiCallFunction({
                        contentType: configJSON.searchApiContentType,
                        method: configJSON.httpPutMethod,
                        endPoint: `${configJSON.putUpdateBranchEndPoint}/${this.state.branchID}`,
                        body: {
                            branch: {
                                name: this.state.branchName,
                                address: this.state.branchAddress,
                                business_id: this.state.businessID
                            },
                        }
                    });
                }
            }

    }

    getBranchUserData = async () => {
        this.setState({ loading: true })
        this.getBranchesUserApiCallId = await this.apiCallFunction({
            contentType: configJSON.searchApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.getBranchUserEndPoint}?id=${this.state.branchID}`,
        });
    }

    putUpdateBusinessOwner = async () => {
        this.setState({ loading: true })
        this.putUpdateBusinessOwnerApiCallId = await this.apiCallFunction({
            contentType: configJSON.searchApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: `${configJSON.putUpdateBusinessOwnerEndPoint}?permission_id=${this.state.itemIDtobeMakeEditDelete}&id=${this.state.branchID}`,
            body: {
                branch: {
                    name: this.state.branchName,
                    address: this.state.branchAddress,
                    business_id: this.state.businessID
                },
            }
        });
    }

    putUpdateUserPermissionOwner = async () => {
        this.setState({ loading: true })
        this.putUpdateUserPermissionApiCallId = await this.apiCallFunction({
            contentType: configJSON.searchApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: `${configJSON.putUpdateUserPermissionEndPoint}/${this.state.itemIDtobeMakeEditDelete}`,
            body: {
                permission: {
                    reporting: this.state.isReporting,
                    manage_business: this.state.isManageBusiness,
                    manage_subscription: this.state.isManageSubscription,
                    manage_subscribers: this.state.isManageSubscribers,
                    general_user: this.state.isGeneralUser
                }
            }
        });
    }

    deleteBranchUserOwner = async () => {
        this.setState({ loading: true })
        this.deleteBranchUserApiCallId = await this.apiCallFunction({
            contentType: configJSON.searchApiContentType,
            method: configJSON.httpDeleteMethod,
            endPoint: `${configJSON.deleteBranchUserEndPoint}?branch_user_id=${this.state.tobeDeletedUserID}`,
        });
    }

    getSearchUsers = async () => {
        this.setState({ loading: true })
        this.getSearchUserApiCallId = await this.apiCallFunction({
            contentType: configJSON.searchApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: `bx_block_branch/search_users`,
        });
    }

    postAddUsers = async () => {
        this.setState({ loading: true })
        this.postAddUserApiCallId = await this.apiCallFunction({
            contentType: configJSON.searchApiContentType,
            method: configJSON.httpPostMethod,
            endPoint: `bx_block_branch/add_user`,
            body: {
                branch_id: this.state.branchID,
                account_id: this.state.addUserID,
                reporting: this.state.isReporting,
                manage_business: this.state.isManageBusiness,
                manage_subscription: this.state.isManageSubscription,
                manage_subscribers: this.state.isManageSubscribers,
                general_user: this.state.isGeneralUser
            }
        });
    }


    apiCallFunction = async (data: { contentType: string, method: string, endPoint: string, body?: object, type?: string }) => {
        const { contentType, method, endPoint, body, type } = data;

        const header = {
            "Content-Type": contentType,
            token: this.state.token
        };
        this.setState({ loading: true })
        const requestMessageID = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        body && type != 'formData' ?
            requestMessageID.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )

            : requestMessageID.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        requestMessageID.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessageID.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageID.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageID.id, requestMessageID);
        return requestMessageID.messageId;
    };

    createNewBranch = async () => {
        if (!this.state.branchName && !this.state.branchAddress) {
            this.setState({
                errorText: "Branch Name and Branch Address can't be empty",
                isbranchError: true
            });
        } else if (!this.state.branchName) {
            this.setState({
                errorText: "Branch Name can't be empty",
                isbranchError: true
            });
        } else if (!this.state.branchAddress) {
            this.setState({
                errorText: "Branch Address can't be empty",
                isbranchError: true
            });
        } else {
            this.setState({
                errorText: "",
                isbranchError: false
            });
            if (!this.validBranchName(this.state.branchName)) {
                this.setState({ isBranchNameEmpty: true, isCreateBranchPressed: true })
            }
            else {
                this.setState({ isBranchNameEmpty: false })
                this.setState({ loading: true })
                this.postCreateNewBranchApiCallId = await this.apiCallFunction({
                    contentType: configJSON.searchApiContentType,
                    method: configJSON.httpPostMethod,
                    endPoint: configJSON.addBranch,
                    body: {
                        branch: {
                            name: this.state.branchName,
                            address: this.state.branchAddress,
                            business_id: this.state.businessID
                        }
                    }
                });
            }
        }
    }

    validBranchName = (branchName: string) => {
        return /^[A-Za-z0-9\s-]*$/.test(branchName) && branchName.trim() !== '';
    }

    editButtonHandle = () => {
        this.setState({ isEditable: true })
        this.getBranchUserData()
    }

    handleBranchNameInput = (value: any) => {
        const errors: { [key: string]: string } = {};
        if (!this.validBranchName(value)) {
            this.setState({
                isValid: false,
                branchName: value,
            });
            errors.branch_name = 'Branch name should be alphanumeric';
        }
        else {

            this.setState({ errors: { branch_name: '' }, isValid: true, branchName: value })
        }
    }

    handleChangeField = (text: any) => {
        this.setState({ branchAddress: text })
    }


    handlePop = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ pop: !this.state.pop, anchor: event.currentTarget })
    }

    handleMoreVector = (item: BranchUerlistStateData, index: number) => {
        this.setState({
            itemIDtobeMakeEditDelete: item.attributes.branch.branch_user_permission.id,
            itemNametobeMakeEditDelete: item.attributes.branch.branch_users_name,
            tobeDeletedUserID: item.id
        })
    }
    businessPage = ()=>{
        this.props.navigation.goBack();
    }

    openDeleteUserModal = () => {
        this.setState({ isDeleteModalOpen: true, pop: false })
    }

    deleteUser = () => {
        this.deleteBranchUserOwner();
    }

    handleOpen = () => {
    }

    openMakeOwnerModal = () => {
        this.setState({ isMakeUserModalOpen: true, pop: false })
    }

    handleEditPermission = () => {
        this.setState({ pop: false, isEditPermissionModal: true })
    }

    handleMenuClose = () => {
        this.setState({ pop: false })
    }

    handkeAddUser = () => {
        this.setState({ isAddUser: true })
    }

    handleCancleBusinessOwnerModal = () => {
        this.setState({ isMakeUserModalOpen: false })
    }

    handleCancleDeleteModal = () => {
        this.setState({ isDeleteModalOpen: false })
    }

    handleDropdownAddUserModal = () => {
        this.setState({ isDropdown: !this.state.isDropdown })
    }

    handleDropdownListAddUserModal = (item: SearchUserListState) => {
        this.setState({ addUserID: item.id, searchUserLabelText: `${item.first_name} ${item.last_name}`, isDropdown: false })
    }

    handleReportingAddUserModal = () => {
        this.setState({ isReporting: !this.state.isReporting })
    }

    handleBusinessAddUserModal = () => {
        this.setState({ isManageBusiness: !this.state.isManageBusiness })
    }

    handleSubscriptionsAddUserModal = () => {
        this.setState({ isManageSubscription: !this.state.isManageSubscription })
    }

    handleSubscribersAddUserModal = () => {
        this.setState({ isManageSubscribers: !this.state.isManageSubscribers })
    }

    handleGeneralUserAddUserModal = () => {
        this.setState({ isGeneralUser: !this.state.isGeneralUser })
    }

    handleCancleAddUserModal = () => {
        this.setState({ isEditPermissionModal: false, isAddUser: false, isDropdown: false })
    }

    goBackEditButton = () => {
        this.props.navigation.goBack();
    }

    getAllBusinessApiList = async () => {
        const header = {
            "Content-Type": "application/json",
            token: this.state.token
        };
        this.setState({ loading: true })
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getAllBusinessApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllBusinessApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleAllBusinessApiRes = (response: any) => {
        this.setState({ businessID: response?.data?.id })
    };

    handleCloseErrorDialog = () => {
        this.setState({ createBranchError: "" });
    }
    handleCloseErrorDialogBtn = () => {
        this.setState({ isbranchError: false, errorText: "" });
    }
    handleConfirmationDialogCloseBtn = ()=>{
        this.setState({errorDialog:false})
    }
    setTokenInStorage = (token: any, logInRes: any, otpConfRes: any) => {

        if (!logInRes && !otpConfRes && !token) {
            window.localStorage.clear();
            this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
        }

        if (token) {
            const logRes = { logInToken: token };
            const otpRes = { token: token }

            localStorage.setItem('otpConfRes', JSON.stringify(otpRes));
            localStorage.setItem('logInRes', JSON.stringify(logRes));
            this.setState({ token: token, isCreateBranchActive: true });

            removeStorageData("isProfileCreated");
            setStorageData("userLoggedIn", true);
            setStorageData("isLogin", true);
        } else {
            this.stateSetAfterSignup(otpConfRes);
            this.afterLogInDataResponse(logInRes);
        }

    }

    // Customizable Area End
}



