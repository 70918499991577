import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Typography,
  } from "@material-ui/core";
  import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
  import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
  import CropFreeRoundedIcon from '@material-ui/icons/CropFreeRounded';


const MuiStyledButton = styled(Button)({
  display: "flex",
  fontFamily: "Inter",
  justifyContent: "center",
  width: "225px",
  height: "56px",
  padding: "1px",
  alignItems: "center",
  color: "#fff",
  background: "#000",
  border: "1px solid #F4CD46",
  borderRadius: "50px",
  fontSize: "1rem",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  textTransform: "capitalize",
  "&:hover": {
    background: "#F4CD46",
    color: "#000",
  },
  '@media (max-width: 600px)': {
    width: "150px",
    height: "40px",
  }
});
const TextLabelFirst = styled(Typography)({
  color: '#FFF',
  fontWeight: 600,
  fontFamily: 'Inter',
  fontSize: '2em',
  letterSpacing: '-0.408px',
  textAlign: 'center' as 'center',
  marginBottom: '30px',
});
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";

// Customizable Area End

import VerifyIdentityController, {
  Props,
} from "./VerifyIdentityController";

export default class VerifyIdentity extends VerifyIdentityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
        <ThemeProvider theme={theme}>
          {this.state.loading && <SpinnerLoader/>}
          <Container style={{ maxWidth: '100%', padding: '0 6% 10px', fontSize: 'clamp(10px, 16px, 2vw)' }}>
            <Box>
              <Typography style={webStyle.verifyIdentityLabel}>
                Verify Identity
              </Typography>
    
                <Box sx={webStyle.identityVerifyWrapper}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={webStyle.profileCameraLogoBoxStyle}>
                      <PersonOutlineIcon style={{ ...webStyle.profileCameraIconsStyle, width: '60%', height: '60%'}} />
                      <CropFreeRoundedIcon style={{ ...webStyle.profileCameraIconsStyle, width: '85%', height: '235px'}}/>
                    </Box>
                  </Box>
                  <Box>
                <TextLabelFirst >To complete your sign up, Go QR needs you to verify your identity</TextLabelFirst>
                    <Typography style={webStyle.textLabelSecond}>Get ready to take a photo of your ID and a selfie</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center',}}>
                    <MuiStyledButton
                      data-test-id="btnVerifyIdentity"
                      variant="contained"
                      onClick={this.handleVerifyIdentity}
                    >
                      Verify Identity
                    </MuiStyledButton>
                  </Box>
                </Box>
              
            </Box>
          </Container>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start

const theme = createTheme({
    overrides: {
      MuiFormControlLabel: {
        label: {
          width: "100%",
        },
      },
    },
  });


  
  const webStyle = {
    identityVerifyWrapper: {
      width: '100%',
      display: "flex",
      flexDirection: 'column',
      gap: 'clamp(20px, 40px, 2vw)'
    },
    verifyIdentityLabel: {
      color: '#FFF',
      fontFamily: 'Inter',
      fontSize: '2em',
      fontWeight: 700,
      letterSpacing: '-0.408px',
      marginBottom:'clamp(15px, 30px, 2vw)'
    },
    profileCameraLogoBoxStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '195px',
      height: '172px',
      position: 'relative'
    },
    profileCameraIconsStyle: {
      position: 'absolute' as 'absolute',
      color: '#fff'
    },
    textLabelSecond: {
      color: '#FFF',
      fontFamily: 'Inter',
      letterSpacing: '-0.408px',
      textAlign: 'center' as 'center',
      fontSize: '1.5em',
      fontWeight: 400,
    },
  };
// Customizable Area End