import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, styled } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { eyeIconInvisible , logo} from './assets';
import CustomeButton from "../../../components/src/CustomeButton.web";

const StyledBox = styled(Box)({
  color: '#FFF',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '-0.408px',
});

const ToggleBox = styled(Box)({
  display: 'flex', 
  justifyContent: 'center',
  marginTop: '20px', 
  borderRadius: "53px",
})

const CustomButton = styled(Button)({
  padding: '10px 84px',
  fontSize: '24px',
  color: 'white',
  border: '2px solid white',
  cursor: 'pointer',
  transition: 'background-color 0.2s ease',
  borderRadius: "53px",
  fontFamily: 'Inter',
  fontWeight: 600
})

const BusinessUserButton = styled(CustomButton)({
  marginRight: '-50px',
  textTransform: 'capitalize',
  fontSize: 'clamp(16px, 3vw, 24px)',
  maxWidth: '295px',
  maxHeight: '56px',
  minWidth: '180px',
  minHeight: '48px',
  whiteSpace: 'nowrap',
  '@media (max-width: 652px)': {
    padding: '14px 70px',
    border: '1px solid white',
    lineHeight: 'normal'
  },
  '@media (max-width: 601px)': {
    padding: '14px 66px',
    border: '1px solid white',
    lineHeight: 'normal'
  }
})

const SubscriberButton = styled(CustomButton)({
  fontSize: 'clamp(16px, 3vw, 24px)',
  maxWidth: '295px',
  maxHeight: '56px',
  minWidth: '180px',
  minHeight: '48px',
  '@media (max-width: 652px)': {
    padding: '14px 70px',
    border: '1px solid white',
    lineHeight: 'normal'
  },
  '@media (max-width: 601px)': {
    padding: '14px 66px',
    border: '1px solid white'
  },
  '@media (max-width: 420px)': {
    marginLeft: '20px'
  }
})

const TitleTypography = styled((props) => <Typography {...props} />)({
  color: 'white', 
  marginTop: "10%", 
  fontFamily: 'Inter',
  fontSize: 'clamp(23px, 3vw, 32px)',
  '@media (max-width: 601px)': {
    lineHeight: 1,
    marginBottom: '0',
    marginTop: '22px'
  }
})

const MainInputBox = styled(Box)({
  padding: "20px 0 50px 0",
  '@media (max-width: 652px)': {
    padding: '8px 0 26px 0',
  },
  '@media (max-width: 601px)': {
    padding: '8px 0 20px 0',
  }
})

const EmailInputBox = styled(Box)({
  padding: "20px 0",
  '@media (max-width: 652px)': {
    padding: '6px 0',
  },
  '@media (max-width: 601px)': {
    padding: '0',
  }
})

const DocumentBox = styled(Box)({
  display: 'flex',
  color: '#fff',
  fontFamily: 'Inter',
  alignItems: 'center',
  position:'relative',
  fontSize: 'clamp(12px, 3vw, 16px)',
  '@media (max-width: 601px)': {
    alignItems: 'center',
    flexWrap: 'wrap'
  }
})

const AcceptBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 601px)': {
    alignItems: 'center',
    whiteSpace: 'nowrap'
  }
})

const DocumentButton = styled(Button)({
  color: "white",
  fontFamily: "Inter",
  fontWeight: 700,
  textTransform: 'none',
  letterSpacing: -0.40799999237060547,
  fontSize: "16px",
  lineHeight: 'normal',
  '@media (max-width: 601px)': {
    alignItems: 'center',
    whiteSpace: 'nowrap'
  }
})

const DocumentTermsButton = styled(DocumentButton)({
  fontSize: 'clamp(12px, 3vw, 16px)'
})

const DocumentPrivacyButton = styled(DocumentButton)({
  fontSize: 'clamp(12px, 3vw, 16px)'
})

const SignUpBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "10px 0px",
  '@media (max-width: 601px)': {
    alignItems: 'center'
  }
})

const LoginBox = styled(Box)({
  color: '#fff',
  fontFamily: 'Inter',
  display: 'flex',
  alignItems: 'center',
  fontSize: 'clamp(14px, 3vw, 16px)',
  marginTop: '10px', 
  '@media (max-width: 601px)': {
    justifyContent: 'center' 
  }
})

const SignupButton = styled(Button)({
  width: '225px',
  height: '56px',
  borderRadius: '53px',
  border: '0.5px solid #242424',
  backgroundColor:'#FFFF',
  color: '#111',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: '-0.408px',
  textTransform:'capitalize',
  marginTop: '10px', 

  "&:hover": {
    background: "#F4CD46"
  },
  '@media (max-width: 601px)': {
    width: '100%',
    background: '#000000',
    color: '#FFF',
    border: '1px solid #FFF',
    "&:hover": {
      background: '#000000',
      border: '1px solid #F4CD46',
    },
  }
})

const FlexBox = styled(Box)({
  display: 'flex',
  gap: '8px',
  padding: '1% 0 0 0',
  alignItems: 'center'
});

const ButtonClose = {
  width: '180px',
  height: '56px',
  padding: '4px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  textAlign: 'center' as 'center',
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: '-0.408px',
  borderRadius: '70px',
  color: '#fff',
  textTransform: 'capitalize' as 'capitalize',
  border: '1px solid #fff',
 
};

const webStyle = {
  dialogTitleStyle: {
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    fontSize: 'clamp(22px, 32px, 5vw)',
    fontWeight: 600 as 600,
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.408px',
    color: '#fff',
    textAlign: 'center' as 'center',
    padding: '5px 10px',
    marginBottom: '15px'
  },
  dialogContentStyle: {
    marginBottom: '15px',
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    fontSize: 'clamp(16px, 24px, 4vw)',
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
  },
  dialogButtonStyle: {
    width: '180px',
    fontSize: '16px',
    height: '56px',
    padding: '4px 8px',
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    textTransform: 'capitalize' as 'capitalize'
  }
}

// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import UserContext from "../../../components/src/UserProvider";
import ErrorTooltip from "../../../components/src/ErrorTooltip.web";
import Loader from "../../../components/src/Loader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiBackdrop: {
      'root': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }
    },
    MuiDialog: {
      'paperWidthSm': {
        maxWidth: '694px',
        padding: '50px 0',
        background: '#212226'
      },
    },
    MuiFilledInput: {
      'inputMarginDense': {
        backgroundColor: 'white',
        padding: '12px',
        paddingTop: '12px',
        paddingBottom: '12px',
      },
      'root': {
        height: '48px'
      },
      'input': {
        height: '24px'
      }
    },
    MuiSelect: {
      'selectMenu': {
        height: '24px',
        backgroundColor: 'white',
      },
      'filled': {
        backgroundColor: 'white'
      }
    },
    MuiGrid: {
      'grid-xs-6': {
        marginTop: '10px',
        flexBasis: '48%',
      },
      'grid-xs-12': {
        marginTop: '7px',
        height: '155px',
      } 
    }
  }
});

export default class EmailAccountLoginBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    EmailAccountRegistrationController.contextType = UserContext
    // Customizable Area End
  }
  // Customizable Area Start

  private activeButtonStyle: React.CSSProperties = {
    // ...this.buttonStyle,
    backgroundColor: 'white',
    color: 'black',
     textTransform:'capitalize'
  };

  private inactiveButtonStyle: React.CSSProperties = {
    // ...this.buttonStyle,
    backgroundColor: '',
    color: 'white',
    textTransform:'capitalize'
  };

  LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[2],
      fontSize: '16px',
      alignItems: 'center',
      width: '110%',
      padding: '10px',
      maxWidth: 'none',
      left: "40%"
    },
    arrow: {
      fontSize: 20,
      left: '4px' as any,
      margin: '-4% 0% 0% -1%',
      color: "rgba(134, 118, 118, 0.9)",
      "&::before": {
        backgroundColor: "white",
      }
    }
  }))(Tooltip);

  ButtonClose = styled(Button)({
    width: '180px',
    height: '56px',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    color: '#fff',
    textTransform: 'capitalize' as 'capitalize',
    border: '1px solid #fff',
    "&:hover": {
      border: "1.5px solid #F4CD46",
    },
  });

  HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: 'white',
      color: '#222',
      fontSize: '14px',
      fontFamily: 'Inter',
      border: '1px solid #808080',
      letterSpacing: '-0.408px',
      width: '100%',
      padding: '13% 9% 16% 12%',
      maxWidth: 'none',
    },
    arrow: {
      fontSize: 20,
      left: 0,
      margin: '-4% 0% 0% -1%',
      color: "rgba(134, 118, 118, 0.9)",
      "&::before": {
        backgroundColor: "white",
      }
    }

  }))(Tooltip);


  // Customizable Area End
  render() {
    const renderLoading = () => {
      const keyframes = `
      @keyframes rotation  {
        0% { transform: rotate(0deg) }
        100% { transform: rotate(360deg)}
      }
    `;
  
    return (
      <>
        <style>{keyframes}</style>
        <div style={{ position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',background: 'rgba(0, 0, 0, 0.8)',display: 'flex',justifyContent: 'center',alignItems: 'center',zIndex: 9999,
        }}>
          <Fab
            aria-label="save"
            color="primary"
            style={{width: '56px', height: '56px', padding: 0,
            fontSize: '0.875rem',minWidth: 0,boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
            boxSizing: 'border-box',minHeight: '36px',transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',fontWeight: 500,lineHeight: 1.75,borderRadius: '50%',
            letterSpacing: '0.02857em',textTransform: 'uppercase',backgroundColor: 'black'}}
          >
            <img
              style={{
                fill: 'white', 
                width: '1.5em', height: '1.5em', 
                display: 'inline-block',
                fontSize: '1.4rem', 
                transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', userSelect: 'none', 
                flexShrink: 0, position: 'absolute', zIndex: 1, borderRadius: '9px'
              }}
              src={logo} height={24} width={24} alt="" />
          </Fab>
          <div style={{width: '56px',
            height: '56px',
            borderRadius: '50%',
            display: 'inline-block',
            position: 'absolute',
            background: 'linear-gradient(0deg, #3A3111 33%, #3A3111 33%, #4E4115 66%, #4E4115 66%, #F6CB38 100%)',
            boxSizing: 'border-box',
            animation: 'rotation 1s linear infinite'}}>
            <span style={{content: '',boxSizing: 'border-box',position: 'absolute',
  left: '50%',top: '50%',transform: 'translate(-50%, -50%)',width: '44px',height: '44px',
  borderRadius: '50%',background: 'black'}} ></span>
          </div>
        </div>
      </>
    );
    };
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
         {this.state.loading && renderLoading()}
        <Container maxWidth="md">
          <Box
            sx={{
              flexDirection: "column",
              alignItems: "center",
              padding: "0 8px",
            }}
          >
            <Box>
              <ToggleBox data-test-id='toggleBox'>
                {!this.state.toggleButton ? (
                  <>
                    <BusinessUserButton
                      data-test-id='toggleActive'
                      style={{ ...this.activeButtonStyle }}
                      onClick={this.handleToggle}
                    >
                      Business User
                    </BusinessUserButton>
                    <SubscriberButton
                      data-test-id='toggleInActive'
                      style={this.inactiveButtonStyle}
                      onClick={this.handleToggle}
                    >
                      Subscriber
                    </SubscriberButton>
                  </>
                ) : (
                  <>
                    <BusinessUserButton 
                      data-test-id='toggleActive'
                      style={{ ...this.inactiveButtonStyle }}
                      onClick={this.handleToggle}
                    >
                      Business User
                    </BusinessUserButton>
                    <SubscriberButton
                      data-test-id='toggleInActive'
                      style={{...this.activeButtonStyle , textTransform:'capitalize'}}
                      onClick={this.handleToggle}
                    >
                      Subscriber
                    </SubscriberButton>
                  </>
                )}
              </ToggleBox>
              <TitleTypography variant="h4" component="h2">
                {this.labelTitle}{!this.state.toggleButton ? 'Business User' : 'Subscriber'}
              </TitleTypography>
            </Box>
            <Box sx={{ padding: "20px 0 50px 0" }}>
              <EmailInputBox>
                <Typography style={{
                  color: "#fff", fontFamily: 'Inter', float: "left", fontSize: '16px', fontStyle: 'normal', lineHeight: '22px', /* 137.5% */letterSpacing: '-0.408px', marginBottom:'8px'
                }}>
                  Email
                </Typography>
                <Box style={{position:'relative'}}>
                  <Input
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px 20px',
                        justifyContent: 'center',
                        backgroundColor: "#fff",
                        gap: '12px',
                        flexShrink: 0,
                        border: this.state.emailValidation ? '3px solid red' : 'none'
                      }}
                      fullWidth={true}
                      data-test-id="txtInputEmail"
                      onChange={(e) => this.setEmail(e.target.value)}
                      value={this.state.email}
                    />
                    <ErrorTooltip 
                      message={this.state.isEmailRegistered ? "Email is already registered" : "Please enter a valid email"}
                      visible={this.state.emailValidation}
                    />
                </Box>
              </EmailInputBox>
              <Box sx={{ padding: "20px 0px" }}>
                    <Typography style={{
                      color: "#fff",
                      fontFamily: 'Inter',
                      float: "left",
                      fontSize: '16px',
                      fontStyle: 'normal',
                      lineHeight: '22px',
                      letterSpacing: '-0.408px',
                      marginBottom:'8px'
                    }}>
                      Password
                    </Typography>
                <Box style={{position:'relative'}}>
                  <Input
                      type={this.state.enablePasswordField ? "password" : "text"}
                      style={{ display: 'flex', backgroundColor: "#fff", padding: '10px 20px', justifyContent: 'center', alignItems: 'center', border: !this.state.passwordFieldSatisfied ? '3px solid red' : 'none', gap: '12px', flexShrink: 0 }}
                      data-test-id="txtInputPassword"
                      value={this.state.password}
                      onChange={(e) => this.setPassword(e.target.value.trim())}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            data-test-id="showHidePassword"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {!this.state.enablePasswordField ? (
                              <VisibilityOutlinedIcon style={{color:'black'}}/>
                            ) : (
                              <img src={eyeIconInvisible} alt="" width={24} height={24}/>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      fullWidth={true}
                    />
                    <ErrorTooltip 
                      message={!this.state.password ? "Please enter password" : "Password does not meet requirements"}
                      visible={!this.state.passwordFieldSatisfied}
                    />
                </Box>
                <Box data-test-id='htmlTooltip' style={{ display: 'inline', marginTop: '2%' }} >
                  <StyledBox style={{ marginTop: '5%' }}>
                    Your Password must contain
                  </StyledBox>

                  <FlexBox>
                    <Box>
                      {
                        !this.state.isValidLength ?
                        <CheckCircleIcon style={{ color: '#89898980', width: '24px', height: '24px' }} />
                          :
                          <CheckCircleIcon style={{ color: 'rgba(244, 205, 70, 1)', width: '24px', height: '24px' }} />
                      }
                    </Box>
                    <StyledBox>
                      At least 8 characters
                    </StyledBox>
                  </FlexBox>
                  <FlexBox>
                    <Box>
                      {
                        !this.state.hasUppercase ?
                        <CheckCircleIcon style={{ color: '#89898980', width: '24px', height: '24px' }} />
                          :
                          <CheckCircleIcon style={{ color: 'rgba(244, 205, 70, 1)', width: '24px', height: '24px' }} />
                      }
                    </Box>
                    <StyledBox>
                      At least one uppercase letter
                    </StyledBox>
                  </FlexBox>
                  <FlexBox>
                    <Box>
                      {
                        !this.state.hasLowerCase ?
                        <CheckCircleIcon style={{ color: '#89898980', width: '24px', height: '24px' }} />
                          :
                          <CheckCircleIcon style={{ color: 'rgba(244, 205, 70, 1)', width: '24px', height: '24px' }} />
                      }
                    </Box>
                    <StyledBox>
                      At least one lowercase letter
                    </StyledBox>
                  </FlexBox>

                  <FlexBox>
                    <Box>
                      {
                        !this.state.hasNumber ?
                        <CheckCircleIcon style={{ color: '#89898980', width: '24px', height: '24px' }} />
                          :
                          <CheckCircleIcon style={{ color: 'rgba(244, 205, 70, 1)', width: '24px', height: '24px' }} />
                      }
                    </Box>
                    <StyledBox>
                      At least one number
                    </StyledBox>
                  </FlexBox>

                  <FlexBox>
                    <Box>
                      {
                        !this.state.hasSymbol ?
                        <CheckCircleIcon style={{ color: '#89898980', width: '24px', height: '24px' }} />
                          :
                          <CheckCircleIcon style={{ color: 'rgba(244, 205, 70, 1)', width: '24px', height: '24px' }} />
                      }
                    </Box>
                    <StyledBox>
                      At least one special character
                    </StyledBox>
                  </FlexBox>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  color: '#fff',
                  fontFamily: 'Inter',
                }}
                data-test-id="termsAndConditionBox"
              >
                <DocumentBox>
                  <AcceptBox sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                    data-test-id="termsAndConditionCheckBox"
                    onClick={() => this.handleClickCheckButton()}
                  >
                    {this.state.checkButton ? <CheckCircleIcon style={{ color: 'rgba(244, 205, 70, 1)', paddingRight: '15px' }} /> : <CheckCircleOutlineIcon style={{ paddingRight: '15px' }} />}
                    {this.state.agreeTCtext}
                  </AcceptBox>
                  <DocumentTermsButton
                    data-test-id="termsAndConditionButton"
                    onClick={() => { this.goToTermsAndCondition() }}                    
                  >
                    {this.state.tcText}
                  </DocumentTermsButton>
                  {" and "}
                  <DocumentPrivacyButton
                    onClick={() => this.goToPrivacyPolicy()}
                    data-test-id = "privacyPolicyBtn"
                  >
                    {this.state.privacyPolicyText}
                  </DocumentPrivacyButton>
                  <ErrorTooltip 
                      message="Please accept the Terms and Conditions and Privacy Policy"
                      visible={this.state.tAndCCheckBox}
                      tooltipPosition="top-left"
                    />
                </DocumentBox>
              </Box>
              <SignUpBox>
                <SignupButton
                style={{textTransform:'capitalize'}}
                  data-test-id="btnEmailSignup"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => this.createAccount()}
                >
                  Sign up
                </SignupButton>
              </SignUpBox>
              <LoginBox>
                {this.state.labelOr}
                <Button
                  onClick={() => this.goToLogin()}
                  data-test-id="gotoLoginBtn"
                  style={{
                    color: "white",
                    fontFamily: "Inter",
                    fontWeight: 700,
                    textTransform: 'none',
                    letterSpacing: -0.40799999237060547,
                    fontSize: "clamp(14px, 3vw, 16px)",
                    textDecorationLine: "underline",
                    lineHeight: 'normal',
                    paddingLeft:0
                  }}
                >{this.state.signupText}</Button>
              </LoginBox>
            </Box>
          </Box>
        </Container>

        <Dialog open={this.state.popupValidation} aria-labelledby="form-dialog-title"  style={{ backdropFilter: 'blur(5px)'}}>
          <Typography id="form-dialogue-title" style={webStyle.dialogTitleStyle}>
            <ErrorOutlineOutlinedIcon style={{ color: '#E82929', marginRight: '10px', fontSize: '1em' }}/>
            Verify your email
          </Typography>
                  <DialogContent>
                    <DialogContentText style={{...webStyle.dialogContentStyle, fontWeight: 300}}>
                    You previously started sign up. A new one-time password has been sent to your email address. Please continue below to complete your account setup.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: 'center', gap: '24px', flexWrap: 'wrap' }}>
            
                   <CustomeButton text = "Cancel" style = {ButtonClose} onclicked = {this.handleCloseDialog} primary={false} data-test-id="login-dialog-close-btn" />

                   <CustomeButton text = "Continue" style={{ ...webStyle.dialogButtonStyle, border: '1px solid #F4CD46', }} onclicked ={ this.goToForgotPasswordOTP } primary={true}  data-test-id="signup-dialog-continue-btn" />
                   
                  </DialogActions>
        </Dialog>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}
