import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
interface DeactivateMessage {
    message:string
}

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { getNavigationMessage } from "../../../components/src/Utils";

const verificationStatusMockRes = {
    "message": "success"
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
       isCancel:boolean;
        logInToken: any;
        isDeactivated:any;
        loading:boolean;
        isSubscriber: boolean,
        confirmed:boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class DeactivateAccountController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    deactivateAccountApiId: string = "";
    checkDeactivateAccountApiId: string = "";
    deactivateAccountForNonApiId : string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
          isCancel:false,
          logInToken: null,
          isDeactivated:false,
          loading:false,
          isSubscriber: false,
          confirmed:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(responseJson){
                this.setState({loading:false})
            }
            if (apiRequestCallId === this.deactivateAccountApiId) {
                this.deactivateAccountApiRes(verificationStatusMockRes);
            }
            if(apiRequestCallId === this.checkDeactivateAccountApiId){
                this.checkDeactivateResp(responseJson)
            }
            if(apiRequestCallId === this.deactivateAccountForNonApiId){
                this.deactivateAccountForNonBuesinessResponse(responseJson)
            }
            runEngine.debugLog("Message Recived", message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const logInRes = await getStorageData('logInRes', true);
        const otpConfRes = await getStorageData('otpConfRes', true);
        const isSubscriber = await getStorageData('isSubscriber');

        this.setStateAfterLogIn(logInRes);
        this.stateSetAfterSignup(otpConfRes);
        this.setUserType(isSubscriber);
        this.checkDeactivateAccountApi()
    }

      setUserType=(isSubscriber:any)=>{
        if (isSubscriber === "true") {
          this.setState({ isSubscriber: true });
        }
      }
    setStateAfterLogIn = (logInRes: any) => {
        if (logInRes) {
            this.setState({
                logInToken: logInRes.logInToken
            })
        }
    }
    stateSetAfterSignup = (otpConfRes: any) => {
        if (otpConfRes) {
            this.setState({ logInToken: otpConfRes.token });
        }
    }

    deactivateAccountApi = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.state.logInToken
        };
        this.setState({loading:true})
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.deactivateAccountApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deactivateAccountApiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPutMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    checkDeactivateAccountApi = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.state.logInToken
        };
        this.setState({loading:true})
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.checkDeactivateAccountApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/check_account_deactivation`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

       deactivateAccountApiRes=(res:any)=>{
          if(res){
            this.setState({isDeactivated:true})
            this.closeCancelSubscriptionDialog()
          }
        }
        checkDeactivateResp = (res:any)=>{
            const {is_account_deactivated} = res.account
            if(is_account_deactivated){
                this.setState({isDeactivated:true})
            }
        }

      openCancelSubscriptionDialog=()=>{
     this.setState({isCancel:true})
   }

   closeCancelSubscriptionDialog=()=>{
     this.setState({isCancel:false})
   } 

   onLogout = () => {
    this.context.set({
        loginData: null,
        isLogin: false,
        isSignup: false,
        isSubscriber: null,
        signUpToken: null,
        logInToken:null,
        email:null,
        isProfileCreated:false,
        isRegister:false
    });
    window.localStorage.removeItem('user')
    localStorage.removeItem('isRegister')
    window.localStorage.clear();
    this.removeSessionToken();
    this.send(getNavigationMessage("EmailAccountRegistration", this.props));
}
removeSessionToken = () => {
  const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
msg.addData(
  getName(MessageEnum.SessionResponseToken),
  null
);

this.send(msg);
}

  goBack=()=>{
  history.back()
}
goToLandingPage = () => {
      this.send(getNavigationMessage("LandingPage", this.props));
  }
 
deactivateAccountForNonBusinessUser = ()=>{
    
    const header = {
        "Content-Type": "application/json",
        token: this.state.logInToken
    };
    this.setState({loading:true})
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.deactivateAccountForNonApiId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/deactivate_account_non_business_owner?is_business_owner=false`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

}

deactivateAccountForNonBuesinessResponse = (response:DeactivateMessage)=>{
    if (response.message === "account not found or you're not business user") {
        this.setState({ confirmed: false })
    }
    else {
        this.setState({ confirmed: true })
        setTimeout(() => {
            this.onLogout()

        }, 3000);
    }

}
    // Customizable Area End
}