import React from "react";
// Customizable Area Start
import { Box, Button, Container, Typography } from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
// Customizable Area End

import ForgotPasswordCredController, {
    Props,
} from "./ForgotPasswordCredController";

const LandingPageButton = styled(Button)({
    display: "flex",
    width: "225px",
    height: "56px",
    padding: "1px",
    justifyContent: "center",
    alignItems: "center",
    background: "#000",
    border: "1px solid #F4CD46",
    borderRadius: "50px",
    fontSize: "1rem",
    fontWeight: 600,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
        background: "#F4CD46",
        color: "#000",
    }
});

export default class ForgotPasswordLinkExpired extends ForgotPasswordCredController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Container>
                <Box style={textContainerStyle}>
                    <Typography style={textStyle}>
                        Link is Expired
                    </Typography>
                </Box>
            </Container>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const buttonStyle = {
    display: "flex",
    width: "225px",
    height: "56px",
    padding: "1px",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
    borderRadius: "50px",
    fontSize: "1rem",
    fontWeight: "600",
    letterSpacing: "-0.408px",
    color: "#111"
}
const textStyle = {
    color: "#fff",
    fontSize: "clamp(24px, 3vw, 32px)",
    fontWeight: 700,
    letterSpacing: "-0.72px"
},
textContainerStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}
// Customizable Area End
