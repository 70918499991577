import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Typography,
  } from "@material-ui/core";
  import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
  import CancelIcon from '@material-ui/icons/Cancel';
  import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
// Customizable Area End

import VerifyIdentityFailedController, {
  Props,
} from "./VerifyIdentityFailedController";

export default class VerifyIdentityFailed extends VerifyIdentityFailedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
        <ThemeProvider theme={theme}>
          {this.state.loading && <SpinnerLoader/>}
          <Container style={{ marginBottom: '50px', maxWidth: '100%', padding: '0 6%', fontSize: 'clamp(10px, 16px, 2vw)' }}>
            <Box>
              <Typography style={webStyle.verifyIdentityLabel}>
                Verify Identity
              </Typography>

                <Box sx={webStyle.identityVerifyWrapper}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box sx={webStyle.profileLogoBoxStyle}>
                    <CancelIcon style={{ color: '#fff', width: '60%', height: '60%' }} />
                  </Box>
                </Box>
                <Box>
                  <Typography style={webStyle.textLabelSecond}>
                    We were unable to verify your identity. Please retry or get in touch with the Go QR team.
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                  <MuiStyledButton
                    variant="contained"
                    onClick={this.handleRetryVerification}
                    data-test-id = "retry-verification-btn"
                  >
                    Retry
                  </MuiStyledButton>
                </Box>
              </Box>
              
            </Box>
          </Container>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start

const theme = createTheme({
    overrides: {
      MuiFormControlLabel: {
        label: {
          width: "100%",
        },
      },
    },
  });

const MuiStyledButton = styled(Button)({
    width: "225px",
    height: "56px",
    display: "flex",
    padding: "1px",
    fontFamily: "Inter",
    justifyContent: "center",
    alignItems: "center",
    background: "#000",
    border: "1px solid #F4CD46",
    borderRadius: "50px",
    fontSize: "1em",
    fontWeight: 600,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      color: "#000",
      background: "#F4CD46",
    }
  });
  
  const webStyle = {
    identityVerifyWrapper: {
      display: "flex",
      width: '100%',
      gap: 'clamp(20px, 50px, 5vw)',
      flexDirection: 'column',
    },
    verifyIdentityLabel: {
      color: '#FFF',
      fontFamily: 'Inter',
      fontSize: '2em',
      fontWeight: 700,
      letterSpacing: '-0.408px',
      marginBottom:'clamp(20px, 50px, 5vw)'
    },
    profileLogoBoxStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '195px',
      height: '172px',
    },
    textLabelFirst: {
      color: '#FFF',
      fontFamily: 'Inter',
      fontSize: '2em',
      fontWeight: 600,
      letterSpacing: '-0.408px',
      textAlign: 'center' as 'center',
      marginBottom: 'clamp(20px, 40px, 5vw)'
    },
    textLabelSecond: {
      color: '#FFF',
      fontFamily: 'Inter',
      fontSize: '1.5em',
      fontWeight: 400,
      letterSpacing: '-0.408px',
      textAlign: 'center' as 'center'
    },
    identityVerifyButton: {
      width: '269px',
      height: '56px',
      padding: '4px 8px',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      textAlign: 'center' as 'center',
      fontFamily: 'Inter, sans-serif',
      fontSize: '1.25em',
      fontWeight: 600,
      letterSpacing: '-0.408px',
      borderRadius: '70px',
      textTransform: 'capitalize' as 'capitalize',
      color: '#fff',
      background: '#000',
      border: '0.5px solid #F4CD46'
    },
    submitButton: {
      backgroundColor: "#6200EE",
      color: "#fff",
    },
    cancelButton: {
      backgroundColor: "#FF0000",
    },
  };
// Customizable Area End