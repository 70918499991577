import React from "react";
// Customizable Area Start
import Toolbar from '@material-ui/core/Toolbar';
import { Box, IconButton, Drawer, List, ListItem, Hidden, Theme, withStyles, Paper, styled, Button, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, ThemeProvider, createTheme, Select, MenuItem } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom"
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import Dropdown from "./Dropdown";
import { logo, goQrLogo, profileImage, googleWallet, appleWallet } from "./assets";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { jsPDF } from "jspdf";
// Customizable Area End

import HeaderController, {
    Props, configJSON
} from "./HeaderController.web";
import UserContext from "./UserProvider";
import BusinessDropdown from "./BusinessDropdown";
import LogoutDropdown from "./LogoutDropdown";
import SpinnerLoader from "./SpinnerLoading.web";
import CustomDialog from "./CustomDailog.web";
import CustomeButton from "./CustomeButton.web";

// Customizable Area Start

export const headerStyles = (theme: Theme) => ({
    myQrBtnStyle: {
        fontSize: '20px',
        fontWeight: 500,
        textTransform: 'capitalize' as 'capitalize',
        color: '#fff',
        fontFamily: "Inter",
        letterSpacing: "-0.408px",
        "@media (max-width:910px)": {
            fontSize: "15px"
        },
        "&:hover": {
            color: "#F4CD46"
        }
    },
    navLinkStyle: {
        fontSize: "20px",
        color: "#ffffffff",
        fontWeight: 500,
        textDecoration: "none",
        fontFamily: "Inter",
        textAlign: "center" as const,
        textTransform: "capitalize" as const,
        "@media (max-width:910px)": {
            fontSize: "15px"
        },
        "&:hover": {
            color: "#F4CD46"
        }
    },
    publicLinkStyle: {
        fontSize: "20px",
        color: "#ffffffff",
        fontWeight: 500,
        textDecoration: "none",
        fontFamily: "Inter",
        textAlign: "center" as const,
        textTransform: "capitalize" as const,
        "&:hover": {
            color: "#F4CD46"
        }
    },
    HeaderToolbar: {
        // top: '.5em'
        minHeight: '100px',
        padding: '0 4vw',
        // height: '16vh'
    },
    accountMenu: {
        opacity: "0.3 !important",
    },
    HeaderCustomPopoverRoot2: {
        background: "transparent"
    },
    customPopoverRoot: {

        top: "53px !important",
    },
    ulLink: {
        listStyle: "none",
        display: "flex",
        alignItems: "center",
    },

    ToolbarSecondary: {
        display: "flex",
        justifyContent: "flex-start",
        // minHeight: "0px !important",
        width: "100%",
        "& .ItemText": {
            lineHeight: "32px",
            color: 'rgba(0, 77, 116, 1)',
            fontWeight: 600,
            opacity: '0.9',
            textAlign: "center" as const,
            textDecoration: "none",
            padding: "4px 24px",
            alignItems: 'center',
            fontFamily: 'Manrope',
            borderRadius: '6px',
            background: "transparent",
            fontSize: '24px',

        },
        "& .ToggleButton": {
            height: "42px",
            color: 'white',
        },
    },
    LoginHeaderDrawer: {
        "& .ItemText": {
            color: "#004D74"
        }
    },
    DesktopHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between" as const,
        width: "100%",
        // padding: '40px',
        "& .LeftHeaderMenu": {
            display: "flex",
            alignItems: "center",
            gap: "24px",

            "& .LeftHeaderMenuMobile": {
                display: 'none'
            }
        },
        "& .RightHeaderMenu": {
            display: "flex",
            alignItems: "center",
            "& .HeaderButton": {
                height: "42px",
                color: "#ffffffff"
            },
            "& .PersonButton": {
                border: "1px solid #004d74",
                borderRadius: "6px",
                height: "42px",
                width: "42px",
                margin: "0px 12px",
                "& .PersonIcon": {
                    height: "26px",
                    width: "26px"
                },
            },
        },
        '@media (max-width: 775px)': {
            padding: '16px 0',
        },

    },
    HeaderLogo: {
        height: "69px",
        width: "69px",
        objectFit: "contain" as const,
        // margin: "0px 24px"
    },
    HeaderPorfileIcon: {
        width: '40px',
    },
    MobileHeaderLogo: {
        height: "59px",
        width: "59px",
        objectFit: "contain" as const,
        margin: "24px 0px"
    },
    HeaderProfileIcon: {
        width: 8
    },
    HeaderDiv: {
        display: "flex",
        alignItems: "center",
        gap: "2vw"
    },
    HeaderDrawer: {
        "& .MuiDrawer-paper": {
            minWidth: "180px"
        },
        "& .ItemText": {
            lineHeight: "32px",
            color: "#fff",
            fontWeight: 500,
            fontFamily: "Inter",
            margin: "0px",
            padding: "3px 0px",
            alignItems: 'center',
            width: '155px',
            height: '20px',
            fontSize: '16px',
            textDecoration: 'none'
        },
        typography: {
            padding: theme.spacing(2),
            background: "#fff"
        }
    },

    '@media only screen and (max-width: 775px)': {
        DesktopHeader: {
            "& .RightHeaderMenu": {
                display: "none",
            },
            "& .LeftHeaderMenu": {
                width: '100%',
                justifyContent: 'space-between',

                "& .LeftHeaderMenuMobile": {
                    display: 'block',
                }
            },
        },
        HeaderLogo: {
            display: "none",
        },

    },


})

export const MenuWrapper = styled(Paper)({
    top: '60px',
    left: '665px'
});

const DialogTitleTextStyled = styled(Typography)({
    fontSize: '18px',
    fontWeight: 700,
    color: '#fff',
    "@media(max-width: 450px)": {
        fontSize: '16px',
    }
})

const StyledSelect = styled(Select)({
    width: '100%',
    background: '#212226',
    color: '#fff',
    border: 'none',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Inter',
    opacity: '0.7',
    '&::after': {
        display: 'none',
    },
    '&::before': {
        display: 'none',
    },
    '& .MuiSelect-select': {
        padding: '0 24px 0 0'
    }
});

// Customizable Area End

export class Header extends HeaderController {
    dropdownRef = React.createRef<HTMLDivElement>();
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        HeaderController.contextType = UserContext
        // Customizable Area End

    }
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { branchList } = this.state;
        const array = window.location.pathname.split("/");
        const isRegister = localStorage.getItem('isRegister')
        const open = Boolean(this.state.anchorEl);
        const renderBusinessUserMenu = () => {
            if (this.state.businessOwner === true) {
                return (
                    <>

                        <NavLink to={"/ScanCode"} activeStyle={{ color: '#F4CD46' }} className={classes.navLinkStyle}>
                            {configJSON.scanCode}
                        </NavLink>

                        <NavLink to={"/Customform4"} activeStyle={{ color: '#F4CD46' }} className={classes.navLinkStyle}>
                            {configJSON.subscription}
                        </NavLink>

                        <NavLink to={"/SubscribersList"} activeStyle={{ color: '#F4CD46' }} className={classes.navLinkStyle}>
                            {configJSON.subscriber}
                        </NavLink>
                        <BusinessDropdown
                            {...this.props}
                            userProfilePhoto={this.state.userProfilePhoto}
                            onLogout={this.onLogout}
                            isBusinessOwner={true}
                            isBranchPermission={true}
                        />
                        <NavLink to={"/VisualAnalyticsWeb"} activeStyle={{ color: '#F4CD46' }} className={classes.navLinkStyle}>
                            {configJSON.reporting}
                        </NavLink>
                    </>
                )
            } else {
                return (
                    <>
                        {this.state.businessUserPermissions.general_user &&
                            <NavLink to={"/ScanCode"} activeStyle={{ color: '#F4CD46' }} className={classes.navLinkStyle}>
                                {configJSON.scanCode}
                            </NavLink>}
                        {this.state.businessUserPermissions.manage_subscription &&
                            <NavLink to={"/Customform4"} activeStyle={{ color: '#F4CD46' }} className={classes.navLinkStyle}>
                                {configJSON.subscription}
                            </NavLink>}
                        {this.state.businessUserPermissions.manage_subscribers &&
                            <NavLink to={"/SubscribersList"} activeStyle={{ color: '#F4CD46' }} className={classes.navLinkStyle}>
                                {configJSON.subscriber}
                            </NavLink>}
                        <BusinessDropdown
                            {...this.props}
                            userProfilePhoto={this.state.userProfilePhoto}
                            onLogout={this.onLogout}
                            isBusinessOwner={false}
                            isBranchPermission={this.state.businessUserPermissions.manage_business}
                        />

                        {this.state.businessUserPermissions.reporting &&
                            <NavLink to={"/VisualAnalyticsWeb"} activeStyle={{ color: '#F4CD46' }} className={classes.navLinkStyle}>
                                {configJSON.reporting}
                            </NavLink>}
                    </>
                )
            }
        }
        const renderBusinessMobileUserMenu = () => {
            if (this.state.businessOwner === true) {
                return (
                    <>
                        <ListItem button >
                            <Link to="/ScanCode" className="ItemText" >
                                {configJSON.scanCode}
                            </Link>
                        </ListItem>
                        <ListItem button >
                            <Link to="/Customform4" className="ItemText" >
                                {configJSON.subscription}
                            </Link>
                        </ListItem>
                        <ListItem button >
                            <Link to="/SubscribersList" className="ItemText">
                                {configJSON.subscriber}
                            </Link>
                        </ListItem>
                        <Accordion style={{ background: 'black' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ width: '120' }} className="ItemText">{configJSON.business}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: 'column', marginLeft: '12' }}>
                                <Link style={{ fontSize: '14' }} className="ItemText" to="/CreateNewBusiness">{configJSON.businessProfile}</Link>
                                <Link style={{ fontSize: '14', marginTop: '10' }} className="ItemText" to="/BusinessBranches">{configJSON.branches}</Link>
                            </AccordionDetails>
                        </Accordion>
                        <ListItem button >
                            <Link to="/VisualAnalyticsWeb" className="ItemText">
                                {configJSON.reporting}
                            </Link>
                        </ListItem>
                    </>
                )
            } else {
                return (
                    <>
                        {this.state.businessUserPermissions.general_user &&
                            <ListItem button >
                                <Link to="/ScanCode" className="ItemText" >
                                {configJSON.scanCode}
                                </Link>
                            </ListItem>
                        }
                        {this.state.businessUserPermissions.manage_subscription &&
                            <ListItem button >
                                <Link to="/Customform4" className="ItemText" >
                                {configJSON.subscription}
                                </Link>
                            </ListItem>}

                        {this.state.businessUserPermissions.manage_subscribers &&
                            <ListItem button >
                                <Link to="/SubscribersList" className="ItemText">
                                {configJSON.subscriber}
                                </Link>
                            </ListItem>}
                        <Accordion style={{ background: 'black' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ width: '120' }} className="ItemText">{configJSON.business}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: 'column', marginLeft: '12' }}>
                                <Link style={{ fontSize: '14' }} className="ItemText" to="/CreateNewBusiness">{configJSON.businessProfile}</Link>
                                {
                                    this.state.businessUserPermissions.manage_business &&
                                    <Link style={{ fontSize: '14', marginTop: '10' }} className="ItemText" to="/BusinessBranches">{configJSON.branches}</Link>}
                            </AccordionDetails>
                        </Accordion>

                        {this.state.businessUserPermissions.reporting &&
                            <ListItem button >
                                <Link to="/VisualAnalyticsWeb" className="ItemText">
                                    {configJSON.reporting}
                                </Link>
                            </ListItem>}
                    </>
                )
            }
        }


        const downloadPDF = async () => {

            const { userSubscriberQrDetails, userBusinessDetails, isSubscriber, userName } = this.state;
            if (userSubscriberQrDetails || userBusinessDetails) {
                const doc = new jsPDF();

                const img1 = new Image();
                img1.src = goQrLogo;

                const img2 = new Image();
                img2.src = userBusinessDetails.photo || profileImage

                doc.setFillColor("#212226");
                doc.rect(5, 70, doc.internal.pageSize.getWidth() - 10, doc.internal.pageSize.getHeight() - 85, 'F');

                doc.addImage(img1, 'PNG', 10, 10, 50, 50); // Adjust dimensions as needed
                // Embed the first image and the name in the PDF
                doc.setFontSize(16);
                doc.setTextColor(255, 255, 255)

                {isSubscriber === "false" && doc.addImage(img2, 'PNG', 70, 95, 10, 10)}

                doc.setDrawColor("#212226");
                for (let i = 5; i <= 7; i += 0.05) {
                    doc.circle(75, 100, i, "S");
                }
                doc.clip();

                doc.text(isSubscriber === "true" ? userName : userBusinessDetails.business_name, 85, 102)

                // Create a new image object for the second image
                const img3 = new Image();
                // Assuming imageUrl2 is the URL for the second image
                img3.src = isSubscriber === "true" ? userSubscriberQrDetails.qr_code_url : userBusinessDetails.qr_code_url;
                await new Promise((resolve, reject) => {
                    img3.onload = resolve;
                    img3.onerror = reject;
                });

                // Embed the second image in the PDF
                doc.addImage(img3, 'PNG', 50, 120, 100, 100); // Adjust position and dimensions as needed

                // Save the PDF
                doc.save('dialog.pdf');
            }

        };

        return (
            <>
                <ThemeProvider theme={theme}>
                    <Toolbar className={classes.HeaderToolbar} component="nav" variant="dense" >
                        {this.state.loading && <SpinnerLoader />}
                        <Box className={`${classes.ToolbarSecondary}`}>

                            {/*** Desktop Header ***/}
                            <Box className={classes.DesktopHeader} style={{ display: 'flex', justifyContent: 'space-between' }} >

                                <Box className="LeftHeaderMenu"  >
                                    <Box className="LeftHeaderMenuMobile">
                                        {(this.state.isLogin || this.state.isProfileCreated == "true") ? <IconButton
                                            color="inherit"
                                            className="ToggleButton"
                                            aria-label="open drawer"
                                            onClick={this.handleDrawerOpen}
                                            edge="start">
                                            <MenuIcon />
                                        </IconButton>
                                            : 
                                            <img className={classes.MobileHeaderLogo} src={logo} alt="Logo Img" crossOrigin="anonymous" />}
                                    </Box>
                                    <Button
                                        style={{ cursor: "pointer", padding: 0, margin: 0 }}
                                        onClick={this.handleLogoClick}
                                    >
                                        <img className={classes.HeaderLogo} src={logo} alt="Logo Img" crossOrigin="anonymous" />
                                    </Button>
                                    {this.state.branchList.length > 0 &&
                                        <Box style={webstyles.branchListBoxStyle}>
                                            <Typography style={webstyles.businessNameTextStyle}>{this.state.userBusinessDetails?.business_name}</Typography>
                                            <StyledSelect
                                                id="demo-simple-select"
                                                value={this.state.selectedBranchId}
                                                onChange={this.handleBranchChange}
                                                onOpen={this.updateBranchList}
                                            >
                                                {branchList.map((listItem: any) => {
                                                    return (
                                                        <MenuItem
                                                            key={listItem[0].id}
                                                            value={listItem[0].id}
                                                            style={{ fontSize: '14px', fontWeight: 400, }}
                                                        >
                                                            {listItem[0].name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </StyledSelect>
                                        </Box>
                                    }
                                </Box>
                                {!this.state.isLogin && this.state.isProfileCreated == "false" && this.state.isPublicUrl &&
                                    <div style={{ display: 'flex', gap: '20px', position: 'absolute', right: '50px' }}>
                                        <NavLink to={"/EmailAccountLoginBlock"} activeStyle={{ color: '#F4CD46' }} className={classes.publicLinkStyle}>
                                            {"Login"}
                                        </NavLink>

                                        <NavLink to={"/EmailAccountRegistration"} activeStyle={{ color: '#F4CD46' }} className={classes.publicLinkStyle}>
                                            {"Sign up"}
                                        </NavLink>
                                    </div>
                                }

                                <Box className="RightHeaderMenu">

                                    {this.state.profileHeaderIcon &&
                                        <div>

                                            <IconButton onClick={this.handleShowLogout} >
                                                <div className="container">
                                                    <style>{`
        

          .menu-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .menu-trigger {
            background: #fff;
            border-radius: 90px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 0px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
            border: none;
            vertical-align: middle;
            transition: box-shadow 0.4s ease;
            margin-left: auto;
          }

          .menu-trigger:hover {
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
          }

          .menu-trigger span {
            font-weight: 700;
            vertical-align: middle;
            font-size: 14px;
            margin: 0 10px;
          }

          .menu-trigger img {
            border-radius: 90px;
          }
          .menu {
            background: #8D8D8D66;
            border-radius: 20px;
            position: absolute;
            top: 60px;
            right: 0;
            width: 200px;
            height: 50px;
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
            opacity: 0;
            visibility: hidden;
            transform: translateY(-20px);
            transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
          }

          .menu.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }

          .menu ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }

          .menu li {
            // border-bottom: 1px solid #dddddd;
          }

          .menu li p {
            text-decoration: none;
            color: #fff;
            padding: 0px 3px 3px 3px;
            margin: 0px 3px 10px 3px;
            display: block;
          }

          .liFlex{
            display:flex !important;
            align-items:center;
            justify-content:center:
          }
          .liFlex a{
            color: #FFF;
            text-decoration: none;
          }
        `}</style>
                                                    <div className="menu-container">
                                                        <Box style={{ height: '56px' }}>

                                                            <nav ref={this.dropdownRef} className={`menu ${this.state.isActive ? "active" : "inactive"}`}>
                                                                {/* {this.state.showLogout &&

                                                                <IconButton onClick={this.handleProfileAccountIcon}>
                                                                    <ul>
                                                                        <li>
                                                                            <p className="liFlex" style={{ marginLeft: '-60px' }}><div><img src={logoutImage} style={{ marginRight: '10px', width: '20px' }} /></div><div style={{ color: '#FFFFFF', marginLeft: '10px', fontSize: '14px' }}>Logout</div></p>
                                                                        </li>
                                                                    </ul>
                                                                </IconButton>
                                                            } */}
                                                            </nav>
                                                        </Box>
                                                    </div>
                                                </div>

                                            </IconButton>
                                        </div>
                                    }
                                    {(this.state.isLogin || this.state.isProfileCreated == "true") &&
                                        <div className={classes.HeaderDiv}>
                                            <Button
                                                className={classes.myQrBtnStyle}
                                                onClick={this.handleMyQrBtn}
                                            >
                                                {configJSON.myQr}
                                            </Button>
                                            
                                            {this.state.isSubscriber == "false" ?
                                                renderBusinessUserMenu()
                                                : <>
                                                    <NavLink to={"/ScanCode"} activeStyle={{ color: '#F4CD46' }} className={classes.navLinkStyle}>
                                                        {configJSON.scanCode}
                                                    </NavLink>

                                                    <NavLink to={"/Subscriptionbilling2"} activeStyle={{ color: '#F4CD46' }} className={classes.navLinkStyle}>
                                                        {"My Subscriptions"}
                                                    </NavLink>
                                                </>
                                            }
                                            <div>
                                                <Dropdown
                                                    {...this.props}
                                                    userProfilePhoto={this.state.userProfilePhoto}
                                                    email={this.state.email}
                                                    userName={this.state.userName}
                                                    onLogout={this.onLogout}
                                                    handleChangePassword={this.handleChangePassword}
                                                />
                                            </div>
                                        </div>}
                                </Box>

                            </Box>
                            {/*** mobile Header ***/}
                            {(this.state.isLogin || this.state.isProfileCreated == "true") && <Box style={{ background: 'black' }}>
                                <Drawer
                                    className={classes.HeaderDrawer}
                                    PaperProps={{
                                        style: {
                                            backgroundColor: 'black', // Setting background color to black
                                        }
                                    }}
                                    variant="temporary"
                                    anchor="left"
                                    hideBackdrop={false}
                                    transitionDuration={500}
                                    open={this.state.drawerStatus}
                                    onClose={this.handleDrawerClose}
                                >
                                    <List style={{ background: 'black' }}>
                                        <ListItem>
                                            <Link
                                                to="/LandingPage"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => { }}
                                            >
                                                <img className={classes.MobileHeaderLogo} src={logo} alt="Logo Img" crossOrigin="anonymous" />
                                            </Link>
                                        </ListItem>


                                        <Accordion style={{ width: '160', background: 'black' }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon style={{ color: '#FFF' }} />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Avatar style={{ width: '60', height: '60' }} alt="Profile" src={this.state.userProfilePhoto} className={classes.large} />
                                            </AccordionSummary>
                                            <AccordionDetails style={{ flexDirection: 'column', }}>
                                                <Link style={{ fontSize: '14' }} className="ItemText" to="/UserProfile">Profile</Link>
                                                <Accordion style={{ background: 'black' }}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography style={{ width: '60', marginTop: '-5px', fontSize: '14', marginLeft: '-16' }} className="ItemText">Settings</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ flexDirection: 'column', }}>
                                                        {(this.state.isSubscriber === "true" || localStorage.getItem("BusinessOwner") != "true") ? null : <Link style={{ fontSize: '14' }} className="ItemText" to="/Membership">Membership</Link>}
                                                        <Button
                                                            style={{ fontSize: '14', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textTransform: 'capitalize', marginTop: '8px' }}
                                                            className="ItemText"
                                                            onClick={this.handleChangePassword}
                                                        >
                                                            Change Password
                                                        </Button>
                                                        {(localStorage.getItem("BusinessOwner") != "true") && <Link style={{ fontSize: '14' }} className="ItemText" to="/DeactivateAccount">Deactivate  Account</Link>}
                                                        <Link style={{ fontSize: '14' }} className="ItemText" to="/TermsConditions">Terms and Conditions</Link>
                                                        <Link style={{ fontSize: '14' }} className="ItemText" to="/PrivacyPolicies">Privacy Policy</Link>
                                                        <Link style={{ fontSize: '14' }} className="ItemText" to="/RefundPolicy">Refund  Policy</Link>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <div style={{ fontSize: '14', marginTop: '-5px' }} className="ItemText" onClick={this.onLogout}>Logout</div>
                                            </AccordionDetails>
                                        </Accordion>

                                        <ListItem button style={{ height: '42px', paddingBottom: '0' }}>
                                            <Button
                                                style={webstyles.myQrBtnMobileStyle}
                                                onClick={this.handleMyQrBtn}
                                            >
                                                {configJSON.myQr}
                                            </Button>
                                        </ListItem>

                                        {this.state.isSubscriber == "false" ?
                                            renderBusinessMobileUserMenu()

                                            : <>
                                                <ListItem button >
                                                    <Link to="/ScanCode" className="ItemText" >
                                                        {configJSON.scanCode}
                                                    </Link>
                                                </ListItem>
                                                <ListItem button >
                                                    <Link to="/Subscriptionbilling2" className="ItemText">
                                                        My Subscriptions
                                                    </Link>
                                                </ListItem>
                                            </>}

                                    </List>
                                </Drawer>
                            </Box>}
                        </Box>
                    </Toolbar>
                    <Dialog open={this.state.isMyQrDialogOpen} aria-labelledby="form-dialog-title">
                        <Box sx={webstyles.dialogStyle}>
                            <Box sx={webstyles.dialogTitleStyle}>
                                {this.state.userBusinessDetails?.photo &&
                                    <Box sx={webstyles.businessPhotoStyle}>
                                        <img src={this.state.userBusinessDetails.photo} style={{ width: '100%', height: '100%' }} />
                                    </Box>
                                }
                                <DialogTitleTextStyled id="form-dialog-title" className={classes.dialogTitleTextStyle}>
                                    {this.state.isSubscriber === "true" ? "My QR" : this.state.userBusinessDetails.business_name}
                                </DialogTitleTextStyled>
                                <CloseIcon
                                    style={{ color: '#fff', position: 'absolute', right: '20px', top: '20px', cursor: 'pointer' }}
                                    onClick={this.handleQrDialogClose}
                                />
                            </Box>
                            <DialogContent style={webstyles.dialogContentStyle}>
                                <Box sx={webstyles.qrImageStyle}>
                                    <img
                                        src={this.state.isSubscriber === "true" ? this.state.userSubscriberQrDetails.qr_code_url : this.state.userBusinessDetails.qr_code_url}
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                </Box>
                            </DialogContent>
                            <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                paddingTop: '20px', textAlign: 'center', gap: '30px'
                            }} >

                                <GoogleButton onClick={this.sendQrToEmail} >
                                <Box sx={webstyles.googleImage}>
                                        <img
                                            src={googleWallet}
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                    </Box>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '3px' }} > 
                                        <span style={{  position: 'relative', top: '5px' }}>Add to </span>
                                        <span style={{ position: 'relative', bottom: '4px' }} >Google Wallet</span> 
                                    </div>
                                </GoogleButton>

                            </div>
                            <DialogActions style={{ justifyContent: 'center', gap: '24px' }}>
                                <DownloadQRButton style={{}} onClick={downloadPDF} data-test-id="">
                                    Download PDF
                                </DownloadQRButton>
                            </DialogActions>
                        </Box>
                    </Dialog>


                    <Dialog open={this.state.sendEmail} aria-labelledby="form-dialog-title">
                        <Typography id="form-dialog-title" style={dialogTitleStyle}>
                            <CheckCircleOutlineIcon style={{ color: '#008000', marginRight: '0px', fontSize: '32px', marginTop: '35px', marginLeft: '50px' }} />
                            <div style={{ marginTop: '80px', marginRight: '50px' }}>Pass has been send to your email.</div>
                        </Typography>
                        <DialogActions style={{ justifyContent: 'center', gap: '24px ', background: '#212226' }}>
                            <OkayButton onClick={this.closeDialogs} data-test-id="cancel-confirm-dialog-close-btn">
                                OK
                            </OkayButton>
                        </DialogActions>
                    </Dialog>

                    <CustomDialog
                        isError={true}
                        open={this.state.openInvalidTokenDialog}
                        actions={[
                            {
                                label: "OK",
                                handler: this.handleInvalidTokenDialogBtn,
                                borderColor: "#F4CD46",
                                testId: 'dialogCloseBtn',
                                primary: true
                            },
                        ]}
                        content="Your session expired, please login again "
                        title="Session Expired"
                    />
                </ThemeProvider>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(headerStyles)(Header);
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    },
    overrides: {
        MuiDialog: {
            'paperWidthSm': {
                maxWidth: '600px',
                width: '40%',
                minWidth: '300px',
                overflowY: "scroll",
                scrollbarWidth: 'none'
            },
        },
        MuiBackdrop: {
            'root': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }
        },
        MuiSelect: {
            'icon': {
                color: '#fff'
            },
            'select': {
                padding: '0 24px 0 0'
            }
        },
        MuiPaper: {
            'root': {
                color: '#fff',
                backgroundColor: '#777777',
            },
        }
    }
});
const webstyles = {
    myQrBtnMobileStyle: {
        fontSize: '16px',
        fontWeight: 500,
        textTransform: 'capitalize' as 'capitalize',
        color: '#fff',
        fontFamily: "Inter",
        paddingLeft: '0px',
    },
    dialogStyle: {
        padding: { xs: '20px', lg: '35px' },
        background: '#212226'
    },
    businessPhotoStyle: {
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        overflow: 'hidden',
        "@media (max-width:450px)": {
            width: '48px',
            height: '48px',
        },
    },
    qrImageStyle: {
        width: '400px',
        height: '400px',
        "@media (max-width:1150px)": {
            width: '350px',
            height: '350px',
        },
        "@media (max-width:990px)": {
            width: '320px',
            height: '320px',
        },
        "@media (max-width:900px)": {
            width: '300px',
            height: '300px',
        },
        "@media (max-width:860px)":{
            width: '280px',
            height: '280px',
        },
        "@media (max-width:800px)": {
            width: '260px',
            height: '260px',
        },
        "@media (max-width:720px)": {
            width: '250px',
            height: '250px',
        },
        "@media (min-width:1100px) and (max-height:750px)": {
            width: '300px',
            height: '300px',
        },
    },
    appleImage:{
        width: '50px',
        height: '40px',
        marginRight: '10px',
        "@media(max-width: 1051px)": {
            width: "40px",
            marginLeft:'3px'
        },
        "@media(max-width: 844px)": {
            width: "28px",
            height:'28px',
            marginLeft:'5px'
        },
        "@media(max-width:900px)":{
            width:'25px',
            height:'28px'
        }
    },
    googleImage:{
        width: '40px',
        height: '40px',
        marginRight: '8px',
        "@media(max-width: 1051px)": {
            width: "30px",
        },
        "@media(max-width: 900px)": {
            width: "28px",
            height:'28px',
            marginLeft:'5px'
        }
    },
    branchListBoxStyle: {
        maxWidth: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#212226',
        borderRadius: '10px',
        flexDirection: 'column' as 'column',
        padding: '8px',
        overflow: 'hidden',
    },
    businessNameTextStyle: {
        fontSize: '20px',
        fontWeight: 500,
        color: '#fff',
        fontFamily: "Inter",
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as const
    },
    dialogTitleStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '10px'
    },
    dialogContentStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        gap: '20px',
        padding: 0,
    }
}

const DownloadQRButton = styled(Button)({
    display: "flex",
    width: "225px",
    height: "56px",
    padding: "1px",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    border: "1px solid #F4CD46",
    borderRadius: "50px",
    fontSize: "1rem",
    fontWeight: 600,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",
    marginTop: '20px',
    "&:hover": {
        background: "#F4CD46",
        color: "#000",
    },
    "@media(max-width: 450px)": {
        width: "215px",
        height: "48px",
        fontSize: "0.8rem",
        marginTop: '10px',
    }
});
const OkayButton = styled(Button)({
    display: "flex",
    width: "225px",
    height: "56px",
    padding: "1px",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    border: "1px solid #F4CD46",
    borderRadius: "50px",
    fontSize: "1rem",
    fontWeight: 600,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",
    marginTop: '20px',
    marginBottom: '30px',
    "&:hover": {
        background: "#F4CD46",
        color: "#000",
    },
    "@media(max-width: 450px)": {
        width: "215px",
        height: "48px",
        fontSize: "0.8rem",
        marginTop: '10px',
    }
});

const AppleButton = styled(Button)({
    
    width: "225px",
    height: "56px",
    padding: "1px",
    
    background: "black",
    border: "1px solid #fff",
    borderRadius: "50px",
    fontSize: "1rem",
    fontWeight: 500,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",

    "@media(max-width: 450px)": {
        width: "100%",
        height: "48px",
        fontSize: "10px",
        marginTop: '12px',
    },
    "@media(max-width: 960px)": {
        width: "100%",
        height: "48px",
        fontSize: "12px",
        marginTop: '12px',
    },
    "@media(max-width: 796px)": {
        width: "100%",
        height: "48px",
        fontSize: "10px",
        marginTop: '12px',
    },
    
});

const GoogleButton = styled(Button)({
    // display: "flex",
    width: "225px",
    height: "56px",
    
    background: "transpatrent",
    border: "1px solid #fff",
    borderRadius: "50px",
    fontSize: "1rem",
    fontWeight: 500,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",

    "@media(max-width: 450px)": {
        width: "100%",
        height: "48px",
        fontSize: "7px",
        marginTop: '12px',
    },
    "@media(max-width: 960px)": {
        width: "100%",
        height: "48px",
        fontSize: "12px",
        marginTop: '12px',
    },
    "@media(max-width: 852px)": {
        width: "100%",
        height: "48px",
        fontSize: "10px",
        marginTop: '12px',
    }, "@media(max-width: 774px)": {
        width: "100%",
        height: "48px",
        fontSize: "8px",
        marginTop: '12px',
    },

});


const buttonStyles2 = {
    width: '225px',
    height: '56px',
    background: 'transpatrent',
    border: '1px solid white',
    borderRadius: '50px',
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '-0.408px',
    color: '#fff',
    '&:hover': {
        background: '#F4CD46',
        color: 'red',
    },
    '@media(max-width: 450px)': {
        width: '215px',
        height: '48px',
        fontSize: '0.8rem',
        marginTop: '10px',
    },
};
const dialogTitleStyle = {
    fontSize: 'clamp(22px, 32px, 5vw)',
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.408px',
    color: '#fff',
    textAlign: 'center' as 'center',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    background: '#212226',

}
const dialogContentStyle = {
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    fontSize: 'clamp(16px, 24px, 4vw)',
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
    background: '#212226'
}
const dialogButtonStyle = {
    width: '180px',
    height: '56px',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    color: '#000',
    textTransform: 'capitalize' as 'capitalize'
}
// Customizable Area End