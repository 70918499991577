import React from 'react';
import { styled } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";



const CustomeButton = (props: any) => {
    const { text, onclicked, primary, style,type } = props
    const defaultStyleForSecondary = {
        display: 'flex',
        width: "225px",
        height: '50px',
        padding: '1px',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        border: '1px solid #fff',
        borderRadius: '70px',
        fontSize: '20px',
        // fontWeight: 600,
        letterSpacing: '-0.408px',
        color: '#fff',
        textTransform: 'capitalize',
        '&:hover': {
            color: 'black',
            backgroundColor: '#FFFFFF'
        }
    };

    const defaulrForPrimary = {
        display: 'flex',
        width: "225px",
        height: '56px',
        padding: '1px',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        border: '1px solid #F4CD46',
        borderRadius: '70px',
        fontSize: '20px',
        // fontWeight: 600,
        letterSpacing: '-0.408px',
        color: 'white',
        textTransform: 'capitalize',
        '&:hover': {
            border: '1px solid #F4CD46',
            color: 'black',
            backgroundColor: '#F4CD46'
        }
    };
    const SecondaryButton = styled(Button)(style ? { ...defaultStyleForSecondary, ...style } : defaultStyleForSecondary);

    const PrimaryButton = styled(Button)(style ? { ...defaulrForPrimary, ...style } : defaulrForPrimary);

    return (
        <>
            {primary ? <PrimaryButton type={type} onClick={onclicked} >{text}</PrimaryButton>
                : <SecondaryButton type={type} onClick={onclicked} >{text}</SecondaryButton>
            }
        </>
    );
};

export default CustomeButton;