import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
import { getNavigationMessage } from "../../../components/src/Utils";
import React from "react";
import { Button } from "@material-ui/core";
import CustomeButton from "../../../components/src/CustomeButton.web";
interface TransactionHistory {
  id: number;
  transaction_type: string;
  date: string;
  user_subscription_id: number;
  created_at: string;
  updated_at: string;
  payment_status: string | null;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pageNo: number,
  rowsPerPage: number,
  subscribersData: any,
  openSubscriberDetail: boolean,
  subscriberProfileDetails: any,
  expandTransactionHistory: boolean,
  openPauseSubscriptionDialog: boolean,
  openCancelSubscriptionDialog: boolean,
  logInToken: any,
  isSubscriptionCanceled: boolean,
  isSubscriptionPaused: boolean,
  loading: boolean,
  branch_id: number,
  transactionHis: any,
  errorDialog: boolean

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubscribersListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubscribersListApiId: string = "";
  cancelSubscriberSubscriptionApiId = "";
  pauseSubscriberSubscriptionApiId = "";
  viewDetailsApi = "";
  transactionHistoryData = [
    { name: "Subscription redeemed", date: "21 August 2023, 5:05pm" },
    { name: "Subscription redeemed", date: "26 August 2023, 6:26pm" },
    { name: "Subscription redeemed", date: "24 July 2023, 3:33pm" },
    { name: "Subscription redeemed", date: "20 June 2023, 3:33pm" },
    { name: "Subscription redeemed", date: "2 June 2023, 3:33pm" },
  ];
  subscriberListRes = {
    "accounts_with_subscriptions": [
      {
        "subscription": {
          "id": 12,
          "name": "Super",
          "description": "Desc of JKL",
          "price": "699.0",
          "number_of_units": 6,
          "name_of_units": "classes",
          "branch_name": "1branch31",
          "duration": 3,
          "per": "month",
          "pauses_allowed": 2,
          "active": true,
          "account_id": 25,
          "branch_id": 72
        },
        "user_subscription_details": {
          "id": 2,
          "account_id": 26,
          "custom_user_subs_subscription_id": 12,
          "created_at": "2023-12-19T13:16:02.607Z",
          "updated_at": "2023-12-19T13:16:02.607Z",
          "is_paused": false,
          "paused_until": null,
          "is_cancle": false,
          "cancle_at": null
        },
        "account_details": {
          "photo": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBVUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--692fea499984a6b368075bcf594d136d4e6bc66d/nihal-karkala-EgtLW0teNyg-unsplash.jpg",
          "subscriber_name": "subsuser1 last1",
          "status": "regular",
          "plan": true
        }
      },
      {
        "subscription": {
          "id": 12,
          "name": "Super",
          "description": "Desc of JKL",
          "price": "699.0",
          "number_of_units": 6,
          "name_of_units": "classes",
          "branch_name": "1branch31",
          "duration": 3,
          "per": "month",
          "pauses_allowed": 2,
          "active": true,
          "account_id": 25,
          "branch_id": 72
        },
        "user_subscription_details": {
          "id": 1,
          "account_id": 27,
          "custom_user_subs_subscription_id": 12,
          "created_at": "2023-12-19T14:11:58.995Z",
          "updated_at": "2023-12-19T14:11:58.995Z",
          "is_paused": false,
          "paused_until": null,
          "is_cancle": false,
          "cancle_at": null
        },
        "account_details": {
          "photo": "",
          "subscriber_name": "subsuser2 last2",
          "status": "regular",
          "plan": true
        }
      }
    ]
  }


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SelectedBranch)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pageNo: 0,
      rowsPerPage: 10,
      subscribersData: [],
      openSubscriberDetail: false,
      subscriberProfileDetails: null,
      expandTransactionHistory: false,
      openPauseSubscriptionDialog: false,
      openCancelSubscriptionDialog: false,
      logInToken: null,
      isSubscriptionCanceled: false,
      isSubscriptionPaused: false,
      loading: false,
      branch_id: 0,
      transactionHis: [],
      errorDialog: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ loading: false })
      }
      if (apiRequestCallId === this.getSubscribersListApiId) {
        this.handleSubscribersListApiRes(responseJson);
        this.setState({ loading: false })
      }
      if (apiRequestCallId === this.cancelSubscriberSubscriptionApiId) {
        this.handleCancelSubscriptionApiRes(responseJson);
        this.setState({ loading: false })
      }
      if (apiRequestCallId === this.pauseSubscriberSubscriptionApiId) {
        this.handlePauseSubscriptionApiRes(responseJson);
        this.setState({ loading: false })
      }
      if (apiRequestCallId === this.viewDetailsApi) {
        this.handleViewDetailsResponse(responseJson.accounts_with_subscriptions.transaction_histories)
      }
      runEngine.debugLog("Message Recived", message);
    }
    if (getName(MessageEnum.SelectedBranch) === message.id) {
      const branchDetails = message.getData(getName(MessageEnum.SelectedBranchData));
      this.setState({ branch_id: branchDetails[0].id })
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const logInRes = await getStorageData('logInRes', true);
    const otpConfRes = await getStorageData('otpConfRes', true);
    this.stateSetAfterSignup(otpConfRes);
    this.setStateAfterLogIn(logInRes);
    this.fetchSubscribersListData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevState.branch_id !== this.state.branch_id) {
      this.fetchSubscribersListData();
    }
  }

  setStateAfterLogIn = (logInRes: any) => {
    if (logInRes) {
      this.setState({
        logInToken: logInRes.logInToken
      })
    }
  }

  stateSetAfterSignup = (otpConfRes: any) => {
    if (otpConfRes) {
      this.setState({ logInToken: otpConfRes.token });
    }
  }

  handleSubscribersListApiRes = (response: any) => {
    if (response.accounts_with_subscriptions) {
      let userData;
      userData = [...response.accounts_with_subscriptions];
      this.setState({ subscribersData: userData, });
    }else if(response.message === "No subscriber found") {
      this.setState({ subscribersData: [], });
    }
  }

  handleViewSubscriberDetails = (row: any) => {
    console.log('reo', row)
    this.setState({
      openSubscriberDetail: true,
      subscriberProfileDetails: row,
    });
    this.fetchDetails(row.user_subscription_details.id)
  }

  handleCancelSubscriptionApiRes = (response: any) => {
    if (!response.error) {
      this.setState({ openCancelSubscriptionDialog: false, isSubscriptionCanceled: true })
    }
  }

  handlePauseSubscriptionApiRes = (response: any) => {
    if (!response.errors) {
      this.setState({ openPauseSubscriptionDialog: false, isSubscriptionPaused: true })
    }
    if (response.errors) {
      this.setState({ errorDialog: true, openPauseSubscriptionDialog: false, isSubscriptionPaused: true })
    }
  }

  handleBackToSubscribersList = () => {
    this.setState({ openSubscriberDetail: false });
  }

  handleTranscationHistoryExpand = () => {
    this.setState({ expandTransactionHistory: !this.state.expandTransactionHistory })
  }

  handleSubscriberPauseBtn = () => {
    this.setState({ openPauseSubscriptionDialog: !this.state.openPauseSubscriptionDialog })
  }

  handleSubscriberCancelBtn = () => {
    this.setState({ openCancelSubscriptionDialog: !this.state.openCancelSubscriptionDialog })
  }
  toTheLandingPage = () => {
    this.send(getNavigationMessage("LandingPage", this.props));
  }

  handleSubscriberRedeemBtn = () => { }

  fetchSubscribersListData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    this.setState({ loading: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getSubscribersListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubscribersListApiEndPoint}${this.state.branch_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  pauseSubscriberSubscriptionApi = async () => {
    const { user_subscription_details } = this.state.subscriberProfileDetails;
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };

    const body = {
      id: user_subscription_details.id
    }
    this.setState({ loading: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.pauseSubscriberSubscriptionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscribersSubscriptionPauseApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  cancelSubscriberSubscriptionApi = async () => {
    const { user_subscription_details } = this.state.subscriberProfileDetails;
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    const body = {
      id: user_subscription_details.id,
    }
    this.setState({ loading: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.cancelSubscriberSubscriptionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscribersSubscriptionCancelApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchDetails = (id: any) => {
    this.setState({ loading: true });
    if (id) {
      const header = {
        token: this.state.logInToken,
        "Content-Type": "application/json",
      };

      const viewDetailsRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.viewDetailsApi = viewDetailsRequestMessage.messageId;

      viewDetailsRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_custom_user_subs/view_details?user_subscription_id=${id}`,
      );

      viewDetailsRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
      viewDetailsRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
      runEngine.sendMessage(viewDetailsRequestMessage.id, viewDetailsRequestMessage);
    }
  }
  handleViewDetailsResponse = (res: TransactionHistory) => {
    this.setState({ loading: false });
    if (res && Array.isArray(res)) {
      const sortedTransactions = res.sort((a: any, b: any) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      this.setState({ transactionHis: sortedTransactions });
    } else {
      console.error("Invalid response or transaction histories data.");
    }
  }


  handleCancelDialogCancelBtn = () => {
    this.setState({ openCancelSubscriptionDialog: false })
  }

  handlePauseDialogCancelBtn = () => {
    this.setState({ openPauseSubscriptionDialog: false })
  }

  handleCancelDialogConfirmBtn = () => {
    this.cancelSubscriberSubscriptionApi();
  }

  handlePauseDialogConfirmBtn = () => {
    this.pauseSubscriberSubscriptionApi();
  }

  handleConfirmationDialogCloseBtn = () => {
    this.setState({ isSubscriptionCanceled: false, isSubscriptionPaused: false, errorDialog: false, openSubscriberDetail: false });
    this.fetchSubscribersListData()
  }
  butttonRender = (data: any) => {
    const buttonCommonStyle = {
      width: '269px',
      height: '56px',
      padding: '4px 8px',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      textAlign: 'center' as 'center',
      fontFamily: 'Inter, sans-serif',
      fontSize: '20px',
      fontWeight: 600,
      letterSpacing: '-0.408px',
      borderRadius: '70px',
      textTransform: 'capitalize' as 'capitalize',
      color: '#fff',
      background: '#000'
    }
    return (
      <>
        {(data?.user_subscription_details?.is_cancle || data?.user_subscription_details?.is_paused) ?
          (
            <>

            </>
          ) :
          (
            <>
            
              <CustomeButton style={{ ...buttonCommonStyle, border: '1px solid #FFF' }} primary={false} text = "Pause" onclicked = {this.handleSubscriberPauseBtn} data-test-id="subscriber-detail-pause-btn" />

              <CustomeButton style={{ ...buttonCommonStyle, border: '1px solid #FFF' }} primary={false} text = "Cancel" onclicked = {this.handleSubscriberCancelBtn} data-test-id="subscriber-detail-cancel-btn" />

              
              </>
          )
        }

      </>
    )
  }
  formatDate = (date: Date) => {
    let nextBillingDate = new Date(date);
    let formattedDate = nextBillingDate.getDate() + ' ' + nextBillingDate.toLocaleString('default', { month: 'short' }) + ' ' + nextBillingDate.getFullYear();  // format - 14 Apr 2024
    return formattedDate;
  }
  convertDateFormat = (date: any) => {
    let newDate = new Date(date);

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
    let day = newDate.getDate();
    let month = months[newDate.getMonth()];
    let year = newDate.getFullYear();
    
    let hours = newDate.getHours();
    let minutes: any = newDate.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    
    let formattedDate = `${day} ${month} ${year}, ${hours}:${minutes}${ampm}`;

    return formattedDate;
  }

  getBranchNameList = (branch: any) => {
    let branchArray;
    if(branch){
      branchArray = JSON.parse(branch);
      return branchArray.join(",");
    }
    return ""
    
  }
  // Customizable Area End
}
