exports.dashboarContentType = "application/json";
exports.getMethod = "GET";
exports.postMethod = "POST";
exports.getRefershTokenEndPoint = "/refresh_token";
exports.logoutEndPoint="/account_devices";
exports.methodTypeApiDelete = "DELETE";
exports.getBusinessDetailsApiEndpoint = "account_block/business";
exports.getUserProfileDetailsApiEndpoint = "account_block/specific_account"
exports.getSubscriberQrApiEndpoint = "account_block/my_qr"
exports.branchListApiEndpoint = "bx_block_branch/joined_business_user_branches"
exports.scanCode = "Scan Code"
exports.myQr = "My QR"
exports.subscription = "Subscriptions"
exports.subscriber = "Subscribers"
exports.reporting = "Reporting"
exports.business = "Business"
exports.businessProfile = "Business Profile"
exports.branches = "Branches"
