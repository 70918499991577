import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { getNavigationMessage } from "../../../components/src/Utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    logInToken: string,
    loading: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class StripeSetupSuccessfullController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    stripeSetupCheckCallId: string = "";
    freeTrialApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            logInToken: "",
            loading: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.stripeSetupCheckCallId) {
                this.handleStripeSetupCheckApiRes(responseJson);
            }
            if (apiRequestCallId === this.freeTrialApiCallId) {
                this.handleFreeTrialApiRes(responseJson);
            }

            runEngine.debugLog("Message Recived", message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const logInRes = await getStorageData('logInRes', true);
        const otpConfRes = await getStorageData('otpConfRes', true);
        const userLoggedIn = await getStorageData("userLoggedIn", true);
        this.stateSetAfterSignup(otpConfRes);
        this.setStateAfterLogIn(logInRes);
        this.checkLoggedIn(userLoggedIn, logInRes, otpConfRes);
    }

    checkLoggedIn = (user: any, logInRes: any, otpConfRes: any) => {
        if (user) {
            this.send(getNavigationMessage("LandingPage", this.props));
            this.stripeSetupCheckApiCall();
        } else if (!logInRes && !otpConfRes) {
            window.localStorage.clear();
            this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
        }
    }

    setStateAfterLogIn = (logInRes: any) => {
        if (logInRes) { 
            this.setState({ logInToken: logInRes.logInToken }) ;
            this.stripeSetupCheckApiCall();
        }
    }
    stateSetAfterSignup = (otpConfRes: any) => {
        if (otpConfRes) {
            this.setState({ logInToken: otpConfRes.token });
            this.stripeSetupCheckApiCall();
        }
    }

    handleFreeTrialBtn = () => {

        this.setState({ loading: true });

        const originUrl = window.location.origin;
        const header = {
            "Content-Type": "application/json",
            token: this.state.logInToken
        };
        const body = {
            "success_url": `${originUrl}/LandingPage`,
            "cancel_url": `${originUrl}/FreeTrialFailed`
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.freeTrialApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.freeTrialApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    stripeSetupCheckApiCall = async () => {
        this.setState({ loading: true })
        const header = {
            "Content-Type": "application/json",
            token: this.state.logInToken
        };
        
        const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.stripeSetupCheckCallId = message.messageId;
        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.stripeSetupCheckEndpoint
        );
        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(message.id, message);
    }

    handleStripeSetupCheckApiRes = async (response: any) => {
        
        if (response.errors || response.error) {
            this.send(getNavigationMessage("StripeSetupFailed", this.props)); 
        } else {
            this.setState({loading: false});
        }
    }

    handleFreeTrialApiRes = async (response: any) => {
        this.setState({ loading: false });
        const { url } = await response.data;

        if (url) {
            removeStorageData('isLogin')
            setStorageData("isProfileCreated", "true");
            removeStorageData('isRegister')
            setStorageData("userLoggedIn", true);
            window.location.href = url;
        }
    }
    // Customizable Area End
}