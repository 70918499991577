import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

import { getStorageData } from "framework/src/Utilities";
import { getNavigationMessage } from "../../../components/src/Utils";
interface LogInResponse {
    logInToken: string;
}
interface OtpResponse {
    token: string
}
interface PasswordChangeResponse {
    message: string;
    meta: string;
    error?: string[];
    errors?: string[];
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    enablePasswordField: boolean;
    confirmenablePasswordField: boolean;
    forgotpasswordFieldSatisfied: boolean;
    Forgotpassword: string,
    succesScreen: boolean,
    confirmForgotpassword: string,
    isForgotValidLength: boolean;
    hasForgotUppercase: boolean,
    hasForgotLowerCase: boolean,
    hasForgotNumber: boolean,
    hasForgotSymbol: boolean,
    iSForgotValidPassword: boolean,
    errMessage:string,
    logInToken:string,
    errorDialog:boolean,
    errorMessage :string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ChangeNewPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    changePasswordApi:string = ""

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        this.state = {
            loading: false,
            enablePasswordField: true,
            confirmenablePasswordField: true,
            isForgotValidLength: false,
            hasForgotUppercase: false,
            hasForgotLowerCase: false,
            hasForgotNumber: false,
            hasForgotSymbol: false,
            iSForgotValidPassword: false,
            forgotpasswordFieldSatisfied: true,
            Forgotpassword: "",
            succesScreen: false,
            confirmForgotpassword: "",
            errMessage:"",
            logInToken:"",
            errorDialog:false,
            errorMessage :""
        };

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const logInRes = await getStorageData('logInRes', true);
        const otpConfRes = await getStorageData('otpConfRes', true);

        this.setStateAfterLogIn(logInRes);
        this.stateSetAfterSignup(otpConfRes);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                this.setState({ loading: false })
            }

            if (apiRequestCallId === this.changePasswordApi) {
                this.resetPasswordApiRes(responseJson);
            }
            runEngine.debugLog("Message Recived", message);
        }
        // Customizable Area End 
    }

    // Customizable Area Start

    setStateAfterLogIn = (logInRes: LogInResponse) => {
        if (logInRes) {
            this.setState({
                logInToken: logInRes.logInToken
            })
        }
    }
    stateSetAfterSignup = (otpConfRes: OtpResponse) => {
        if (otpConfRes) {
            this.setState({ logInToken: otpConfRes.token });
        }
    }

    resetPasswordApiRes = (responseJson: PasswordChangeResponse) => {
        if (responseJson?.message) {
            this.setState({succesScreen:true})
        }
    else if (responseJson?.error && responseJson.error.length > 0) {
        const errorMessage = responseJson.error.join(', ');
        this.setState({ errorDialog: true, errorMessage });
    } else if (responseJson?.errors && responseJson.errors.length > 0) {
        const errorMessage = responseJson.errors.join(', ');
        this.setState({ errorDialog: true, errorMessage });
    }

    }


    confirmforgothandleClickShowPassword = () => {
        this.setState({
            confirmenablePasswordField: !this.state.confirmenablePasswordField,
        });
    };
    dialogOkay = ()=>{
        this.setState({errorDialog:false})
    }

    forgothandleClickShowPassword = () => {
        this.setState({ enablePasswordField: !this.state.enablePasswordField});
    };

    setConfirmforgotPassword = (text: string) => {
        this.setState({
            confirmForgotpassword: text,
            forgotpasswordFieldSatisfied: true
        });
        this.validatePassword(text)
    };

    setforgotPassword = (text: string) => {
        this.setState({
            Forgotpassword: text,
            forgotpasswordFieldSatisfied: true
        });
        this.validatePassword(text)
    };

    updatedPassword = () => {

        if (this.state.Forgotpassword.trim() === '' || this.state.confirmForgotpassword.trim() === '') {
            this.setState({ forgotpasswordFieldSatisfied: false, errMessage:"Please enter Password !" });
            return
        }
        if(!this.state.isForgotValidLength){
            this.setState({ errorDialog: true, errorMessage:"Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character" });
            return
        }
        this.setState({ loading: true })
                const header = {
                    "Content-Type": "application/json",
                    token: this.state.logInToken
                };
                const data = {
                    current_password: this.state.Forgotpassword,
                    new_password: this.state.confirmForgotpassword,
                };
                const requestMessage = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                );
                this.changePasswordApi = requestMessage.messageId;

                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    "account_block/change_password"
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    "PUT"
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    JSON.stringify(data)
                );
                
                runEngine.sendMessage(requestMessage.id, requestMessage);

            }
        
    

    validatePassword = (password: string) => {
        const regexUppercase = /[A-Z]/;
        const regexLength = /.{8,}/;
        const regexNumber = /\d/;
        const regexLowerCase = /[a-z]/;
        const regexSymbol = /[!@#$%^&*(),.?":{}|<>]/;

        this.setState({
            hasForgotUppercase: regexUppercase.test(password),
            isForgotValidLength: regexLength.test(password),
            hasForgotNumber: regexNumber.test(password),
            hasForgotLowerCase: regexLowerCase.test(password),
            hasForgotSymbol: regexSymbol.test(password),
        });

        if (this.state.isForgotValidLength && this.state.hasForgotLowerCase && this.state.hasForgotUppercase && this.state.hasForgotNumber && this.state.hasForgotSymbol && !this.isStringNullOrBlank(this.state.Forgotpassword)) {
            this.setState({ iSForgotValidPassword: true })
        }
        else {
            this.setState({ iSForgotValidPassword: false })
        }
    };

    isStringNullOrBlank(str: string) {
        return str === null || str.length === 0;
    }

    goBack = () => {
        this.send(getNavigationMessage("LandingPage", this.props));
    }
    

    // Customizable Area End
}