import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Input,
    Menu,
    MenuItem,
    MuiThemeProvider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    createTheme,
    Modal,
    styled,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
export const configJSON = require("./config");
import CustomTextField from "../../../components/src/CoustomTextfield.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomeButton from "../../../components/src/CustomeButton.web";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

// Customizable Area End

// Customizable Area Start
const defaultTheme = createTheme({
    overrides: {
        MuiInputBase: {
            input: {
                '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
                '&[type="number"]': {
                    MozAppearance: 'textfield',
                },
            },
        },
    }
});

const BranchesMainContainer = styled(Box)({
    width: "75%",
    background: "#000",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    fontSize: 'clamp(10px, 16px, 2vw)',
    '@media (max-width: 950px)': {
        width: "80%",
    },
    '@media (max-width: 650px)': {
        width: "85%",
    },
    '@media (max-width: 450px)': {
        width: "90%",
        marginLeft: "5%",
        marginRight: "5%",
    },
});

const ManageBranchHeader = styled(Box)({
    // height: "15%",
    background: "#000",
    fontFamily: "Inter",
    fontSize: "2em",
    fontWeight: 700,
    // lineHeight: "22px",
    letterSpacing: "-0.408px",
    textAlign: "left",
    color: "#fff",
    alignItems: "center",
    display: "flex",
    marginBottom: "15px",
    '@media (max-width: 1000px)': {
        // height: "10%",
        // fontSize: "24px",
        // fontWeight: 700,
    },
});

const BranchDetailsContainer = styled(Box)({
    // height: "100%",
    width: "70%",
    margin: "auto",
    // marginBottom: '100px',
    // marginTop: '40px',
    background: "#000",
    '@media (max-width: 1000px)': {
        width: "80%",
    },
    '@media (max-width: 600px)': {
        width: "90%",
    },
});

const BranchNameInput = styled(Input)({
    height: "48px",
    width: "100%",
    background: "#424242",
    padding: "10px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "-0.40799999237060547px",
    textAlign: "left",
    color: "#fff",
    marginBottom: "10px"
});

const EditAndBackButtonMainBox = styled(Box)({
    height: "80px",
    background: "#000",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    '@media (max-width: 500px)': {
        height: "70px",
        justifyContent: "center",
    },
    "&:hover": {
        opacity: "0.7"
    },
});

const EditButton = {
    height: "50px",
    width: "220px",
    border: "1.5px solid #fff",
    borderRadius: "53px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.40799999237060547px",
    textAlign: "center",
    color: "#fff",
    textTransform: "none",
    
    '@media (max-width: 1200px)': {
        height: "45px",
        width: "180px",
    },
    '@media (max-width: 700px)': {
        height: "40px",
        width: "150px",
    },
    '@media (max-width: 380px)': {
        height: "35px",
        width: "120px",
    },
};

const foredit = {
    height: "50px",
    width: "220px",
    border: "1.5px solid #fff",
    borderRadius: "53px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.40799999237060547px",
    textAlign: "center",
    color: "#fff",
    textTransform: "none",
   
    '@media (max-width: 1200px)': {
        height: "45px",
        width: "180px",
    },
    '@media (max-width: 700px)': {
        height: "40px",
        width: "150px",
    },
    '@media (max-width: 380px)': {
        height: "35px",
        width: "120px",
    },
};

const BackButton = {
    height: "50px",
    width: "220px",
    border: "1.5px solid #F4CD46",
    borderRadius: "53px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.40799999237060547px",
    textAlign: "center",
    color: "#fff",
    textTransform: "none",
    marginLeft: "20px",
    '@media (max-width: 1200px)': {
        height: "45px",
        width: "180px",
    },
    '@media (max-width: 700px)': {
        height: "40px",
        width: "150px",
    },
    '@media (max-width: 380px)': {
        height: "35px",
        width: "120px",
    },
};
const forback = {
    height: "50px",
    width: "220px",
    border: "1px solid #F4CD46",
    borderRadius: "53px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.40799999237060547px",
    textAlign: "center",
    color: "#fff",
    textTransform: "none",
    marginLeft: "20px",
    '@media (max-width: 1200px)': {
        height: "45px",
        width: "180px",
    },
    '@media (max-width: 700px)': {
        height: "40px",
        width: "150px",
    },
    '@media (max-width: 380px)': {
        height: "35px",
        width: "120px",
    },
};

const AddUserAndSaveButtonMainBox = styled(Box)({
    background: "#000",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    flexWrap: 'wrap',
    gap:'15px',
    '@media (max-width: 500px)': {
        justifyContent: "center",
    },
});

const AddUserButton = styled(Button)({
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "-0.40799999237060547px",
    textAlign: "center",
    height: "50px",
    width: "220px",
    border: "1.5px solid #fff",
    borderRadius: "53px",
    color: "#fff",
    textTransform: "none",
    lineHeight: "22px",
    '@media (max-width: 700px)': {
        height: "40px",
        width: "150px",
    },
    '@media (max-width: 1200px)': {
        height: "45px",
        width: "180px",
    },
    '@media (max-width: 380px)': {
        height: "35px",
        width: "120px",
    },
    "&:hover": {
        border: "1.5px solid #F4CD46",
    },
});
const addButton = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "-0.40799999237060547px",
    textAlign: "center",
    height: "50px",
    width: "220px",
    border: "1.5px solid #fff",
    borderRadius: "53px",
    color: "#fff",
    textTransform: "none",
    lineHeight: "22px",
    '@media (max-width: 700px)': {
        height: "45px",
        width: "180px",
    },
    '@media (max-width: 500px)': {
        height: "40px",
        width: "150px",
    },
    '@media (max-width: 400px)': {
        height: "40px",
        width: "135px",
    },
}

const SaveButton = {
    height: "50px",
    lineHeight: "22px",
    width: "220px",
    border: "1.5px solid #F4CD46",
    letterSpacing: "-0.40799999237060547px",
    textAlign: "center",
    color: "#fff",
    textTransform: "none",
    borderRadius: "53px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    '@media (max-width: 700px)': {
        height: "45px",
        width: "180px",
    },
    '@media (max-width: 500px)': {
        height: "40px",
        width: "150px",
    },
    '@media (max-width: 400px)': {
        height: "40px",
        width: "135px",
    },
};

const TableCellHeader = styled(TableCell)({
    color: "white",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "22px",
    overflow: 'hidden',
    width: "14%",
    padding: "0px",
    textAlign: "center",
    letterSpacing: ".5",
    borderBottomWidth: '0px',
    borderLeft: "1px solid #5B5B5B",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
        width: "0px",
    },
    '@media (max-width: 600px)': {
        fontSize: "8px",
        fontWeight: 500,
    },
});

const TableMainContainer = styled(TableContainer)({
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
        width: "0px",
    }
});

const TableCellHeaderName = styled(TableCell)({
    color: "white",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "22px",
    overflow: 'hidden',
    width: "15%",
    padding: "0px",
    textAlign: "center",
    borderBottomWidth: '0px',
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
        width: "0px",
    },
    '@media (max-width: 600px)': {
        fontSize: "8px",
        fontWeight: 500,
    },
});

const TableCellDetails = styled(TableCell)({
    color: "white",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    overflow: 'hidden',
    width: "15%",
    height: "50px",
    padding: "0px",
    textAlign: "center",
    borderBottomWidth: '0px',
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
        width: "0px",
    },
    '@media (max-width: 600px)': {
        fontSize: "8px",
        fontWeight: 500,
    },
    "& .MuiList-padding": {
        padding: "0px"
    }
});


const TableLockAndEditIconMainCellBox = styled(Box)({
    color: "green",
    marginRight: '0px',
    position: 'absolute',
    right: 1,
    top: "18px"
});

const ModalAddUserModal = styled(Modal)({
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
});

const ModalUserMainBox = styled(Box)({
    boxSizing: "border-box",
    background: "#212226",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
});

const ModalDeleteUserMainBox = styled(ModalUserMainBox)({
    minHeight: "320px",
    width: "clamp(260px, 708px, 90%)",
    padding: "clamp(15px, 50px, 5%)"
});

const ModalDeleteUserTitleText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "32px",
    fontWeight: 600,
    // lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "#fff",
    // marginBottom: "56px"
});

const ModalDeleteUserFollowUpText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "22px",
    fontWeight: 500,
    // lineHeight: "32px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "#fff",
    alignSelf: "center",
    // marginBottom: "43px"
});

const ModalDeleteUserButtonMainBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    gap: '10px'
});

const ModalBusinessOwnerMainBox = styled(ModalUserMainBox)({
    height: "345px",
    width: "652px",
    padding: "56px 102px"
});

const ModalAddUserModalTitleText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "#fff"
});

const ModalAddUserModalFollowUpText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "#fff",
    width: "300px",
    alignSelf: "center"
});

const ModalAddUserModalButtonMainBox = styled(Box)({
    display: "flex",
    justifyContent: "center"
});

const ModalAddUserMainBox = styled(Box)({
    boxSizing: "border-box",
    // height: "536px",
    width: "clamp(300px, 776px, 85%)",
    background: "#212226",
    padding: "48px"
});

const ModalAddUserInnerBox = styled(Box)({
    position: "relative",
});

const ModalDropdownButton = styled(Button)({
    textDecoration: "none",
    fontSize: "16px",
    color: "#fff",
    display: "block",
    border: "none",
    background: "none",
    width: "100%",
    textAlign: "left",
    cursor: "pointer",
    outline: "none",
    textTransform: "none",
});

const ModalAddUserDropDownMainBox = styled(Box)({
    height: "160px",
    flexDirection: "column",
    display: "flex",
    backgroundColor: "#212226",
    width: "90%",
    padding: "0px",
    zIndex: 1,
    position: "absolute",
    overflow: "scroll",
    marginLeft: "5%",
    marginRight: "5%",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
        width: "0px",
    },
    top: "114px",
    boxShadow: "6px 0px 10px 0px #000000"
});

const ModalAddUserButtomMainBox = styled(Box)({
    display: "flex",
    marginTop: "32px",
    justifyContent: "flex-end",
    gap: '20px'
});

const ModalAddUserDropDownMainBoxButton = styled(Button)({
    display: "flex",
    textTransform: "none",
    textAlign: "left",
    justifyContent: "flex-start",
    '&:hover': {
        boxShadow: "0px 6px 10px 0px #000",
    }
});

const ModalAddUserDropDownButtonText = styled(Typography)({
    textAlign: "left",
    color: "#fff",
    marginLeft: "60px",
});

const ModalAddUserButtons = styled(Button)({
    color: "#fff",
    width: "225px",
    height: "48px",
    border: "1px solid #F4CD46",
    borderRadius: "70px",
    textTransform: "none",
    "&:hover": {
        border: "1.5px solid #F4CD46",
        backgroundColor:'#F4CD46',
        color:'black'
    },
    fontSize: "16px",
    fontWeight: 600
});
const AddUser = {
    color: "#fff",
    width: "225px",
    height: "48px",
    border: "1px solid #F4CD46",
    borderRadius: "70px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600
}
const cancelButton = {
    color: "#fff",
    width: "225px",
    height: "48px",
    border: "1px solid #fff",
    borderRadius: "70px",
    // marginRight: "24px",
    textTransform: "none",
   
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: "-0.41px",
};
const ButtonCancle = styled(Button)({
    color: "#fff",
    width: "225px",
    height: "48px",
    border: "1px solid #fff",
    borderRadius: "70px",
    // marginRight: "24px",
    textTransform: "none",
    "&:hover": {
        border: "1.5px solid #F4CD46",
    },
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: "-0.41px",
});

const ButtonDeleteCancle = styled(ButtonCancle)({
    width: "180px",
    height: "56px"
});

const ButtonBusinessCancle = styled(ButtonCancle)({
    width: "180px",
    height: "48px"
});

const ButtonConfirm = styled(Button)({
    color: "#fff",
    width: "180px",
    height: "56px",
    border: "1px solid #fff",
    borderRadius: "70px",
    textTransform: "none",
    "&:hover": {
        border: "1.5px solid #F4CD46",
    },
    fontSize: "16px",
    fontWeight: 600
});

const ButtonBusinessConfirm = styled(ButtonConfirm)({
    width: "180px",
    height: "48px"
});

const CustomMenuMainBox = styled(Box)({
    borderRadius: "10px",
    background: "#000",
});

const CustomMenu = styled(Menu)({
    // right: "110px",
    // position: "fixed",
    // top: "-1px",
    // borderRadius: "10px",
    // padding: "0px",
    // left: "80px",
    // inset: '-1px 110px 0px 65px',
    "& .MuiList-padding-255": {
        padding: "0px"
    },
    "@media (max-width: 880px)": {
        // inset: '0px',
        // right: "0px",
        // position: "fixed",
        // top: "0px",
        // padding: "0px",
        // left: "0px",
    }
});

const CustomMenuMainInnerBox = styled(Box)({
    // height: "73px",
    backgroundColor: "#000",
    // borderRadius: "20px"
});

const CustomMenuInnerBoxBesideBorderArrow = styled(Box)({
    width: "100%",
    background: "#000",
    borderRadius: "20px"
});
const ButtonClose = styled(Button)({
    width: '180px',
    height: '56px',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    color: '#fff',
    textTransform: 'capitalize' as 'capitalize',
    border: '1px solid #fff',
    backgroundColor:'#212226',
    marginBottom:'40px',
    "&:hover": {
      color:'black',
      background:'white'
    },
  });

const CustomMenuArrowStyle = styled(Box)({
    height: "35px",
    width: "35px",
    transform: "rotate(45deg)",
    padding: "0px",
    marginTop: "20px",
    borderTop: "none",
    borderRight: "none",
    background: "#424242",
    position: "fixed"
});

const InvalidBranchText = styled(Typography)({
    color: "red"
})

// Customizable Area End

// Customizable Area Start
import ManageBranchController, {
    Props,
    BranchUerlistStateData,
    SearchUserListState
} from "./ManageBranchController";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
import CustomDialog from "../../../components/src/CustomDailog.web";
// Customizable Area End
export default class ManageBranch extends ManageBranchController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderManageBranch = () => {
        return (
            <BranchesMainContainer>
                <ManageBranchHeader>
                    <ArrowBackIcon onClick={this.businessPage} style={{ position: 'relative', top: '1px', fontSize: '1em', color: 'white', marginRight: '8px', cursor: 'pointer' }} />
                    {
                        this.state.isCreateBranchActiveForTitle ?
                            configJSON.createNewBranchText :
                            configJSON.manageBranchText
                    }
                </ManageBranchHeader>
                <BranchDetailsContainer>
                    {this.renderInput()}
                    {this.renderTable()}
                    {this.state.isCreateBranchActive ? this.addUserAndCreateButton() : !this.state.isEditable ? this.editButton() : this.saveButton()}
                </BranchDetailsContainer>
            </BranchesMainContainer>
        )
    }

    renderaddressInput = () => {
        let inputStyle = webStyles.inputDisable;
        if (this.state.isEditable || this.state.isCreateBranchActive) {
            inputStyle = webStyles.inputEnable;
        }
        return (
            <BranchNameInput
                style={inputStyle}
                disabled={!this.state.isEditable && !this.state.isCreateBranchActive}
                value={this.state.branchAddress}
                data-test-id="input-branch-id"
                onChange={(e) => this.handleChangeField(e.target.value)}
                disableUnderline
            ></BranchNameInput>
        );
    }


    renderInput = () => {
        return (
            <Box>
                <Typography style={webStyles.branchNameTextLabel}>
                    {configJSON.branchNameText}*
                </Typography>

                <BranchNameInput
                    style={this.state.isEditable ? webStyles.inputEnable : this.state.isCreateBranchActive ? webStyles.inputEnable : webStyles.inputDisable}
                    disabled={!this.state.isEditable && !this.state.isCreateBranchActive}
                    value={this.state.branchName}
                    data-test-id="input-branch-id"
                    onChange={(e) => this.handleBranchNameInput(e.target.value)}
                    disableUnderline></BranchNameInput>
                {
                    this.state.isCreateBranchPressed
                    &&
                    !this.validBranchName(this.state.branchName)
                    &&
                    <>
                        <InvalidBranchText>
                            {configJSON.branchNameNotEmptyText}
                        </InvalidBranchText>
                    </>

                }

                <Typography style={{ ...webStyles.branchNameTextLabel, marginTop: '10px' }}>
                    {configJSON.addressFieldName}*
                </Typography>
                {this.renderaddressInput()}

                <Typography style={{ ...webStyles.branchNameTextLabel, marginTop: '10px' }}>
                    {configJSON.branchUsersText}
                </Typography>
            </Box>
        )
    }

    renderTableData = (item: any, index: any) => {
        return (
            <TableRow key={index}>
                <TableCellDetails>{item.attributes.branch.branch_users_name}</TableCellDetails>
                {item.attributes.branch.branch_user_permission.reporting ?
                    <TableCellDetails>
                        <CheckIcon />
                    </TableCellDetails>
                    :
                    <TableCellDetails>
                        <RemoveOutlinedIcon />
                    </TableCellDetails>
                }
                {item.attributes.branch.branch_user_permission.manage_business ?
                    <TableCellDetails>
                        <CheckIcon />
                    </TableCellDetails>
                    :
                    <TableCellDetails>
                        <RemoveOutlinedIcon />
                    </TableCellDetails>
                }
                {item.attributes.branch.branch_user_permission.manage_subscription ?
                    <TableCellDetails>
                        <CheckIcon />
                    </TableCellDetails>
                    :
                    <TableCellDetails>
                        <RemoveOutlinedIcon />
                    </TableCellDetails>
                }
                {item.attributes.branch.branch_user_permission.manage_subscribers ?
                    <TableCellDetails>
                        <CheckIcon />
                    </TableCellDetails>
                    :
                    <TableCellDetails>
                        <RemoveOutlinedIcon />
                    </TableCellDetails>
                }
                <TableCellDetails style={{ position: 'relative' }} align="center">
                    {item.attributes.branch.branch_user_permission.general_user ? <CheckIcon /> : <RemoveOutlinedIcon />}
                    {item.attributes.branch.branch_user_permission.business_owner ?

                        <Tooltip title="Business Owner" placement="right"
                            onOpen={this.handleOpen} arrow
                            style={webStyles.tooltipStyle}>
                            <LockOutlinedIcon style={{
                                color: "#fff",
                                marginRight: '0px',
                                position: 'absolute',
                                right: 1,
                                height: "21px",
                                width: "17px"
                            }} data-test-id='business-owner' />
                        </Tooltip>
                        :
                        this.state.isEditable &&
                        <TableLockAndEditIconMainCellBox>
                            <Button data-test-id="moreVector-button-id"
                                onClick={(event: React.MouseEvent<HTMLElement>) => this.handlePop(event)}>
                                <MoreVertIcon style={{
                                    color: "#fff",
                                    marginRight: '0px',
                                    position: 'absolute',
                                    right: 1,
                                    height: "21px",
                                    width: "17px"
                                }}
                                    onClick={() => { this.handleMoreVector(item, index) }} />
                            </Button>
                            <CustomMenuMainBox>
                                <CustomMenu
                                    style={this.state.screenWidth >= 880 ? webStyles.webEditUserMenuStyle : webStyles.MobileEditUserMenuStyle}
                                    data-test-id="custom-menu-id"
                                    id="customized-menu"
                                    anchorEl={this.state.anchor}
                                    keepMounted
                                    open={this.state.pop}
                                    onClose={this.handleMenuClose}
                                    MenuListProps={{ disablePadding: true }}
                                >
                                    <CustomMenuMainInnerBox>
                                        <CustomMenuInnerBoxBesideBorderArrow>
                                            <CustomMenuArrowStyle></CustomMenuArrowStyle>
                                            <MenuItem onClick={this.handleEditPermission} data-test-id="handle-permission-id"
                                                style={webStyles.menuItemStyle}>
                                                <Typography style={webStyles.customTooltipTextStyle}>
                                                    {configJSON.editPermissionText}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem onClick={this.openDeleteUserModal} style={webStyles.menuItemStyle}>
                                                <Typography style={webStyles.customTooltipTextStyle}>
                                                    {configJSON.deleteText}
                                                </Typography>
                                            </MenuItem>
                                        </CustomMenuInnerBoxBesideBorderArrow>
                                    </CustomMenuMainInnerBox>
                                </CustomMenu>
                            </CustomMenuMainBox>
                        </TableLockAndEditIconMainCellBox>
                    }
                </TableCellDetails>
            </TableRow>
        )
    }

    renderValidData = (item: any, index: any) => {
        if (item.attributes.branch.branch_users_name && item.attributes.branch.branch_users_name.trim() !== "") {
            return <>{this.renderTableData(item, index)}</>
        } else {
            return null
        }
    }

    renderTable = () => {
        return (
            <Box style={webStyles.renderTableMainBox}>
                <TableMainContainer>
                    <Table style={webStyles.tableStyle}>
                        <TableHead style={webStyles.renderTableHeadStyle}>
                            <TableRow>
                                <TableCellHeaderName>{configJSON.tableHeaderNameText}</TableCellHeaderName>
                                <TableCellHeader>{configJSON.tableHeaderReportingText}</TableCellHeader>
                                <TableCellHeader>{configJSON.tableHeaderManageBusinessText}</TableCellHeader>
                                <TableCellHeader>{configJSON.tableHeaderManageSubscriptionText}</TableCellHeader>
                                <TableCellHeader>{configJSON.tableHeaderManageSubscribersText}</TableCellHeader>
                                <TableCellHeader>{configJSON.tableHeaderGeneralUserText}</TableCellHeader>
                            </TableRow>
                        </TableHead>

                        <TableBody style={webStyles.renderTableBodyStyle}>
                            {this.state.userList?.map((item: BranchUerlistStateData, index: number) => {
                                return this.renderValidData(item, index)
                            })}
                        </TableBody>
                    </Table>
                </TableMainContainer>
            </Box>
        )
    }

    editButton = () => {
        return (
            <EditAndBackButtonMainBox>
                <CustomeButton primary={false} data-test-id="edit-button-id" text ={configJSON.editText} style = {foredit} onclicked = {this.editButtonHandle}/>
                <CustomeButton primary={true} data-test-id="back-button-id" text ={configJSON.backText} style = {forback} onclicked = {this.goBackEditButton}/>
            </EditAndBackButtonMainBox>
        )
    }

    saveButton = () => {
        return (
            <AddUserAndSaveButtonMainBox>
                <CustomeButton primary={false} data-test-id="adduser-button-id" text ={configJSON.addUserText} style = {addButton} onclicked = {this.handkeAddUser}/>
                <CustomeButton primary={true} data-test-id="save-button-id" text ={configJSON.saveText} style = {SaveButton} onclicked = {this.putUpdateBranch}/>

            </AddUserAndSaveButtonMainBox>
        )
    }

    addUserAndCreateButton = () => {
        return (
            <EditAndBackButtonMainBox>
                <CustomeButton primary={false} text ={configJSON.addUserText} onclicked={this.handkeAddUser} style ={EditButton} />
                <CustomeButton primary={true} text ={configJSON.createText} onclicked={this.createNewBranch} style ={BackButton} />
            </EditAndBackButtonMainBox>
        )
    }

    modalMakeBusinessOwner = () => {
        return (
            <ModalAddUserModal
                disableAutoFocus
                disableEnforceFocus
                open={this.state.isMakeUserModalOpen}>
                <ModalBusinessOwnerMainBox>
                    <ModalDeleteUserTitleText>
                        {configJSON.makeBusinessOwnerText}
                    </ModalDeleteUserTitleText>
                    <ModalDeleteUserFollowUpText>
                        {configJSON.areYouSureYouWantToMakeText} {this.state.itemNametobeMakeEditDelete} {configJSON.theBusinessOwnerText}
                    </ModalDeleteUserFollowUpText>
                    <ModalDeleteUserButtonMainBox>
                        <ButtonBusinessCancle data-test-id="cancel-modal"
                            onClick={this.handleCancleBusinessOwnerModal}>
                            {configJSON.cancleText}
                        </ButtonBusinessCancle>
                        <ButtonBusinessConfirm data-test-id="confirm-owner-modal"
                            onClick={this.putUpdateBusinessOwner}>
                            {configJSON.confirmText}
                        </ButtonBusinessConfirm>
                    </ModalDeleteUserButtonMainBox>
                </ModalBusinessOwnerMainBox>
            </ModalAddUserModal>
        )
    }

    modalDeleteUser = () => {
        return (
            <ModalAddUserModal
                disableAutoFocus
                disableEnforceFocus
                open={this.state.isDeleteModalOpen}>
                <ModalDeleteUserMainBox>
                    <ModalDeleteUserTitleText>
                        {configJSON.deleteUserText}
                    </ModalDeleteUserTitleText>
                    <ModalDeleteUserFollowUpText>
                        {configJSON.areYouSureYouWantToDeleteText} {this.state.itemNametobeMakeEditDelete}?
                    </ModalDeleteUserFollowUpText>
                    <ModalDeleteUserButtonMainBox>
                        {/* <ButtonDeleteCancle data-test-id="delete-user-id"
                            onClick={this.handleCancleDeleteModal}>
                            {configJSON.cancleText}
                        </ButtonDeleteCancle> */}
                        <CustomeButton 
                        primary={false} 
                        text={configJSON.cancleText} 
                        data-test-id="delete-user-id" 
                        onclicked={this.handleCancleDeleteModal} 
                        style={{width: '180px', height: '56px'}}  
                        />
                        <CustomeButton 
                        primary={true} 
                        text={configJSON.deleteText} 
                        data-test-id="delete-userAPI-id" 
                        onclicked={this.deleteUser} 
                        style={{width: '180px', height: '56px'}}  
                        />
                        {/* <ButtonConfirm data-test-id="delete-userAPI-id"
                            onClick={this.deleteUser}>
                            {configJSON.deleteText}
                        </ButtonConfirm> */}
                    </ModalDeleteUserButtonMainBox>
                </ModalDeleteUserMainBox>
            </ModalAddUserModal>
        )
    }

    modalAddUser = (permission: boolean) => {
        return (
            <ModalAddUserModal
                open={permission} >
                <ModalAddUserMainBox>
                    <ModalAddUserInnerBox>
                        <Typography style={webStyles.modalAddUserTitleText}>
                            {configJSON.addUserText}
                        </Typography>
                        <Box style={webStyles.dropDownMainBox}>
                            <Box style={webStyles.dropDownInnerBox}>
                                <SearchIcon style={webStyles.modalSerachIcon} />
                                {
                                    this.state.isEditPermissionModal ?
                                        <ModalDropdownButton data-test-id="addModal-Data-id"
                                            onClick={this.handleDropdownAddUserModal}>
                                            {this.state.itemNametobeMakeEditDelete}
                                        </ModalDropdownButton>
                                        :
                                        <ModalDropdownButton data-test-id="addModal-dropdown-id"
                                            onClick={this.handleDropdownAddUserModal}>
                                            {this.state.searchUserLabelText}
                                        </ModalDropdownButton>
                                }
                                <ArrowDropDownIcon style={webStyles.modalDownIcon} />
                            </Box>
                        </Box>
                        {this.state.isAddUser && this.state.isDropdown &&
                            <ModalAddUserDropDownMainBox>
                                {this.state.searchUserList?.map((item: SearchUserListState, i: any) => {
                                    if (item.first_name !== null) {
                                        return (
                                            <ModalAddUserDropDownMainBoxButton key={i} data-test-id="adduser-searchlist-id"
                                                onClick={() => { this.handleDropdownListAddUserModal(item) }}>
                                                <ModalAddUserDropDownButtonText>
                                                    {item.first_name} {item.last_name}
                                                </ModalAddUserDropDownButtonText>
                                            </ModalAddUserDropDownMainBoxButton>
                                        )
                                    }
                                    return null
                                })}
                            </ModalAddUserDropDownMainBox>
                        }
                        <Dialog  open={this.state.errorDialog} aria-labelledby="form-dialog-title" >
                            
                            <DialogContent style={{background:'#212226', display:'flex', }} >
                                <DialogContentText style={{ ...dialogContentStyle, fontWeight: 600 , margin:'40px',fontSize:'32px', }}>
                            <ErrorOutlineOutlinedIcon style={{ color: '#E82929', fontSize: '32px', marginRight: '10px',position:'relative', top:'5px' }} />
                                User already added
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'center', gap: '24px', background:'#212226',  }}>
                                <ButtonClose onClick={this.handleConfirmationDialogCloseBtn}
                                    id = "okayId">
                                    Okay
                                </ButtonClose>
                            </DialogActions>
                        </Dialog>
                        <Box style={webStyles.modalPermissionsMainBox}>
                            <Box style={webStyles.modalPermissionSingleBoxes}>
                                <Typography style={webStyles.modalPermissionText}>{configJSON.tableHeaderReportingText}</Typography>
                                <Box data-test-id="adduser-reporting-id"
                                    onClick={this.handleReportingAddUserModal}
                                    style={webStyles.modalCheckBoxWrap}>
                                    <Box style={webStyles.modalCheckBox}>
                                        {this.state.isReporting && <CheckIcon style={webStyles.modalCheckIcon} />}
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={webStyles.modalPermissionSingleBoxes}>
                                <Typography style={webStyles.modalPermissionText}>{configJSON.tableHeaderManageBusinessText}</Typography>
                                <Box data-test-id="adduser-manageBusiness-id"
                                    onClick={this.handleBusinessAddUserModal}
                                    style={webStyles.modalCheckBoxWrap}>
                                    <Box style={webStyles.modalCheckBox}>
                                        {this.state.isManageBusiness && <CheckIcon style={webStyles.modalCheckIcon} />}
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={webStyles.modalPermissionSingleBoxes}>
                                <Typography style={webStyles.modalPermissionText}>{configJSON.tableHeaderManageSubscriptionText}</Typography>
                                <Box data-test-id="adduser-subscription-id"
                                    onClick={this.handleSubscriptionsAddUserModal}
                                    style={webStyles.modalCheckBoxWrap}>
                                    <Box style={webStyles.modalCheckBox}>
                                        {this.state.isManageSubscription && <CheckIcon style={webStyles.modalCheckIcon} />}
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={webStyles.modalPermissionSingleBoxes}>
                                <Typography style={webStyles.modalPermissionText}>{configJSON.tableHeaderManageSubscribersText}</Typography>
                                <Box data-test-id="adduser-subscriber-id"
                                    onClick={this.handleSubscribersAddUserModal}
                                    style={webStyles.modalCheckBoxWrap}>
                                    <Box style={webStyles.modalCheckBox}>
                                        {this.state.isManageSubscribers && <CheckIcon style={webStyles.modalCheckIcon} />}
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={webStyles.modalPermissionSingleBoxes}>
                                <Typography style={webStyles.modalPermissionText}>{configJSON.tableHeaderGeneralUserText}</Typography>
                                <Box data-test-id="general-user-id"
                                    onClick={this.handleGeneralUserAddUserModal}
                                    style={webStyles.modalCheckBoxWrap}>
                                    <Box style={webStyles.modalCheckBox}>
                                        {this.state.isGeneralUser && <CheckIcon style={webStyles.modalCheckIcon} />}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <ModalAddUserButtomMainBox>
                            <CustomeButton primary={false} data-test-id="cancel-id" text ={configJSON.cancleText} style = {cancelButton} onclicked = {this.handleCancleAddUserModal}/>
                                {
                                    this.state.isEditPermissionModal ?
                                        <ModalAddUserButtons data-test-id="update-user-permission-id"
                                            onClick={this.putUpdateUserPermissionOwner}>
                                            {configJSON.saveText}
                                        </ModalAddUserButtons>
                                        :

                                        <CustomeButton primary={true} data-test-id="post-adduser-id" text ={configJSON.addText} style = {AddUser} onclicked = {this.postAddUsers}/>
                                }
                            </ModalAddUserButtomMainBox>
                        </Box>
                    </ModalAddUserInnerBox>
                </ModalAddUserMainBox>
            </ModalAddUserModal>
        )
    }

    createBranchErrorDialogActions = [
        {
            label: "Back",
            handler: this.handleCloseErrorDialog,
            borderColor: "#F4CD46",
            testId: 'dialogCloseBtn',
            primary:true
        }
    ];

    createBranchErrorDialogActionsBtn = [{
        label: "Back", handler: this.handleCloseErrorDialogBtn,
        borderColor: "#F4CD46", testId: 'dialogCloseBtn',primary:true
    }];

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MuiThemeProvider theme={defaultTheme}>
                {this.state.loading && <SpinnerLoader />}
                <Box style={webStyles.container}>
                    {this.renderManageBranch()}
                    {this.state.isEditPermissionModal ? this.modalAddUser(this.state.isEditPermissionModal) : this.modalAddUser(this.state.isAddUser)}
                    {this.modalMakeBusinessOwner()}
                    {this.modalDeleteUser()}
                </Box>
                <CustomDialog open={!!this.state.isbranchError} isError={true} actions={this.createBranchErrorDialogActionsBtn} title={`${this.state.errorText}`} content={""}
                    data-test-id="create-branch-error-dialog" />
                <CustomDialog
                    open={!!this.state.createBranchError}
                    isError={true}
                    actions={this.createBranchErrorDialogActions}
                    title={"Branch limit exceed"}
                    content={this.state.createBranchError}
                    data-test-id="create-branch-error-dialog"
                />
            </MuiThemeProvider>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const dialogContentStyle=  {
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    fontSize: '24px',
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
    marginBottom: '15px'
  }
const webStyles = {
    container: {
        background: "#000",
    },

    branchNameTextLabel: {
        fontFamily: "Inter",
        fontSize: "1em",
        fontWeight: 300,
        // lineHeight: "22px",
        letterSpacing: "-0.40799999237060547px",
        color: "white",
        marginBottom: "5px"
    },

    branchBoxTitleText: {
        fontFamily: "Inter",
        fontSize: "2em",
        fontWeight: 700,
        // lineHeight: "22px",
        letterSpacing: "-0.40799999237060547px",
        textAign: "left",
        color: "#fff",
        '@media (max-width: 1000px)': {
            color: "green"
        },
    },

    inputEnable: {
        background: "#fff",
        color: "#000"
    },

    inputDisable: {
        background: '#212226',
        color: "white"
    },

    branchNameTitleText: {
        fontFamily: "Inter",
        fontSize: "1.5em",
        fontWeight: 600,
        // lineHeight: "32px",
        letterSpacing: "-0.40799999237060547px",
        color: "#fff",
        '@media (max-width: 1200px)': {
            // fontSize: "22px",
            // fontWeight: 200,
        },
    },

    branchNameTotalUserText: {
        fontFamily: "Inter",
        fontSize: "1.3em",
        // lineHeight: "32px",
        letterSpacing: "-0.40799999237060547px",
        color: "#fff",
        '@media (max-width: 1200px)': {
            // fontSize: "22px",
            // fontWeight: 200,
        },
    },

    tableMainContainer: {
        minHeight: "30%",
        background: "#000"
    },

    tableRowStyle: {
        width: "100%",
        height: "0px",
        overflow: 'hidden'
    },

    modalHeaderText: {
        fontFamily: "Inter",
        fontSize: "1.5em",
        fontWeight: 600,
        // lineHeight: "22px",
    },

    renderModalFormControlStyle: {
        display: "flex",
        justifyContent: "center",
    },

    renderModalSelect: {
        width: "450px",
        height: "50px",
    },

    renderModalRoleMainBox: {
        width: "300px"
    },

    renderModalRoleSelectionMainBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    renderModalRoleSelectionCheckbox: {
        height: "10px",
        width: "10px",
        border: "2px solid #000"
    },

    tableStyle: {
        background: "#000",
        width: '100%',
        scroll: "auto",
    },

    tooltipStyle: {
        width: "142px",
        height: "36px"
    },

    menuItemStyle: {
        background: "#424242",
        minHeight: 'auto'
    },

    modalAddUserTitleText: {
        fontFamily: "Inter",
        fontSize: "1.5em",
        fontWeight: 600,
        // lineHeight: "22px",
        letterSpacing: "0px",
        color: "white"
    },

    dropDownMainBox: {
        borderBottom: "1px solid #fff",
        marginTop: "48px"
    },

    dropDownInnerBox: {
        display: "flex",
        alignItems: "center",
        margin: "0 15px"
    },

    modalSerachIcon: {
        color: "#fff"
    },

    modalDownIcon: {
        color: "#fff"
    },

    modalPermissionsMainBox: {
        marginTop: "48px",
        padding: "0 15%"
    },

    modalPermissionSingleBoxes: {
        display: "flex",
        justifyContent: "space-between",
        margin: "auto",
        alignItems: "center",
        paddingBottom: "18px"
    },

    modalPermissionText: {
        fontFamily: "Inter",
        fontSize: "1.2em",
        // lineHeight: "22px",
        color: "#fff",
        letterSpacing: '-0.36px'
    },

    modalCheckIcon: {
        height: "10px",
        width: "10px",
        color: "#fff"
    },

    modalCheckBoxWrap: {
        height: "18px",
        width: "18px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    modalCheckBox: {
        height: "11.5px",
        width: "11.5px",
        padding: "0px",
        border: "1px solid #fff",
        borderRadius: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    renderTableMainBox: {
        display: "flex",
        background: "green",
        maxHeight: "300px"
    },

    renderTableHeadStyle: {
        background: '#212226',
        height: '52px',
        borderRadius: '3px',
        color: '#5B5B5B',
        padding: '0px',
        borderBottomWidth: '0px'
    },

    renderTableBodyStyle: {
        height: "0px"
    },

    customTooltipTextStyle: {
        color: "#fff"
    },
    webEditUserMenuStyle: {
        position: "fixed" as const,
        borderRadius: "10px",
        padding: "0px",
        inset: '-1px 110px 0px 65px',
    },
    MobileEditUserMenuStyle: {
        position: "fixed" as const,
        borderRadius: "10px",
        padding: "0px",
        inset: '0px',
    },

}
// Customizable Area End