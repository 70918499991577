// Customizable Area Start
import React from "react";
import { Input, Tooltip, Typography, withStyles, Theme } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import './CoustomTextfield.css';

export const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Inter' as any,
        display: 'flex',
        padding: '8px',
        maxWidth: 'none',
        position: 'fixed',
        flexDirection: 'row',
        width: "220px",
        left: '120px',
        '@media (max-width: 601px)': {
            left: '0px',
            top: '-15px'
        },
    },
    arrow: {
        fontSize: 15,
        left: 0 as any,
        color: "white",
        "&::before": {
            backgroundColor: "white",
        }
    }
}))(Tooltip);


const CustomTextField = (props: any) => {
    const { istrue, label, name, value, onChange, error, placeholder = '', disabled, type, dataTestId, styles = {}, isDisableStyle, check44 } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Do nothing if input is readonly
        if (!props.readOnly) {
            onChange(event);
        }
    };
    
    

    return <>
        {/* <Typography className="field-lable" style={{marginBottom:"8.42px"}}>{label}</Typography> */}
        <LightTooltip
            PopperProps={{
                disablePortal: true,
            }}
            placement="bottom-start"
            open={!!error}
            disableFocusListener
            arrow
            disableHoverListener
            disableTouchListener
            title={<><ErrorOutlineOutlinedIcon className="error-icon" />{error}</>}
        >
            <Input
                className="input-field"
                name={name}
                placeholder={placeholder}
                value={value}
                type={type}
                disabled={disabled}
                onChange={onChange}
                error={!!error}
                data-test-id={dataTestId}
                style={{
                    backgroundColor: isDisableStyle ? '#212226' : styles.backGroundColor,
                    color: isDisableStyle ? '#fff' : styles.color
                }}
            />
        </LightTooltip>
    </>
};
export default CustomTextField;
// Customizable Area End