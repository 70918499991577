Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.paymentMethodEndpoint = "stripe_payments/payment_methods";
exports.paymentEndpoint = "stripe_payments/payments";
exports.confirmPaymentEndpoint = "stripe_payments/payments/confirm";
exports.getPaymentMethodsMethod = "GET";
exports.createPaymentMethodMethod = "POST";
exports.createPaymentMethod = "POST";
exports.confirmPaymentMethod = "POST";

exports.orderId = "Order ID";
exports.loading = "Loading...";
exports.noPaymentMethods = "No payment methods found.";
exports.loginToSeePaymentMethods = "Please login to see payment methods.";
exports.addPaymentMethod = "Add new payment method";
exports.completePayment = "Complete Payment";
exports.cardNumberLabel = "Card Number";
exports.cardNumberPlaceholder = "Enter your credit card number";
exports.expiryDateLabel = "Expiry Date";
exports.expiryDatePlaceholder = "MM / YYYY";
exports.cvcLabel = "CVC";
exports.cvcPlaceholder = "XXX";
exports.cancelText = "Cancel";
exports.submitText = "Submit";

exports.stripePayment = "Stripe Payment";
exports.successMessage = "Your payment was successful!";
exports.ok = "OK";
exports.stripePayments = "Stripe Integration";
exports.stripePublishableKey = "pk_test_51NToCPKvvrYfpGrwfE9GKiFOeToYsaRJXRxEKbOWksqTZXNhfk9dOlixmTGv6JvmoZatWutN3zWTBAF8GeP5ZQ9800kyue9fi0";
exports.stripeVerifyIdentityEndpoint = "/bx_block_stripe_integration/create_verification_session"
exports.httpPostMethod = "POST"
exports.stripeSetupEndpoint = "bx_block_stripe_integration/setup_stripe"
exports.freeTrialApiEndpoint = "bx_block_stripe_integration/start_thirty_days_trial";
exports.stripeSetupCheckEndpoint = "bx_block_stripe_integration/check_stripe_setup"
exports.stripeSetupText = "Connect to Stripe"
exports.freeTrialFailedText = "Your Free Trial account could not be set up. Please retry or get in touch with the Go QR team."
// Customizable Area End
