import React from "react";
// Customizable Area Start
import { Box, Button, Checkbox, FormControlLabel, InputAdornment, MenuItem, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import CustomTextField from "../../../components/src/CoustomTextfield.web";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Backdrop from '@material-ui/core/Backdrop';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledPaper = styled('div')(({ theme }) => ({
  backgroundColor: "#212226",
  color:"#FFFFFF",
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
}));

const styles = {
  bottom_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: '6%',
    paddingBottom: '2%',
    gap: '4%',
  },
  bottom_btn: {
    borderRadius: '50px',
    width: '35%',
    height: '40px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '-0.408px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
  },
  btn_cancel: {
    border :'1px solid #fff',
    color: '#FFF',
    marginleft: '254px',
    '&:hover': {
      backgroundColor: '#000',
      boxShadow: 'none',
    },
  },
  btn_add: {
    border :'1px solid #F4CD46',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#F4CD46',
      boxShadow: 'none',
    },
  },
};
// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";



const CreateBranchPageButton = styled(Button)({
  display: "flex",
  marginLeft:'911px',
  width: "225px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#fff",
  borderRadius: "50px",
  fontSize: "16px",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#111",
  textTransform: "capitalize",
  "&:hover": {
    background: "#F4CD46"
  }
});

export default class CreateBranch extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start 
    // Customizable Area End
  }

  // Customizable Area Start
  renderBranchTable(user: any) {
    const { branch_user_permission } = user.attributes.branch;
  
    const renderCheckIcon = (isTrue: any) => (
      isTrue ? <CheckIcon style={{ color: "#fff" }} /> : <RemoveOutlinedIcon style={{ color: "#fff" }} />
    );
  
    return (
      <TableRow key={user.id} style={{ background: '#000' }}>
        <TableCell style={{ border: '0px', borderBottomWidth: '0px', borderLeft: '0px #000', borderRight: '0px #000' }} align="center">
          <Typography style={{ fontSize: '16px', fontWeight: 500, color: '#FFF' }}>{user.attributes.account.first_name}</Typography>
        </TableCell>
        <TableCell style={{ borderBottomWidth: '0px', borderLeft: '0px #000', borderRight: '0px #000' }} align="center">{renderCheckIcon(branch_user_permission.reporting)}</TableCell>
        <TableCell style={{ borderBottomWidth: '0px', borderLeft: '0px #000', borderRight: '0px #000' }} align="center">{renderCheckIcon(branch_user_permission.manage_business)}</TableCell>
        <TableCell style={{ borderBottomWidth: '0px', borderLeft: '0px #000', borderRight: '0px #000' }} align="center">{renderCheckIcon(branch_user_permission.manage_subscription)}</TableCell>
        <TableCell style={{ borderBottomWidth: '0px', borderLeft: '0px #000', borderRight: '0px #000' }} align="center">{renderCheckIcon(branch_user_permission.manage_subscribers)}</TableCell>
        <TableCell style={{ borderBottomWidth: '0px', position: 'relative', borderLeft: '0px #000', borderRight: '0px #000' }} align="center">
          {renderCheckIcon(branch_user_permission.general_user)}
          {branch_user_permission.business_owner ?
            <Tooltip title="Business Owner" placement="right" arrow style={{ width: "142px", height: "36px" }}>
              <LockOutlinedIcon style={{ color: "#fff", marginRight: '0px', position: 'absolute', right: 1 }} />
            </Tooltip> :
            <MoreVertIcon style={{ color: "#fff", marginRight: '0px', position: 'absolute', right: 1 }} />
          }
        </TableCell>
      </TableRow>
    );
  }
  // Customizable Area End


  render() {
    // Customizable Area Start
    const { newBranch, creatingBranch, creatingBranchUser, addUser, businessUserResponse , listBusinessUsers, errors} = this.state;
    
    
    return (
      <div style={{ height: "100%", width: '100%' }}>
        {this.state.loading && <SpinnerLoader/>}
        <Typography style={{ fontSize: '32px',marginLeft:'152px',lineHeight: "22px", color:'#FFF',marginTop:'32.58px'}}>Create New Branch</Typography>
        <div style={{ width: '100%', maxWidth: '854px', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px' }}>
                <CustomTextField
                    name="name"
                    label="Branch Name"
                    value={newBranch.name}
                    onChange={this.onBranchNameChange}
                    type="text"
                    error={errors.branch_name}
                    dataTestId="txtInputBranchName"
                    styles={creatingBranch ? branchTextBoxStyle:disabledBoxStyle}
                    disabled={creatingBranch}
                    istrue={true}
                />    
              <Typography style={{marginBottom:"8.42px",fontSize: "16px",fontWeight:300, color: '#FFF', fontFamily: 'Inter',height:'22px',fontStyle:'normal'}}>Branch Users</Typography>
              <TableContainer component={Paper}>
              <Table style={{ width: '100%' }} aria-label="simple table">
            <TableHead style={tableheaderRowStyle}>
              <TableRow>
                <TableCell style={tableheaderStyle} align="center"><Typography style={{ fontSize: '14px', fontWeight: 500 }}>Name</Typography></TableCell>
                <TableCell style={tableheaderStyle} align="center"><Typography style={{ fontSize: '14px', fontWeight: 500 }}>Reporting</Typography></TableCell>
                <TableCell style={tableheaderStyle} align="center"><Typography style={{ fontSize: '14px', fontWeight: 500 }}>Manage Business</Typography></TableCell>
                <TableCell style={tableheaderStyle} align="center"><Typography style={{ fontSize: '14px', fontWeight: 500 }}>Manage Subscriptions</Typography></TableCell>
                <TableCell style={tableheaderStyle} align="center"><Typography style={{ fontSize: '14px', width: '77px', height: '44px', marginLeft: '33px', fontWeight: 500 }}>Manage Subscribers</Typography></TableCell>
                <TableCell style={tableheaderStyle} align="center"><Typography style={{ fontSize: '14px', fontWeight: 500 }}>General User</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.branchResponse.map(user => this.renderBranchTable(user))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
        <div className="create-button" style={{ padding: '5%', marginLeft: '40%', marginRight: 'auto' }}>
        {creatingBranch ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CreateBranchPageButton
              className="edit-branch"
              data-test-id='add-user'
              disabled={!creatingBranch}
              style={{ marginLeft: '50px' }}
              onClick={this.handleEdit}
            >
              Edit
            </CreateBranchPageButton>
            <CreateBranchPageButton
              className="create-branch-page-btn"
              data-test-id='create-branch'
              style={{ marginLeft: '20px' }}
              onClick={this.handleBack}
            >
              Back
            </CreateBranchPageButton>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CreateBranchPageButton
              className="add-user"
              data-test-id='edit-branch'
              style={{ marginLeft: '50px' }}
              onClick={this.handleUserToggle}
            >
              Add User
            </CreateBranchPageButton>
            <CreateBranchPageButton
              className="create-branch-page-btn"
              data-test-id='back'
              style={{ marginLeft: '20px' }}
              onClick={this.handleBack}
            >
              Create
            </CreateBranchPageButton>
          </div>
        )}
      </div>
          { creatingBranchUser &&(<div>
            <StyledModal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={creatingBranchUser}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <StyledPaper>
                <div>
                <Typography style={{ fontSize: '24px',marginLeft:'48px',lineHeight: "22px",marginTop:'48px'}}>Add User</Typography>
                <div style={{ position: 'relative' }}>
      <TextField
        id="search"
        placeholder="Search Users"
        data-test-id='business-users'
        style={{
          width: '680px',
          height: '48px',
          borderBottom: '1px solid #fff',
          marginLeft: '48px',
          marginRight: '48px',
          marginTop: '30px',
          border: 'none',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{color:'#fff'}}/>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" onClick={this.handleOpenUsersDropdown}>
              <ArrowDropDownIcon style={{color:'#fff'}} />
            </InputAdornment>
          ),
          style: { height: '48px' ,color:'#fff',borderBottom: '1px solid #fff',},
        }}
        onClick={this.handleOpenUsersDropdown}
        onChange={this.onBusinessUserChange}
        value={this.state.selectedUser?.first_name || ''}
      />
      {listBusinessUsers && (
        <div
          style={{
            marginLeft: '48px',
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '690px',
            borderTop:'0px',
            background: '#212226',
            maxHeight: '172px',
            overflowY: 'auto',
            zIndex: 1,
            boxShadow:"6px 0px 10px 0px #000000"
          }}
        >
          {this.renderBusinessUsersList()}
        </div>
      )}
    </div>
                <div>
                  <div >
                  <FormControlLabel
                  labelPlacement="start" style={{marginTop:'30px'}}
                  control={
                    <Checkbox
                      checked={addUser.reporting}
                      onChange={this.onReportingChange}
                      color="primary"
                      style={{color:""}}
                      data-test-id='reportingcheck'
                      icon={<div style={{ width: 20, 
                        height: 20, border: '1px solid #fff' }} />}
                      checkedIcon={
                        <div style={{ width: 20, height: 20, 
                        backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid black' }}>
                          <CheckIcon style={{ fontSize: 16, color: 'black' }} />
                        </div>
                      }
                    />
                  }
                  label={<div style={{ marginLeft: '192.5px', 
                  marginRight: '250px' }}>Reporting</div>}
                />
      </div>
      <div >
      <FormControlLabel  labelPlacement="start"
        control={
          <Checkbox
          style={{color:"white"}}
                      icon={<div style={{ width: 20, height: 20, border: '1px solid #fff' }} />}
                      checkedIcon={
                        <div style={{ width: 20, height: 20, backgroundColor: 'white',
                         display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid black' }}>
                          <CheckIcon style={{ fontSize: 16, 
                            color: 'black' }} />
                        </div>
                      }
            checked={addUser.manage_business}
            onChange={this.onManageBusinessChange}
            color="primary"
            data-test-id='manageBusiness'
          />
        }
        label={<div style={{ marginLeft: '192.5px',marginRight: '192px' }}>
          Manage Business</div>}
      />
      </div>
      <div >
      <FormControlLabel  labelPlacement="start"
        control={
          <Checkbox
          style={{color:"white"}}
                      icon={<div
                         style={{ width: 20, height: 20,
                           border: '1px solid #fff' }} />}
                      checkedIcon={
                        <div style={{ width: 20, height: 20, backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #fff' }}>
                          <CheckIcon style={{ fontSize: 16, color: 'black' }} />
                        </div>
                      }
            checked={addUser.manage_subscription}
            onChange={this.onManageSubscriptionChange}
            color="primary"
            data-test-id='manageSubscription'
          />
        }
        label={<div style={{ 
          marginLeft: '192.5px',marginRight: '165px' }}>Manage Subscription</div>}
      />
      </div>
      <div >
      <FormControlLabel  labelPlacement="start"
        control={
          <Checkbox
          style={{color:"white"}}
                      icon={<div style={{ width: 20, height: 20, border: '1px solid #fff' }} />}
                      checkedIcon={
                        <div 
                        style={{ width: 20, height: 20, backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid black' }}>
                          <CheckIcon style={{
                            fontSize: 16, color: 'black' }} />
                        </div>
                      }
            checked={addUser.manage_subscribers}
            onChange={this.onManageSubscriberChange}
            color="primary"
            data-test-id='manageSubscriber'
          />
        }
        label={<div style={{ marginLeft: '192.5px',marginRight: '178px' }}>Manage Subscriber</div>}
      />
      </div>
      <div data-test-id='generalUser'>
      <FormControlLabel  labelPlacement="start"
        control={
          <Checkbox
          style={{color:"white"}}
                      icon={<div style={{ width: 20, height: 20, border: '1px solid #fff' }} />}
                      checkedIcon={
                        <div style={{ width: 20, height: 20, backgroundColor: 'white', display: 'flex', 
                        justifyContent: 'center', alignItems: 'center', 
                        border: '1px solid black' }}>
                          <CheckIcon style={{ fontSize: 16, 
                            color: 'black' }} />
                        </div>
                      }
            checked={addUser.general_user}
            onChange={this.onGeneralUserChange}
            color="primary"
            data-test-id='generalUser'
          />
        }
        label={<div style={{ marginLeft: '192.5px',marginRight: '226px' }}>General User</div>}
      />
      </div>
    </div>
                <Box style={styles.bottom_container}>
                <Button
                style={{ ...styles.bottom_btn, ...styles.btn_cancel, marginLeft: '264px',textTransform:'none'}}
                variant="outlined"
                fullWidth
                onClick={this.handleUserToggle}
                data-test-id='cancel'
              >
                Cancel 
              </Button>

              <Button
                style={{ ...styles.bottom_btn, ...styles.btn_add,textTransform:'none' }}
                variant="outlined"
                fullWidth
                onClick={this.handleUserToggle}
                id="add"
              >
                Add
              </Button>
            </Box>
                </div>
              </StyledPaper>
            </StyledModal>
        </div>)}
      </div> 
    );
     // Customizable Area End
  }
}

// Customizable Area Start

const buttonStyle = {
  display: "flex",
  width: "225px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#fff",
  borderRadius: "50px",
  fontSize: "1rem",
  fontWeight: "600",
  letterSpacing: "-0.408px",
  color: "#111"
}
const welcomeTextStyle = {
  color: "#fff",
  fontSize: "3rem",
  fontWeight: 700,
  letterSpacing: "-0.72px",
}
const branchTextBoxStyle={
  
    width: '854px',
    padding: '0px',
    backGroundColor:'#424242',
    color: '#fff' 
}
const disabledBoxStyle={
  width: '854px',
  padding: '0px',
  backGroundColor:  '#fff',
  color:'#000' 
}

const tableheaderStyle={
   color: 'white',
   width: '143px', 
   padding: '0px',
   borderRight: '1px solid #5B5B5B' 
   ,borderBottomWidth: '0px'  
}
const tableheaderRowStyle={ 
  width: '854px', 
  marginLeft: '531px', 
  marginTop: '458px', 
  background: '#404040', 
  height: '52px', 
  borderRadius: '3px', 
  border: '1px solid #5B5B5B', 
  color: '#5B5B5B', 
  padding: '0px',
  borderBottomWidth: '0px' 
 }

 const tableRowCellStyle={ 
  border:'0px',
  borderBottomWidth: '0px', 
  borderLeft:'0px #000', 
  borderRight:'0px #000'  
}

const iconcolor={
  color:'#fff'
}
// Customizable Area End
