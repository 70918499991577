Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "Don't have an account? ";
exports.labelTitle = "Login";
exports.loginAPiEndPoint1 = "bx_block_login/logins";
exports.singUp = "Sign up";
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.agreeTCtext = "By logging in, you agree our";
exports.tcText = "Terms and Conditions";
exports.privacyPolicyText = "Privacy Policy";
exports.emailErrorMsg = "Please enter a valid email!";
exports.passErrorMsg = "Please enter password!";
exports.emailNotRegister = "Email is not registered";
exports.incorrectPass = "Incorrect password! ";
// Customizable Area End