import React from "react";

// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { AccordionSummary, Container} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { PoliciesWrapGrid } from "./PrivacyPolicies.web";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start    
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Container 
      maxWidth="lg" 
      style={{marginTop:'10px'}}>
        <Grid justifyContent="center" alignItems="center" container xs={12} sm={12} style={{ fontSize: 'clamp(10px, 16px, 2vw)', }}>
          <PoliciesWrapGrid>
            <Box
              id="panel1a-header"
              style={{display: 'flex', gap: '10px', alignItems: 'center'}}
            >
                <ArrowBackIcon onClick={this.goBack} style={{ color: '#fff', cursor: 'pointer', fontSize: '2em',}} data-test-id="arrow-back-icon-btn-create" />
                <Typography color="primary"
                  style={{
                    color: '#FFF',
                    fontFamily: 'Inter',
                    fontSize: '2em',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    letterSpacing: '-0.41px'
                  }}
                >{configJSON.termsConds}

                </Typography>
            </Box>
            <Typography data-test-id="termsCondsList" style={{ display: 'flex', justifyContent: 'center', fontSize: '1em', }} component="div">
              <div style={{
                color: '#FFFBED',
                fontFamily: 'Inter',
                gap: '32px',
                fontStyle: 'normal',
                fontWeight: 300,
                letterSpacing: '-0.408px',
                width: '90%'
              }} dangerouslySetInnerHTML={{
                __html: `
            <style>
              ol {
                list-style-type: none;
                padding-left: 16px;
              }
            </style>
            ${configJSON.termsConditionText}
          ` }} />
            </Typography>
          </PoliciesWrapGrid>
        </Grid>
      </Container>
 )
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
