// Customizable Area Start
import React from "react";
import { FormControl, Select, MenuItem, Typography, styled } from "@material-ui/core";
import './CoustomDropdown.css';

export const StyledSelect = styled(Select)({
    marginBottom: '20px',
    // marginRight: '16px',
    fontSize: '16px',
    display: 'flex',
    backgroundColor: '#fff',
    padding: '10px 18px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    flexShrink: 0,
    height: '48px',
    outline: 'none',
    border: 'none',
    color: '#000',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '-0.408px',
    marginTop: "8px"
});

const CustomDropdown = (props: any) => {
    const { value, onChange, items, label,isDisableStyle, ...rest } = props;
    return (<div>
        <Typography className="custom-label">{label}</Typography>
        <FormControl className="custom-form-control">
            <StyledSelect
                value={value}
                onChange={onChange}
                {...rest}
                style={{ backgroundColor: isDisableStyle ? '#212226': '#fff', color: isDisableStyle ? '#fff': '#000'}}
            >
                {items?.map((item: any) => (
                    <MenuItem key={item.name} value={item.value}>
                        {item.name}
                    </MenuItem>
                ))}
            </StyledSelect>
        </FormControl>
    </div>)

};
export default CustomDropdown;
// Customizable Area End