import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

export interface BranchListResponseData {
  branch: {
    data: {
      id: string;
      type: string;
      attributes: {
        qr_code_url: string;
        qr_pdf_url: string;
        account: {
          account_id: number;
          first_name: string;
        };
        branch: {
          branch_id: number;
          branch_name: string;
          branch_users_name: string;
          branch_user_permission: {
            id: number;
            reporting: boolean;
            manage_business: boolean;
            manage_subscription: boolean;
            manage_subscribers: boolean;
            general_user: boolean;
            business_owner: boolean;
            account_id: number;
            branch_user_id: number;
            created_at: string;
            updated_at: string;
          };
        };
      };
    };
  };
  total_users: number;
}

export interface BusinessdetailsResponse {
  data: [
    {
      id: string;
      type: string;
      attributes: {
        business_name: string;
        address: string;
        company_number: string;
        industry: null;
        business_type: string;
        number_of_branches: number;
        number_of_employees: number;
        expected_number_of_subscribers: number;
        branches: [
          {
            id: number;
            name: string;
            account_id: number;
            created_at: string;
            updated_at: string;
            business_id: number
          }
        ];
        transaction_fee: null;
        photo: null;
        qr_code_url: string;
      }
    }
  ],
  meta: {
    message: string;
  }
}

interface LoginResponseData {
  isLogin: boolean;
  logInToken: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEditable: boolean;
  isAdduserModalopen: boolean;
  token: string;
  branchList: Array<BranchListResponseData>;
  tokenSave: string;
  businessDetails: Array<BusinessdetailsResponse>;
  idOfBusiness: any;
  qrCodeImage: boolean;
  indexID: number;
  loading:boolean;
  itemForImg: BranchListResponseData;
  joinedBranchList:any;
  businessUserPermissions: { [key: string]: any },
    selectedBranch: any,
    selectedBranchId: string,
    isBusinessOwner: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BusinessBranchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getBranchesApiCallId: string = "";
  getBusinessDetailsApiCallId: string = "";
  branchListApiId:string="";
  getUserProfileDetailsApiId= "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationManageButtonMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SelectedBranch)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isEditable: false,
      isAdduserModalopen: false,
      token: "",
      branchList: [],
      joinedBranchList:[],
      businessUserPermissions: {},
      selectedBranch: "",
      selectedBranchId: "",
      tokenSave: "",
      businessDetails: [],
      idOfBusiness: null,
      qrCodeImage: false,
      indexID: 0,
      loading:false,
      itemForImg: {
        branch: {
          data: {
            id: "",
            type: "",
            attributes: {
              qr_code_url: "",
              qr_pdf_url: "",
              account: {
                account_id: 496,
                first_name: ""
              },
              branch: {
                branch_id: 59,
                branch_name: "",
                branch_users_name: "",
                branch_user_permission: {
                  id: 280,
                  reporting: false,
                  manage_business: false,
                  manage_subscription: false,
                  manage_subscribers: false,
                  general_user: false,
                  business_owner: false,
                  account_id: 521,
                  branch_user_id: 277,
                  created_at: "",
                  updated_at: ""
                }
              }
            }
          }
        },
        "total_users": 4
      },
      isBusinessOwner: false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const logInRess = await getStorageData('logInRes', true);
    const otpConfResp = await getStorageData('otpConfRes', true);
    this.stateSetAfterSignup(otpConfResp);
    this.setStateAfterLogIn(logInRess);
    this.getUserProfileDetailsApiCall()
    this.getBusinessDetaisApiCallBack();
    this.branchListApiCall();
  }

  setStateAfterLogIn = (logInResp: LoginResponseData) => {
    if (logInResp) {
      this.setState({
        tokenSave: logInResp.logInToken
      });
    }
  }

  stateSetAfterSignup = (otpConfResp: any) => {
    if (otpConfResp) {
        this.setState({ tokenSave: otpConfResp.token });
    }
}

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(responseJson){
        this.setState({loading:false})
      }
      if (apiRequestCallId === this.getBranchesApiCallId) {
        this.getBranchApiResponse(responseJson?.branch, errorReponse)
        this.setState({loading:false})
      }
      if (apiRequestCallId === this.branchListApiId) {
        this.getBranchListSuccessResponseApi(responseJson?.branch)
      }
      if (apiRequestCallId === this.getBusinessDetailsApiCallId) {
        this.getBusinessDetailsApiResponse(responseJson, errorReponse)
        this.setState({loading:false})
      }
      if (apiRequestCallId === this.getUserProfileDetailsApiId) {
        this.handleUserDetailsApiRes(responseJson)
      }
    }
    if (getName(MessageEnum.SelectedBranch) === message.id) {
      const branchDetails = message.getData(getName(MessageEnum.SelectedBranchData));
      this.setState({ selectedBranchId: branchDetails[0].id, businessUserPermissions: branchDetails[1] })
  }
    // Customizable Area End
  }

  getBranchApiResponse = async (response: BranchListResponseData, errorReponse: string) => {
    if (response != null) {
      if (Array.isArray(response)) {
        this.getBranchSuccessResponseApi(response);
      }
    }
  }

  getBranchListApiResponse = async (response: any, errorReponse: string) => {
    if (response != null) {
      if (Array.isArray(response)) {
        this.getBranchListSuccessResponseApi(response);
      }
    }
  }

  getBranchSuccessResponseApi = async (response: Array<BranchListResponseData>) => {
    this.setState({ branchList: response });
  }

  getBranchListSuccessResponseApi = async (response:any) => {
    this.setState({ joinedBranchList: response });
  }

  testGetApiOfWeb = () => {
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.tokenSave
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBranchesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBranchEndPoint}?business_id=${this.state.idOfBusiness}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBusinessDetaisApiCallBack = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.tokenSave
    };
    this.setState({loading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBusinessDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBusinessDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBusinessDetailsApiResponse = async (response: BusinessdetailsResponse, errorReponse: string) => {
    if (response != null) {
      this.getBusinessDetailsSuccessResponseApi(response);
    }
  }

  getBusinessDetailsSuccessResponseApi = async (responseJson: BusinessdetailsResponse) => {
    let getId:any = responseJson;
    this.setState({ idOfBusiness: getId?.data?.id });
    this.testGetApiOfWeb();
  }

  handleManageTouch = (item: BranchListResponseData) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationManageButtonMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationManageBranchWithData), item);
    msg.addData(getName(MessageEnum.NavigationCreateBranchWithFalseValue), " ");
    msg.addData(getName(MessageEnum.NavigationManageBranchWithBusinessID), this.state.idOfBusiness);
    this.send(msg);
  }

  handleManageClick = (item: any) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationManageButtonMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationManageBranchWithData), item);
    msg.addData(getName(MessageEnum.NavigationCreateBranchWithFalseValue), " ");
    msg.addData(getName(MessageEnum.NavigationManageBranchWithBusinessID), this.state.idOfBusiness);
    this.send(msg);
  }

  goToCreateBranch = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationManageButtonMessage)
    );
    msg.addData(getName(MessageEnum.NavigationCreateBranchWithTrueValue), true);
    msg.addData(getName(MessageEnum.NavigationManageBranchWithBusinessID), this.state.idOfBusiness);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  landingPage = ()=>{
    this.props.navigation.goBack();
  }

  branchListApiCall = () => {
    this.setState({ loading: true });

    const header = {
        "Content-Type": "application/json",
        token: this.state.tokenSave
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.branchListApiId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.branchListApiEndpoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  handleModalStateClose = () => {
    this.setState({ qrCodeImage: false });
  }

  handleModalStateOpen = (item: BranchListResponseData) => {
    this.setState({ itemForImg: item, qrCodeImage: true });
  }
   handleDownloadPDF = (pdfUrl:any) => {
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = "QR.pdf";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  getUserProfileDetailsApiCall = () => {
    this.setState({ loading: true });

    const header = {
        "Content-Type": "application/json",
        token: this.state.tokenSave
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getUserProfileDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getProfileDetailsApiEndPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleUserDetailsApiRes = (responseJson: any) => {
  this.setState({ loading: false });
  if (!responseJson.errors) {
    const {account} = responseJson.data?.attributes;
      this.setState({ isBusinessOwner: account.business_owner });
  }
}
 
  // Customizable Area End
}