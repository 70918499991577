import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

import { getNavigationMessage } from "../../../components/src/Utils";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    logInToken: string,
    loading:boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class VerifyIdentityFailedController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            loading:false,
            logInToken: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const logInRes = await getStorageData('logInRes', true);
        const otpConfRes = await getStorageData('otpConfRes', true);
        this.stateSetAfterSignup(otpConfRes);
        this.setStateAfterLogIn(logInRes);
        const userLoggedIn = await getStorageData("userLoggedIn", true);
        this.checkUserLoggedIn(userLoggedIn, logInRes, otpConfRes);
    }

    checkUserLoggedIn = (user: boolean, logInRes: {logInToken: string}, otpConfRes: {token: string}) => {
        if(user){
          this.send(getNavigationMessage("LandingPage", this.props));
        }else if(!logInRes && !otpConfRes){
            window.localStorage.clear();
            this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
        }
      }

    setStateAfterLogIn = (logInRes: {logInToken: string}) => {
        if (logInRes) {
            this.setState({
                logInToken: logInRes.logInToken
            })
        }
    }
    stateSetAfterSignup = (otpConfRes: {token: string}) => {
        if (otpConfRes) {
            this.setState({ logInToken: otpConfRes.token });
        }
    }

    handleRetryVerification = () => {
        this.send(getNavigationMessage("VerifyIdentity", this.props));
    };

    // Customizable Area End
}