import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Typography,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";

// Customizable Area End

import StripeSetupSuccessfullController, {
    Props,
} from "./StripeSetupSuccessfullController";

export default class StripeSetup extends StripeSetupSuccessfullController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            <ThemeProvider theme={theme}>
                {this.state.loading && <SpinnerLoader/>}
                <Container style={{ marginBottom: '50px', maxWidth: '100%', padding: '0 6%', fontSize: 'clamp(10px, 16px, 2vw)' }}>
                    <Box >
                        <Typography style={webStyle.verifyIdentityLabel}>
                            Connect to Stripe
                        </Typography>

                        <Box sx={webStyle.identityVerifyWrapper}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={webStyle.profileLogoBoxStyle}>
                                    <CheckCircleIcon style={{ color: '#fff', width: '60%', height: '60%' }} />
                                </Box>
                            </Box>
                            <Box>
                                <Typography style={webStyle.textLabelSecond}>
                                    Congratulations, your Stripe account is set up.
                                </Typography>
                                <Typography style={webStyle.textLabelSecond}>
                                    Get ready to Go QR.
                                </Typography>
                                <Typography style={webStyle.textLabelSecond}>
                                    After the 30-day free trial, you auto-enroll in £40 per month 20% VAT plus Stripe processing fees on all transactions.
                                    <br/>
                                    Enjoy a 30-day free trial of Go QR with no charges. Please note, Stripe will apply a transaction fee of 2% + 20p on each transaction.
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                                <MuiStyledButton
                                    variant="contained"
                                    onClick={this.handleFreeTrialBtn}
                                    data-test-id ="freeTrialBtn"
                                >
                                    Start your 30-day free trial
                                </MuiStyledButton>
                            </Box>
                        </Box>


                    </Box>

                </Container>
            </ThemeProvider>
        );
    }
}

// Customizable Area Start

const theme = createTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                width: "100%",
            },
        },
    },
});

const MuiStyledButton = styled(Button)({
    width: "225px",
    display: "flex",
    height: "56px",
    padding: "1px",
    background: "#000",
    fontFamily: "Inter",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    border: "1px solid #F4CD46",
    borderRadius: "50px",
    fontSize: "1em",
    fontWeight: 600,
    color: "#fff",
    letterSpacing: "-0.408px",
    "&:hover": {
        color: "#000",
        background: "#F4CD46",
    }
});

const webStyle = {
    identityVerifyWrapper: {
        display: "flex",
        width: '100%',
        flexDirection: 'column',
        gap: 'clamp(20px, 50px, 5vw)'
    },
    verifyIdentityLabel: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '2em',
        fontWeight: 700,
        letterSpacing: '-0.408px',
        marginBottom: 'clamp(20px, 50px, 5vw)'
    },
 
    profileLogoBoxStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '195px',
        height: '172px',
    },

    textLabelSecond: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '1.5em',
        fontWeight: 400,
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center'
    },
};
// Customizable Area End