import React from "react";

// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { AccordionSummary, Box, Container } from "@material-ui/core";
import { PoliciesWrapGrid } from "./PrivacyPolicies.web";
// Customizable Area End

import RefundPolicyController, {
  Props,
} from "./RefundPolicyController";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";

export default class RefundPolicy extends RefundPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
       <Container 
       maxWidth="lg" style={{marginTop:'50px'}}>
      <Grid 
      justifyContent="center" 
      alignItems="center" 
      container xs={12} sm={12} style={{ fontSize: 'clamp(10px, 16px, 2vw)', }}> 
      {this.state.loading && <SpinnerLoader/>}
        <PoliciesWrapGrid>
          <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{padding: 0}}
            >
               <Box 
               style={{ display: 'flex', gap: '20px', }}> <ArrowBackIcon onClick={this.goBcak}  style={{ color: '#fff', cursor: 'pointer', fontSize: '2em', position:'relative', bottom:'48px' }} data-test-id="arrow-back-icon-btn-create" />
                  <Typography 
                    data-test-id="termsCondsList"  style={{ display: 'flex', justifyContent: 'center', position:'relative',bottom:'70px' }}  variant="body1" 
                    component="div"
                  > 
                  <Typography 
                  data-test-id="termsCondsList" style={{ display: 'flex', justifyContent: 'center' }} variant="body1" component="div">
                    <div style={{
                       color: '#FFFBED',   fontFamily: 'Inter',
                       gap: '32px',
                       fontStyle: 'normal', fontWeight: 300,
                       lineHeight: '32px', letterSpacing: '-0.408px'
                    }} dangerouslySetInnerHTML={{
                      __html: `
                    <style>
                      ol {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        font-size: clamp(12px, 16px, 2vw);
                      }
                      li {
                        margin-bottom: 10px;
                      }
                    </style>
                    <ol>
                      ${this.formatRefundDescriptionList()} 
                    </ol>
                  `
                    }} />
                  </Typography> </Typography>
                </Box> <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start', paddingLeft: '10%' }}> </Box>
            </AccordionSummary> 
        </PoliciesWrapGrid>
      </Grid>
      </Container>
      </>
    )
  }
// Customizable Area End
}

// Customizable Area Start
// Customizable Area End
