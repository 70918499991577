import React, { Component } from 'react';
import { withStyles, Theme, createStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import ImageCrop from '../ImageCroping/ImageCroping.web';

const useStyles = (theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background:'transparent'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  });

class TransitionsModal extends Component<any> {
  handleClose = () => {
    // Implement your handleClose logic here
  };

  getCropedImage = (data: any) => {
    console.log('imageData');
    console.log(data);
  };

  render() {
    const { classes, open, modelComponent, ...rest } = this.props;

    const components: any = {
      ImageCrop: () => (<><ImageCrop {...rest} /></>),
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
            {components[modelComponent]()}
        </Modal>
      </div>
    );
  }
}

export default withStyles(useStyles)(TransitionsModal);
