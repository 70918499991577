// Customizable Area Start
import React, { Component } from 'react';

import DialogActions from '@material-ui/core/DialogActions';

import { ThemeProvider, createTheme, styled } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CustomeButton from './CustomeButton.web';
import { Box, Button, Dialog, DialogContent, DialogContentText, Typography, DialogTitle } from "@material-ui/core";
interface Action {
    label: string;
    handler: () => void;
    borderColor: string;
    testId?: string;
    primary?:boolean
}

interface Props {
    open: boolean;
    onClose?: () => void;
    title: string;
    content: string;
    actions?: Action[];
    isError?: boolean;
    isSuccess?: boolean;
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    },
    overrides: {
        MuiDialog: {
            'paperWidthSm': {
                maxWidth: '694px',
                // padding: '50px 0',
                background: '#212226',
                // minHeight: '220px'
            },
        },
        MuiBackdrop: {
            'root': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }
        }
    }
});

const StyledBox = styled(Box)({
    padding: '35px',
    "@media(max-width: 450px)": {
        padding: '15px'
    }
})

const StyledButton = {
    display: "flex",
    width: "180px",
    height: "56px",
    padding: "1px",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    borderRadius: "50px",
    fontSize: "1rem",
    fontWeight: 600,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "unset",
};

class CustomDialog extends Component<Props> {
    render() {
        const { open, title, content, actions, isError, isSuccess, } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Dialog open={open} aria-labelledby="form-dialog-title">
                    <StyledBox>
                        <DialogTitle style={{padding: 0}}>
                            <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
                                {isError && <ErrorOutlineIcon style={{ color: 'red', marginRight: '10px', fontSize: 'clamp(18px, 32px, 5vw)' }} />}
                                {isSuccess && <CheckCircleOutlineIcon style={{ color: 'green', marginRight: '10px', fontSize: 'clamp(18px, 32px, 5vw)'}} />}
                                {title}
                            </Typography>
                        </DialogTitle>
                        <DialogContent style={{padding: 0}}>
                            <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                                {content}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center', gap: 'clamp(10px, 24px, 3%)', flexWrap: 'wrap', marginBottom: '12px'}}>
                            {actions?.map((action, index) => (
                                <CustomeButton key={index} data-test-id= {action.testId} style={StyledButton} onclicked = {action.handler} text = {action.label} primary = {action.primary} />
                            ))}
                        </DialogActions>
                    </StyledBox>
                </Dialog>
            </ThemeProvider>
        );
    }
}

export default CustomDialog;

const webStyle = {
    dialogTitleStyle: {
        fontSize: 'clamp(18px, 32px, 5vw)',
        fontWeight: 600 as 600,
        fontFamily: 'Inter, sans-serif',
        letterSpacing: '-0.408px',
        color: '#fff',
        textAlign: 'center' as 'center',
        padding: '5px 10px',
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center'
    },
    dialogContentStyle: {
        color: '#fff',
        fontFamily: 'Inter, sans-serif',
        fontSize: 'clamp(16px, 24px, 4vw)',
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center',
        overflow: 'hidden',
        textOverflow: "ellipsis",
        padding: '5px 10px',
    },
}
// Customizable Area End