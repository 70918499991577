import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { getNavigationMessage } from "../../../components/src/Utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    logInToken: string,
    loading: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class FreeTrialSetupFailedController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            logInToken: "",
            loading: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        
        const logRes = await getStorageData('logInRes', true);
        const otpRes = await getStorageData('otpConfRes', true);
        const userLoggedIn = await getStorageData('userLoggedIn', true);
       
        this.setStateAfterSignup(otpRes);
        this.setStateAfterLogIn(logRes);
        this.checkLoggedIn(userLoggedIn, logRes, otpRes);
    }

    checkLoggedIn = (userLoggedIn: boolean, logRes: {logInToken: string}, otpRes: {token: string}) => {
        if (!logRes && !otpRes) {
            window.localStorage.clear();
            this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
        }
        if(userLoggedIn){
            window.location.reload();
        }
    }

    setStateAfterLogIn = (logRes: {logInToken: string}) => {
        if (logRes) { 
            this.setState({ logInToken: logRes.logInToken }) ;
            removeStorageData("isProfileCreated");
            removeStorageData("userLoggedIn");
        }
    }
    setStateAfterSignup = (otpRes: {token: string}) => {
        if (otpRes) {
            this.setState({ logInToken: otpRes.token });
            removeStorageData("isProfileCreated");
            removeStorageData("userLoggedIn");
        }
    }

    handleRetryFreeTrial = () => {
        this.send(getNavigationMessage("StripeSetupSuccessfull", this.props));
    }
    // Customizable Area End
}