import React from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    CardContent, Avatar, Container, Tooltip, withStyles, styled, createTheme,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Inter",
    },
    overrides: {
        MuiBackdrop: {
            'root': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }
        },
        MuiDialog: {
            'paperWidthSm': {
                maxWidth: '694px',
                padding: '50px 0',
                background: '#212226'
            },
        },
    }
});

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        opacity: 1,
        fontSize: '14px',
        backgroundColor: '#212226',
        fontFamily: 'inter',
        padding: theme.spacing(2),
    },
}))(Tooltip);

const SubsDetails = styled(Box)({
    paddingLeft: "clamp(5px, 150px, 10%)",
    display: 'flex',
    gap: '25px',
    paddingTop: '10px',
    flexWrap: 'wrap',
    paddingBottom: '10px',
    paddingRight: '10px',
    "@media(max-width: 700px)": {
        justifyContent: 'center'
    }
});

const Card = styled(Box)({
    width: '373px',
    backgroundColor: '#000',
    color: 'white',
    border: '1px solid #262525',
    textAlign: 'center' as const,
    height: '256px',
    boxSizing: 'border-box' as const,
    "@media(max-width: 875px)": {
        height: '225px',
        width: '350px',
    },
    "@media(max-width: 825px)": {
        width: '300px',
        height: '220px',
    },
    "@media(max-width: 700px)": {
        width: '280px',
        height: '210px',
    }
})

const BuyButton = styled(Button)({
    display: "flex",
    width: "270px",
    height: "56px",
    padding: "1px",
    justifyContent: "center",
    alignItems: "center",
    background: "#000",
    border: "1px solid #F4CD46",
    borderRadius: "70px",
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",
    marginTop: '15px',
    "&:hover": {
        color: "#000",
        backgroundColor: 'F4CD46'
    }
});

const ViewDetailsButton = styled(Button)({
    display: "flex",
    width: "112px",
    height: "40px",
    padding: "1px",
    justifyContent: "center",
    alignItems: "center",
    background: "#000",
    border: "1px solid #FFF",
    borderRadius: "70px",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
        color: "#000",
        backgroundColor: 'FFF'
    }
});


// Customizable Area End

import BusinessSubscriptionsController, {
    Props,
} from "./BusinessSubscriptionsController";

export default class BusinessSubscriptions extends BusinessSubscriptionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        const nameList = this.state.branchName?.reduce((acc: any, item: any, index: any, array: any) => {
            const isLastItem = index === array.length - 1;
            const element = <span key={index}>{item}{!isLastItem && ', '}</span>;
            acc.push(element);
            return acc;
        }, []);

        return (
            // Customizable Area Start
            <>
                {this.state.loading && <SpinnerLoader />}
                {!this.state.openDetails &&
                    <Box style={{ boxSizing: 'border-box', fontSize: 'clamp(10px, 16px, 2vw)' }}>
                        {(!this.state.subsciptionData) ?
                            <Box style={{ height: '50vh' }}>
                                <div style={{ color: 'white', fontSize: '1.5em', fontFamily: 'inter', fontWeight: 200, marginLeft: '40%', marginTop: '80px' }}>No Subscriptions found</div>
                            </Box>
                            :
                            <Box>
                                <Box style={{ display: 'flex', gap: '20px', alignItems: 'center', ...webStyle.wholeContent }}>
                                    <Avatar style={{ cursor: 'pointer' }} alt="Profile" src={this.state.businessData?.photo || ""} />
                                    <Typography style={webStyle.heading}>{this.state.businessData?.business_name}</Typography>
                                </Box>
                                <SubsDetails>
                                    {this.state.subsciptionData?.map((list: any, i: any) => {
                                        return (
                                            <Card className="carde-style" key={i}>
                                                <CardContent style={webStyle.cardContentStyle}>
                                                    <Typography style={webStyle.title} gutterBottom>
                                                        {list.name}
                                                    </Typography>
                                                    <Typography style={webStyle.premium} gutterBottom>
                                                        {list.number_of_units} {list.name_of_units} per  {list.per}
                                                    </Typography>
                                                    <Typography style={{ ...webStyle.description }} color="textSecondary">
                                                        <span style={{ fontWeight: 500 }}>&#163;{list.price}</span>/month
                                                    </Typography>
                                                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <ViewDetailsButton
                                                            onClick={() => { this.viewDetailsOpening(list) }}
                                                        >
                                                            View Details
                                                        </ViewDetailsButton>
                                                    </Box>

                                                </CardContent>
                                            </Card>
                                        )
                                    })}
                                </SubsDetails>
                            </Box>
                        }
                    </Box>
                }
                {this.state.openDetails &&
                    <Box style={{ boxSizing: 'border-box', fontSize: 'clamp(10px, 16px, 2vw)' }}>
                        <Container maxWidth="lg">
                            <Box style={{ display: 'flex', marginBottom: 'clamp(1.5rem, 4rem, 5vw)', gap: '20px', alignItems: 'center' }}>
                                <ArrowBackIcon id="backtosubscription" onClick={this.handleBackToSubscriptionList} style={{ fontSize: '2em', color: '#fff', cursor: 'pointer' }} />
                                <Avatar style={{ cursor: 'pointer' }} alt="Profile" src={this.state.businessData?.photo || ""} />
                                <Typography style={webstyless.headingStyle}>{this.state.businessData?.business_name}</Typography>
                            </Box>
                        </Container>
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 'clamp(1rem, 3rem, 3vw)' }}>
                            <Box>
                                <Typography style={{ fontSize: '2.5em', color: '#fff', fontWeight: 600, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px', textTransform: 'capitalize' }}>{`${this.state.subscriptionProfileData?.name}`}</Typography>
                            </Box>
                            <Box>
                                <Typography style={{ color: '#fff', fontSize: '1.8em', fontWeight: 200, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px' }}>
                                    <span style={{ color: '#fff', fontSize: '1.7em', fontWeight: 700, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.408px' }}>&#163;{`${this.state.subscriptionProfileData?.price}/`}</span>month</Typography>
                            </Box>
                            <Box style={{ width: '100%' }}>
                                <Typography style={{ fontWeight: 600, color: '#fff', fontSize: '1.5em', fontFamily: 'Inter, sans-serif', letterSpacing: '-0.72px', marginBottom: '15px', textAlign: 'center' }}>About this Plan</Typography>
                                <Typography style={{ fontFamily: 'Inter, sans-serif', color: '#fff', fontSize: '1.25em', fontWeight: 200, letterSpacing: '-0.408px', textAlign: 'center', padding: '0 19%' }}>{this.state.subscriptionProfileData?.description}</Typography>
                            </Box>
                        </Box>
                        <Box style={{ ...webstyless.subscriptionDescriptionContainerStyle, flexDirection: 'row' }}>
                            <Box>
                                <Typography style={webstyless.descHeadingStyle}>What you get</Typography>
                                <Typography style={webstyless.descHeadingValueStyle}>{`${this.state.subscriptionProfileData?.number_of_units} ${this.state.subscriptionProfileData?.name_of_units} per ${this.state.subscriptionProfileData?.per}`}</Typography>
                            </Box>
                            <Box>
                                <Typography style={webstyless.descHeadingStyle}>Duration</Typography>
                                <Typography style={webstyless.descHeadingValueStyle}>
                                    {this.state.subscriptionProfileData?.duration == 0 ? 
                                    'Unlimited' : 
                                    `${this.state.subscriptionProfileData?.duration} months`}
                                </Typography>
                            </Box>
                            <Box>
                                <Box>
                                    <Typography style={webstyless.descHeadingStyle}>Pauses</Typography>
                                    <Typography 
                                        style={webstyless.descHeadingValueStyle}
                                    >
                                        {`${this.state.subscriptionProfileData?.pauses_allowed}`}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box>
                                    <Typography style={webstyless.descHeadingStyle}>Valid Branches</Typography>
                                    <CustomTooltip title={nameList}
                                        arrow
                                        PopperProps={{
                                            popperOptions: {
                                                modifiers: {
                                                    offset: {
                                                        offset: '20, -15',
                                                    },
                                                },
                                            },
                                        }}

                                        style={webstyless.tooltipStyle}>
                                        <Typography style={webstyless.descHeadingValueStyle}>{`${this.state.subscriptionProfileData?.valid_branches}`}</Typography>
                                    </CustomTooltip>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            style={{ display: 'flex', justifyContent: 'center', padding: '10px', gap: '20px', flexWrap: 'wrap', alignItems: 'center' }}
                        >
                            <BuyButton
                                onClick={this.handleBuyNowBtn}
                                data-test-id="subscription-detail-buy-btn"
                            >
                                Buy now
                            </BuyButton>
                        </Box>
                    </Box>
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    cardContentStyle: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box' as const
    },
    wholeContent: {
        paddingLeft: "clamp(5px, 150px, 10%)",
        paddingTop: '10px'
    },
    btngrp: {
        paddingLeft: '185px',
        paddingTop: '30px'
    },
    heading: {
        color: 'white',
        fontWeight: 700,
        fontFamily: 'Inter',
        fontSize: '2em',
    },

    card: {
        backgroundColor: '#000',
        color: 'white',
        border: '1px solid #262525',
        textAlign: 'center' as const,
        width: '373px',
        height: '256px',
        boxSizing: 'border-box' as const
    },

    btnstyle: {
        color: 'white',
        border: '0.5px solid white',
        fontSize: '1em',
        fontWeight: 600,
        fontFamily: 'Inter',
        margin: '17px',
        width: '225px',
        height: '56px',
        borderRadius: '53px',

    },
    premium: {
        fontFamily: 'Inter',
        fontSize: '1em',
        fontWeight: 500,
    },
    description: {
        marginTop: '8px',
        color: 'white',
        marginBottom: '8px',
        fontSize: '1.5em',
        fontWeight: 200,
    },
    title: {
        marginTop: '12px',
        fontSize: '1.5em',
        fontWeight: 700,
    },

    units: {
        marginLeft: '290px',
        color: 'white',
    },
    btn: {
        width: "112px",
        height: '40px',
        color: 'white',
        border: '1px solid white',
        borderRadius: '70px',
        padding: '5px 8px 5px 8px',
        fontSize: '0.8em',
        marginTop: '55px',
        fontFamilty: 'inter',
        fontWeight: 600
    },
}
const webstyless = {

    tooltipStyle: {
        width: "198px",
        height: "73.2px"
    },

    headingStyle: {
        fontFamily: 'Inter, sans-serif',
        color: '#FFF',
        fontSize: '2em',
        fontWeight: 600,
        letterSpacing: '-0.72px',
    },

    descHeadingStyle: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '1.5em',
        color: '#FFF',
        fontWeight: 600,
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center',
    },
    viewDetails: {
        width: "112px",
        height: '40px',
        color: 'white',
        border: '1px solid #F4CD46',
        borderRadius: '70px',
        padding: '5px 8px 5px 8px',
        fontSize: '0.8em',
    },
    buttonCommonStyle: {
        width: '225px',
        height: '56px',
        padding: '4px 8px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        textAlign: 'center' as 'center',
        fontFamily: 'Inter, sans-serif',
        fontSize: '1em',
        textTransform: 'capitalize' as 'capitalize',
        color: '#fff',
        fontWeight: 600,
        letterSpacing: '-0.408px',
        borderRadius: '60px',
        background: '#000'
    },
    subscriptionDescriptionContainerStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        backgroundColor: 'transparent',
        gap: '2rem 10%',
        flexWrap: 'wrap' as 'wrap',
        padding: 'clamp(2rem, 3rem, 3vw) 5vw',
    },
    descHeadingValueStyle: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '1.5em',
        color: '#FFF',
        fontWeight: 200,
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center'
    },
}
// Customizable Area End
