// Customizable Area Start
import React, { useState } from "react";
import { TextField, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import './CoustomDatePicker.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
registerLocale('en-GB', enGB);


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            background: '#FFF'
        },
        textField: {
            paddingLeft: '60px',
            paddingRight: theme.spacing(1),
            width: '100%',
            height:'48px',
            fontSize:'18px',
            fontWeight:400,
            fontFamily:'Inter',
            background: '#FFF',
            
            '& div': {
                '& input': {
                    height: '35px',
                }
            }
        },
        textField1: {
            paddingLeft: '60px',
            paddingRight: theme.spacing(1),
            width: '100%',
            height:'45px',
            fontSize:'18px',
            fontWeight:400,
            fontFamily:'Inter',
            background: '#212226',
            color:'#fff',

            '& div': {
                '& input': {
                    height: '35px',
                    borderBottomColor:'#212226'
                }
            }
        },
        cusTypo:{
            marginBottom: "8px",
            color: "#fff"
        }
    }),
);
const CustomDatePicker = (props: any) => {
    const {startDate,name, label, dateFormat, value, onChange, maxDate,isDisableStyle, ...rest } = props;
    const classes = useStyles();
    // const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //     // Prevent key presses from affecting the input
    //     e.preventDefault();
    //   };
      const maxDatee = new Date(); 
      const handleDateChange = (date: Date | null) => {
        if (date) {
          // Pass the date and the input's name back to the parent's change handler
          onChange(date, name);
        }
      };


    return <div className="picker-div">
        <Typography className={classes.cusTypo}>{label}</Typography>
        <div className={classes.container}>
            {/* <TextField
                id="date"
                type="date"
                format="MMMM d, yyyy"
                inputFormat={dateFormat}
                defaultValue={value}
                value={value}
                onChange={onChange}
                className={classes.textField}
                inputProps={{ max: maxDate }}
                InputLabelProps={{
                    shrink: true,
                }}
                {...rest}
            /> */}
            <DatePicker
                 selected={value ? new Date(value) : null}
                onChange={ handleDateChange }
                dateFormat="MMMM d, yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className={isDisableStyle ? classes.textField1 : classes.textField}
                maxDate={maxDatee} 
                locale="en-GB"
                disabled={isDisableStyle}
                // onKeyDown={handleKeyDown}
                
            />
        </div>
    </div>

};
export default CustomDatePicker;
// Customizable Area End