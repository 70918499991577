import React from "react";
import { Link, NavLink } from "react-router-dom"
import { BlockComponent } from "framework/src/BlockComponent";
import { Dialog, ThemeProvider, createTheme, styled } from "@material-ui/core";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";


export interface Props {
  navigation: any;
  id: string;
  onLogout: any,
  userProfilePhoto: any;
  isBusinessOwner: any;
  isBranchPermission: any;
}

export interface S {
  isActive: boolean;
}

export interface SS {
  id: any;
}

class BusinessDropdown extends BlockComponent<
  Props,
  S,
  SS
> {
  dropdownRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  onClick = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  goToBusinessBranch = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationtoManageBranchButtonMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  CustomNavbar = styled(NavLink)({
    fontSize: "20px",
    color: "#ffffffff",
    fontWeight: 500,
    textDecoration: "none",
    fontFamily: "Inter",
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
    "@media (max-width:910px)": {
      fontSize: "15px"
    },
    "&:hover": {
      color: "#F4CD46"
    }
  })
  handleLogout = () => {
    const { onLogout } = this.props;
    onLogout();
    this.onClick();
  }

  handleClose = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  }

  render() {
    const { isActive } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div className="container">
          <style>{`
        

          .menu-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .business-menu-trigger {
            background: #fff;
            border-radius: 90px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 6px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
            border: none;
            vertical-align: middle;
            transition: box-shadow 0.4s ease;
            margin-left: auto;
          }

          .business-menu-trigger:hover {
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
          }

          .business-menu-trigger span {
            font-weight: 700;
            vertical-align: middle;
            font-size: 14px;
            margin: 0 10px;
          }

          .business-menu-trigger img {
            border-radius: 90px;
          }
          .business-menu {
            background: #777777;
            border-radius: 8px;
            top: 60px;
            right: 0;
            width: 200px;
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
            opacity: 0;
            visibility: hidden;
            transform: translateY(-20px);
            transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
          }

          .business-menu.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
          .business-menu.inactive{
            backgroundcolor:'red'
          }

          .business-menu ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }

          .business-menu li {
            // border-bottom: 1px solid #dddddd;
          }

          .business-menu li p {
            text-decoration: none;
            color: #fff;
            padding: 3px 20px;
            display: block;
          }

          .business-liFlex{
            display:flex !important;
            align-items:center;
            justify-content:center:
            colo
          }
          .business-liFlex a{
            color: #FFF;
            text-decoration: none;
          }
        `}</style>
          <div className="menu-container">
            <this.CustomNavbar onClick={this.onClick} to={'#'} activeStyle={{ color: '#F4CD46' }}
              className="buiness_header"
            >
              {"Business"}
            </this.CustomNavbar>
            <Dialog
              open={this.state.isActive}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <nav ref={this.dropdownRef} style={{ width: "280px", borderRadius: "20px", zIndex: 9 }} className={`business-menu ${isActive ? "active" : "inactive"}`}>
                <ul>
                  <li style={{ borderBottom: "1px solid black", cursor: 'pointer' }}>
                    <p className="business-liFlex" >
                      <Link
                        to="/CreateNewBusiness"
                        style={{ cursor: "pointer" }}
                        onClick={() => { }}
                      >
                        <text style={{ paddingLeft: "1rem" }}>
                          Business Profile</text>
                      </Link>
                    </p>
                  </li>

                  {this.props.isBusinessOwner &&
                    <li style={{ cursor: 'pointer' }}>
                      <p className="business-liFlex" onClick={this.goToBusinessBranch}>
                        <text style={{ paddingLeft: "1rem" }}>Branches</text>
                      </p>
                    </li>
                  }
                  {!this.props.isBusinessOwner && this.props.isBranchPermission &&
                    <li style={{ cursor: 'pointer' }}>
                      <p className="business-liFlex" onClick={this.goToBusinessBranch}>
                        <text style={{ paddingLeft: "1rem" }}>Branches</text>
                      </p>
                    </li>
                  }
                </ul>
              </nav>
            </Dialog>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default BusinessDropdown;

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiDialog: {
      'paperWidthSm': {
        position: 'absolute',
        top: '80px',
        right: '50px',
        background: 'transparent'
      },
    },
    MuiBackdrop: {
      'root': {
        backgroundColor: 'transparent'
      }
    }
  }
});

