import React from "react";

import {
  Container,
  Button,
  Typography,
  // Customizable Area Start
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  Box
  // Customizable Area End
} from "@material-ui/core";
import { TextAlignProperty, TextTransformProperty, PositionProperty } from 'csstype';

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles, Theme, styled } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CustomeButton from "../../../components/src/CustomeButton.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiDialog: {
      'paperWidthSm': {
        maxWidth: '694px',
        padding: '50px 0',
        background: '#212226'
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        fontFamily: 'inter',
        padding: '8px',
        backgroundColor: '#212226',
        opacity: 1,
        maxWidth: 300,
      },
      arrow: {
        color: '#212226',
      }
    },
    MuiBackdrop: {
      'root': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }
    },
    MuiFilledInput: {
      'inputMarginDense': {
        padding: '12px',
        paddingTop: '12px',
        paddingBottom: '12px',
        backgroundColor: 'white'
      },
      'root': {
        height: '48px'
      },
      'input': {
        height: '24px'
      }
    },
    MuiSelect: {
      'selectMenu': {
        backgroundColor: 'white !important',
        height: '24px'
      },
      'filled': {
        backgroundColor: 'white'
      },
    },
    MuiGrid: {
      'grid-xs-6': {
        flexBasis: '48%',
        // marginTop: '10px',
        marginTop: '0px'
      },
      'grid-xs-12': {
        // height: '155px',
        marginTop: '7px'
      },
      'item': {
        padding: '0px 17px 0px 0px !important'
      },
    },
  }
});

const selectTheme = createTheme({
  palette: { primary: { main: "#fff", contrastText: "#fff", } },
  typography: { h6: { fontWeight: 500, }, subtitle1: { margin: "20px 0px", }, },
  overrides: {
    MuiDialog: {
      'paperWidthSm': { maxWidth: '694px', padding: '50px 0', background: '#212226' },
    },
    MuiBackdrop: {
      'root': { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
    },
    MuiFilledInput: {
      'inputMarginDense': { padding: '12px', paddingTop: '12px', paddingBottom: '12px', backgroundColor: 'white' },
      'root': { height: '48px' },
      'input': { height: '24px' }
    },
    MuiSelect: {
      'selectMenu': { backgroundColor: 'white !important', height: '24px' },
      'filled': { backgroundColor: 'white' },
      'select': { backgroundColor: 'white', },
    },
    MuiInput: {
      formControl: { backgroundColor: 'white !important', height: '48px !important', paddingLeft: '15px' }
    },
    MuiMenuItem: {
      root: { borderBottom: '1px solid gray !important', height: '48px', }
    },
    MuiList: {
      padding: { padding: '0px !important' }
    },
    MuiCheckbox: {
      root: {
        color: 'black',
        '&$checked': {
          color: 'black !important',
        },
      },
      colorPrimary: {
        '&$checked': {
          color: 'black !important',
        },
      },
      colorSecondary: {
        '&$checked': {
          color: 'black !important',
        },
      },
    },
  }
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginTop: '40px'
    },
  },
};


// Customizable Area End

import Customform4Controller, {
  Props,
} from "./Customform4Controller";



export default class Customform4 extends Customform4Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  ButtonClose = styled(Button)({
    width: '180px',
    height: '56px',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    color: '#fff',
    textTransform: 'capitalize' as 'capitalize',
    border: '1px solid #fff',
    "&:hover": {
      color: 'black',
      backgroundColor: '#FFFFFF'
    },
  });


  // Customizable Area End


  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={{fontSize: 'clamp(10px, 16px, 2vw)'}}>
        {this.renderLoader()}
        {this.showHeading(webStyle)}
        <Container maxWidth={"lg"}>
          <Container style={{ marginBottom: '50px', maxWidth: '100%', padding: '0 6%', }}>
            {this.renderEditCreateUi(webStyle, selectTheme, MenuProps)}
            {this.viewDetails(webStyle)}
            {this.renderDialogRestore(webStyle)}

            <Dialog open={this.state.openArchiveSubscriptionDialog} aria-labelledby="form-dialog-title">
              {this.renderLoader()}
              <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
                <CheckCircleOutlineIcon style={{ color: '#008000', marginRight: '10px', fontSize: 'clamp(18px, 32px, 5vw)' }} />
                Archive Subscription
              </Typography>
              <DialogContent style={{padding: '0 2%'}}>
                <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                  Are you sure you want to archive this subscription plan? The plan will no longer be available to new subscribers, but existing subscribers will still be able to access it.
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ justifyContent: 'center', gap: 'clamp(10px, 24px, 3%)', flexWrap: 'wrap' }}>
                <this.ButtonClose 
                  onClick={this.handleArchiveDialogCloseBtn}
                  data-test-id="archive-dialog-close-btn"
                >
                  Close
                </this.ButtonClose>
                <CustomeButton 
                  primary={true} 
                  text="Archive" 
                  data-test-id="archive-dialog-archive-btn" 
                  onclicked={()=>{ this.handleSubscriptionArchiveApi(this.state.selectedSubscription?.id)} } 
                  style={{ ...webStyle.dialogButtonStyle, border: '1px solid #F4CD46', }}
                />
              </DialogActions>
            </Dialog>
          </Container>
        </Container>
        </Box>
        
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  headingStyle: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '2em',
    fontWeight: 600,
    lineHeight: '56px',
    letterSpacing: '-0.72px',
  },
  headingStyleCreateBtn: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: 'clamp(24px, 32px, 5vw)',
    fontWeight: 600,
    lineHeight: '56px',
    letterSpacing: '-0.72px',
    position: 'relative' as 'relative',
    // right: '80px'
  },

  mainWrapper: {
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "flex-start",
    background: "#000",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  btnStyle: {
    color: 'white',
    border: '1px solid #F4CD46',
    borderRadius: '90px',
    width: ' 100px',
  },
  dropDownStyle: {
    color: 'white',
    border: '1px solid white',
    borderRadius: '90px',
    width: ' 100%',
    bottomBorder: 'none',
    backgroundColor: 'black',
    height: '35px',
    '&:focus': {
      backgroundColor: 'black', // Change the background color on focus
      borderRadius: '90px',
      borderColor: 'red', // Change the border color on focus
    },
  },
  header: {
    display: 'flex',
    alignItem: 'center',
    flexDirection: 'column' as 'column'
  },
  subscriptionList: {
    color: 'white',
    border: `1px solid #F4CD46`,
    textAlign: 'center' as TextAlignProperty,
    padding: '30px'
  },
  subscriptionListStyle: {
    color: 'white',
    border: `1px solid #D9D9D9`,
    textAlign: 'center' as TextAlignProperty,
    padding: '30px'
  },
  headerBtnGroup: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'flex-start'
  },
  listBtnGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    gap: '8px',
    flexWrap: 'wrap' as 'wrap'
  },
  statusBtn: {
    color: 'white',
    border: '1px solid white',
    width: "112px",
    height: '40px',
    borderRadius: '70px',
    padding: '5px 15px 5px 15px',
    fontSize: '14px',
    lineHeight: '22px',
    // textTransform: 'none',
  },
  viewDetails: {
    color: 'white',
    border: '1px solid #F4CD46',
    width: "112px",
    height: '40px',
    borderRadius: '70px',
    padding: '5px 8px 5px 8px',
    fontSize: '14px',
    lineHeight: '22px',
    // textTransform: 'none'
  },
  createNew: {
    backgroundColor: '#F4CD46',
    color: 'black',
    padding: '10px 15px 10px 15px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative' as PositionProperty,
    // bottom: '22px',
    textTransform: 'capitalize' as TextTransformProperty
  },
  createNewNoData: {
    color: 'white',
    borderRadius: '90px',
    width: 'auto',
    border: '1px solid #F4CD46',
    textTransform: 'capitalize' as TextTransformProperty,
    textAlign: 'center' as 'center',
    marginTop: '45px',
    fontSize:'18px',
    padding: '16px'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItem: 'center',
    // marginTop:'10px'
  },
  createSubscriptionflexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItem: 'center',
    // paddingLeft: '120px'
  },
  descHeadingStyle: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.5em',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
    lineHeight: '40px', // dummy commit for checking that commits are showing in tracker or not
  },
  descHeadingValueStyle: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.5em',
    fontWeight: 200,
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center'
  },
  subscriptionDescriptionContainerStyle: {
    display: 'flex',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItem: 'center',
    gap: '2% 10%',
    flexWrap: 'wrap' as 'wrap',
    padding: '2vw 0'
  },
  buttonCommonStyle: {
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '225px',
    height: '56px',
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    textTransform: 'capitalize' as 'capitalize',
    color: '#fff',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '60px',
    background: '#000'
  },
  dialogTitleStyle: {
    fontSize: 'clamp(22px, 32px, 5vw)',
    fontWeight: 600 as 600,
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.408px',
    color: '#fff',
    textAlign: 'center' as 'center',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    marginBottom: '15px'
  },
  dialogContentStyle: {
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    fontSize: 'clamp(16px, 24px, 4vw)',
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
    marginBottom: '15px'
  },
  dialogButtonStyle: {
    width: '180px',
    height: '56px',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    color: '#fff',
    textTransform: 'capitalize' as 'capitalize'
  },
  inputLabel: {
    color: 'white',
    marginBottom: '10px'
  },
  infoIconStyle: {
    position: 'relative' as 'relative',
    top: '7px',
    left: '5px'
  },
  tootipIconStyle: {
    color: '#F4CD46',
    width: '24px',
    height: '24px',
    paddingRight: '5px',
    position: 'relative' as 'relative',
    top: '7px'
  },
  inputTextfiled: {
    height: '48px !important',
    //  width: '420px',
    backgroundColor: 'white',
  }
};

// Customizable Area End
