import { Fab } from "@material-ui/core";
import React from "react";
import { LogoDark } from './assets'

export default function SpinnerLoader() {
    const keyframes = `
      @keyframes rotation  {
        0% { transform: rotate(0deg) }
        100% { transform: rotate(360deg)}
      }
    `;
    return (
        <>
            <style>{keyframes}</style>
            <div style={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999,
            }}>
                <Fab
                    aria-label="save"
                    color="primary"
                    style={{
                        width: '56px', height: '56px', padding: 0,
                        fontSize: '0.875rem', minWidth: 0, boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                        boxSizing: 'border-box', minHeight: '36px', transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontWeight: 500, lineHeight: 1.75, borderRadius: '50%',
                        letterSpacing: '0.02857em', textTransform: 'uppercase', backgroundColor: 'black'
                    }}
                >
                    <img
                        style={{
                            fill: 'white',
                            width: '1.5em', height: '1.5em',
                            display: 'inline-block',
                            fontSize: '1.4rem',
                            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', userSelect: 'none',
                            flexShrink: 0, position: 'absolute', zIndex: 1, borderRadius: '9px'
                        }}
                        src={LogoDark} height={24} width={24} alt="" />
                </Fab>
                <div style={{
                    width: '56px',
                    height: '56px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    position: 'absolute',
                    background: 'linear-gradient(0deg, #3A3111 33%, #3A3111 33%, #4E4115 66%, #4E4115 66%, #F6CB38 100%)',
                    boxSizing: 'border-box',
                    animation: 'rotation 1s linear infinite'
                }}>
                    <span style={{
                        content: '', boxSizing: 'border-box', position: 'absolute',
                        left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: '44px', height: '44px',
                        borderRadius: '50%', background: 'black'
                    }} ></span>
                </div>
            </div>
        </>
    )
}
