import React from "react";

// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {AccordionSummary, Container } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { styled } from "@material-ui/core/styles";
// Customizable Area End

import PrivacyPoliciesController, {
  Props,
} from "./PrivacyPoliciesController";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";

export const PoliciesWrapGrid = styled(Grid)({
  maxWidth: '1194px', 
  padding: "0 64px",
  '@media (max-width: 1041px)': {
    padding: "0 32px",
  },
  '@media (max-width: 601px)': {
    padding: "0 15px",
  }
});

export default class PrivacyPolicies extends PrivacyPoliciesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Container maxWidth="lg" style={{marginTop:'50px'}}>
        <Grid justifyContent="center" alignItems="center" container xs={12} sm={12} style={{ fontSize: 'clamp(10px, 16px, 2vw)', }}>
        {this.state.loading && <SpinnerLoader/>}
        <PoliciesWrapGrid>
          {/* <Accordion > */}
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box style={{ display: 'flex', gap: '20px', }}>
                  <ArrowBackIcon onClick={this.goBack}  style={{ color: '#fff', cursor: 'pointer', fontSize: '2em', position:'relative', bottom:'48px' }} data-test-id="arrow-back-icon-btn-create" />
                  <Typography 
                    data-test-id="termsCondsList" 
                    style={{ display: 'flex', justifyContent: 'center', position:'relative',bottom:'75px' }} 
                    variant="body1" 
                    component="div"
                  > 
         
                  <Typography data-test-id="termsCondsList" style={{ display: 'flex', justifyContent: 'center', fontSize: '1em', }} variant="body1" component="div">
                    <div style={{
                       color: '#FFFBED',
                       fontFamily: 'Inter',
                       gap: '32px',
                       fontStyle: 'normal',
                       fontWeight: 300,
                       letterSpacing: '-0.408px'
                    }} dangerouslySetInnerHTML={{
                      __html: `
                    <style>
                      ol {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        font-size: clamp(12px, 16px, 2vw);
                      }
                      li {
                        margin-bottom: 10px;
                      }
                    </style>
                    <ol>
                      ${this.formatDescriptionList()} 
                    </ol>
                  `
                    }} />
                  </Typography>
                
                  </Typography>
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start', }}>
              </Box>
            </AccordionSummary>
        </PoliciesWrapGrid>
      </Grid>
      </Container>
    )
  }
// Customizable Area End
}

// Customizable Area Start
// Customizable Area End
