import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { getNavigationMessage, sendAPIRequest } from "../../../components/src/Utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  signupText: string;
  agreeTCtext: string;
  tcText: string;
  privacyPolicyText: string;
  emailValidation: boolean;
  isEmailNotRegistered: boolean,
  isPasswordIncorrect: boolean,
  passwordValidation: boolean,
  loading: boolean;
  popupValidation: boolean,
  errors: {
    [key: string]: string;
  };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  reSendOTPAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseToken),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      signupText: configJSON.singUp,
      agreeTCtext: configJSON.agreeTCtext,
      emailValidation: false,
      passwordValidation: false,
      popupValidation: false,
      privacyPolicyText: configJSON.privacyPolicyText,
      tcText: configJSON.tcText,
      isEmailNotRegistered: false,
      isPasswordIncorrect: false,
      loading: false,
      errors: {},
    };

    this.emailReg = (/\S+@\S+\.\S+/);
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const userLoggedIn = await getStorageData("userLoggedIn", true);
    this.checkUserLoggedIn(userLoggedIn);
    // Customizable Area End
  }

  // Customizable Area Start

  checkUserLoggedIn = (user: any) => {
    if (user) {
      this.send(getNavigationMessage("LandingPage", this.props));
    }
  }
  goToEmailSignup() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToForgotPasswordOTP = () => {
    this.setState({ loading: true })
    let reqBody = {
      data: {
        attributes: {
          email: this.state.email
        }
      }
    }
    this.reSendOTPAPICallId = sendAPIRequest(
      `account_block/resend_otp`,
      {
        method: "POST",
        body: { ...reqBody },
        headers: {
          "Content-Type": "application/json"
        }
      },
    );
  }

  navigateToOtpPage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordOTPMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(msg);
  }

  handleReSendOtpApiRes = (response: any) => {
    this.setState({ loading: false });
    const { meta } = response;
    if (meta) {
      setStorageData("signUpToken", meta.token);
      setStorageData("logInEmail", this.state.email);
      this.navigateToOtpPage();
    }
  }

  goToTermsandCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      popupValidation: false
    })
  }

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
    this.setState({ emailValidation: false })
    localStorage.setItem("logInEmail", text)
  };

  doEmailLogin = () => {
    if (Object.keys(this.checkValidations()).length === 0) {
      // Handle form submission

      const headerData = {
        "Content-Type": configJSON.loginApiContentType,
      };

      const headerAttributes = {
        email: this.state.email,
        password: this.state.password,
      };

      const data = {
        type: "email_account",
        attributes: headerAttributes,
      };

      const httpBodyData = {
        data: data,
      };
      this.setState({ loading: true });
      const emailLoginrequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiEmailLoginCallId = emailLoginrequestMessage.messageId;
      emailLoginrequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginAPiEndPoint1
      );

      emailLoginrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerData)
      );

      emailLoginrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBodyData)
      );

      emailLoginrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(emailLoginrequestMessage.id, emailLoginrequestMessage);

      return true;
    } else {
      this.setState({ errors: this.checkValidations() });
      return false;
    }
  }

  checkValidations = () => {
    const errors: { [key: string]: string } = {};
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.setState({ emailValidation: true });
      errors.email = configJSON.emailErrorMsg;
    }
    if (this.state.password === null || this.state.password.length === 0) {
      this.setState({ passwordValidation: true })
      errors.password = configJSON.passErrorMsg;
    }

    return errors;
  }


  setPassword = (text: string) => {
    this.setState({
      password: text,
      passwordValidation: false
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  setLogginDataContext = (responseJson: any) => {
    const { meta } = responseJson;
    this.context.set({
      baseURL: '',
      loginData: JSON.stringify(meta.account),
      isLogin: true,
      isSignup: false,
      isSubscriber: (meta.account.role_id == 2),
      logInToken: responseJson.meta.token
    });
  }

  setLoginResStorage = (meta: any) => {
    const {account} = meta;
    const logInRes = { logInToken: meta.token };
    const isSubscriber = meta.account.role_id == 2;
    setStorageData("logInRes", JSON.stringify(logInRes));
    setStorageData("isSubscriber", JSON.stringify(isSubscriber));
    this.openNextInfoPage(account);

  }
  openNextInfoPage(account: any) {
    if (account.first_name === null) {
      this.send(getNavigationMessage("UserProfile", this.props));
    }
    else if( account.is_joined_business==false && account.role_name === "business user" && account.is_business_created === false){
      this.send(getNavigationMessage("SetupBusiness", this.props));
    } 
    else if( account.is_joined_business==false && account.role_name === "business user" && account.stripe_id == null){
      this.send(getNavigationMessage("VerifyIdentity", this.props));
    }
    else if(!account.is_joined_business && account.role_name === "business user" && account.thirty_days_cs_id == null){
      this.send(getNavigationMessage("StripeSetupSuccessfull",this.props))
    }
    else {
      removeStorageData("isProfileCreated");
      setStorageData("userLoggedIn", true);
      setStorageData("isLogin", true);
      this.send(getNavigationMessage("LandingPage", this.props));
    }
  }

  handleEmailLoginApiRes = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.setLoginResStorage(responseJson.meta);
      this.saveLoggedInUserData(responseJson);
      this.setState({ loading: false });
      removeStorageData("subscription_to_buy");
      this.sendLoginSuccessMessage();
    } else if (responseJson.errors) {
      const errors: { [key: string]: string } = {};
      this.setState({ loading: false });
      if (responseJson.errors[0].failed_login === 'not activated') {
        this.setState({ popupValidation: true })
      }
      if (responseJson.errors[0].failed_login === "Account not found") {
        errors.email = "Email is not registered";
        this.setState({ emailValidation: true, errors });
      }
      if (responseJson.errors[0].failed_login === "Login Failed") {
        errors.password = configJSON.incorrectPass;
        this.setState({ passwordValidation: true, errors });
      }
    }
  }

  handleValidationApiRes = (responseJson: any) => {
    let arrayholder = responseJson.data;

    if (arrayholder && arrayholder.length !== 0) {
      let regexData = arrayholder[0];

      if (regexData && regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  handleUserCredentials = (countryCode: any, userName: any, password: any) => {
    if (!countryCode && userName && password) {
      this.setState({
        email: userName,
        password: password,
        checkedRememberMe: true,
      });

      //@ts-ignore
      this.txtInputEmailProps.value = userName;

      //@ts-ignore
      this.txtInputPasswordProps.value = password;

      this.CustomCheckBoxProps.isChecked = true;
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {

      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      this.handleUserCredentials(countryCode, userName, password);

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.validationApiCallId) {
        this.handleValidationApiRes(responseJson);

      }

      if (apiRequestCallId === this.apiEmailLoginCallId) {
        this.handleEmailLoginApiRes(responseJson);

      }

      if (apiRequestCallId === this.reSendOTPAPICallId) {
        this.handleReSendOtpApiRes(responseJson);
      }

    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
