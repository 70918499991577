import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React, { createRef } from 'react';
import { getNavigationMessage, sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
// Customizable Area Start
// Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  otp_code: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  cursorPointer: any;
  loading: boolean;
  otperror: boolean;
  otperrorText: string;
  labelInfo: string;
  timer: number;
  isResend:boolean;
  isTimerActive:boolean;
  signUpToken: string;
  email: string;
  openResendOtpDialog: boolean;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  timerInterval: NodeJS.Timeout | null = null;
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  submitButtonColor: any = configJSON.submitButtonColor;
  sendOTPAPICallId: string = '';
  reSendOTPAPICallId: string = '';
  otp1Ref: any;
  otp2Ref: any;
  otp3Ref: any;
  otp4Ref: any;
  userInfoApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      toMessage: "",
      isFromForgotPassword: false,
      otp_code: '',
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      cursorPointer: 0,
      loading: false,
      otperror: false,
      otperrorText: '',
      labelInfo: '',
      timer: 0,
      isResend:false,
      isTimerActive:false,
      signUpToken: "",
      email: "",
      openResendOtpDialog: false
    };

    this.btnTxtSubmitOtp = 'Verify OTP';
    this.placeHolderOtp = 'OTP';
    this.otp1Ref = createRef();
    this.otp2Ref = createRef();
    this.otp3Ref = createRef();
    this.otp4Ref = createRef();
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse != null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
       if (apiRequestCallId === this.sendOTPAPICallId) {
        this.handleSendOtpAPI(responseJson);
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.reSendOTPAPICallId) {
        this.handleReSendOtpAPI(responseJson);
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.userInfoApiCallId) {
        this.handleUserInfoApiRes(responseJson);
        this.setState({ loading: false });
      }

      if (errorReponse != null) {
        this.setState({ otperror: true });
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const logInEmail = await getStorageData('logInEmail');
    const signUpToken = await getStorageData('signUpToken');
    const userLoggedIn = await getStorageData("userLoggedIn");
    const otpConfRes = await getStorageData("otpConfRes", true);

    this.checkPageAuth(signUpToken, userLoggedIn, otpConfRes);
    
    this.setToken(signUpToken);
    this.setEmail(logInEmail);
  }

  setToken = (token: string) => {
    this.setState({signUpToken: token});
  }
  setEmail = (email: string) => {
    this.setState({email: email});
  }

  checkPageAuth = (signUpToken: any, userLoggedIn: any, otpConfRes: any) => {
    if(userLoggedIn){
      this.send(getNavigationMessage("LandingPage", this.props));
    }
    if(otpConfRes){
      let token = otpConfRes.token;
      this.userInfoApiCall(token);
    }
    if(!signUpToken && !otpConfRes){
      this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
    }
  }

  userInfoApiCall = (token: string) => {
    this.setState({loading: true});

    this.userInfoApiCallId = sendAPIRequest(
      `account_block/specific_account`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": token
        }
      },
    );
  }

  handleUserInfoApiRes = (responseJson: any) => {

    if(responseJson.data?.attributes?.first_name == null){
      this.setState({loading: false});
      this.send(getNavigationMessage("UserProfile", this.props));
    } else if(responseJson.data?.attributes?.role_name === "business user" && responseJson.data?.attributes.account.business_id == null){
      this.setState({loading: false});
      this.send(getNavigationMessage("SetupBusiness", this.props));
    }
  }

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text })
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric"
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  btnSubmitOTPProps = {
    onPress: () => this.setState({ otp: '' })
  };
  goBack = () => {
    this.props.navigation.goBack()
    this.setState({isResend:false})
  }
  onSubmitOTP = (inputEvent: React.SyntheticEvent) => {
    this.setState({ loading: true });
    inputEvent.preventDefault();
    const {
      otp1,
      otp2,
      otp3,
      otp4,
      otp_code,
      timer,
      isResend,
    } = this.state;
  
    if (isResend && timer === 0) {
      this.setState({
        otperror: true,
        otperrorText: "Oops! You have entered an incorrect OTP. Please re-enter or resend.",
        loading:false
      });
      setTimeout(() => {
        this.setState({
          otperror: false,
        });
      }, 5000);
      return;
    }
  
    if ((otp1 + otp2 + otp3 + otp4).length === 0) {
      this.setState({ otperror: true, otperrorText: "Please enter OTP", loading:false });
    } else if ((otp1 + otp2 + otp3 + otp4).length < 4) {
      this.setState({
        otperror: true,
        otperrorText:
          "Oops! You have entered an incorrect OTP. Please re-enter or resend.",
          loading:false
      });
    } else {
      this.setState({ otperror: false , loading:false});
    }
    
    if (
      otp1.length === 0 ||
      otp2.length === 0 ||
      otp3.length === 0 ||
      otp4.length === 0
    ) {
      this.setState({ otperror: true, otperrorText: "Please enter OTP" , loading:false});
      if (
        otp_code.concat(otp1, otp2, otp3, otp4).length < 4 &&
        otp_code.concat(otp1, otp2, otp3, otp4).length > 0
      ) {
        this.setState({
          otperror: true,
          otperrorText:
            "Oops! You have entered an incorrect OTP. Please re-enter or resend.",
            loading:false
        });
      }
      return false;
    }
    this.sendOTP(otp_code.concat(otp1, otp2, otp3, otp4));
    localStorage.setItem('isRegister', JSON.stringify(true))
  };

  submitOtp=()=>{

  }
 

  sendOTP = (otp_code: string) => {
    this.setState({ loading: true });
    let reqBody = {
      data: {
        token: this.state.signUpToken,
        otp_code: otp_code
      }
    }

    this.sendOTPAPICallId = sendAPIRequest(
      `account_block/signup_otp_confirmation`,
      {
        method: configJSON.apiVerifyOtpMethod,
        body: { ...reqBody },
        headers: {
          "Content-Type": "application/json"
        }
      },
    );
  }

  startTimer = () => {
    const timerLimit = 30;
    this.setState({ timer: timerLimit ,isTimerActive:false });
    this.timerInterval = setInterval(() => {
      const { timer } = this.state;
      if (timer > 0) {
        this.setState({ timer: timer - 1, isTimerActive:true });
      } else {
        if (this.timerInterval !== null) {
          clearInterval(this.timerInterval);
          this.timerInterval = null;
          this.setState({isTimerActive:false})
        }
      }
    }, 1000); 
  };
  reSendOTP = () => {
    this.setState({isResend:true})
    let reqBody = {
      data: {
        attributes: {
          email: this.state.email
        }
      }
    }
    this.reSendOTPAPICallId = sendAPIRequest(
      `account_block/resend_otp`,
      {
        method: configJSON.apiVerifyOtpMethod,
        body: { ...reqBody },
        headers: {
          "Content-Type": "application/json"
        }
      },
    );
    this.startTimer();
  }
  handleSendOtpAPI = (response: any) => {
    
    if(!response.errors){
      const otpConfRes = response.messages[0];
      if (otpConfRes) {
        localStorage.setItem('otpConfRes', JSON.stringify(otpConfRes));
      }
      this.goToCreateProfilePage();
    }
    else{
      this.setState({otperror:true,otperrorText:response.errors[0].otp || response.errors[0].pin})
    }
  }
  handleReSendOtpAPI = (response: any) => {
    const { meta } = response;
    if(meta){
      setStorageData("signUpToken", meta.token);
      this.setState({openResendOtpDialog: true})
    }
    
  }

  goToCreateProfilePage() {
    this.send(getNavigationMessage("UserProfile", this.props));
    removeStorageData("signUpToken");
  }

  onPasteTextField = (event: any) => {
    const otp = event.clipboardData.getData('text');
    if (otp && otp.length >= 4) {
      this.setState({
        otp1: otp.charAt(0),
        otp2: otp.charAt(1),
        otp3: otp.charAt(2),
        otp4: otp.charAt(3),
      });
    }
  }
  onChangeTextField = (event: any, stateName: any, nextField: any) => {
    this.setStateForOtpField(event, stateName);
    if (event.target.value.length === 1) {
      const nextfield = this.getInputRef(nextField);
      if (nextfield.current) {
        nextfield.current.focus();
      }
    }
  }

  setStateForOtpField = (event: React.ChangeEvent<HTMLInputElement>, stateName: 'otp1' | 'otp2' | 'otp3' | 'otp4') => {
    const setStateFn = {
      otp1: () => this.setState({ otp1: event.target.value }),
      otp2: () => this.setState({ otp2: event.target.value }),
      otp3: () => this.setState({ otp3: event.target.value }),
      otp4: () => this.setState({ otp4: event.target.value }),
    }
    setStateFn[stateName]();
  }

  getInputRef = (refName: 'otp1' | 'otp2' | 'otp3' | 'otp4') => {
    const otpInputRef = {
      otp1: () => this.otp1Ref,
      otp2: () => this.otp2Ref,
      otp3: () => this.otp3Ref,
      otp4: () => this.otp4Ref
    }
    return otpInputRef[refName]();
  }

  onTextFieldKeyDown = (event: any, prevField: any, currentOtpState: string) => {
    let prevfield = this.getInputRef(prevField);
    if (event.key === 'Backspace') {
      if (currentOtpState.length == 0 && prevfield.current) {
        prevfield.current.focus();
      }
    }
  }

  sliceInputValue = (event: any) => {
    return Math.max(0, parseInt(event.target.value)).toString().slice(0, 1);
  }

  handleDialogOkBtn = () => {
    this.setState({openResendOtpDialog: false});
  }

  handleErrorTooltipClose = () => {
    this.setState({otperror: false})
  }

  // Customizable Area End
}
