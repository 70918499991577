import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IPrivacyPolicy {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAdminUser: boolean;
  accountId: number;
  privacyPolicy: IPrivacyPolicy | null;
  privacyPolicyList: IPrivacyPolicy[];
  isPrivacyPolicyAccepted: boolean;
  isLoading: boolean;
  title: string,
  loading:boolean,
  desription: string,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacyPoliciesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPrivacyPolicyCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      privacyPolicyList: [],
      isAdminUser: true,
      privacyPolicy: null,
      isPrivacyPolicyAccepted: false,
      isLoading: false,
      title: "",
      loading:false,
      desription: ''

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getPrivacyPolicy()
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    
    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPrivacyPolicyCallId.length > 0 &&
      apiRequestCallId === this.getPrivacyPolicyCallId
    ) {
      this.setState({
        title: responseJson[0].title, loading:false
      })
      this.setState({
        desription: responseJson[0].description, loading:false
      })
      this.setState({loading:false})
    }
    // Customizable Area End
  }

  getPrivacyPolicy = async () => {
    let id = 1
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    this.setState({loading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivacyPolicyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_privacy_policy/privacy_policy'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoading: true });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  formatDescriptionList() {
    const lines = this?.state?.desription?.split('\n');
    let listContent = '';
    let headingCount = 0;
    let subheadingCount = 0;
    let currentHeading = '';
  
    const addListItem = (text: string, prefix = '') => {
      const listItemText = `${prefix}${text}`;
      listContent += `<li>${listItemText}</li>`;
    };
  
    const processHeading = (line: string, headingPrefix = '') => {
      headingCount++;
      subheadingCount = 0;
      currentHeading = `${headingCount}`;
      addListItem(line, `${currentHeading}${headingPrefix}`);
    };
    const processSubheading = (line: string) => {
      subheadingCount++;
      addListItem(`${currentHeading}.${subheadingCount} ${line}`);
    };
    lines?.forEach((line, index) => {
      if (line.trim() !== '') {
        const trimmedLine = line.trim();
        if (trimmedLine.startsWith('Privacy Policy')) {
          listContent += `<h1 style="font-size: 2em">${trimmedLine}</h1>`;
        } else if (trimmedLine.startsWith('Information')) {
          processHeading(trimmedLine, '. ');
        } else if (trimmedLine.startsWith('Personal Information') || trimmedLine.startsWith('Usage Information')) {
          processSubheading(trimmedLine.slice(0));
        } else if (trimmedLine.startsWith('How We Use Your Information')) {
          processHeading(trimmedLine, '. ');
        } else if (trimmedLine.startsWith('We may use the information we collect for various purposes, including')) {
          processSubheading(trimmedLine.slice(0));
        } else if (trimmedLine.startsWith('To')) {
          addListItem(` - ${trimmedLine}`);
        } else if (trimmedLine.startsWith('Disclosure of Your Information')) {
          processHeading(trimmedLine, '. ');
        } else if (trimmedLine.startsWith('We may share your personal information with third parties under the following circumstances')) {
          processSubheading(trimmedLine.slice(0));
        } else {
          addListItem(trimmedLine);
        }
      }
    });
  
    return listContent;
  }
  
  

  // Customizable Area End

}
