import React from "react";

// Customizable Area Start
import { Box, FormControl, IconButton, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography, Select, Input, styled, InputAdornment, Tabs, Tab, Button } from "@material-ui/core";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { LightTooltip } from "../../customform4/src/Customform4Controller";
import MuiTableCell from "@material-ui/core/TableCell";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { CSVLink } from "react-csv";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import "react-multi-date-picker/styles/layouts/mobile.css"
import InputIcon from "react-multi-date-picker/components/input_icon";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";

const StyledButton2 = styled(Button)({
  display: "flex",
  width: "220px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  border: "1px solid #F4CD46",
  borderRadius: "70px",
  fontSize: "1rem",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "capitalize",
  "&:hover": {
    background: "#F4CD46",
    color: "#000"
  },
  "@media(max-width: 650px)": {
    width: "200px",
    height: "50px",
    fontSize: "15px",
  }
});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      'paperWidthSm': {
        maxWidth: '694px',
        padding: '50px 0',
        background: '#212226'
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        fontFamily: 'inter',
        padding: '8px',
        backgroundColor: '#212226',
        opacity: 1,
        maxWidth: 300,
      },
      arrow: {
        color: '#212226',
      }
    },
    MuiTabs: {
      scrollButtons: {
        color: '#fff'
      }
    },
    MuiTab: {
      root: {
        '&.Mui-selected': {
          border: '1px solid #F4CD46'
        }
      }
    },
  }
});

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

const styles = {
  formControl: {
    margin: '10px',
    minWidth: 120,
    maxWidth: 300,
  },
  menuItem: {
    borderBottom: '1px solid #ccc',
    backgroundColor: '#212226',
    width: '215px',
  },
  chips: {
    display: 'flex' as const,
    flexWrap: 'wrap' as const,
  },
  chip: {
    margin: 2,
  },
};

const StyledSelect = styled(Select)({
  marginBottom: '20px',
  // marginRight: '16px',
  fontSize: '16px',
  display: 'flex',
  backgroundColor: '#212226',
  padding: '10px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  flexShrink: 0,
  height: '48px',
  outline: 'none',
  border: 'none',
  color: 'white',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '-0.408px',
  marginTop: "8px",
  // width:'314px'
});

const BusinessReportContainer = styled(Box)({
  maxWidth: '1051px', 
  marginTop: '25px', 
  display: 'flex', 
  alignItems: 'center', 
  flexWrap: 'wrap', 
  gap: '10px', 
  cursor: 'pointer', 
  marginLeft: '10%',
  "@media(max-width: 650px)": {
    justifyContent: 'center',
  }
});

const BusinessReportCustomBox = styled(Box)({
  width: '225px', 
  height: '100px', 
  padding: '15px', 
  background: '#212226',
  "@media(max-width: 650px)": {
    width: '200px', 
    height: '90px', 
    padding: '10px',
  }
});

const SelectBranchBox = styled(Box)({
  width: '314px',
  "@media(max-width: 450px)": {
    width: '275px',
  }
});


const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const,
  },
  getContentAnchorEl: null,
};

// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  calenderLeftPlugin = () => {
    return (
      <CalendarLeftMenu position={"left"} >
        <ThemeProvider theme={theme}>
          <Tabs
            data-test-id="left-calendar-tab"
            orientation={this.state.isMobile ? "horizontal" : "vertical"}
            variant="scrollable"
            scrollButtons="on"
            value={this.state.selectedCalendarMenu}
            onChange={this.handleCalendarMenuChange}
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
          >

            <Tab label="All Time" {...this.a11yProps(0)} style={webstyle.calenderLeftMenuStyle} data-test-id="tab-0" />
            <Tab label="Today" {...this.a11yProps(1)} style={webstyle.calenderLeftMenuStyle} data-test-id="tab-1" />
            <Tab label="Yesterday" {...this.a11yProps(2)} style={webstyle.calenderLeftMenuStyle} data-test-id="tab-2" />
            <Tab label="This Week" {...this.a11yProps(3)} style={webstyle.calenderLeftMenuStyle} data-test-id="tab-3" />
            <Tab label="This Month" {...this.a11yProps(4)} style={webstyle.calenderLeftMenuStyle} data-test-id="tab-4" />
            <Tab label="Year to Date" {...this.a11yProps(5)} style={webstyle.calenderLeftMenuStyle} data-test-id="tab-5" />
            <Tab label="Custom" {...this.a11yProps(6)} style={webstyle.calenderLeftMenuStyle} data-test-id="tab-6" />
          </Tabs>
        </ThemeProvider>
      </CalendarLeftMenu>
    )
  }

  calenderTopPlugin = () => {
    return (
      <CalendarTopPlugin position={"top"}>
        <Box style={webstyle.topPluginBoxStyle}>
          <Typography style={{ fontSize: '16px', fontWeight: 400, }}>Starting Date</Typography>
          <Typography style={webstyle.calenderTopDateStyle}>{this.state.startDate}</Typography>
          <CloseRoundedIcon style={{ color: '#fff', cursor: 'pointer' }} onClick={this.handleStartingDateCrossIcon} />
        </Box>
        <Box style={webstyle.topPluginBoxStyle}>
          <Typography style={{ fontSize: '16px', fontWeight: 400, }}>Ending Date</Typography>
          <Typography style={webstyle.calenderTopDateStyle}>{this.state.endDate}</Typography>
          <CloseRoundedIcon style={{ color: '#fff', cursor: 'pointer' }} onClick={this.handleEndingDateCrossIcon} />
        </Box>
      </CalendarTopPlugin>
    )
  }

  renderSubscriberReportUi = () => {
    return (
      <>
        <Box>
          <h1>Subscriber Report</h1>
          <TableContainer component={Paper}>
            <Table style={{ width: '100%', border: 'none' }}>
              <TableHead style={{ background: 'rgb(33, 34, 38)' }}>
                <TableRow>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px' }}>Subscriber</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px' }}>Subscription</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px' }}>MRR</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px' }}>Total Billing</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px' }}>Total Pauses</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px' }}>Total Redemptions</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px' }}>Total Cancellations</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px' }}>Total Failed Payments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ border: 'none' }}>
                {this.state.SubscriberData && this.state.SubscriberData.length > 0 ? (
                  this.state.SubscriberData.map((report: any, index: number) => (
                    report.map((subscriber: any, subIndex: number) => (
                      <TableRow key={`${index}-${subIndex}`}>
                        <TableCell style={{ color: 'white', textAlign: 'center' }}>{subscriber?.Subscriber_Name}</TableCell>
                        <TableCell style={{ color: 'white', textAlign: 'center' }}>{subscriber?.Subscription}</TableCell>
                        <TableCell style={{ color: 'white', textAlign: 'center' }}>{subscriber?.MRR}</TableCell>
                        <TableCell style={{ color: 'white', textAlign: 'center' }}>{subscriber?.Total_Billing}</TableCell>
                        <TableCell style={{ color: 'white', textAlign: 'center' }}>{subscriber?.Total_Paused}</TableCell>
                        <TableCell style={{ color: 'white', textAlign: 'center' }}>{subscriber?.Total_Redemption}</TableCell>
                        <TableCell style={{ color: 'white', textAlign: 'center' }}>{subscriber?.Total_Cancellation}</TableCell>
                        <TableCell style={{ color: 'white', textAlign: 'center' }}>{subscriber?.Total_Failed_Payments}</TableCell>
                      </TableRow>
                    ))
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} style={{ color: 'white', textAlign: 'center' }}>No Report found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    )
  }

  renderBillingReportUi = () => {
    return (
      <>
        <Box>
          <h1>Billing Report</h1>
          <TableContainer component={Paper}>
            <Table style={{ width: '100%', border: 'none' }}>
              <TableHead style={{ background: 'rgb(33, 34, 38)' }}>
                <TableRow>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px', textAlign: 'center' }}>Month</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px', textAlign: 'center' }}>MRR</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px', textAlign: 'center' }}>ARPU</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px', textAlign: 'center' }}>Total Billing</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px', textAlign: 'center' }}>Churn Rate</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px', textAlign: 'center' }}>Total Failed Payments</TableCell>
                  <TableCell style={{ color: 'white', fontWeight: 500, fontSize: '16px', textAlign: 'center' }}>Total Billing Outstanding</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ border: 'none' }}>
                {Object.keys(this.state.row2).some(key => key === 'error' || key === 'errors') ? (
                  <TableRow>
                    <TableCell colSpan={8} style={{ color: 'white', textAlign: 'center' }}>No Report found</TableCell>
                  </TableRow>
                ) : (
                  Object.keys(this.state.row2).map(month => (
                    <TableRow key={month}>
                      <TableCell style={{ color: 'white', textAlign: 'center' }}>{month}</TableCell>
                      {Object.keys(this.state.row2[month]).map(metric => (
                        metric !== 'count' && (
                          <TableCell key={metric} style={{ color: 'white', textAlign: 'center' }}>{this.state.row2[month][metric]}</TableCell>
                        )
                      ))}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    )
  }

  renderBusinessReportUi = () => {
    return (
      <>
        <BusinessReportContainer>
          <BusinessReportCustomBox test-id={`click_card_`} onClick={this.toBillingReport} >
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}>{this.state.BusinessData?.MRR !== undefined ? <>&#163;{this.state.BusinessData?.MRR} </> : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Monthly Recurring Revenue
                <LightTooltip arrow placement="bottom-start" title="Total monthly subscription cost for all subscribers as at the end of the selected period">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>

          <BusinessReportCustomBox test-id={`click_card_`} onClick={this.toBillingReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}>{this.state.BusinessData?.Average_Revenue_Per_Subscriber !== undefined ? <> &#163;{this.state.BusinessData?.Average_Revenue_Per_Subscriber} </> : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Average Revenue per Subscriber (ARPU)
                <LightTooltip arrow placement="bottom-start" title="Total MRR divided by total number of subscribers as at the end of the selected period">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>
          <BusinessReportCustomBox test-id={`click_card_`} onClick={this.toBillingReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}>{this.state.BusinessData?.Total_Billing !== undefined ? <>&#163;{this.state.BusinessData?.Total_Billing}</> : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Total Billing
                <LightTooltip arrow placement="bottom-start" title="Total value billed for the selected period">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>
          <BusinessReportCustomBox test-id={`click_card_`} id="subsreport" onClick={this.toSubscriberReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}>{this.state.BusinessData?.Total_Active_Subscription !== undefined ? this.state.BusinessData?.Total_Active_Subscription : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Total Active Subscriptions
                <LightTooltip arrow placement="bottom-start" title="Total active subscriptions as at the end of the selected period">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>
          <BusinessReportCustomBox test-id={`click_card_`} onClick={this.toSubscriberReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}>{this.state.BusinessData?.Total_Subscribers !== undefined ? this.state.BusinessData?.Total_Subscribers : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Total Subscribers
                <LightTooltip arrow placement="bottom-start" title="Total number of subscribers as at the end of the selected period">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>
          <BusinessReportCustomBox test-id={`click_card_`} id="billingReport" onClick={this.toBillingReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}> {this.state.BusinessData?.Total_Cancellation !== undefined ? this.state.BusinessData?.Total_Cancellation : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Total Cancellations
                <LightTooltip arrow placement="bottom-start" title=" Total number of cancellations that occurred during the selected period">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>
          <BusinessReportCustomBox test-id={`click_card_`} onClick={this.toBillingReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}>{this.state.BusinessData?.Churn_Rate !== undefined ? <> {this.state.BusinessData?.Churn_Rate}% </> : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Churn Rate
                <LightTooltip arrow placement="bottom-start" title="Total number of cancellations divided by total number of subscribers at the beginning of the period, multiplied by 100">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>
          <BusinessReportCustomBox test-id={`click_card_`} onClick={this.toSubscriberReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}> {this.state.BusinessData?.Total_Pauses !== undefined ? this.state.BusinessData?.Total_Pauses : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Total Pauses
                <LightTooltip arrow placement="bottom-start" title="Total number of pauses that occurred during the selected period">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>
          <BusinessReportCustomBox test-id={`click_card_`} onClick={this.toSubscriberReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}>{this.state.BusinessData?.Total_Redemption !== undefined ? this.state.BusinessData?.Total_Redemption : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Total Redemptions
                <LightTooltip arrow placement="bottom-start" title="Total number of redemptions that occurred during the selected period">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>

          <BusinessReportCustomBox test-id={`click_card_`} onClick={this.toBillingReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}>{this.state.BusinessData?.Redemption_Rate !== undefined ? <>{this.state.BusinessData?.Redemption_Rate}%</> : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Redemption Rate
                <LightTooltip arrow placement="bottom-start" title="Total number of redemptions divided by total redemption units available for the current period across subscribers, multiplied by 100">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>

          <BusinessReportCustomBox test-id={`click_card_`} onClick={this.toBillingReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}>{this.state.BusinessData?.Total_Billing_Outstanding !== undefined ? this.state.BusinessData?.Total_Billing_Outstanding : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Total Billing Outstanding
                <LightTooltip arrow placement="bottom-start" title="Total billing still to be collected for the current period">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>

          <BusinessReportCustomBox test-id={`click_card_`} onClick={this.toSubscriberReport}>
            <Typography style={{ fontWeight: 700, fontSize: '1.5em' }}> {this.state.BusinessData?.Total_Failed_Payments !== undefined ? this.state.BusinessData?.Total_Failed_Payments : "-"}</Typography>
            <Box>
              <Typography variant="body1" style={{ alignItems: "center", color: 'rgba(255, 255, 255, 0.4)', fontWeight: 600, fontSize: '1.3em' }}>
                Total Failed Payments
                <LightTooltip arrow placement="bottom-start" title="Total number of failed payments for the selected period">
                  <IconButton style={{ padding: 0, margin: '0 4px' }}>
                    <ErrorOutlineIcon style={{ color: 'rgba(255, 255, 255, 0.4)' }} />
                  </IconButton>
                </LightTooltip>
              </Typography>
            </Box>
          </BusinessReportCustomBox>

        </BusinessReportContainer>
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.loading && <SpinnerLoader />}
        <Box style={{fontSize: 'clamp(10px, 16px, 2vw)', padding: '20px 5%', color: '#ffffff'}}>
          {this.renderHeadng()}

          <Box className="report_button_wrapper" style={{ marginLeft: this.state.report ? "" : '10%' }}>
            <Box className="radio">
              {["Business", "Subscribers", "Billing"].map((option, index) => (
                <React.Fragment key={index}>
                  <input
                    type="radio"
                    className="radio__input"
                    value={option}
                    name="myRadio"
                    test-id="report_button"
                    onChange={this.handleCurrentData}
                    id={`myRadio${index}`}
                    checked={this.state.currentData === option}
                  />
                  <label className="radio__label" htmlFor={`myRadio${index}`}>
                    {option}
                  </label>
                </React.Fragment>
              ))}
            </Box>
          </Box>

          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', maxWidth: this.state.report ? '' : '1051px', marginLeft: this.state.report ? " " : '10%' }}>

            <Box style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>

              <Box >
                <Typography
                  style={{ color: '#fff', fontSize: '16px', fontWeight: 300, marginBottom: '8px' }}
                >
                  Select Date
                </Typography>
                <DatePicker
                  value={this.state.selectedDateArray}
                  onChange={date => this.handleDateChange(date)}
                  range
                  portal
                  disableMonthPicker={this.toggleMonthPickerDisable()}
                  disableYearPicker={this.toggleYearPickerDisable()}
                  format="DD-MM-YYYY"
                  showOtherDays
                  render={<InputIcon />}
                  numberOfMonths={2}
                  style={webstyle.calendarInputStyle}
                  arrow={false}
                  className="custom-calendar rmdp-mobile"
                  mobileLabels={{
                    OK: "Apply",
                    CANCEL: "Cancel",
                  }}
                  plugins={[
                    this.calenderLeftPlugin(),
                    this.calenderTopPlugin()
                  ]}
                />
              </Box>

              {this.state.isDropdownOpen && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.9)',
                  zIndex: 999,
                }} onClick={this.handleDropdownClose}></div>
              )}
              <SelectBranchBox>
                <Typography className="custom-label">Branch</Typography>
                <FormControl className="custom-form-control">

                  <StyledSelect
                    labelId="demo-mutiple-chip-label"
                    id="menuOptions"
                    onOpen={this.handleDropdownOpen}
                    onClose={this.handleDropdownClose}
                    defaultValue="All"
                    input={
                      <Input
                        id="select-multiple-chip"
                        endAdornment={
                          <InputAdornment position="end">
                            <ArrowDropDownIcon style={{ color: 'white', cursor: 'pointer' }} />
                          </InputAdornment>
                        }
                      />
                    }
                    IconComponent={() => null}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="all" id="allId" onClick={() => this.setId("All")} value="All" style={{ width: '215px', background: '#212226', color: 'white', borderBottom: '1px solid #ccc' }}>
                      All
                    </MenuItem>
                    {this.state.branchValue?.map((item: any) => (
                      <MenuItem id="numbers" onClick={() => this.setId2(item)} key={item[0].id} value={item[0].name} style={{ width: '215px', background: '#212226', color: 'white', borderBottom: '1px solid #ccc', }}>
                        {item[0].name}
                      </MenuItem>
                    ))}
                  </StyledSelect>


                </FormControl>
              </SelectBranchBox>

            </Box>

            <StyledButton2>
              <CSVLink
                data={this.getCsvData() || "no data found"}
                style={{ textDecoration: 'none', color: "inherit", display: 'flex', alignItems: 'center', fontSize: 'inherit' }}
              >
                <svg
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  style={{ color: 'inherit', marginRight: '15px', width: '24', height: '24' }}
                >
                  <path d="M.5 9.9a.5.5 0 01.5.5v2.5a1 1 0 001 1h12a1 1 0 001-1v-2.5a.5.5 0 011 0v2.5a2 2 0 01-2 2H2a2 2 0 01-2-2v-2.5a.5.5 0 01.5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 00.708 0l3-3a.5.5 0 00-.708-.708L8.5 10.293V1.5a.5.5 0 00-1 0v8.793L5.354 8.146a.5.5 0 10-.708.708l3 3z" />
                </svg>
                Download Report
              </CSVLink>
            </StyledButton2>
          </Box>

          {!this.state.report && this.renderBusinessReportUi()}
          {this.state.currentData === "Subscribers" && this.renderSubscriberReportUi()}
          {this.state.currentData === "Billing" && this.renderBillingReportUi()}
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webstyle = {
  calenderLeftMenuStyle: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Inter',
    textTransform: 'capitalize' as 'capitalize'
  },
  calenderBottomContainerStyle: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: '80px',
    gap: '10px'
  },
  calenderCancelButtonStyle: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Inter',
    textTransform: 'capitalize' as 'capitalize',
    width: '220px',
    height: '56px',
    border: '1px solid #fff',
    borderRadius: '53px'
  },
  calenderApplyButtonStyle: {
    width: '220px',
    height: '56px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Inter',
    textTransform: 'capitalize' as 'capitalize',
    border: '1px solid #F4CD46',
    borderRadius: '53px'
  },
  calenderTopContainerStyle: {
    display: "flex",
    gap: '20px',
    justifyContent: 'end'
  },
  calendarInputStyle: {
    width: 'clamp(280px, 300px, 100%)',
    height: '48px',
    background: '#212226',
    color: '#fff',
    overflow: 'hidden',
    border: 'none',
    borderRadius: 0
  },
  topPluginBoxStyle: {
    display: 'flex',
    gap: '8px',
    width: '285px',
    height: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff'
  },
  calenderTopDateStyle: {
    fontSize: '14px',
    fontWeight: 400,
    border: '1px solid #C6C6C6',
    borderRadius: '4px',
    padding: '2px 8px'
  }
}

const CalendarLeftMenu = styled(Box)({
  display: 'flex',
  flexDirection: 'column' as 'column',
  textAlign: 'left' as 'left',
  padding: '20px',
  '@media (max-width: 750px)': {
    padding: '5px',
  },
});

const CalendarTopPlugin = styled(Box)({
  display: "flex",
  gap: '20px',
  justifyContent: 'end',
  '@media (max-width: 750px)': {
    gap: '10px',
    flexWrap: 'wrap',
  },
});
// Customizable Area End
