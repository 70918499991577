import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { landingPageImage } from "./assets";
import CustomDialog from "../../../components/src/CustomDailog.web";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

const RendingTime = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '140px',
  "@media (max-width: 776px)": {
    marginBottom: '120px',
  },
  "@media (max-width: 478px)": {
    alignItems: 'center',
    margin: 0
  }
})
const LandingPageButton = styled(Button)({
  display: "flex",
  width: "225px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  border: "1px solid #F4CD46",
  borderRadius: "50px",
  fontSize: "1rem",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "capitalize",
  "&:hover": {
    background: "#F4CD46",
    color: "#000"
  },
  "@media (max-width: 775px)": {
    width: "155px",
    fontSize: "0.8rem",
    // marginLeft: "11px",
    height: "45px"
  }
});

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  WelcomeTypography = styled(Typography)({
    color: "#fff",
    fontSize: "3rem",
    fontWeight: 700,
    letterSpacing: "-0.72px",
    marginLeft: "0",

    "@media (max-width: 600px)": {
      fontSize: "1.5rem"
      // marginLeft: "14"
    },
    "@media (max-width:775px)": {
      fontSize: "1.6rem"
      // marginLeft: "14"
    }
  });
  SubHeadingText = styled(Typography)({
    color: "#FFF",
    opacity: 0.7,
    fontSize: "1.5rem",
    fontFamily: "Inter",
    fontWeight: 400,
    letterSpacing: "-0.408px",

    "@media (max-width: 600px)": {
      fontSize: "1.1rem"
      // marginLeft: "14"
    }
  });

  createSubscriptionButton = () => {
    if (this.state.branchList.length !== 0) {
      return (
        <>
          <LandingPageButton
            className="landing-page-btn"
            data-test-id="create-subscriptions"
            onClick={this.goToSubscriptionPage}
          >
            Create Subscriptions
          </LandingPageButton>
        </>
      )
    }
    // else {
    //   return null
    // }
  }

  renderDescription = () => {
    if (this.state.branchList.length === 0) {
      return (
        <this.SubHeadingText>
          <p>Ready to get started?</p>
          <p>First things first: Create your first Branch</p>
        </this.SubHeadingText>
      )
    }
    else {
      return (
        <>
          <this.SubHeadingText>
            <p>Ready to get started? Click the button below to set up
              branches for your business, or create your first
              subscription.
            </p>
          </this.SubHeadingText>
        </>
      )
    }
  }

  renderTime = () => {
    const { remainingDays } = this.state;
    return (
      <>
        {((Math.floor(remainingDays / 10) !== 0) || (remainingDays % 10 !== 0)) && (
          <Box>
            <h2 style={{ color: 'white', fontWeight: 400, fontSize: 'clamp(18px, 30px, 4vw)' }}>Your Free Trial ends in</h2>
            <div style={{ display: 'flex', alignItems: 'end' as 'end', color: 'white', gap: '15px', flexWrap: 'wrap' }}>
              <div style={{
                backgroundImage: `linear-gradient(rgb(32, 32, 32) 45%, #919191 50%, rgb(0, 0, 0) 25%, #313131)`,
                height: 'clamp(75px, 100px, 10vw)', width: 'clamp(75px, 100px, 10vw)', fontSize: 'clamp(40px, 69px, 8vw)', display: 'flex', justifyContent: 'center', alignItems: 'center',
                borderRadius: '5px',
                boxShadow: 'white 0px 1px 4px'
              }}>{Math.floor(remainingDays / 10)}</div>
              <div
                style={{
                  backgroundImage: `linear-gradient(rgb(32, 32, 32) 45%, #919191 50%, rgb(0, 0, 0) 25%, #313131)`,
                  height: 'clamp(75px, 100px, 10vw)', width: 'clamp(75px, 100px, 10vw)', fontSize: 'clamp(40px, 69px, 8vw)', display: 'flex', justifyContent: 'center',
                  alignItems: 'center', borderRadius: '5px', boxShadow: 'white 0px 1px 4px'
                }}
              >{remainingDays % 10}</div><div style={{ color: '#F4CD46', fontWeight: 400, fontSize: 'clamp(20px, 30px, 4vw)', fontFamily: 'Inter' }}>Days</div>
            </div>
          </Box>
        )}
      </>
    )
  }

  renderSubscriberUi = () => {
    return (
      <Grid
        container
        spacing={3}
        style={{ height: "100%", paddingLeft: "5vw", margin: 0, maxWidth: '100%' }}
      >
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "30px",
            padding: "0"
          }}
        >
          <Box>
            <this.WelcomeTypography>
              {this.state.isLogin === true ? `Welcome back!` : `Welcome to GoQR`}
            </this.WelcomeTypography>
            {this.state.isProfileCreated && (
              <this.SubHeadingText>
                Keep an eye out for GoQR at your local businesses and click
                the button below to scan a GoQR code.
              </this.SubHeadingText>
            )}
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px"
            }}
          >
            {this.state.isLogin === true && (
              <>
                <LandingPageButton
                  className="landing-page-btn"
                  data-test-id="view-my-subscription"
                  onClick={this.goToMySubscription}
                >
                  View My Subscriptions
                </LandingPageButton>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    )
  }
  renderBusinessUserUi = () => {
    return (
      <Grid
        container
        spacing={3}
        style={{ height: "100%", paddingLeft: "5vw", maxWidth: '100%', margin: 0 }}
      >
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0",
            gap: "30px"
          }}
        >
          <Box>
            <this.WelcomeTypography variant="h1">
              {(this.state.isLogin === true || this.state.isJoinedMember) ? `Welcome back!` : `Welcome to GoQR`}
            </this.WelcomeTypography>
            {
              this.state.isProfileCreated && (
                this.renderDescription()
              )
            }
          </Box>
          <Box>
            {(this.state.isLogin === true || this.state.isJoinedMember) && !this.state.businessOwner && (
              <Box
                className="landingPageBtnAfterLogin"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px"
                }}
              >
                {this.state.businessUserPermissions.general_user &&
                  <LandingPageButton
                    className="landing-page-btn"
                    data-test-id="login-scan-qr-code"
                    onClick={this.gotoMyQr}
                  >
                    Scan QR Code
                  </LandingPageButton>}
                {this.state.businessUserPermissions.manage_subscription &&
                  <LandingPageButton
                    className="landing-page-btn"
                    data-test-id="manage-subscriptions"
                    onClick={this.goToSubscriptionPage}
                  >
                    Manage Subscriptions
                  </LandingPageButton>}
                {this.state.businessUserPermissions.manage_business &&
                  <LandingPageButton
                    className="landing-page-btn"
                    data-test-id="manage-branches"
                    onClick={this.goToBusinessBranch}
                  >
                    Manage Branches
                  </LandingPageButton>}
              </Box>
            )}
            {(this.state.isLogin === true && this.state.businessOwner) && (
              <Box
                className="landingPageBtnAfterLogin"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px"
                }}
              >
                <LandingPageButton
                  className="landing-page-btn"
                  data-test-id="login-scan-qr-code"
                  onClick={this.gotoMyQr}
                >
                  Scan QR Code
                </LandingPageButton>

                <LandingPageButton
                  className="landing-page-btn"
                  data-test-id="manage-subscriptions"
                  onClick={this.goToSubscriptionPage}
                >
                  Manage Subscriptions
                </LandingPageButton>

                <LandingPageButton
                  className="landing-page-btn"
                  data-test-id="manage-branches"
                  onClick={this.goToBusinessBranch}
                >
                  Manage Branches
                </LandingPageButton>
              </Box>
            )}



            {this.state.isProfileCreated && this.state.businessOwner && (
              <Box
                className="landingPageBtnAfterSignUp"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px"
                }}
              >
                <LandingPageButton
                  className="landing-page-btn"
                  data-test-id="create-branches"
                  onClick={this.goToCreateBranch}
                >
                  Create Branch
                </LandingPageButton>
                {this.createSubscriptionButton()}

              </Box>
            )}

          </Box>
        </Grid>

        <RendingTime >
          {
            this.state.isProfileCreated && this.state.businessOwner && (
              this.renderTime()
            )}


          {(this.state.isLogin === true || this.state.isJoinedMember) && this.state.businessOwner && (
            this.renderTime()
          )}
        </RendingTime>
      </Grid>
    )
  }

  // Customizable Area End

  render() {
    return (
      <div className="landingPageContainer" style={{ height: "100%" }}>
        {this.state.isSubscriber ? this.renderSubscriberUi() : this.renderBusinessUserUi()}
        <CustomDialog
          isError={true}
          open={this.state.openBranchMessageDialog}
          actions={[
            {
              label: "OK",
              handler: this.handleBranchDialogBtn,
              borderColor: "#F4CD46",
              testId: 'dialogCloseBtn',
              primary: true
            },
          ]}
          content="The business owner needs assigns you to a branch first"
          title="Not assigned to any branch"
        />
      </div>
    );
  }
}

// Customizable Area Start
const webstyles = {
  renderTimer: {
    // width:'50%',
    paddingBottom: "150px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  }
}
// Customizable Area End
