import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import React from "react";
import { Box, Typography } from "@material-ui/core"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getNavigationMessage } from "../../../components/src/Utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: any;
  currentData: string;
  selected_branch: string;
  report: boolean;
  branchValue: any;
  BusinessData: any;
  SubscriberData: any;
  row2: any;
  loginToken: any;
  branchId: any,
  isDropdownOpen: boolean;
  startDate: string;
  endDate: string;
  openDateModel: boolean,
  selectedDateArray: string[],
  selectedCalendarMenu: number;
  loading: boolean;
  profileCreationDate: string;
  isMobile: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: any;
  getSubscriptionReport: string = "";
  getBillingReport: string = "";
  getBusinessReport: string = "";
  getBranches: string = "";
  getProfileDetailsApiId: string = "";
  csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  ];
  calendarRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      currentData: 'Business',
      selected_branch: 'All',
      report: false,
      branchValue: [],
      BusinessData: {},
      SubscriberData: [],
      row2: [{
        id: 1,
        month: 'Jan 2023',
        mrr: '£1.00',
        arpu: '2%',
        totalBilling: '£1.00',
        churnRate: '2%',
        totalFailedPayments: '2',
        totalBillingOutstanding: '£ 1.00',
      },
      {
        id: 2,
        month: 'Feb 2023',
        mrr: '£1.00',
        arpu: '2%',
        totalBilling: '£1.00',
        churnRate: '2%',
        totalFailedPayments: '2',
        totalBillingOutstanding: '£ 1.00',
      },
      {
        id: 3,
        month: 'Mar 2023',
        mrr: '£2.00',
        arpu: '2%',
        totalBilling: '£2.00',
        churnRate: '2%',
        totalFailedPayments: '2',
        totalBillingOutstanding: '£ 2.00',
      }],
      loginToken: null,
      branchId: "All",
      isDropdownOpen: false,
      startDate: new Date().toLocaleDateString("en-GB"),
      endDate: new Date().toLocaleDateString("en-GB"),
      openDateModel: false,
      selectedDateArray: [new Date().toLocaleDateString("en-GB"), new Date().toLocaleDateString("en-GB")],
      selectedCalendarMenu: 1,
      loading: false,
      profileCreationDate: "",
      isMobile: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.calendarRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getSubscriptionReport) {

        this.handleSubscriberReport(responseJson)
      }
      if (apiRequestCallId === this.getBillingReport) {

        this.handleBillingReport(responseJson)
      }
      if (apiRequestCallId === this.getBusinessReport) {
        this.handleBusinessReport(responseJson)
      }
      if (apiRequestCallId === this.getBranches) {
        this.handleBranch(responseJson)
      }
      if (apiRequestCallId === this.getProfileDetailsApiId) {
        this.handleUserProfileDetailsRes(responseJson)
      }

    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start

    window.addEventListener('resize', this.handleWindowSizeChange);
    this.handleWindowSizeChange();

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const logInRes = await getStorageData('logInRes', true);
    const otpConfRes = await getStorageData('otpConfRes', true);

    this.setTokenInStorage(token, logInRes, otpConfRes);

    this.fetchBusinessReport(this.state.branchId)
    this.fetchBillingReport(this.state.branchId)
    this.fetchSubscriptionReport(this.state.branchId)
    this.fetchBranches()
    this.fetchProfileData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevState.selectedDateArray !== this.state.selectedDateArray) {
      this.callDataApisAfterDate();
    }
  }

  async componentWillUnmount() {
    // Remove event listener when component unmounts
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    // Check if window width is less than 600px (you can adjust the threshold as needed)
    const isMobile = window.innerWidth < 620;

    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
  };

  callDataApisAfterDate = () => {
    if (this.state.report) {
      if (this.state.currentData === "Subscribers") {
        this.fetchSubscriptionReport(this.state.branchId)
      } else if (this.state.currentData === "Billing") {
        this.fetchBillingReport(this.state.branchId)
      }
    } else {
      this.fetchBusinessReport(this.state.branchId)
    }
  }

  dayRavenue = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatisticAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dayAudience = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.audienceFollowersAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCurrentData = (e: any) => {
    let currData = e.target.value;
    this.setState({ currentData: currData });
    if (currData === "Subscribers") {
      this.fetchSubscriptionReport(this.state.branchId)
      this.setState({ report: true })

    }
    else if (currData === "Billing") {
      this.fetchBillingReport(this.state.branchId)
      this.setState({ report: true })
    }
    else {
      this.fetchBusinessReport(this.state.branchId)
      this.setState({ report: false })
    }
  };

  getCsvData = () => {
    if (this.state.report) {
      if (this.state.currentData === "Subscribers") {
        return this.state.SubscriberData.flat()
      } else if (this.state.currentData === "Billing") {
        const { row2 } = this.state
        const dataArray = Object.keys(row2).map(month => {
          return {
            "Month": month,
            "MRR": row2[month].MRR,
            "ARPU": row2[month].ARPU,
            "Total Billing": row2[month]["Total Billing"],
            "Churn Rate": row2[month]["Churn Rate"],
            "Total Failed Payment": row2[month]["Total Failed Payment"],
            "Total Billing Outstanding": row2[month]["Total Billing Outstanding"]
          };
        });
        return dataArray
      }
    } else {
      return [{ ...this.state.BusinessData }]
    }
  }

  fetchBusinessReport = async (id: any) => {
    this.setState({ loading: true })
    const { selectedDateArray } = this.state
    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getBusinessReport = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_visual_analytics/reporting_data?branch_id=${id}&start_date=${selectedDateArray[0]}&end_date=${selectedDateArray[1]}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  fetchSubscriptionReport = async (id: any) => {
    this.setState({ loading: true })
    const { selectedDateArray } = this.state
    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getSubscriptionReport = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_visual_analytics/subscriber_reports?branch_id=${id}&start_date=${selectedDateArray[0]}&end_date=${selectedDateArray[1]}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchBillingReport = async (id: any) => {
    this.setState({ loading: true })
    const { selectedDateArray } = this.state

    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getBillingReport = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_visual_analytics/billing_reports?branch_id=${id}&start_date=${selectedDateArray[0]}&end_date=${selectedDateArray[1]}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  fetchBranches = async () => {
    const id = await localStorage.getItem("BusinessId")

    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getBranches = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_branch/joined_business_user_branches`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleBusinessReport = (response: any) => {
    this.setState({ loading: false })
    if (response) {
      this.setState({ BusinessData: response.reporting_data })

    }
  }
  handleSubscriberReport(response: any) {
    this.setState({ loading: false });
    if (response && response.subscriber_reports) {
      this.setState({ SubscriberData: response.subscriber_reports });
    } else {
      this.setState({ SubscriberData: [] }); 
    }
  }
  handleBillingReport(response: any) {
    this.setState({ loading: false })
    if (response) {
      this.setState({ row2: response })
    }
  }
  handleBranch = (response: any) => {
    if (response) {
      this.setState({ branchValue: response.branch })
    }
  }

  toBillingReport = () => {
    this.setState({ currentData: "Billing", report: true })
  }

  toSubscriberReport = () => {
    this.setState({ currentData: "Subscribers", report: true })
  }
  setId = (item: any) => {
    this.setState({ branchId: "All" });
    this.fetchBillingReport("All");
    this.fetchSubscriptionReport("All");
    this.fetchBusinessReport("All");

  }
  setId2 = (item: any) => {
    if (item && item.length > 0) {
      const id = item[0].id;
      this.setState({ branchId: id });
      this.fetchBillingReport(id);
      this.fetchSubscriptionReport(id);
      this.fetchBusinessReport(id);
    }
  }

  handleDropdownOpen = () => {
    this.setState({ isDropdownOpen: true })
  };

  handleDropdownClose = () => {
    this.setState({ isDropdownOpen: false })
  };

  renderHeadng = () => {
    return (
      <>
        <Box style={{ marginLeft: this.state.report ? "" : '10%' }}>

          {!this.state.report &&
            <>
              <Typography style={{fontSize: "2em"}}>Reporting</Typography>
            </>}
          {this.state.currentData === "Subscribers" &&
            <Typography id="subscriber" onClick={() => this.setState({ report: false, currentData: 'Business' })} style={{ cursor: 'pointer', fontSize: '2em' }}><ArrowBackIcon style={{ color: 'white', marginRight: '8px' }} />Subscriber Report</Typography>}
          {
            this.state.currentData === "Billing" &&
            <Typography id="billing" onClick={() => this.setState({ report: false, currentData: 'Business' })} style={{ cursor: 'pointer', fontSize: '2em' }}><ArrowBackIcon style={{ color: 'white', marginRight: '8px' }} />Billing Report</Typography>
          }
        </Box>
      </>
    )
  }
  formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`;
  }

  handleDateChange = (array: any) => {
    let newArray = array?.join(",").split(',');


    this.setState({
      selectedDateArray: array,
      startDate: newArray[0],
      endDate: newArray[1]
    })
  }

  handleAllTimeMenu = () => {
    let startDate = this.state.profileCreationDate
    let endDate = new Date
    const dateArray = [startDate, endDate.toLocaleDateString("en-GB")]


    this.setState({
      selectedDateArray: dateArray,
      startDate: dateArray[0],
      endDate: dateArray[1]
    })
  }
  handleTodayMenu = () => {
    let startDate = new Date
    let endDate = new Date
    const dateArray = [startDate.toLocaleDateString("en-GB"), endDate.toLocaleDateString("en-GB")];

    this.setState({
      selectedDateArray: dateArray,
      startDate: dateArray[0],
      endDate: dateArray[1]
    })
  }
  handleYesterdayMenu = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const startDate = yesterday;
    const endDate = yesterday;
    const dateArray = [startDate.toLocaleDateString("en-GB"), endDate.toLocaleDateString("en-GB")];

    this.setState({
      selectedDateArray: dateArray,
      startDate: dateArray[0],
      endDate: dateArray[1]
    })
  }
  handleThisWeekMenu = () => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()); 
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));

    const dateArray = [startDate.toLocaleDateString("en-GB"), endDate.toLocaleDateString("en-GB")];

    this.setState({
      selectedDateArray: dateArray,
      startDate: dateArray[0],
      endDate: dateArray[1]
    })
  }
  handleThisMonthMenu = () => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const dateArray = [startDate.toLocaleDateString("en-GB"), endDate.toLocaleDateString("en-GB")];

    this.setState({
      selectedDateArray: dateArray,
      startDate: dateArray[0],
      endDate: dateArray[1]
    })
  }
  handleYearToDateMenu = () => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), 0, 1);
    const endDate = new Date();
    const dateArray = [startDate.toLocaleDateString("en-GB"), endDate.toLocaleDateString("en-GB")];

    this.setState({
      selectedDateArray: dateArray,
      startDate: dateArray[0],
      endDate: dateArray[1]
    })
  }
  handleCustomMenu = () => {
    let startDate = new Date
    let endDate = new Date
    const dateArray = [startDate.toLocaleDateString("en-GB"), endDate.toLocaleDateString("en-GB")];

    this.setState({
      selectedDateArray: dateArray,
      startDate: dateArray[0],
      endDate: dateArray[1]
    })
  }

  a11yProps = (index: any) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  handleCalendarMenuChange = (event: React.ChangeEvent<{}>, newValue: number) => {

    this.setState({ selectedCalendarMenu: newValue });
    switch (newValue) {
      case 0:
        this.handleAllTimeMenu();
        break;
      case 1:
        this.handleTodayMenu();
        break;
      case 2:
        this.handleYesterdayMenu();
        break;
      case 3:
        this.handleThisWeekMenu();
        break;
      case 4:
        this.handleThisMonthMenu();
        break;
      case 5:
        this.handleYearToDateMenu();
        break;
      case 6:
        this.handleCustomMenu();
        break;
      default:
        break;
    }
  };

  toggleMonthPickerDisable = () => {
    return this.state.selectedCalendarMenu === 6 ? false : true
  }

  toggleYearPickerDisable = () => {
    return this.state.selectedCalendarMenu === 6 ? false : true
  }
  stateSetAfterLogin = (logInRes: any) => {
    if (logInRes) {
      this.setState({
        loginToken: logInRes.logInToken
      })
    }
  }

  stateSetAfterSignup = (otpConfRes: any) => {
    if (otpConfRes) {
      this.setState({ loginToken: otpConfRes.token });
    }
  }

  setTokenInStorage = (token: any, logInRes: any, otpConfRes: any) => {

    if (!logInRes && !otpConfRes && !token) {
      window.localStorage.clear();
      this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
    }

    if (token) {

      const logRes = { logInToken: token };

      localStorage.setItem('logInRes', JSON.stringify(logRes));
      this.setState({ loginToken: token });
      removeStorageData("isProfileCreated");
      setStorageData("userLoggedIn", true);
      setStorageData("isLogin", true);
    } else {
      this.stateSetAfterSignup(otpConfRes);
      this.stateSetAfterLogin(logInRes);
    }

  }

  handleStartingDateCrossIcon = () => {
    const { selectedCalendarMenu } = this.state;
    let startDate = new Date
    let endDate = new Date
    if (selectedCalendarMenu === 2) {
      const dateArray = [startDate.toLocaleDateString("en-GB"), endDate.toLocaleDateString("en-GB")];
      this.setState({ selectedCalendarMenu: 6 })
      this.handleDateChange(dateArray);
    } else {
      const dateArray = [startDate.toLocaleDateString("en-GB"), this.state.endDate];
      this.setState({ selectedCalendarMenu: 6 })
      this.handleDateChange(dateArray);
    }

  }

  handleEndingDateCrossIcon = () => {
    let endDate = new Date
    const dateArray = [this.state.startDate, endDate.toLocaleDateString("en-GB")];
    this.setState({ selectedCalendarMenu: 6 })
    this.handleDateChange(dateArray);
  }

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getProfileDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileDetailsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUserProfileDetailsRes = (response: any) => {
    if (!response.errors) {
      const { attributes } = response.data;

      let dateStr = attributes?.created_at;
      let date = new Date(dateStr);

      let day: number|string = date.getDate();
      let month: number|string = date.getMonth() + 1;
      let year = date.getFullYear();

      day = day < 10 ? "0" + day : day;
      month = month < 10 ? "0" + month : month;

      let formattedDate = `${day}/${month}/${year}`;
      this.setState({profileCreationDate: formattedDate});
    }
  }

  // Customizable Area End
}
