import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import { getNavigationMessage} from "../../../components/src/Utils";
import { Box, Button, Typography, styled } from "@material-ui/core";
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import CustomeButton from "../../../components/src/CustomeButton.web";
interface TransactionHistory {
  id: number;
  transaction_type: string;
  date: string;
  user_subscription_id: number;
  created_at: string;
  updated_at: string;
  payment_status: string | null;
}

const StyledButton = styled(Button)({
  display: "flex",
  width: "269px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  border: "1px solid #FFF",
  borderRadius: "70px",
  fontSize: "1em",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "unset",
  "&:hover": {
    background: "#fff",
    color: "#000"
  },
  "@media(max-width: 610px)": {
    width: "220px",
    height: "45px",
  },
  "@media(max-width: 500px)": {
    width: "180px",
    height: "40px",
  }
});

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  value: number;
  activeButton: string,
  SubsciptionData: any,
  loading: boolean,
  loginToken: any,
  ischeck: string,
  openDetails: boolean;
  subscriptionDetails: any;
  expandTransactionHistory: boolean;
  viewDetailsData: any;
  PauseCheck: boolean,
  PauseConfirm: boolean
  openCancelDialog: boolean;
  isSubscriptionCanceled: boolean;
  subStatus: string,
  activeBtn:string,
  isBranchTooltipOpen: boolean;
  transactionData:any
  openPauseErrorDialog: boolean
  pauseErrorDialogMsg: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllSubscriptionListApi: string = "";
  makePaymentApiCallId: string = "";
  pauseSubscriptionApiCallId: string = "";
  viewDetailsApiCallId: string = "";
  cancelSubscriptionApiCallId: string = "";

  transactionHistoryData = [
    { name: "Subscription redeemed", date: "21 August 2023, 5:05pm" },
    { name: "Subscription redeemed", date: "26 August 2023, 6:26pm" },
    { name: "Subscription redeemed", date: "24 July 2023, 3:33pm" },
    { name: "Subscription redeemed", date: "20 June 2023, 3:33pm" },
    { name: "Subscription redeemed", date: "2 June 2023, 3:33pm" },
  ];
  viewDetailsData = {

    "subscription": {
      "id": 218,
      "name": "Regular",
      "description": "acd wwwwwwwwwwwwwwwwwwwwwwwwwwwwww ffffffffffffe ef ge  grerwwwwwwwwwwwwwwwvfbvergvtrbvvvvvvvvvvvvvvvvvvv",
      "price": "29.99",
      "number_of_units": 1,
      "name_of_units": "classes",
      "branch_name": "[\"ab15branch\"]",
      "duration": 1,
      "per": "Day",
      "pauses_allowed": 1,
      "active": true,
      "account_id": 2059,
      "branch_ids": [
        "773"
      ],
      "valid_branches": 1
    },
    "user_subscription_details": {
      "id": 132,
      "account_id": 2032,
      "custom_user_subs_subscription_id": 218,
      "created_at": "2024-03-11T10:56:13.528Z",
      "updated_at": "2024-03-11T11:00:22.540Z",
      "is_paused": false,
      "paused_until": null,
      "is_cancle": false,
      "cancle_at": null,
      "next_billing": "2024-04-11T10:56:13.519Z",
      "unites_remaining": 1,
      "stripe_cs_id": "cs_test_a1fIuof5yFKHW1EUXdDjW9PE5qtyyBQXnrpzBk97iOSVNlAebvVisatAgP",
      "pause_remaining": 1,
      "status": null
    },
    "account_details": {
      "subscriber_name": "aksubs15",
      "status": "regular",
      "plan": true
    }
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      value: 0,
      activeButton: 'active',
      loginToken: null,
      loading: false,
      SubsciptionData: null,
      ischeck: 'active',
      openDetails: false,
      subscriptionDetails: null,
      expandTransactionHistory: false,
      viewDetailsData: this.viewDetailsData,
      PauseCheck: false,
      PauseConfirm: false,

      openCancelDialog: false,
      isSubscriptionCanceled: false,
      subStatus: "",
      isBranchTooltipOpen: false,
      activeBtn:"active",
      transactionData:[],
      openPauseErrorDialog: false,
      pauseErrorDialogMsg: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        this.setState({ loading: false })
      }
      if (apiRequestCallId === this.getAllSubscriptionListApi) {
        this.handleAllSubscriptionListApiRes(responseJson);
      }
      if (apiRequestCallId === this.makePaymentApiCallId) {
        this.handleMakePaymentApiRes(responseJson);
      }
      if (apiRequestCallId == this.pauseSubscriptionApiCallId) {
        this.handlePauseSubs(responseJson)
      }
      if (apiRequestCallId === this.viewDetailsApiCallId) {
        this.handleViewDetailsApiRes(responseJson);
      }
      if (apiRequestCallId === this.cancelSubscriptionApiCallId) {
        this.handleCancelSubscriptionApiRes(responseJson);
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const logInRes = await getStorageData('logInRes', true);
    const otpConfRes = await getStorageData('otpConfRes', true);
    this.stateSetAfterSignup(otpConfRes);
    this.setStateAfterLogIn(logInRes);
    this.fetchAllSubscriptionListData('active');
  }

  stateSetAfterSignup = (otpConfRes: any) => {
    if (otpConfRes) {
      this.setState({ loginToken: otpConfRes.token });
    }
  }
  setStateAfterLogIn = (logInRes: any) => {
    if (logInRes) {
      this.setState({
        loginToken: logInRes.logInToken
      })
    }
  }

  setActiveButton = (buttonName: string) => {
    this.setState({ activeButton: buttonName, ischeck: buttonName })
    this.fetchAllSubscriptionListData(buttonName);
  };

  setActiveBtnName = (buttonName: string) => {
    this.setState({ activeBtn: buttonName })
  };

  fetchAllSubscriptionListData = async (isCheck: any) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken
    };
    this.setState({ loading: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAllSubscriptionListApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllSubscriptionListApiEndPoint}${isCheck}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAllSubscriptionListApiRes = (response: any) => {
    let userData;
    userData = [...response.data];
    this.setState({ SubsciptionData: userData });
  }

  handleBackToList = () => {
    this.setState({ openDetails: false });
  }
  LandingPage = ()=>{
    this.send(getNavigationMessage("LandingPage", this.props));
  }

  handleTranscationHistoryExpand = () => {
    this.setState({ expandTransactionHistory: !this.state.expandTransactionHistory });
  }

  handlePauseBtn = () => {
    this.setState({ PauseCheck: true })
  }
  PauseCancelBtn = () => {
    this.setState({ PauseCheck: false })
  }

  handleCancelBtn = () => {
    this.setState({ openCancelDialog: true });
  }

  cancelSubscriptionApiCall = () => {

    const { viewDetailsData } = this.state;
    this.setState({ openCancelDialog: false })

    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken
    };
    const body = {
      "id": viewDetailsData.user_subscription_details?.id,
    };
    this.setState({ loading: true });

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.cancelSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelSubscriptionApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePayNowBtn = () => {
    const { user_subscription_details } = this.state.viewDetailsData;
    if (user_subscription_details.id) {
      this.buySubscriptionApiCall(user_subscription_details.id);
    }
  }

  unpaidTextUi = (boxStyle: {}, textStyle: {}) => {
    if (this.state.subStatus === "unpaid") {
      return (
        <Box style={boxStyle}>
          <Typography style={textStyle}>UNPAID</Typography>
        </Box>
      )
    }
  }

  renderButtonUi = (boxStyle: {}) => {
    if (this.state.ischeck === 'active') {

      if (this.state.subStatus === "unpaid") {
        return (
          <Box style={boxStyle}>
            <StyledButton
              onClick={this.handlePayNowBtn}
              data-test-id="detail-pay-now-btn"
            >
              Pay now
              <LaunchRoundedIcon style={{color: 'inherit', marginLeft: '10px'}}/>
            </StyledButton>
          </Box>
        )
      }
      return (
        <Box style={boxStyle}>
          <StyledButton
            onClick={this.handlePauseBtn}
            id="pauseId"
          >
            Pause
          </StyledButton>
          <StyledButton
            onClick={this.handleCancelBtn}
            data-test-id="detail-cancel-btn"
          >
            Cancel
          </StyledButton>
        </Box>
      )
    }
  }

  handleMakePaymentApiRes = (responseJson: any) => {

    if (!responseJson.errors) {
      const { url } = responseJson.data;
      if (url) {
        window.location.href = url;
      }
    } else {
      const { errors } = responseJson;
      this.showAlert("api error", errors[0]);
    }
  }

  renderButtonss = (list: any) => {
    const buttonStyles = {
      margin: '8px',
      color: 'white',
      width: '112px',
      height: '40px',
      border: '1px solid #FFFFFF',
      borderRadius: '70px',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'inter',
      padding: '4px 8px 4px 8px',
    };
    const buttonStyle = {
      margin: '8px',
      color: 'white',
      width: '112px',
      height: '40px',
      border: '1px solid #FFFFFF',
      borderRadius: '70px',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'inter',
      padding: '4px 8px 4px 8px',
    };

    const buttonStyle2 = {
      margin: '8px',
      color: 'white',
      border: '1px solid #F4CD46',
      borderRadius: '70px',
      width: '130px',
      height: '40px',
      fontSize: '14px',
      fontWeight: 600,
      padding: '4px 8px 4px 8px',
      fontFamily: 'inter',
    };
    return (
      <>
        {this.state.ischeck === 'cancel' &&
          (
            <Box mt={3} style={{display: 'flex', justifyContent: 'center'}}>
              
              <CustomeButton className="view-details-button"  style={{ ...buttonStyles, textAlign: 'center', textTransform: 'none' }} text="View Details" onclicked={() => this.handleViewDetailsBtn(list)}  />
            </Box>
          )}
        {this.state.ischeck === 'pause' &&
          (
            <Box mt={3} style={{display: 'flex', justifyContent: 'center'}}>
              {/* <Button
                variant="outlined"
                id="viewD"
                style={{ ...buttonStyles, textAlign: 'center', textTransform: 'none' }}
                onClick={() => this.handleViewDetailsBtn(list)}
                data-test-id="active-view-details-btn"
              >
                View Details
              </Button> */}
              <CustomeButton id="viewD"   style={{ ...buttonStyles, textAlign: 'center', textTransform: 'none' }} text="View Details" data-test-id="active-view-details-btn" onclicked={() => this.handleViewDetailsBtn(list)}  />
            </Box>
          )}
        {(list.attributes.status === "unpaid") && (this.state.ischeck !== 'pause') &&
          (
            <Box mt={3} style={{display:'flex' , justifyContent: 'center'}}>
              <CustomeButton id="viewD"   style={{ ...buttonStyle, textAlign: 'center', textTransform: 'none' }} text="View Details" data-test-id="active-view-details-btn" onclicked={() => this.handleViewDetailsBtn(list)}  />
              <CustomeButton id="viewD"   style={{ ...buttonStyle2, textAlign: 'center', textTransform: 'none' }} text="Make payment" data-test-id="active-view-details-btn" onclicked={() => this.handleMakePaymentBtn(list)} primary={true}  />
            </Box>
          )}
        {(this.state.ischeck === 'active' && list.attributes.status === 'paid') &&
          (
            <Box mt={3} style={{display: 'flex', justifyContent: 'center'}}>
          
              <CustomeButton  style={{ ...buttonStyles, textAlign: 'center', textTransform: 'none' }} text="View Details" data-test-id="active-view-details-btn" onclicked={() => this.handleViewDetailsBtn(list)}  />
            </Box>

          )}
      </>
    )
  };

renderNoDataFoundMessage=()=>{
    if(this.state.SubsciptionData?.length === 0){
    return <div style={{color:'white', fontSize:'1.5em', fontWeight:200, textAlign:'center', marginTop:'85px'}}>No {this.state.activeBtn} Subscriptions</div>
  }
  else{
    return null
  }
}

  gotoSub = () => {
    this.setState({ openDetails: false, PauseConfirm: false })
    this.fetchAllSubscriptionListData('active');
  }

  pauseSubscriptionApiCall = () => {
    this.setState({ PauseCheck: false })
    const { viewDetailsData } = this.state;

    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken
    };
    const body = {
      "id": viewDetailsData.user_subscription_details?.id,

    };
    this.setState({ loading: true });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.pauseSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.PauseSubscroptionEndPoint}${viewDetailsData.user_subscription_details?.id}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePauseSubs = (response: any) => {
    if (!response.errors) {
      this.setState({ PauseConfirm: true })
    }else {
      const {errors} = response;
      this.setState({openPauseErrorDialog: true, pauseErrorDialogMsg: errors})
    }

  }

  handlePauseErrorDialogBtn = () => {
    this.setState({openPauseErrorDialog: false });
  }


  handleViewDetailsBtn = (details: any) => {
    this.setState({ loading: true });
    const { id, attributes } = details;
    this.setState({ subStatus: attributes.status })

    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken
    };

    this.setState({ loading: true });

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.viewDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewSubscriptionDetailsApiEndPoint}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleViewDetailsApiRes = (responseJson: any) => {
    this.setState({ loading: false });
    if (!responseJson.errors) {
      const { accounts_with_subscriptions } = responseJson;
      this.setState({ viewDetailsData: accounts_with_subscriptions, openDetails: true });
    }
    const {accounts_with_subscriptions } = responseJson
    const {transaction_histories}  = accounts_with_subscriptions
    if (transaction_histories  && Array.isArray(transaction_histories)) {
      const sortedTransactions = transaction_histories.sort((a: any, b: any) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      this.setState({ transactionData: sortedTransactions });
    }
  }

  getNextBillingDate = () => {
    const {user_subscription_details} = this.state.viewDetailsData;

    let nextBillingDate = new Date(user_subscription_details?.next_billing);
    let formattedDate = nextBillingDate.getDate() + ' ' + nextBillingDate.toLocaleString('default', { month: 'short' }) + ' ' + nextBillingDate.getFullYear();  // format - 14 Apr 2024
    return formattedDate;
  }

  getBranchNameList = () => {

    const {subscription} = this.state.viewDetailsData;
    let branch_name_string = subscription?.branch_name;
    let branchArray = JSON.parse(branch_name_string);

    return branchArray.join(",");
  }

  handleCancelSubscriptionApiRes = (response: any) => {
    if(!response.errors){
      this.setState({ isSubscriptionCanceled: true });
    }
  }

  handleCancelDialogCancelBtn = () => {
    this.setState({ openCancelDialog: false });
  }

  handleCancelDialogConfirmBtn = () => {
    this.cancelSubscriptionApiCall();
  }

  handleConfirmationDialogCloseBtn = () => {
    this.setState({ isSubscriptionCanceled: false, openDetails: false });
    this.fetchAllSubscriptionListData('active');
  }

  handleMakePaymentBtn = (listItem: any) => {
    const { attributes } = listItem;
    this.buySubscriptionApiCall(attributes?.id);
  }

  buySubscriptionApiCall = (id: number) => {

    const originUrl = window.location.origin;
    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken
    };
    const body = {
      "id": id,
      "success_url": `${originUrl}/Subscriptionbilling2`,
      "cancel_url": `${originUrl}/Subscriptionbilling2`
    };
    this.setState({ loading: true });

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.makePaymentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makePaymentApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  settingDAte = ()=>{
    const currentDate = new Date();
    const oneMonth = new Date(currentDate);
    oneMonth.setMonth(oneMonth.getMonth() + 1);
    const formattedDate = `${oneMonth.getDate()} ${oneMonth.toLocaleDateString('en-US', { month: 'long' })} ${oneMonth.getFullYear()}`;
    return formattedDate
  }
  convertDateFormat = (date: Date) => {
    let newDate = new Date(date);
    let formattedDate = newDate.getDate() + ' ' + newDate.toLocaleString('default', { month: 'long' }) + ' ' + newDate.getFullYear() + ', ';
    let minutes = newDate.getMinutes();
    let hours = newDate.getHours();

    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; 

    let formattedTime = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ampm;
    formattedDate += formattedTime;
    return formattedDate;
  }

  formatDate = (date: Date) => {
    let nextBillingDate = new Date(date);
    let formattedDate = nextBillingDate.getDate() + ' ' + nextBillingDate.toLocaleString('default', { month: 'short' }) + ' ' + nextBillingDate.getFullYear();  // format - 14 Apr 2024
    return formattedDate;
  }

  getDuration = (duration: string | number) => {
    return duration === 0 ? 'Unlimited' : `${duration} months`
  }
  // Customizable Area End
}

