import React, { useState, useRef, useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import { Box, Typography, withStyles } from '@material-ui/core';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';


export const cssClasses: any = (theme: any) => ({
  imageCorpContainer: {
    borderRadius: '10px',
    background: '#212226',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 50px',
    maxHeight: '90vh',
    overflowY: 'scroll',
    "&::-webkit-scrollbar": {
      width: "0px",
  },
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem ',
    gap: '3%',
  },
  reactCropContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding:'0 3rem',
    '& img': {
      height: '250px' as any,
      width: '250px' as any,
      display: 'block' as any,
    }
  },
  image_Small: {
    fill: '#fff',
    height: '18px',
    width: '18px',
  },
  image_big: {
    fill: '#fff',
    height: '24px',
    width: '24px'
  },
  canvas: {
    // width: '300',
    // height: '300',
    [theme.breakpoints.only("xs")]: {
      width: '70%',
      // height: '240',
    },
    [theme.breakpoints.only("sm")]: {
      width: '80%',
      // height: '240',
    },
  },
  bottom_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '10px 0',
    gap: '10px',
    // flexWrap: 'wrap'
  },
  bottom_btn: {
    borderRadius: '50px',
    width: '35%',
    height: '40px',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '-0.408px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none' as any,
  },
  btn_cancel: {
    background: '#212226',
    color: '#FFF',
    width: '180px',
    height: '48px',
    border:'1px solid #fff',
    textTransform:'capitalize',
    fontWeight:600,
    fontSize:'16px',
    '&:hover': {
      backgroundColor: '#FFF' as any,
      boxShadow: 'none' as any,
      color: '#000' as any,
      border: '1px solid #F4CD46',
    },
    '@media(max-width: 420px)': {
      width: '150px',
      height: '35px',
      fontSize:'14px',
    }
  },
  btn_crop: {
    background: '#212226',
    color: '#fff',
    border: '1px solid #F4CD46',
    textTransform:'capitalize',
    fontWeight:600,
    fontSize:'16px',
    width: '180px',
    height: '48px',
    '&:hover': {
      backgroundColor: '#F4CD46' as any,
      boxShadow: 'none' as any,
      color: '#000' as any,
    },
    '@media(max-width: 420px)': {
      width: '150px',
      height: '35px',
      fontSize:'14px',
    }
  },
  crop_header: {
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: 'clamp(20px, 3vw, 24px)',
    fontStyle: 'normal',
    fontWeight: 600,
    paddingBottom: '1rem',
    width: '50%',
    lineHeight: '22px',
    whiteSpace: 'nowrap'
  }
});

const StyledSlider = withStyles({
  root: {
    color: '#fff',
    height: 2,
    // padding: '15px 0',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    marginTop: -10,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
    },
  },
})(Slider)

const ImageCrop: React.FC = (props: any) => {

  const { classes, src } = props;
  const [circleSize, setCircleSize] = useState<number>(50);

  const imageRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (src && imageRef.current && canvasRef.current) {
      // Draw the circle overlay with the default radius
      drawCircleOverlay(circleSize);
    }
  }, [src, circleSize]);

  const handleSliderChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    console.log(newValue);
    setCircleSize(newValue as number);
    drawCircleOverlay(newValue as number);
  };

  const cancel = () => {
    const { setOpen } = props;
    setOpen();
  }


  const handleCropImage = () => {
    const { handleCropImage, src } = props;
    getCroppedImg()
      .then((url) => {
        handleCropImage({ url: url });
      })
      .catch((error) => {
        console.error('Error cropping image:', error);
        handleCropImage({ error: error });
      });
  }

  const onImageLoad = () => {
    const width = imageRef.current?.width || 0;
    const height = imageRef.current?.height || 0;
    setCircleSize(Math.min(width, height) / 2);
  }

  const getCroppedImg = () => {
    return new Promise((resolve, reject) => {
      if (src && imageRef.current && canvasRef.current) {
        const circleRadius = circleSize;

        // Get the width and height of the circular image
        const width = 2 * circleRadius;
        const height = 2 * circleRadius;

        // Create a new canvas with the same dimensions as the circular image
        const resultCanvas = document.createElement('canvas');
        resultCanvas.width = width;
        resultCanvas.height = height;
        const resultCtx = resultCanvas.getContext('2d');

        if (!resultCtx) {
          reject(new Error('Canvas not supported'));
          return;
        }

        // Clear the new canvas entirely
        resultCtx.clearRect(0, 0, width, height);

        // Create a circular path (mask)
        resultCtx.beginPath();
        resultCtx.arc(circleRadius, circleRadius, circleRadius, 0, 2 * Math.PI);
        resultCtx.closePath();
        resultCtx.clip();

        // Draw the circular image onto the new canvas
        resultCtx.drawImage(
          imageRef.current,
          (imageRef.current.width - width) / 2,
          (imageRef.current.height - height) / 2,
          width,
          height,
          0,
          0,
          width,
          height
        );

        // Get the cropped image as data URL
        const croppedImageUrl = resultCanvas.toDataURL('image/png');
        if (!croppedImageUrl) {
          reject(new Error('Error cropping image'));
          return;
        } else {
          console.log('Cropped Image URL:', croppedImageUrl);
          resolve(croppedImageUrl);
        }
      }
    });
  };


  const drawCircleOverlay = (circleRadius: number) => {
    if (src && imageRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const width = imageRef.current.naturalWidth;
        const height = imageRef.current.naturalHeight;

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Create a radial gradient for the shadow effect (blur)
        const gradient = ctx.createRadialGradient(
          width / 2,
          height / 2,
          circleRadius + 1, // Slightly outside the circle
          width / 2,
          height / 2,
          circleRadius
        );
        gradient.addColorStop(0, 'rgba(0, 0, 0, 0.5)');
        gradient.addColorStop(1, 'transparent');

        // Draw the entire image with the shadow effect
        ctx.drawImage(imageRef.current, 0, 0, width, height);

        // Apply the gradient as a shadow to the entire canvas
        ctx.fillStyle = gradient;
        ctx.globalCompositeOperation = 'source-over';
        ctx.fillRect(0, 0, width, height);

        // Clear the selected circle area
        ctx.globalCompositeOperation = 'destination-out';
        ctx.beginPath();
        ctx.arc(
          width / 2,
          height / 2,
          circleRadius,
          0,
          2 * Math.PI
        );
        ctx.closePath();
        ctx.fill();

        // Reset composite operation
        ctx.globalCompositeOperation = 'source-over';
      }
    }
  };


  return (<>
    {src && <div className={classes.imageCorpContainer} style={{scrollbarWidth: 'none'}}>
      <Typography className={classes.crop_header} variant="h4" color="secondary">Crop Image</Typography>
      {(
        <>
          <div className={classes.reactCropContainer}>
            <img
              src={src as string}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                display: 'none'
              }}
              alt="Selected"
              ref={imageRef}
              onLoad={onImageLoad}
            />
            {imageRef.current && 
            
            <canvas
              ref={canvasRef}
              width={imageRef.current?.width}
              height={imageRef.current?.height}
              style={{
                maxWidth: '350',
                minWidth: '250',
                width: '85%'
                // height: '300',
              }}
              className={classes.canvas}
            ></canvas>
          }
          </div>
          {imageRef.current && (<>
            <div className={classes.sliderContainer}>
              <ImageOutlinedIcon className={classes.image_Small} />
              <StyledSlider
              // style={{color:"#fff"}}
                value={circleSize}
                min={0}
                max={Math.min(imageRef.current?.width || 0, imageRef.current?.height || 0) / 2}
                onChange={handleSliderChange}
              />
              <ImageOutlinedIcon className={classes.image_big} />
            </div>
            <Box className={classes.bottom_container}>
              <Button
                className={`${classes.bottom_btn} ${classes.btn_cancel}`}
                data-test-id={"btnEmailLogIn"}
                variant="contained"
                fullWidth
                onClick={cancel}
              >
                Cancel
              </Button>

              <Button
                className={`${classes.bottom_btn} ${classes.btn_crop}`}
                data-test-id={"btnEmailLogIn"}
                variant="contained"
                fullWidth
                onClick={handleCropImage}
              >
                Crop
              </Button>
            </Box>
          </>)}
        </>
      )}
    </div>}
  </>
  );
};

export default withStyles(cssClasses)(ImageCrop);


