Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.btnExampleTitle = "CLICK ME";
exports.getAllSubscriptionListApiEndPoint = "bx_block_custom_user_subs/my_subscriptions?search="
exports.makePaymentApiEndPoint = "bx_block_stripe_integration/unpaid_subcription_payment"
exports.PauseSubscroptionEndPoint = "bx_block_custom_user_subs/paused_subscription?id="
exports.viewSubscriptionDetailsApiEndPoint = "bx_block_custom_user_subs/view_details?user_subscription_id=";
exports.cancelSubscriptionApiEndPoint = "bx_block_custom_user_subs/cancel_subscription";
exports.getSubscriptionListApiEndPoint = "bx_block_custom_user_subs/subscription_list_subscriber?business_id="
exports.cancelledDialogContent = "Your subscription has been cancelled successfully"
exports.cancelledDialogTitle = "Cancelled Successfully"
exports.dialogContextText1 = " You will immediately lose access of the subscription benefits until"
exports.dialogContextText2 = "and skip one month of billing."
exports.dialogContextText3 = "Are you sure you want to pause the subscription?"
exports.dialogContextText4 = "Your subscription access will resume on"
exports.dialogContextText5 = " Are you sure you want to cancel the subscription?"
exports.purchaseDateText = "Purchase Date"
exports.nextBillingText = "Next Billing"
exports.unitsRemainingText = "Units remaining for period"
exports.transactionHistoryText = "Transaction History"
// Customizable Area End