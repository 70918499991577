import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
import { getNavigationMessage } from "./Utils";
// import { toast } from "react-toastify";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  token?: string;
  classes?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  logout: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getValidTokenApiCallId: string = "";
  logOutApiCallId: string = "";
  getRefreshTokenApiCallId:string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      logout: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // this.getToken();
    const logInResp = await getStorageData('logInRes', true);
    const otpConfResp = await getStorageData('otpConfRes', true);
    const userLoggedIn = await getStorageData("userLoggedIn", true);
    // if(!logInResp.logInToken || !otpConfResp.token){
    //     this.props.navigation.navigate("EmailAccountLoginBlock");
    // }
    if(!userLoggedIn){
      this.send(getNavigationMessage("EmailAccountLoginBlock", this.props))
      window.localStorage.clear();
    }
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = await message.getData(getName(MessageEnum.SessionResponseToken));
      let userData = message.getData(getName(MessageEnum.SessionResponseData));
      
      runEngine.debugLog("TOKEN", token);
      if(!token){
        // this.showAlert("token expired", "please login again");
        // this.props.navigation.navigate("EmailAccountLoginBlock")
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // if (apiRequestCallId === this.getValidTokenApiCallId) {
      //   if (responseJson != null) {
      //     if (!responseJson?.errors) {
      //       let selectedId = localStorage.getItem("selectedUser") || ""
      //       let data: any[] = responseJson?.data?.filter((_data: any) => _data.id == selectedId)
      //       let user_data1 = localStorage.getItem('selected_user_data');
      //       if (JSON.stringify(data[0]?.attributes) !== JSON.stringify(user_data1)) {
      //         localStorage.setItem('selected_user_data', JSON.stringify(data[0]?.attributes));
      //         const eventNew = new StorageEvent('storage')
      //         window.dispatchEvent(eventNew)
      //       }
      //     }
      //     if (responseJson?.errors) {
      //       (responseJson?.errors[0]?.token || responseJson?.errors == "Not Authorized, Token Expired") && this.getRefreshToken()
      //     }

      //   }
      // }
      // if (apiRequestCallId === this.getRefreshTokenApiCallId) {
      //   if (responseJson != null) {
      //     if (!responseJson?.errors) {
      //       if (responseJson?.error == "Not Authorized, Refresh Token Expired") {
      //         this.setState({ logout: true })
      //       }
      //       if (responseJson?.data?.token) {
      //         let exp_time: any = moment().add(23, "hours").format("DD/MM/YYYY hh:mm a")
      //         localStorage.setItem("exp_time", exp_time)
      //         localStorage.setItem("Token", responseJson?.data?.token)
      //         window.location.reload()
      //       }
      //     }
      //     else {
      //       this.setState({ logout: true })
      //     }
      //   }

      // }
      if (apiRequestCallId === this.logOutApiCallId) {
        if (responseJson && responseJson) {
          let darkMode = localStorage.getItem("darkMode") || "true"
          let lang = localStorage.getItem("i18nextLng") || "en"
          localStorage.clear()
          //   toast.success(responseJson.message)
          localStorage.setItem("darkMode", darkMode)
          localStorage.setItem("i18nextLng", lang)
          this.props.navigation.navigate("EmailAccountLoginBlockWeb")

        }
      }
    }
  }

  // getToken() {
  //   const headers = {
  //     "Content-Type": configJSON.dashboarContentType,
  //     token: localStorage.getItem("Token"),
  //   };

  //   const getValidationsMsg = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );
  //   this.getValidTokenApiCallId = getValidationsMsg.messageId;

  //   getValidationsMsg.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(headers)
  //   );
  //   getValidationsMsg.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.getMethod
  //   );
  //   runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  // }

  getRefreshToken() {
    const headers = {
      token: localStorage.getItem("refresh_token"),
    };

    let formData = new FormData();

    formData.append('device_unique_id', localStorage.getItem("deviceId") || '');

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRefreshTokenApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRefershTokenEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleLogout = () => {

    this.setState({ logout: false })
    const headers = {
      token: localStorage.getItem("Token"),
      language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
    };

    let formData = new FormData();

    formData.append('device_unique_id', localStorage.getItem("deviceId") || '');

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.logOutApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiDelete
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };
}
