import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  createTheme,
  createStyles,
  withStyles,
  Theme,
  styled,

} from "@material-ui/core/styles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomDialog from "../../../components/src/CustomDailog.web";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";


const StyledBox = styled(Box)({
  color: "#FFF",
  fontFamily: "Inter",
  fontSize: "0.95em",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  letterSpacing: "-0.408px",
});
export const Styles = (theme: Theme) =>
  createStyles({
    checkEmailTitle: {
      fontSize: '1.5em',
      fontWeight: 700,
      marginTop: '24px',
      marginBottom: '90px',
      '@media (max-width: 601px)': {
        marginBottom: '14px',
      }
    },
    confirmPasswordText: {
      margin: "95px 0 136px 0",
      fontSize: "1em",
      '@media (max-width: 601px)': {
        margin: "20px 0 40px 0",
      }
    },
    confirmValidationPass: {
      background: 'black' as any,
      border: '1px solid #F4CD46',
      '&:hover': {
        backgroundColor: '#F4CD46',
      }
    },
    resetdefaultBg: {
      background: 'black' as any,
      border: '1px solid #F4CD46',
      '&:hover': {
        backgroundColor: '#F4CD46',
      }
    },
    resetValidationPass: {
      background: '#F4CD46' as any,
      '&:hover': {
        backgroundColor: '#F4CD46',
      }
    },
    defaultStyle: {
      background: 'black' as any,
      color: 'white',
      border: '1px solid #F4CD46',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      }
    },
    confirmPasswordBtn: {
      width: "225px",
      height: "56px",
      textTransform: "capitalize",
      letterSpacing: "-0.408px",
      fontSize: "16px",
      fontStyle: "normal",
      borderRadius: "53px",
      color: "white",
      textAlign: "center",
      lineHeight: "22px",

      "&:hover": {
        background: "#F4CD46",
        color: 'black'
      },
      '@media (max-width: 601px)': {
        width: '100%',
        background: '#000000',
        color: '#FFF',
        border: '1px solid #F4CD46',
        "&:hover": {
          background: '#F4CD46',
          border: '1px solid #F4CD46',
        },
      }
    }
  })


const FlexBox = styled(Box)({
  display: "flex",
  gap: "8px",
  padding: "1% 0 0 0",
  alignItems: "center",
});

// Customizable Area End

import ChangeNewPasswordController, {
  Props,
} from "./ChangeNewPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export class ChagneNewPassword extends ChangeNewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  private buttonsStyle: React.CSSProperties = {
    padding: "10px 84px",
    fontSize: "16px",
    color: "white",
    border: "2px solid white",
    cursor: "pointer",
    transition: "background-color 0.2s ease",
    borderRadius: "53px",
    fontFamily: "Inter",
  };

  private activebuttonsStyle: React.CSSProperties = {
    ...this.buttonsStyle,
    backgroundColor: "white",
    color: "black",
  };

  private inactivebuttonsStyle: React.CSSProperties = {
    ...this.buttonsStyle,
    backgroundColor: "",
    color: "white",
  };

  HtmlTool = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "black",
      color: "#222",
      fontSize: "14px",
      fontFamily: "Inter",
      border: "1px solid #808080",
      letterSpacing: "-0.408px",
      width: "100%",
      padding: "13% 9% 16% 12%",
      maxWidth: "none",
    },
    arrow: {
      fontSize: 20,
      left: 0,
      margin: "-4% 0% 0% -1%",
      color: "rgba(134, 118, 118, 0.9)",
      "&::before": {
        backgroundColor: "white",
      },
    },
  }))(Tooltip);

  LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "10px",
      maxWidth: "none",
      boxShadow: theme.shadows[2],
      left: "40%",
      fontSize: "16px",
      alignItems: "center",
      width: "100%",
      '@media (max-width: 601px)': {
        fontSize: "12px",
        lineHeight: '2em',
        left: 0,
        top: "-15px"
      }
    },
    arrow: {
      color: "rgba(134, 118, 118, 0.9)",
      margin: "-4% 0% 0% -1%",
      fontSize: 20,
      left: "4px" as any,
      "&::before": {
        backgroundColor: "white",
      },
    },
  }))(Tooltip);

  // Customizable Area End
  render() {

    const { classes } = this.props;
    return (
      // Customizable Area Start
      // Required for all blocks
      <>

        {!this.state.succesScreen &&
          <div style={{
            backgroundColor: "black",
            width: 'auto',
          }}>
            {this.state.loading && <SpinnerLoader/>}
            {<Container
              style={{
                fontSize: 'clamp(10px, 16px, 2vw)',
                alignContent: "center",
                display: "flex",
                justifyContent: "center",
                padding: '10px 24px'
              }}
              maxWidth="md"
            >
              <ArrowBackIcon onClick={this.goBack} style={{ color: '#fff', cursor: 'pointer', fontSize: '2em', marginRight: '10px', marginTop: '5px' }} data-test-id="arrow-back-icon-btn" />
              <Box
                style={{
                  flexDirection: "column",
                  display: 'flex',
                  width: '100%',
                }}
              >
                <Box>
                  <Typography
                    style={{
                      color: "white",
                      fontFamily: "Inter",
                      fontWeight: 700,
                      fontSize: '2em'
                    }}
                  >
                    Change password
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "1em",
                      color: "white",
                      float: "left",
                      fontFamily: "Inter",
                      fontWeight: 400,
                      letterSpacing: "-0.408px",
                      marginTop: "16px",
                      marginBottom: "clamp(10px, 30px, 5vw)",
                    }}
                  >
                    Keeping your account secure is essential. To update your password enter your current password and a new password.
                  </Typography>
                </Box>
                <Box sx={{ paddingTop: "50px" }}>
                  <Box sx={{ padding: "22px 0px" }}>
                    <Typography
                      style={{
                        color: "#fff",
                        fontFamily: "Inter",
                        float: "left",
                        fontSize: "1em",
                        fontStyle: "normal",
                        letterSpacing: "-0.408px",
                        fontWeight: 300,
                        marginBottom: '8px'
                      }}
                    >
                      Current password
                    </Typography>
                    <Box>
                      <this.LightTooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        placement="bottom-start"
                        open={!this.state.forgotpasswordFieldSatisfied}
                        disableHoverListener
                        arrow
                        disableFocusListener
                        disableTouchListener
                        title={
                          <>
                            <Box
                              style={{
                                padding: "2% 2% 2% 4%",
                                width: "105%",
                                display: "flex",
                              }}
                            >
                              <Box>
                                <ErrorOutlineOutlinedIcon
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    color: "#ff2A2A",
                                  }}
                                />
                              </Box>
                              <Box style={{
                                marginLeft: "2%"
                              }}>
                                {this.state.errMessage}
                              </Box>
                            </Box>
                          </>
                        }
                      >
                        <Input
                          type={
                            this.state.confirmenablePasswordField ? "password" : "text"
                          }
                          style={{
                            padding: "10px 20px",
                            justifyContent: "center",
                            border: !this.state.forgotpasswordFieldSatisfied
                              ? "4px solid red"
                              : "none",
                            flexShrink: 0,
                            alignItems: "center",
                            display: "flex",
                            backgroundColor: "#fff",
                            gap: "11px",

                          }}
                          data-test-id="PasswordTextInputTestId"
                          value={this.state.Forgotpassword}
                          onChange={(e) =>
                            this.setforgotPassword(e.target.value.trim())
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                data-test-id="showHidePassword"
                                onClick={this.confirmforgothandleClickShowPassword}
                                edge="end"
                              >
                                {!this.state.confirmenablePasswordField ? (
                                  <VisibilityOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          fullWidth={true}
                        />
                      </this.LightTooltip>
                    </Box>
                  </Box>
                  <Box style={{ padding: "20px 0px" }}>
                    <Box style={{ display: "flex" }}>
                      <Box>
                        <Typography
                          style={{
                            color: "#fff",
                            fontFamily: "Inter",
                            float: "left",
                            fontSize: "1em",
                            fontStyle: "normal",
                            letterSpacing: "-0.408px",
                            fontWeight: 300,
                            marginBottom: '8px'
                          }}
                        >
                          Enter new password
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <this.LightTooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        placement="bottom-start"
                        open={!this.state.forgotpasswordFieldSatisfied}
                        disableHoverListener
                        arrow
                        disableTouchListener
                        disableFocusListener
                        title={
                          <>
                            <Box
                              style={{
                                width: "105%",
                                padding: "2% 2% 2% 4%",
                                display: "flex",
                              }}
                            >
                              <Box>
                                <ErrorOutlineOutlinedIcon
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    color: "#ff2A2A",
                                  }}
                                />
                              </Box>
                              <Box style={{
                                marginLeft: "2%"
                              }}>
                                {this.state.errMessage}
                              </Box>
                            </Box>
                          </>
                        }
                      >
                        <Input
                          type={
                            this.state.enablePasswordField ? "password" : "text"
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "11px",
                            flexShrink: 0,
                            border: !this.state.forgotpasswordFieldSatisfied
                              ? "4px solid red"
                              : "none",
                            backgroundColor: "#fff",
                            padding: "10px 20px",
                          }}
                          data-test-id="PasswordInput"
                          value={this.state.confirmForgotpassword}
                          onChange={(e) =>
                            this.setConfirmforgotPassword(e.target.value.trim())
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                data-test-id="showHidePassword"
                                onClick={this.forgothandleClickShowPassword}
                                edge="end"
                              >
                                {!this.state.enablePasswordField ? (
                                  <VisibilityOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          fullWidth={true}
                        />
                      </this.LightTooltip>
                    </Box>
                    <Box
                      data-test-id="HtmlTool"
                      style={{ display: "inline", marginTop: "2%" }}
                    >
                      <StyledBox style={{ marginTop: "5%" }}>
                        Your Password must contain
                      </StyledBox>

                      <FlexBox>
                        <Box>
                          {!this.state.isForgotValidLength ? (
                            <CheckCircleOutlineIcon
                              style={{
                                color: "rgba(137, 137, 137, 0.50)",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          ) : (
                            <CheckCircleIcon
                              style={{
                                color: "rgba(244, 205, 70, 1)",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          )}
                        </Box>
                        <StyledBox>At least 8 characters</StyledBox>
                      </FlexBox>

                      <FlexBox>
                        <Box>
                          {!this.state.hasForgotUppercase ? (
                            <CheckCircleOutlineIcon
                              style={{
                                color: "rgba(137, 137, 137, 0.50)",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          ) : (
                            <CheckCircleIcon
                              style={{
                                color: "rgba(244, 205, 70, 1)",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          )}
                        </Box>
                        <StyledBox>At least one uppercase letter</StyledBox>
                      </FlexBox>

                      <FlexBox>
                        <Box>
                          {!this.state.hasForgotLowerCase ? (
                            <CheckCircleOutlineIcon
                              style={{
                                color: "rgba(137, 137, 137, 0.50)",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          ) : (
                            <CheckCircleIcon
                              style={{
                                color: "rgba(244, 205, 70, 1)",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          )}
                        </Box>
                        <StyledBox>At least one lowercase letter</StyledBox>
                      </FlexBox>

                      <FlexBox>
                        <Box>
                          {!this.state.hasForgotNumber ? (
                            <CheckCircleOutlineIcon
                              style={{
                                color: "rgba(137, 137, 137, 0.50)",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          ) : (
                            <CheckCircleIcon
                              style={{
                                color: "rgba(244, 205, 70, 1)",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          )}
                        </Box>
                        <StyledBox>At least one number</StyledBox>
                      </FlexBox>

                      <FlexBox>
                        <Box>
                          {!this.state.hasForgotSymbol ? (
                            <CheckCircleOutlineIcon
                              style={{
                                color: "rgba(137, 137, 137, 0.50)",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          ) : (
                            <CheckCircleIcon
                              style={{
                                color: "rgba(244, 205, 70, 1)",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          )}
                        </Box>
                        <StyledBox>At least one special character</StyledBox>
                      </FlexBox>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px 0px",
                      marginTop: "3%",
                    }}
                  >
                    <Button
                      data-test-id={"Resendotp"}
                      // variant="contained"
                      onClick={this.updatedPassword}
                      className={`${classes.confirmPasswordBtn} ${classes.confirmValidationPass} `}
                      color="primary"
                    // fullWidth
                    >
                      Change Password
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Container>}

          </div>}
        {this.state.succesScreen &&
          <>
            <Box style={{
              color: 'white', 
              backgroundColor: 'black',
              width: "100%", 
              fontSize: 'clamp(10px, 16px, 2vw)',
            }}>
              <Box style={{width: '70%', margin: 'auto'}}>
              <Typography 
              style={{fontSize: '2em', fontWeight: 600, fontFamily: 'Inter', margin: 'clamp(10px, 80px, 5vw) 0'}}
              >
                Password Successfully Changed
              </Typography>
              <Box 
              style={{fontSize: '1.25emem', fontWeight: 600, fontFamily: 'Inter', marginBottom: '90px'}}
              >
                Congratulations! Your password has been successfully changed
              </Box>
              <Box>
                <Button
                  onClick={this.goBack}
                  className={`${classes.confirmPasswordBtn} ${!this.state.forgotpasswordFieldSatisfied ? classes.resetValidationPass : classes.resetdefaultBg}`}
                >
                  Go To Homepage
                </Button>
              </Box>
              </Box>
            </Box>

          </>
        }
        <CustomDialog
          open={this.state.errorDialog}
          isError={true}
          actions={
            [
              {
                label: "Okay",
                handler: this.dialogOkay,
                borderColor: "#F4CD46",
                testId: 'dialogCloseBtn',
                primary: false
              }
            ]
          }
          title={"Failed to change password"}
          content={this.state.errorMessage}
          data-test-id="change-password-error-dialog"
        />
      </>
      // Customizable Area End
    );
  }
}

export default withStyles(Styles, { withTheme: true })(ChagneNewPassword);
