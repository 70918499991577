import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, styled } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import UserContext from "../../../components/src/UserProvider";
import Loader from "../../../components/src/Loader";
import { Fab } from '@material-ui/core'
import { eyeIconInvisible, logo } from './assets';
import CustomeButton from "../../../components/src/CustomeButton.web";

const webStyle = {
  dialogTitleStyle: {
    fontWeight: 600 as 600,
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.408px',
    color: '#fff',
    fontSize: "clamp(16px, 3vw, 32px)",
    textAlign: 'center' as 'center',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    marginBottom: '15px',
  },
  dialogContentStyle: {
    fontSize: "clamp(12px, 3vw, 24px)",
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
    marginBottom: '15px'
  }
}
const ButtonClose = {
  fontSize: '16px',
  color: '#fff',
  width: '180px',
  height: '56px',
  borderRadius: '70px',
  padding: '4px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  textAlign: 'center' as 'center',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 600,
  letterSpacing: '-0.408px',
  textTransform: 'capitalize' as 'capitalize',
  border: '1px solid #fff',
  '@media (max-width: 601px)': {
    width: '130px',
    height: '40px',
  }
};
const ButtonContinue = {
  padding: '4px 8px',
  width: '180px',
  height: '56px',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center' as 'center',
  fontFamily: 'Inter, sans-serif',
  fontSize: "clamp(14px, 3vw, 16px)",
  color: '#fff',
  fontWeight: 600,
  letterSpacing: '-0.408px',
  borderRadius: '70px',
  border: '1px solid #F4CD46',
  textTransform: 'capitalize' as 'capitalize',
  '@media (max-width: 601px)': {
    width: '130px',
    height: '40px',
  }
};
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  // Customizable Area Start
  overrides: {
    MuiDialog: {
      'paperWidthSm': {
        background: '#212226',
        maxWidth: '694px',
        padding: '50px 0',
        '@media (max-width: 601px)': {
          padding: '24px 13px',
        }
      },
    },
    MuiFilledInput: {
      'inputMarginDense': {
        padding: '12px',
        paddingTop: '12px',
        paddingBottom: '12px',
        backgroundColor: 'white'
      },
      'root': {
        height: '48px'
      },
      'input': {
        height: '24px'
      }
    },
    MuiBackdrop: {
      'root': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }
    },
    MuiSelect: {
      'filled': {
        backgroundColor: 'white'
      },
      'selectMenu': {
        height: '24px',
        backgroundColor: 'white',
      },
    },
    MuiGrid: {
      'grid-xs-12': {
        height: '155px',
        marginTop: '7px'
      },
      'grid-xs-6': {
        flexBasis: '48%',
        marginTop: '10px'
      },
    }
  }
  // Customizable Area End
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    EmailAccountLoginController.contextType = UserContext
    // Customizable Area End
}
// Customizable Area Start

  FabBox = styled(Fab)({
    width: '56px', height: '56px', padding: 0,
    fontSize: '0.875rem', minWidth: 0, boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    boxSizing: 'border-box', minHeight: '36px', transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontWeight: 500, lineHeight: 1.75, borderRadius: '50%',
    letterSpacing: '0.02857em', textTransform: 'uppercase', backgroundColor: 'black'
  })

  DivBox = styled(Box)({
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    display: 'inline-block',
    position: 'absolute',
    background: 'linear-gradient(0deg, #3A3111 33%, #3A3111 33%, #4E4115 66%, #4E4115 66%, #F6CB38 100%)',
    boxSizing: 'border-box',
    animation: 'rotation 1s linear infinite'
  })

  SpanBox = styled(Box)({
    content: '', boxSizing: 'border-box', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: '44px', height: '44px', borderRadius: '50%', background: 'black'
  })

  renderLoading = () => {
    const keyframes = `
    @keyframes rotation  {
      0% { transform: rotate(0deg) }
      100% { transform: rotate(360deg)}
    }
  `;

    return (
      <>
        <style>{keyframes}</style>
        <div style={{
          position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999,
        }}>
          <this.FabBox
            aria-label="save"
            color="primary"
          >
            <img
              style={{
                fill: 'white',
                width: '1.5em', height: '1.5em',
                display: 'inline-block',
                fontSize: '1.4rem',
                transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', userSelect: 'none',
                flexShrink: 0, position: 'absolute', zIndex: 1, borderRadius: '9px'
              }}
              src={logo} height={24} width={24} alt="" />
          </this.FabBox>
          <this.DivBox>
            <this.SpanBox component={'span'}></this.SpanBox>
          </this.DivBox>
        </div>
      </>
    );
  };

  LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[2],
      fontSize: '16px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      padding: '10px',
      maxWidth: 'none',
      left: "40%"
    },
    arrow: {
      fontSize: 20,
      left: '12px !important',
      marginLeft: "0px  !important",
      color: "white",

      "&::before": {
        backgroundColor: "white",
      }
    }
  }))(Tooltip);

  DialogActionsWrap = styled(DialogActions)({
    justifyContent: 'center', 
    gap: '24px',
    '@media (max-width: 601px)': {
      gap: '12px',
      padding: 0
    }
  });

  ButtonClose = styled(Button)({
    fontSize: '16px',
    color: '#fff',
    width: '180px',
    height: '56px',
    borderRadius: '70px',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    textTransform: 'capitalize' as 'capitalize',
    border: '1px solid #fff',
    "&:hover": {
      border: "1.5px solid #F4CD46",
    },
    '@media (max-width: 601px)': {
      width: '130px',
      height: '40px',
    }
  });

  ButtonContinue = styled(Button)({
    padding: '4px 8px',
    width: '180px',
    height: '56px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: "clamp(14px, 3vw, 16px)",
    color: '#fff',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    textTransform: 'capitalize' as 'capitalize',
    '@media (max-width: 601px)': {
      width: '130px',
      height: '40px',
    }
  });

  LabelTitleText = styled((props) => <Typography {...props} />)({
    color: "white",
    fontSize: 'clamp(24px, 3vw, 32px)',
    fontFamily: 'Inter',
    fontWeight: 700,
    padding: '12px 0 4px 0'
  });

  LoginBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
    marginBottom: '4px',
    '@media (max-width: 601px)': {
      alignItems: 'center'
    }
  });

  SignUpBox = styled(Box)({
    color: '#fff',
    fontFamily: 'Inter',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 601px)': {
      justifyContent: 'center'
    }
  });

  LoginButton = styled(Button)({
    width: '225px',
    height: '56px',
    borderRadius: '53px',
    border: '1px solid #242424',
    background: '#fff',
    color: '#111',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '-0.408px',
    textTransform: 'capitalize',
    "&:hover": {
      background: "#F4CD46"
    },
    '@media (max-width: 601px)': {
      width: '100%',
      background: '#000000',
      color: '#FFF',
      border: '1px solid #FFF',
      "&:hover": {
        background: '#000000',
        border: '1px solid #F4CD46',
      },
    }
  })
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {this.state.loading && this.renderLoading()}
        <Container maxWidth="md" style={{ padding: '0 24px' }}>
          <Box
            sx={{
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
              maxWidth: '854px'
            }}
          >
            <Box>
              <this.LabelTitleText variant="subtitle1" component="div">
                {this.labelTitle} {/*UI Engine::From Sketch*/}
              </this.LabelTitleText>
            </Box>
            <Box sx={{ width: "100%", padding: "20px 0 50px 0" }}>
              <Box sx={{ padding: "16px 0px", display: 'flex', flexDirection: 'column', }}>
                <Typography style={{
                  color: "#fff", fontFamily: 'Inter', float: "left", fontSize: '16px', fontStyle: 'normal', lineHeight: '22px', /* 137.5% */letterSpacing: '-0.408px', fontWeight: 300, marginBottom: '8px'
                }}>
                  Email
                </Typography>
                <Box>
                  <this.LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="bottom-start"
                    open={this.state.emailValidation && !!this.state.errors.email}
                    disableFocusListener
                    arrow
                    disableHoverListener
                    disableTouchListener
                    title={<><ErrorOutlineOutlinedIcon style={{ color: 'red', width: '24px', height: '24px', paddingRight: '5px' }} />{this.state.errors.email}</>}
                  >
                    <Input
                      style={{
                        display: 'flex',
                        backgroundColor: "#fff",
                        padding: '10px 20px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '12px',
                        flexShrink: 0,
                        border: this.state.emailValidation ? '3px solid red' : 'none',
                        // maxWidth:"854px",
                        height: '48px'
                      }}
                      data-test-id="txtInputEmail"
                      // placeholder={"Email"}
                      fullWidth={true}
                      value={this.state.email}
                      onChange={(e) => this.setEmail(e.target.value)}
                    />
                  </this.LightTooltip>
                </Box>
              </Box>
              <Box sx={{ padding: "16px 0px", display: "flex", flexDirection: 'column' }}>
                <Box sx={{ display: 'flex' }}>
                  <Typography style={{
                    color: "#fff", fontFamily: 'Inter', float: "left", fontSize: '16px', fontStyle: 'normal', lineHeight: '22px', /* 137.5% */letterSpacing: '-0.408px', fontWeight: 300, marginBottom: '8px'
                  }}>
                    Password {console.log(this.state.passwordValidation)}
                  </Typography>
                </Box>
                <this.LightTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="bottom-start"
                  open={this.state.passwordValidation && !!this.state.errors.password}
                  disableFocusListener
                  arrow
                  disableHoverListener
                  disableTouchListener
                  title={<><ErrorOutlineOutlinedIcon style={{ color: '#ff2A2A', width: '24px', height: '24px', paddingRight: '5px' }} /> {this.state.errors.password}</>}
                >
                  <Input
                    style={{
                      display: 'flex', backgroundColor: "#fff", padding: '10px 20px', justifyContent: 'center', alignItems: 'center', border: this.state.passwordValidation ? '3px solid red' : 'none', gap: '12px', flexShrink: 0,
                      height: '48px'
                    }}
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    // placeholder={"Password"}
                    fullWidth={true}
                    value={this.state.password}
                    onChange={(e) => this.setPassword(e.target.value.trim())}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          data-test-id="showHidePassword"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                        >
                          {!this.state.enablePasswordField ? (
                            <VisibilityOutlinedIcon style={{ color: '#000' }} />
                          ) : (
                            <img src={eyeIconInvisible} height={24} width={24} alt="" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </this.LightTooltip>
              </Box>
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: '#fff',
                  fontFamily: 'Inter',
                  textAlign: 'end',
                  marginBottom: '35px'
                }}
              >
                <Button
                  data-test-id={"btnForgotPassword"}
                  style={{
                    color: "white",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    textTransform: 'none',
                    letterSpacing: -0.40799999237060547,
                    fontSize: "16px",
                    lineHeight: 'normal',
                    padding: 0,
                  }}
                  onClick={() => this.goToForgotPassword()}>
                  Forgot password?
                </Button>
              </Box>
              <this.LoginBox>
                <this.LoginButton
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => this.doEmailLogin()}
                >
                  Log in
                </this.LoginButton>
              </this.LoginBox>
              <this.SignUpBox>
                {this.state.labelOr}
               
                <Button
                  data-test-id={"gotoSigunUp"}
                  style={{
                    color: "white",
                    fontFamily: "Inter",
                    fontWeight: 700,
                    textTransform: 'none',
                    letterSpacing: -0.40799999237060547,
                    fontSize: "16px",
                    textDecorationLine: "underline",
                    lineHeight: 'normal',
                    paddingLeft: "2px",
                  }}
                  onClick={() => this.goToEmailSignup()}
                >
                  {this.state.signupText}
                </Button>
              </this.SignUpBox>
            </Box>
          </Box>
        </Container>

        <Dialog open={this.state.popupValidation} style={{ backdropFilter: 'blur(5px)' }} aria-labelledby="form-dialog-title" >
          <Typography id="form-dialogue-title" style={webStyle.dialogTitleStyle}>
            <ErrorOutlineOutlinedIcon style={{ color: '#E82929', fontSize: '32px', marginRight: '10px', }} />
            Verify your email
          </Typography>
          <DialogContent>
            <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
              You previously started sign up. A new one-time password has been sent to your email address. Please continue below to complete your account setup.
            </DialogContentText>
          </DialogContent>
          <this.DialogActionsWrap>

            <CustomeButton text = "Cancel" style = {ButtonClose} onclicked = {this.handleCloseDialog} primary={false} data-test-id="login-dialog-close-btn" />
                  
            <CustomeButton text = "Continue" style = {ButtonContinue} onclicked ={ this.goToForgotPasswordOTP } primary={true}  data-test-id="login-dialog-continue-btn" />

          </this.DialogActionsWrap>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
