import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
// import { toast } from "react-toastify";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  token?: string;
  classes?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  logout: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class RegistrationComponentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getValidTokenApiCallId: string = "";
  logOutApiCallId: string = "";
  getRefreshTokenApiCallId:string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      logout: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // this.getToken();
    const logInResp = await getStorageData('logInRes', true);
    const otpConfResp = await getStorageData('otpConfRes', true);
    const userLoggedIn = await getStorageData("userLoggedIn", true);
    // if(userLoggedIn){
    //     this.props.navigation.navigate("LandingPage");
    // }
    // if(logInResp.logInToken || otpConfResp.token){
    //     this.props.navigation.navigate("LandingPage");
    // }
    
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = await message.getData(getName(MessageEnum.SessionResponseToken));
    //   let userData = message.getData(getName(MessageEnum.SessionResponseData));
      
      runEngine.debugLog("TOKEN", token);
    //   if(token){
    //     this.showAlert("message","you are already login");
    //     this.props.navigation.navigate("LandingPage");
    //   };
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
    }
  }

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };
}
