import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { getNavigationMessage } from "../../../components/src/Utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isProfileCreated: boolean,
  isLogin: any,
  isSubscriber: boolean,
  profilePic: string,
  userProfileData: any,
  logInToken: string,
  businessUserPermissions: { [key: string]: any }
  branches: any,
  businessProfile: any,
  daysLeft: any,
  hoursLeft: any,
  minutesLeft: any,
  endDate: any,
  startDate: any,
  remainingDays: any,
  isJoinedMember: boolean
  branchList: any[];
  selectedBranchId: any,
  openBranchMessageDialog: boolean;
  businessOwner: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileDetailsApiId: string = "";
  getBranchListApiId: string = "";
  getAllBranchesDetailsApiId: string = "";
  getFreeTrialDateApiId: string = ""
  interval: any = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SelectedBranch)
    ];

    this.state = {
      loading: false,
      isProfileCreated: false,
      isLogin: false,
      isSubscriber: false,
      profilePic: "",
      userProfileData: {},
      logInToken: "",
      businessUserPermissions: {},
      branches: null,
      businessProfile: {},
      daysLeft: 30,
      hoursLeft: 0,
      minutesLeft: 0,
      endDate: "",
      startDate: "",
      remainingDays: null,
      isJoinedMember: false,
      branchList: [],
      selectedBranchId: localStorage.getItem("selected_branchId") || "",
      openBranchMessageDialog: false,
      businessOwner: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getProfileDetailsApiId) {
        this.handleUserProfileApiRes(responseJson);
      }
      if (apiRequestCallId === this.getBranchListApiId) {
        this.handleBranchListApiRes(responseJson);
      }
      if (apiRequestCallId === this.getAllBranchesDetailsApiId) {
        this.handleAllBranchesApiRes(responseJson);
      }
      if (apiRequestCallId === this.getFreeTrialDateApiId) {
        this.handleFreeTrialDateRes(responseJson);
      }
      runEngine.debugLog("Message Recived", message);
    }
    if (getName(MessageEnum.SelectedBranch) === message.id) {
      const branchDetails = message.getData(getName(MessageEnum.SelectedBranchData));
      this.setState({ selectedBranchId: branchDetails[0].id, businessUserPermissions: branchDetails[1] })
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.calculateRemainingDays()
    this.interval = setInterval(
      this.calculateRemainingDays, 1000);

    const isProfileCreated = await getStorageData('isProfileCreated');
    const isSubscriber = await getStorageData('isSubscriber');
    const logInResp = await getStorageData('logInRes', true);
    const otpConfResp = await getStorageData('otpConfRes', true);
    const isJoinedMemberPresent = await getStorageData('isBusinessJoined');
    this.stateSetAfterJoinedBusiness(isJoinedMemberPresent)
    this.stateSetAfterSignup(otpConfResp);
    this.setProfileCreated(isProfileCreated);
    this.setUserType(isSubscriber);
    this.setStateAfterLogIn(logInResp);
  }
  async componentWillUnmount() {
    clearInterval(this.interval);
  }

  calculateRemainingDays = () => {
    const start = new Date(this.state.startDate);
    const end = new Date(this.state.endDate);
    const currentDate = new Date()
    const difference = end.getTime() - currentDate.getTime();
    const remainingDays = Math.ceil(difference / (1000 * 3600 * 24)); 
    const remch = remainingDays > 0 ? remainingDays : 0;
    this.setState({ remainingDays:remch });
  };

  setStateAfterLogIn = async(logInRes: any) => {
    if (logInRes) {
      let isLogin = await getStorageData("isLogin", true);
      this.setState({
        isLogin: isLogin,
        logInToken: logInRes.logInToken,
      });
      this.fetchProfileData();
      this.fetchFreeTrialDate();
    }
  }

  setUserType = (isSubscriber: any) => {
    if (isSubscriber === "true") {
      this.setState({ isSubscriber: true });
    }
  }

  stateSetAfterSignup = (otpConfResp: any) => {
    if (otpConfResp) {
      this.setState({ logInToken: otpConfResp.token });
      this.fetchProfileData();
      this.fetchFreeTrialDate();
    }
  }

  setProfileCreated = (isProfileCreated: any) => {
    if(isProfileCreated == "true"){
      this.setState({isProfileCreated: true});
    }
  }

  stateSetAfterJoinedBusiness = (joinedMember: any) => {
    this.setState({ isJoinedMember: joinedMember });
  }

  branchListApiCall = () => {
    if (!this.state.isSubscriber) {
      this.setState({ loading: true });

      const header = {
        "Content-Type": "application/json",
        token: this.state.logInToken
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
      this.getBranchListApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_branch/joined_business_user_branches'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  }

  handleBranchListApiRes = (responseJson: any) => {
    this.setState({ loading: false });
    if (!responseJson.error) {
      const { branch } = responseJson;

      this.setState({ branchList: branch });
      const idsArray = responseJson?.branch.map((nestedArray:any )=> nestedArray[0].id);
      const idsArrayJson = JSON.stringify(idsArray);
      localStorage.setItem('branchIds', idsArrayJson);
      if(!this.state.isSubscriber && !this.state.businessOwner && branch.length === 0 ){
        this.setState({openBranchMessageDialog: true});
      }
    }
  }
  handleBranchDialogBtn = () => {
    this.setState({openBranchMessageDialog: false});
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToCreateBranch = () => {
    this.send(getNavigationMessage("BusinessBranches", this.props));
  }

  goToBusinessBranch = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationtoManageBranchButtonMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleUserProfileApiRes = async (response: any) => {
    this.setState({loading: false})
    if (!response.errors) {
      let userData: any = {};
      let profilePhoto
      let userName;
      const { id, attributes } = response.data;
      userData = { userId: id, ...attributes };
      profilePhoto = attributes?.photo;
      userName = `${attributes?.first_name} ${attributes?.last_name}`
      if (attributes?.account.business_owner === true) {
        this.setState({ businessOwner: true });
      }
      this.setState({ userProfileData: userData, });
      setStorageData('userProfilePhoto', profilePhoto);
      setStorageData('userName', userName);
      this.branchListApiCall()
    }
  }

  fetchProfileData = async () => {
    this.setState({loading: true})
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getProfileDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileDetailsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToSubscriptionPage = () => {
    this.send(getNavigationMessage("Customform4", this.props));
  }

  goToMySubscription = () => {
    this.send(getNavigationMessage("Subscriptionbilling2", this.props));
  }

  gotoMyQr = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationQrCodesMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);

  }

  fetchAllBranchesData = async (id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAllBranchesDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBranchesDetailsApiEndPoint}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAllBranchesApiRes = (response: any) => {
    this.setState({ branches: response });
  }
  handleFreeTrialDateRes = (response: any) => {
    this.setState({ endDate: response?.end_date, startDate: response?.start_date });
    // this.setState({endDate:'2024-04-15T07:13:49.000Z', startDate:'2024-03-19T07:13:49.000Z'})
  }

  fetchFreeTrialDate = async () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.logInToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getFreeTrialDateApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountDownDateApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
