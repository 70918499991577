import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData } from "framework/src/Utilities";
import { getNavigationMessage } from "../../../components/src/Utils";

// Customizable Area End

export const configJSON = require("./config");
export const frameworkConfigJSON = require("../../../framework/src/config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openDetails: boolean;
    loading: boolean;
    subsciptionData: any;
    businessData: any;
    branchName: any;
    subscriptionProfileData: any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BusinessSubscriptionsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSubscriptionListApiCallId: any;

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            openDetails: false,
            loading: false,
            subsciptionData: [],
            businessData: {},
            branchName: [],
            subscriptionProfileData: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const subscription_id = window.location.pathname.split("/").pop();
        this.getSubscriptionListApiCall(subscription_id)
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getSubscriptionListApiCallId) {
                this.handleSubscriptionListApiRes(responseJson);
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleBackToSubscriptionList = () => {
        this.setState({ openDetails: false });
    }

    viewDetailsOpening = (list: any) => {
        const branchList = JSON.parse(list.branch_name)
        this.setState({ branchName: branchList, openDetails: true, subscriptionProfileData: list });
    }

    handleBuyNowBtn = () => {
        window.localStorage.clear();
        setStorageData("subscription_to_buy", this.state.subscriptionProfileData?.id);
        this.send(getNavigationMessage("EmailAccountRegistration", this.props));
    }

    handleSubscriptionListApiRes = (response: any) => {
        this.setState({ loading: false })
        if (!response.errors) {
            this.setState({
                subsciptionData: response.subscriptions,
                businessData: response.business,
            })
        }
    }

    getSubscriptionListApiCall = (id: string | undefined) => {
        if (id) {
            this.setState({ loading: true });
            const header = {
                "Content-Type": "application/json",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
            this.getSubscriptionListApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.httpGetMethod
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getSubscriptionListApiEndPoint}${+id}`
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    // Customizable Area End
}
