import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { getNavigationMessage } from "../../../components/src/Utils";
interface LogInResponse {
    logInToken: string;
}
interface OtpResponse {
    token: string
}

const verificationStatusMockRes = {
    "message": "success"
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isCancel: boolean;
    logInToken: string;
    isOpen: boolean;
    loading: boolean;
    isSubscriber: boolean,
    memberShipData: any,
    confimed: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class MemberShipController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    cacnelMembershipApi: string = "";
    getMembershipDataApi: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            isCancel: true,
            logInToken: "",
            isOpen: false,
            loading: false,
            isSubscriber: false,
            confimed: false,
            memberShipData: {}
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                this.setState({ loading: false })
            }
            if (apiRequestCallId === this.cacnelMembershipApi) {
                this.handleCancelMemberShipRes(responseJson)
            }
            if (apiRequestCallId === this.getMembershipDataApi) {
                this.handleMembershipData(responseJson)
            }

            runEngine.debugLog("Message Recived", message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        const logInRes = await getStorageData('logInRes', true);
        const otpConfRes = await getStorageData('otpConfRes', true);
        const isSubscriber = await getStorageData('isSubscriber');
        this.setTokenInStorage(token, logInRes, otpConfRes);

        this.setUserType(isSubscriber);
        this.getMemberShipData()

    }

    setUserType = (isSubscriber: string) => {
        if (isSubscriber !== "true") {
            this.setState({ isSubscriber: true })
        }
    }
    setStateAfterLogIn = (logInRes: LogInResponse) => {
        if (logInRes) {
            this.setState({
                logInToken: logInRes.logInToken
            })
        }
    }
    stateSetAfterSignup = (otpConfRes: OtpResponse) => {
        if (otpConfRes) {
            this.setState({ logInToken: otpConfRes.token });
        }
    }

    handleCancel = () => {
        this.setState({ isCancel: false })
    }
    openDialog = () => {
        this.setState({ isOpen: true })
    }
    closeDialoge = () => {
        this.setState({ isOpen: false })
    }

    confrimCancel = () => {
        this.cancelMembership()
    }

    getMemberShipData = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.state.logInToken
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getMembershipDataApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_custom_user_subs/get_membership`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleMembershipData = (res: any) => {
        this.setState({ memberShipData: res })
    }

    cancelMembership = async () => {
        const header = {
            "Content-Type": "application/json",
            token: this.state.logInToken
        };
        this.setState({ loading: true })
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.cacnelMembershipApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_custom_user_subs/cancellation_of_membership`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPutMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleCancelMemberShipRes = (responseJson: any) => {
        if (responseJson.message === "No membership found") {
            this.setState({ confimed: false })
        }
        else {
            this.setState({ confimed: true })
            setTimeout(() => {
                this.onLogout()

            }, 3000);
        }
    }

    onLogout = () => {
        window.localStorage.removeItem('user')
        localStorage.removeItem('isRegister')
        window.localStorage.clear();
        this.removeSessionToken();
        this.send(getNavigationMessage("EmailAccountRegistration", this.props));
    }
    removeSessionToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
        msg.addData(
            getName(MessageEnum.SessionResponseToken),
            null
        );

        this.send(msg);
    }

    goBack = () => {
        this.send(getNavigationMessage("LandingPage", this.props));
    }
    formatDate = (date: Date) => {
        let nextBillingDate = new Date(date);
        let formattedDate = nextBillingDate.getDate() + ' ' + nextBillingDate.toLocaleString('default', { month: 'short' }) + ' ' + nextBillingDate.getFullYear();  // format - 14 Apr 2024
        return formattedDate;
    }

    setTokenInStorage = (token: any, logInRes: any, otpConfRes: any) => {

        if (!logInRes && !otpConfRes && !token) {
            window.localStorage.clear();
            this.send(getNavigationMessage("EmailAccountRegistration", this.props));
        }

        if (token) {

            const loginRes = { logInToken: token };
            
            setStorageData("logInRes", JSON.stringify(loginRes));
            this.setState({ logInToken: token });

            setStorageData("userLoggedIn", true);
            removeStorageData("isProfileCreated");
            setStorageData("isLogin", true);
        } else {
            this.stateSetAfterSignup(otpConfRes);
            this.setStateAfterLogIn(logInRes);
        }

    }
    // Customizable Area End
}