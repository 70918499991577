import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Typography,
  } from "@material-ui/core";
  import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
  import CheckCircleIcon from '@material-ui/icons/CheckCircle';
  import SpinnerLoader from "../../../components/src/SpinnerLoading.web";

// Customizable Area End

import VerifyIdentityController, {
  Props,
} from "./VerifyIdentityController";

export default class VerifyIdentitySuccessfull extends VerifyIdentityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
        <ThemeProvider theme={theme}>
          {this.state.loading && <SpinnerLoader/>}
          <Container style={{ marginBottom: '50px', padding: '0 6%', maxWidth: '100%', fontSize: 'clamp(10px, 16px, 2vw)' }}>
            <Box sx={{ marginBottom: '50px' }}>
              <Typography style={webStyle.verifyIdentityLabel}>
                Verify Identity
              </Typography>
                <Box sx={webStyle.identityVerifyWrapper}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box sx={webStyle.profileLogoBoxStyle}>
                    <CheckCircleIcon style={{ color: '#fff', width: '60%', height: '60%' }} />
                  </Box>
                </Box>
                <Box>
                  <Typography style={webStyle.textLabelSecond}>
                    Your Identity has been successfully verified.
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '60px' }}>
                  <MuiStyledButton
                    variant="contained"
                    onClick={this.handleContinueVerified}
                    data-test-id = "continue-verified-btn"
                  >
                    Continue
                  </MuiStyledButton>
                </Box>
              </Box>
            </Box>
          </Container>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start

const theme = createTheme({
    overrides: {
      MuiFormControlLabel: {
        label: {
          width: "100%",
        },
      },
    },
  });

const MuiStyledButton = styled(Button)({
    display: "flex",
    width: "225px",
    height: "56px",
    padding: "1px",
    fontFamily: "Inter",
    justifyContent: "center",
    alignItems: "center",
    background: "#000",
    border: "1px solid #F4CD46",
    borderRadius: "50px",
    fontSize: "1em",
    fontWeight: 600,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      background: "#F4CD46",
      color: "#000",
    }
  });
  
  const webStyle = {
    identityVerifyWrapper: {
      display: "flex",
      width: '100%',
      flexDirection: 'column',
      gap: 'clamp(20px, 50px, 5vw)'
    },
    verifyIdentityLabel: {
      fontFamily: 'Inter',
      fontSize: '2em',
      color: '#FFF',
      fontWeight: 700,
      letterSpacing: '-0.408px',
      marginBottom:'50px'
    },
   
    profileLogoBoxStyle: {
      width: '195px',
      height: '172px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textLabelFirst: {
      fontFamily: 'Inter',
      fontSize: '2em',
      color: '#FFF',
      fontWeight: 600,
      letterSpacing: '-0.408px',
      textAlign: 'center' as 'center',
      marginBottom: '40px'
    },
    textLabelSecond: {
      fontSize: '1.5em',
      color: '#FFF',
      fontFamily: 'Inter',
      fontWeight: 400,
      letterSpacing: '-0.408px',
      textAlign: 'center' as 'center'
    },
  };
// Customizable Area End