import { Box, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import "./errorTooltip.css";

interface ErrorTooltipProps {
    message: string;
    visible: boolean;
    tooltipPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

class ErrorTooltip extends Component<ErrorTooltipProps> {
    render() {
        const { message, tooltipPosition, visible, } = this.props;
        const textWidth = message.length * 8;
        const tooltipStyle: React.CSSProperties = {
            display: visible ? 'flex' : 'none',
            position: 'absolute',
            backgroundColor: 'white',
            border: "1px solid #808080",
            color: 'black',
            padding: '20px',
            borderRadius: '5px',
            zIndex: 1000,
            width: textWidth + 'px',
            alignItems: "center",
            top: "90px",
            left: "10%",
            minWidth: '175px'
        };

        if (tooltipPosition === 'top-left') {
            tooltipStyle.left = '0';
            tooltipStyle.top = '-80px';
        }

        return (
            <Box style={tooltipStyle} className={!tooltipPosition ? `error-tooltip-box tooltip-container-bottom-left` : `error-tooltip-box tooltip-container-${tooltipPosition}`} data-test-id="errorTooltipBlock">
                <Box style={{ display: 'flex', alignItems: 'center', width: '100%', position:'relative' }} className={`tooltip-inner-box`}>
                    <ErrorOutlineOutlinedIcon style={{ color: '#ff2A2A', width: '24px', height: '24px', paddingRight: '5px' }} />
                    <Typography style={{ marginLeft: '2%', color: "#222", fontSize: "14px", fontWeight: 400, fontFamily: 'Inter', letterSpacing: '-0.408px' }} className='tooltip-text'>
                        {message}
                    </Typography>
                </Box>

            </Box>
        );
    }
}

export default ErrorTooltip;