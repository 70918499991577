import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
export const configJSON = require("./config");
import { backSpace } from './assets';
import CustomDialog from "../../../components/src/CustomDailog.web";
export const Styles = (theme: Theme) =>
    createStyles({ 
      subtitle: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 700,
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.408px',
        paddingTop: '6px',
        '@media (max-width: 601px)': {
          fontWeight: 400
        }
      },
      validationPass : {
        background: '#F4CD46' as any,
        '&:hover': {
          backgroundColor: '#F4CD46',
      }
      },
      defaultBg : {
        background: '#FFFFFF' as any,
        '&:hover': {
          backgroundColor: '#FFFFFF',
      }
      },
      sendEmailBtn : {
        width: '225px',
        height: '56px',
        borderRadius: '53px',
        border: '0.5px solid #242424',
        color: '#111',
        textAlign: 'center',
        textTransform:'capitalize',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        marginBottom:'-2px',
        fontWeight: 600,
        lineHeight: '22px',
        letterSpacing: '-0.408px',
        "&:hover": {
          background: "#F4CD46"
        },
        '@media (max-width: 601px)': {
          width: '100%',
          background: '#000000',
          color: '#FFF',
          border: '1px solid #FFF',
          "&:hover": {
            background: '#000000',
            border: '1px solid #F4CD46',
          },
        }
      },
      remember_passowrd_box: {
        color: '#ffff',
        fontFamily: 'Inter',
        display: 'flex',
        marginTop: '-6px',
        '@media (max-width: 601px)': {
          justifyContent: 'center'
        }
      },
      remember_passowrd_text: {
        fontSize: '14px', 
      },
      login_btn: {
        color: "white",
        fontFamily: "Inter",
        fontWeight: 700,
        marginLeft:'-2px',
        textTransform: 'none',
        letterSpacing: '-0.408px',
        fontSize: "16px",
        paddingLeft:'0px'
      },
    })

export const LightTooltip = withStyles((theme: Theme) => ({
      tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'center',
        left: "40%",
        lineHeight: '1.5em',
        display: 'flex',
        padding: '10px',
        '@media (max-width: 601px)': {
          fontSize: "12px",
          left: 0,
          top: "-15px"
        }
      },
      arrow: {
        fontSize: 20,
        left: 0,
        color: "white",
        "&::before": {
          backgroundColor: "white",
        }
      }
    }))(Tooltip);
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export class ForgotPassword extends ForgotPasswordController {
  goBack: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {

    const { classes } = this.props;
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {this.state.loading && <SpinnerLoader/>}
        <Container style={{ display: 'flex', padding: '0 24px' }} maxWidth="md">
          <Box
            sx={{
              flexDirection: "column",
              alignItems: "center",
              padding: "10px 0px",
              width: '100%'
            }}
          >
            <Box>
              <Typography 
                style={{ color: "white", fontSize: 'clamp(24px, 3vw, 32px)', fontWeight: 700, fontFamily: 'Inter, sans-serif', }} 
                variant="subtitle1" 
                component="div"
              >
                {this.labelTitle}
              </Typography>
              <Typography className = {classes.subtitle} variant="subtitle1" component="p">
                {configJSON.labelSubTitle}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", paddingTop: "20px" }}>
              <Box sx={{ padding: "20px 0px" }}>
                <Typography style={{
                  color: "#fff", fontFamily: 'Inter', float: "left", fontSize: '16px', fontStyle: 'normal', lineHeight: '22px', /* 137.5% */letterSpacing: '-0.408px', marginBottom:'8px'
                }}>
                  Email
                </Typography>
                <Box>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="bottom-start"
                    open={(!this.state.isValidEmail && this.state.isSubmitted )}
                    disableFocusListener
                    arrow
                    disableHoverListener
                    disableTouchListener
                    title={<>
                    <ErrorOutlineOutlinedIcon style={{ color: '#ff2A2A', width: '24px', height: '24px', paddingRight: '5px' }} /> 
                      Please enter a valid email!
                    </>}
                  >
                    <Input
                      style={{
                        display: 'flex',
                        backgroundColor: "#fff",
                        padding: '10px 20px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '12px',
                        flexShrink: 0,
                        border: (!this.state.isValidEmail && this.state.isSubmitted )? '3px solid red' : 'none'
                      }}
                      data-test-id="txtInputEmail"
                      placeholder={"Email"}
                      fullWidth={true}
                      value={this.state.email}
                      onChange={(e) => this.setEmail(e.target.value)}
                    />
                  </LightTooltip>
                </Box>
              </Box>
              {/* <div style={{ color: "Red"}}>
                {this.state.inValid}
                {this.setState({ isValidEmail : false })}
              </div> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 0px",
                  marginTop: '5%',
                }}
              >
                <Button
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  className = {`${classes.sendEmailBtn} ${this.state.isValidEmail ? classes.validationPass : classes.defaultBg}` }
                  fullWidth
                  onClick={() => this.sendResetLink()}
                >
                  Send Email
                </Button>
              </Box>
                <Box
                    className={classes.remember_passowrd_box}
                    data-test-id="remeber-passowrd-box"
                    style={{ display: 'flex', alignItems: 'center' , marginBottom:'15%', marginTop:'2px'}}
                  >
                    <Typography variant='body1' className={classes.remember_passowrd_text} style={{ fontSize:'16px',
                      fontWeight: 200 }}>
                      Remember password?
                    </Typography>
                    <Button
                      id="login-btn"
                      data-test-id="login-btn-text"
                      className={classes.login_btn}
                      onClick={() => this.goToLogin()}
                      style={{ textDecoration: 'underline' }}
                    >
                      Login
                    </Button>
                </Box>
            </Box>
          </Box>
        </Container>
        <CustomDialog
          isError={true}
          data-test-id="error-dialog"
          open={this.state.openSendEmailErrorDialog}
          actions={[
            {
              label: "OK",
              handler: this.handleDialogOkBtn,
              borderColor: "#F4CD46",
              testId: 'dialogCloseBtn',
              primary: true
            },
          ]}
          content={this.state.sendEmailError}
          title="Failed to send email"
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

export default withStyles(Styles, { withTheme: true })(ForgotPassword)