import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { blobToFile, dataURLtoBlob, getNavigationMessage, sendAPIRequest } from "../../../components/src/Utils";
import React from 'react';
import { setStorageData } from "framework/src/Utilities";
import { Button, List, ListItem, ListItemIcon, MenuItem, Popover, Typography } from "@material-ui/core";
import TransitionsModal from "../../../components/src/TransitionsModal/TransitionsModal";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PublishIcon from '@material-ui/icons/Publish';
import { getStorageData } from "framework/src/Utilities";
import imageCompression from "browser-image-compression";



export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;
  business_id: number | null;
  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;

  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  firstNameValidation: boolean;
  selectedImage: any;
  imagePreviewURL: any;
  isUpdateProfile: boolean;
  imageURL: any;
  lastNameValidation: boolean;
  phoneNumberValidation: boolean;
  isError: boolean;
  isValid: boolean;
  branchResponse:any[];
  businessUserResponse:any[];
  selectedUser:any;

  formData: {
    [key: string]: string;
  };
  newBranch:{
      name:string,
      business_id: number
  };
  addUser:{
    branch_id: number,
    account_id: any,
    reporting: boolean,
    manage_business: boolean,
    manage_subscription: boolean,
    manage_subscribers: boolean,
    general_user: boolean
  };
  creatingBranchUser:boolean;
  listBusinessUsers: boolean;
  creatingBranch:boolean;
  buttonChange:boolean;
  errors: {
    [key: string]: string;
  };
  isDisable:boolean;
  anchorEl: any,
   loading:boolean,
  isCropping: boolean,
  startDate: any;
  editEnable:boolean,
  hideButton:boolean,
  isRegister:boolean
  isImageError:boolean,
  largeImageError: boolean,
  uploadImageErrorMsg: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  createBranchCallId: any;
  getBusinessUserCallId: any;
  addUserCallId: any;
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  createProfileAPICallId: string = "";
  updateProfileAPICallId: string = "";
  fileInputRef: any;
  uploadProfilePictureAPICallId: string = "";
  getAccountDetailsAPICallId: string = "";
  removeProfilePictureAPICallId: string = "";
  maxDate = '2015-12-31';
  selectedDate = new Date('2015-12-28');
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],
      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
      selectedUser:'',
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,
      business_id: null,
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      firstNameValidation: false,
      lastNameValidation: false,
      phoneNumberValidation: false,
      selectedImage: '',
      imageURL: '',
      branchResponse:[],
      businessUserResponse:[],

      isValid: false,
      isError: false,
      errors: {},
      isDisable:true,

      formData: {
        first_name: '',
        last_name: '',
        email: '',
        date_of_birth: '',
        gender: '',
        photo: '',
        phone_number: ''
      },
      creatingBranchUser:false,
      listBusinessUsers:false,
      creatingBranch:false,
      buttonChange:false,
      newBranch:{
        name:'',
        business_id:0
      },
      addUser:{
        branch_id: 0,
        account_id: 0,
        reporting: false,
        manage_business: false,
        manage_subscription: false,
        manage_subscribers: false,
        general_user: false
      },
      imagePreviewURL: '',
      anchorEl: null,
      isCropping: false,
      isUpdateProfile: false,
      loading:false,
      startDate: "",
      editEnable:true,
      hideButton:true,
      isRegister:false,
      isImageError:false,
      largeImageError: false,
      uploadImageErrorMsg: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getAccountDetails();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (apiRequestCallId === this.createProfileAPICallId) {
        this.handleCreateProfileAPI(responseJson);
      } else if (apiRequestCallId === this.uploadProfilePictureAPICallId) {
        this.handleUpdateProfilePictureAPI(responseJson);
      } else if (apiRequestCallId === this.getAccountDetailsAPICallId) {
        this.handleGetAccountDetailsAPI(responseJson);
      } else if (apiRequestCallId === this.updateProfileAPICallId) {
        this.handleUpdateProfileAPI(responseJson);
      } else if (apiRequestCallId === this.removeProfilePictureAPICallId) {
        this.handleRemoveProfileAPI(responseJson);
      } else if (apiRequestCallId === this.createBranchCallId){
        this.handleCreateBranchAPI(responseJson);
      } else if (apiRequestCallId === this.getBusinessUserCallId){
        this.handleGetBusinessUserAPI(responseJson);
      } else if (apiRequestCallId === this.addUserCallId){
        this.handleAddUserAPI(responseJson);
      }
    }

    // if (this.checkSession(message)) return;
    // Customizable Area End
  }

  // Customizable Area Start



  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    this.setState({loading:true})
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    this.setState({loading:true})
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  handleChooseFile = () => {
    this.fileInputRef.current.click();
  };

  handleCropImage = (response: any) => {
    const { error, url } = response;
    if (!error) {
      this.setState({ imagePreviewURL: url, imageURL: '' });
      this.handleUpload(url);
      this.setOpen();
    } else {
      this.setOpen();
    }
  };

  setOpen = () => {
    this.setState({ isCropping: false, anchorEl: null});
  };


  handleImageChange = async(event: any) => {
    const image = event.target.files[0];
      if (/\.(gif|jpe?g|tiff?|png|webp|bmp|jfif)$/i.test(image?.name) && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const options = {
        maxSizeMB: 0.75,
        maxWidthOrHeight:
          1300,
        useWebWorker: true,
      };
  
      const compressedImg = await imageCompression(file, options);
      const reader = new FileReader();

      reader.onload = (event) => {
        this.setState({ imageURL: event.target?.result as string,anchorEl:null, isCropping: true });
      };

      reader.readAsDataURL(compressedImg);
        this.setState({ isImageError: false })
    }else{this.setState({ isImageError :true}) }  
  };

  handleCloseImageErrorDialog = () => {this.setState({ isImageError:false, largeImageError: false })}

  handleUpload = (url: any) => {
    const token = this.getTokenFromOtpResData()
    
    if (!url)
      return;

    const blob = dataURLtoBlob(url);
    const fileData = blobToFile(blob, 'filename.png');
    if (fileData) {
      const formData = new FormData();
      formData.append('photo', fileData);
      this.setState({loading:true})
      this.uploadProfilePictureAPICallId = sendAPIRequest(
        `account_block/update_profile_pic`,
        {
          method: configJSON.httpPutMethod,
          body: formData,
          headers: {
            "token": token
          }
        },
      );
    }
  }

  removeProfilePhoto = () => {
    const token = this.getTokenFromOtpResData();
    const formData = new FormData();
    formData.append('photo', '');
    this.setState({loading:true})
    this.removeProfilePictureAPICallId = sendAPIRequest(
      `account_block/remove_profile_pic`,
      {
        method: configJSON.httpPutMethod,
        body: formData,
        headers: {
          "token": token
        }
      },
    );
  }

  getAccountDetails = () => {
    this.setState({loading: true});
    const token = this.getTokenFromOtpResData();
    this.setState({loading:true})
    this.getAccountDetailsAPICallId = sendAPIRequest(
      `account_block/specific_account`,
      {
        method: configJSON.httpGetMethod,
        headers: {
          "token": token
        }
      },
    );
  }

  onHandleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: value ? '' : prevState.errors[name], 
      },
    }),() => {
      const {formData} = this.state;
      if(!formData.first_name || !formData.last_name || !formData.email || !formData.phone_number){
        this.setState({ isDisable:true });
      } else {
        this.setState({ isDisable:false });
      }
    });
  };
  

  onBranchNameChange = (e: any) => {
    let value = "text"
    const errors: { [key: string]: string } = {};
    if (!this.isAlphaNumeric(value)) {
      this.setState({
        isValid: false,
      });
      errors.branch_name='Branch name should be alphanumeric'; 
    } else {
      this.setState({
        isValid: true,
        errors: { branch_name: '' },
        newBranch: { name: value, business_id: 0 },
      });
    }
  };

  onBusinessUserChange = (e: any) => {
    const { value } = e.target;
    const selectedUser = this.state.businessUserResponse.find(user => user.id === value);
  
    this.setState({
      addUser: {
        ...this.state.addUser,
        account_id: value,
      },
      selectedUser,
    });
  };

  handleBusinessUserSelect = (selectedUser: any) => {
    this.setState({
      addUser: {
        ...this.state.addUser,
        account_id: selectedUser.id,
      },
      selectedUser, 
      listBusinessUsers: false, 
    });
  };
  handleOptionClick = (value:any) => {
    this.setState({
      addUser: {
        ...this.state.addUser,
        account_id: value,
      },
      listBusinessUsers: false,
    });
  };

  onReportingChange = (e: any) => {
    const isChecked = e?.target?.checked;
    this.setState({
      addUser: {
        ...this.state.addUser,
        reporting: isChecked,
      },
    });
  };

  onManageBusinessChange = (e: any) => {
    const isChecked = e?.target?.checked;
    this.setState({
      addUser: {
        ...this.state.addUser,
        manage_business: isChecked,
      },
    });
  };

  onManageSubscriberChange = (e: any) => {
    const isChecked = e?.target?.checked;
    this.setState({
      addUser: {
        ...this.state.addUser,
        manage_subscribers: isChecked,
      },
    });
  };

  onManageSubscriptionChange = (e: any) => {
    const isChecked = e?.target?.checked;
    this.setState({
      addUser: {
        ...this.state.addUser,
        manage_subscription: isChecked,
      },
    });
  };

  onGeneralUserChange = (e: any) => {
    const isChecked = e?.target?.checked;
    this.setState({
      addUser: {
        ...this.state.addUser,
        general_user: isChecked,
      },
    });
  };

  isValidPhoneNumber = (phoneNumber: any) => {
    // Define a regex pattern for a 10-digit US phone number
    const phonePattern = /^\d*$/;
    return phonePattern.test(phoneNumber);
  };

  isAlphaNumeric = (input: any) => {
    // Define a regex pattern for a 10-digit US phone number
    return /^[A-Za-z0-9\s-]*$/.test(input) && input.trim() !== '';
  };


  onHandleChangeDate = (value: any) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ['date_of_birth']: value,
      },
    }));
  };


  handleSubmit = (event: any) => {
    event.preventDefault();
    // Validate form fields here
    if (Object.keys(this.validateForm()).length === 0) {
      // Handle form submission
      this.createUpdateProfile();
    } else {
      this.setState({ errors: this.validateForm() });
    }
  };

  validateForm = () => {
    this.setState({ isValid: true });
    const { formData } = this.state;
    const errors: { [key: string]: string } = {};
    const isAlphabetic = (field: string) => /^[a-zA-Z]+$/.test(field.trim());
    if (this.checkIsValid(formData.first_name)) {
      this.setState({ isValid: false });
      errors.first_name = 'Required field is missing';
    }
    else if (!isAlphabetic(formData.first_name)) {
      this.setState({ isValid: false });
      errors.first_name = 'First name should be alphabetic';
  }
    if (this.checkIsValid(formData.last_name)) {
      this.setState({ isValid: false });
      errors.last_name = 'Required field is missing';
    } 
    else if (!isAlphabetic(formData.last_name)) {
      this.setState({ isValid: false });
      errors.last_name = 'Last name should be alphabetic';
  } 
    if (this.checkIsValid(formData.email)) {
      this.setState({ isValid: false });
      errors.email = 'Required field is missing';
    }
    if (this.checkIsValid(formData.phone_number)) {
      this.setState({ isValid: false });
      errors.phone_number = 'Required field is missing';
    } else {
      const phoneNumber = formData.phone_number.trim().replace(/\s/g, ''); 
      if (!this.isValidPhoneNumber(phoneNumber)) {
        this.setState({ isValid: false });
        errors.phone_number = 'Phone number should be numeric';
      } 
      else if (!formData.phone_number.match(/^\d{10}$/)){
        this.setState({ isValid: false });
        errors.phone_number = 'Phone number should contain from 10 to 12 digits';
      }
    }
    return errors;
  };



  checkIsValid = (field: any) => {
   return !field || field.trim() === '';
  }

 

  handleChange = (event: React.ChangeEvent<HTMLInputElement> | Date, inputName?: string) => {
    if (event instanceof Date) {
      // Handle DatePicker change
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          // Convert the Date object to a string in the desired format
          [inputName as string]: event.toISOString().split('T')[0],
        }
      }));
    } else {
      const { name, value } = event.target;
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }));
    }
  };




  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };
    this.setState({loading:true})

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createBranch = async () => {
    const token = this.getTokenFromOtpResData();
    this.setState({loading:true})
    this.createBranchCallId = sendAPIRequest(
      `bx_block_branch/branches`,
      {
        method: configJSON.httpPostMethod,
        body: { ...this.state.newBranch, business_id: 51},
        headers: {
          "Content-Type": "application/json",
          "token": token
        }
      },
    );
    this.setState({
      creatingBranch: false,
    });
  };

  handleUserToggle= ()=>{
    const { creatingBranchUser } = this.state;
    if (!creatingBranchUser){
      this.setState({creatingBranchUser: true});
      this.getBusinessUsers()
    }else
    {
      this.setState({creatingBranchUser:false})
      this.addBusinessUsers()
    }
  };

  handleOpenUsersDropdown= ()=>{
    const { listBusinessUsers } = this.state;
    !listBusinessUsers &&
      this.setState({listBusinessUsers: true});
    
  }

  handleEdit = ()=>{
    const { creatingBranch } = this.state;
    if(creatingBranch){
      this.setState({creatingBranch:false})
      this.setState({buttonChange:true})
    }
  }

  handleBack = () => {
    const { creatingBranch } = this.state;
    if(this.state.isValid){

    }
    else{
      return this.setState({
        errors:{branch_name:'This field is required'}, 

      });
    }

    if (creatingBranch) {
      window.location.href='/LandingPage';
    } else {
      this.createBranch();
      this.setState({
        creatingBranch: true,
      });
    }
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;
    this.setState({loading:true})
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onKeyPress = (event: any, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  createUpdateProfile = () => {
    this.state.isUpdateProfile ? this.updateMyProfile() : this.createMyProfile();
  }

  createMyProfile = () => {
    const token = this.getTokenFromOtpResData();
    this.setState({loading:true})
    let profile = new FormData()
    profile.append('profile[first_name]',this.state.formData.first_name)
    profile.append('profile[last_name]',this.state.formData.last_name)
    profile.append('profile[phone_number]',44+this.state.formData.phone_number)
    profile.append('profile[email]',this.state.formData.email)
    profile.append('profile[date_of_birth]',this.state.formData.date_of_birth)
    profile.append('profile[gender]',this.state.formData.gender)
 

    const headers = {
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createProfileAPICallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      profile
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }


  getBusinessUsers = () => {
    const token = this.getTokenFromOtpResData();
    this.setState({loading:true})
    this.getBusinessUserCallId = sendAPIRequest(
      `bx_block_branch/search_users`,
      {
        method: configJSON.httpGetMethod,
        headers: {
          "Content-Type": "application/json",
          "token": token
        }
      },
    );
  }

  addBusinessUsers = () => {
    const token = this.getTokenFromOtpResData();
    const branchId = this.state.branchResponse;  
    this.setState({loading:true}) 
    this.addUserCallId= sendAPIRequest(
      `/bx_block_branch/add_user`,
      {
        method: configJSON.httpPostMethod,
        body: { ...this.state.addUser, branch_id: branchId[0].attributes.branch.branch_id }, 
        headers: {
          "Content-Type": "application/json",
          "token": token
        }
      },
    );
  }

  updateMyProfile = () => {
    const token = this.getTokenFromOtpResData();  this.setState({loading:true});
    let profile = new FormData()

    profile.append('profile[first_name]',this.state.formData.first_name); profile.append('profile[last_name]',this.state.formData.last_name)
    profile.append('profile[phone_number]',44+this.state.formData.phone_number) ; profile.append('profile[email]',this.state.formData.email)
    profile.append('profile[date_of_birth]',this.state.formData.date_of_birth); profile.append('profile[gender]',this.state.formData.gender)
    const headers = {  token: token };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); this.updateProfileAPICallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/update_user_profile`
    );
    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      profile
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getTokenFromOtpResData = ()=>{
    let token;
    const otpRes = localStorage.getItem('otpConfRes');
    const loginRes = localStorage.getItem('logInRes');
    if(otpRes){
      const otpResParse = JSON.parse(otpRes);
      token = otpResParse.token;
      return token;
    }
    if(loginRes){
      const otpResParse = JSON.parse(loginRes);
      token = otpResParse.logInToken;
      return token;
    }
  }

  handleCreateProfileAPI = (response: any) => {
    const { data, errors } = response;
    if (!errors && data) {
      const {attributes} = data;
      window.localStorage.setItem("userName",`${attributes.first_name} ${attributes.last_name}`);
      window.localStorage.setItem("LoginEmail",attributes.email);
      this.openNextPage();
    }
  }

  handleUpdateProfileAPI = (responseJson: any) => {
    if (responseJson.data) {
      const {attributes} = responseJson.data;
      window.localStorage.setItem("userName",`${attributes.first_name} ${attributes.last_name}`);
      window.localStorage.setItem("LoginEmail",attributes.email);
      this.goToLandingPage();
    }
  }

  goToLandingPage = () => {
    const {business_id} = this.state;
    if(business_id === null){
      this.send(getNavigationMessage("SetupBusiness", this.props));
    }else {
      this.send(getNavigationMessage("LandingPage", this.props));
    }
  }
  

  handleUpdateProfilePictureAPI = (response: {errors: {photo: string[]}}) => {
    
    this.setState({loading:false})
    
    if(response.errors && response.errors.photo?.length>0){
      const {errors} = response;
      this.setState({largeImageError: true, uploadImageErrorMsg: `Photo ${errors.photo[0]}` })
    }
  }

  handleGetBusinessUserAPI = (responseJson: any) => {
    if (responseJson?.account) {
      this.setState((prevState) => ({
        businessUserResponse: [...prevState.businessUserResponse, ...responseJson.account]
      }));
    }
  };

  handleCreateBranchAPI = (responseJson: any) => {
    const { data, errors } = responseJson;
    if (!errors && data){
      this.setState({branchResponse:[...this.state.branchResponse, data]});
    }
  };

  handleAddUserAPI = (responseJson: any) => {
    const { data, errors } = responseJson;
    if (!errors && data){
      this.setState({branchResponse:[...this.state.branchResponse, data]});
    }
  };
  
  handleRemoveProfileAPI = (response: any) => {
    const { data } = response;
    if (data) {
      this.setState({loading:false})
      this.showAlert("Remove Photo", "Profile photo removed.");
      this.setState({ imagePreviewURL: null });
      this.handleClose();
    }
    this.handleClose();
  }
   preset=(data: any, profile: any)=>{
    this.setState((prevState) => ({
      imagePreviewURL: data.attributes.photo ? data.attributes.photo : null,
      isUpdateProfile: true,
      formData: {
        ...prevState.formData,
        first_name: profile.first_name || '',
        last_name: profile.last_name || '',
        email: profile.email || '',
        gender: profile.gender || '',
        date_of_birth: profile.date_of_birth || '',
        phone_number: profile.phone_number ? profile.phone_number.split("").reverse().join("").slice(0, 10).split("").reverse().join("") : profile.phone_number,
        photo: '',
      },
    }));
   }
  handleGetAccountDetailsAPI = (response: any) => {
    this.setState({loading: false});
  const { data } = response;
  
  if(data.attributes){
    this.setState({profileRole: data.attributes.role_name});
  }

  if(data.attributes.first_name === null){
    this.setState({isRegister:true})
    this.setState({ editEnable: false });
  }else{
    this.setState({isRegister:false})
    this.setState({ editEnable: true });
  }

  if (data && data.attributes && data.attributes.first_name) {
    const profile = data.attributes.profiles && data.attributes.profiles.length > 0
      ? data.attributes.profiles[0]
      : {};

    this.setState({business_id: data.attributes.account?.business_id});
    this.preset(data, profile)
  } else {
    this.setState((prevState) => ({
      imagePreviewURL: data.attributes.photo ?  data.attributes.photo : null,
      formData: {
        ...prevState.formData,
        email: localStorage.getItem("logInEmail") || '',
      },
    }));
  }
};


  async openNextPage() {
    const {profileRole} = this.state;
    if(profileRole === 'business user'){
      this.send(getNavigationMessage("SetupBusiness", this.props));
    }else {
      window.localStorage.setItem("isProfileCreated","true");
      setStorageData("userLoggedIn",true);
      const subscriptionToBuy = await getStorageData("subscription_to_buy", true);
      if(subscriptionToBuy){
        this.send(getNavigationMessage("QrSubscriptionList", this.props));
      } else {
        this.send(getNavigationMessage("LandingPage", this.props));
      }
      
    }
  }
    

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleEditClicked(){
    this.setState({ editEnable: false });
    this.setState({hideButton:false});
  }

  renderBusinessUsersList() {
    const { businessUserResponse, branchResponse } = this.state;
  
    return businessUserResponse
      .filter(user => !branchResponse.some(branchUser => branchUser.attributes.account.account_id === user.id))
      .map(business_user => {
        if (business_user.first_name !== null) {
          return (
            <MenuItem
              key={business_user.id}
              onClick={() => this.handleBusinessUserSelect(business_user)}
              data-test-id="business-user-list"
            >
              {`${business_user.first_name}`}
            </MenuItem>
          );
        }
        return null;
      });
  }

  renderImageUi=(isCropping:any, imageURL:any)=>{
    const {editEnable, imagePreviewURL} = this.state
    if(!editEnable){
      return (
        <>
           <div className="image-upload-background" style={{height:'clamp(150px, 300px, 25vw)' , width:'clamp(150px, 300px, 25vw)' , display:'flex' , justifyContent:'center' , alignItems:'center'}}>
                <div className="image-upload" style={{height:'100%' , width:'100%', marginBottom:'0px'}} >
                  {imagePreviewURL && <div data-test-id='image-upload-div' style={{height:'100%' , width:'100%', borderRadius:'50%'}} onClick={(event: any) =>this.handleClick(event)}
                  >
                    <img
                      id="profile_image"
                      className="profile-image"
                      key={new Date().getTime()}
                      src={imagePreviewURL}
                      alt="Preview"
                      style={{width: '100%', height: '100%', borderRadius: '50%'}}
                      />
                  </div>}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.handleImageChange}
                    id="handleImageChange"
                    style={{ display: 'none' }}
                    ref={this.fileInputRef}
                    data-test-id="uploadLogoInput"
                  />
                  {
                    !imagePreviewURL ?
                      <div className="image-priview" onClick={this.handleChooseFile}>
                        <button id="handleChooseFile" style={{ border: "none",marginTop:'25%' }}>
                         <CloudUploadIcon />
                        </button>
                        <Typography className="upload-profile-photo">{"Upload Profile Photo"}</Typography>
                      </div> : ""
                  }
                    <TransitionsModal setOpen={this.setOpen} handleCropImage={this.handleCropImage} modelComponent={'ImageCrop'} open={isCropping} src={imageURL} />
                </div>
                {
                  <Popover
                  id={!!this.state.anchorEl ? 'simple-popover' : undefined}
                  open={!!this.state.anchorEl}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    style={{padding: "8px", flexShrink: 0, borderRadius: "8px", width: "192px", height: "82px", display: "flex", flexDirection: "column", justifyContent: "space-evenly", boxSizing:"border-box"}}
                  >
                    <ListItem button style={{ padding: 0, margin: 0, height:"36px"}} onClick={this.handleChooseFile}>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <PublishIcon />
                      </ListItemIcon>
                      <Typography style={{padding: 0, margin: 0, fontSize: "14px", fontFamily: "Inter", color: "black"}}>Upload New Photo</Typography>
                    </ListItem>
                    <ListItem button style={{ padding: 0, margin: 0, height:"36px"}} onClick={this.removeProfilePhoto}>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <DeleteOutlineIcon />
                      </ListItemIcon>
                      <Typography style={{padding: 0, margin: 0,fontSize: "14px",fontFamily: "Inter", color: "black"}}>Remove Photo</Typography>
                    </ListItem>
                  </List>
                </Popover>
                }
              </div>
        </>
      )
    }
    else{
      return (
        <div className="image-upload-background" 
        style={{height:'clamp(150px, 300px, 25vw)' , width:'clamp(150px, 300px, 25vw)' , display:'flex'
         , justifyContent:'center' , 
         alignItems:'center'}}>
        <div className="image-upload" style={{height:'100%' , width:'100%', marginBottom:'0px'}} >
          {imagePreviewURL && <div data-test-id='image-upload-div' style={{height:'100%' , width:'100%', borderRadius: "50%"}} >
            <img id="profile_image"  className="profile-image"  key={new Date().getTime()}  src={imagePreviewURL}
              alt="Preview"
              style={{width:'100%', height:'100%'}} />
          </div>}
          <input
            data-test-id="uploadLogoInput"
            type="file" 
            accept="image/*" 
            onChange={this.handleImageChange} 
            id="handleImageChange"
            style={{ display: 'none' }}
            ref={this.fileInputRef}
          />
          {
            !imagePreviewURL ?
              <div className="image-priview" onClick={this.handleChooseFile}>
                <button id="handleChooseFile" style={{ border: "none" }}>
                <div></div>
                </button>
              </div> : ""
          }
        </div>
      </div>
      )
    }
  }

  renderNextSaveProfileButton=()=>{
    const {isDisable} = this.state
    if(isDisable){
      return (
        <>
         <Button
          className='button'
          data-test-id="btnNext"
          variant="contained"
          color="primary"
          type="submit"
          disabled={true}
          >
          <Typography className="button-text">Next</Typography>
          </Button>
        </>
      )
    }else {
      return (
         <Button className='btn-opacity' data-test-id="btnNext" variant="contained" color="primary" type="submit" disabled={false}>
        <Typography className="button-text">
          Next
          </Typography>
          </Button>
      )
    }
  }

  handleEditCancel=()=>{
    this.send(getNavigationMessage("LandingPage", this.props));
    this.setState({ editEnable: true });
    this.setState({hideButton:true});
  }

  handleUpdateProfilePictureAPIErrorRes = () => {

  }
  // Customizable Area End
}
