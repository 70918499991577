import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
const SubmitButton = styled(Button)({
  display: "flex",
  width: "225px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  border: "1px solid #fff",
  borderRadius: "60px",
  fontSize: "1em",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "capitalize",
  marginTop: '15px',
  "&:hover": {
    // border: "1px solid #F4CD46",
    color: "black",
    backgroundColor: 'white'
  }
});
const SubmitButton2 = styled(Button)({
  display: "flex",
  width: "269px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  border: "1px solid #fff",
  borderRadius: "70px",
  fontSize: "1.25em",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "capitalize",
  flexShrink: 0,
  "&:hover": {
    // border: "1px solid #F4CD46",
    color: "black",
    backgroundColor: 'white'
  },
  "@media(max-width: 450px)": {
    width: "250px",
    height: "48px",
  }
});

const SubmitButton3 = styled(Button)({
  display: "flex",
  width: "269px",
  height: "56px",
  padding: "1px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  border: "1px solid #F4CD46",
  borderRadius: "70px",
  fontSize: "1.25em",
  fontWeight: 600,
  letterSpacing: "-0.408px",
  color: "#fff",
  textTransform: "capitalize",
  flexShrink: 0,
  "&:hover": {
    border: "1px solid #F4CD46",
    color: "#000",
    backgroundColor: 'F4CD46'
  },
  "@media(max-width: 450px)": {
    width: "250px",
    height: "48px",
  }
});



const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiDialog: {
      'paperWidthSm': {
        maxWidth: '694px',
        padding: '30px 10px',
        background: '#212226'
      },
    },
    MuiBackdrop: {
      'root': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }
    }
  }
});
// Customizable Area End

import MemberShipController, {
  Props, configJSON,
} from "./MemberShipController";

export default class Membership extends MemberShipController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  DialogActionsWrap = styled(DialogActions)({
    justifyContent: 'center',
    gap: '24px',
    paddingBottom:'30px',
    flexWrap: 'wrap',
    '@media (max-width: 601px)': {
      gap: '12px',
    }
  });
  ButtonContinue = styled(Button)({
    padding: '4px 8px',
    width: '180px',
    height: '56px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontSize: "1em",
    color: '#fff',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    borderRadius: '70px',
    textTransform: 'capitalize' as 'capitalize',
    "&:hover": {
      background:'#F4CD46',
      color:'black'
    },
    '@media (max-width: 601px)': {
      width: '130px',
      height: '40px',
    }
  });
  ButtonClose = styled(Button)({
    fontSize: '1em',
    color: '#fff',
    width: '180px',
    height: '56px',
    borderRadius: '70px',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center' as 'center',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    letterSpacing: '-0.408px',
    textTransform: 'capitalize' as 'capitalize',
    border: '1px solid #fff',
    "&:hover": {
      background:'white',
      color:'black'
    },
    '@media (max-width: 601px)': {
      width: '130px',
      height: '40px',
    }
  });
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {
          this.state.isSubscriber && (
            <>
              <Container maxWidth="xl" style={{fontSize: 'clamp(10px, 16px, 2vw)'}}>
                <Container maxWidth="lg">
                  <Box style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <ArrowBackIcon onClick={this.goBack} style={{ color: '#fff', cursor: 'pointer', fontSize: '2em' }} data-test-id="arrow-back-icon-btn" />
                    <Typography style={{ ...webStyle.headingStyleCreateBtn }}>Membership</Typography>
                  </Box>
                  {this.state.isCancel &&
                    <Container maxWidth="lg" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <div>
                        <Container maxWidth="lg" >
                          <div>
                            <p style={{ ...webStyle.strongdescription, display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'inter' }}>Go QR Membership</p>
                          </div>
                          <div>
                          <p style={{ ...webStyle.lightdescription, marginTop: '20px' }}><strong style={{ fontSize: '1.5em', fontWeight: 500 }}>£40/</strong>month</p>

                            <p style={{ ...webStyle.lightdescription, marginTop: '20px' }}>
                            plus Stripe processing fees 
                            </p>
                          
                          </div>
                          <div style={{ fontSize: '1.5em', color: 'white', fontWeight: 600, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            Next Billing
                          </div>
                          <div style={{ fontSize: '1.5em', fontWeight: 200, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            {this.formatDate(this.state.memberShipData?.next_billing)}
                          </div>
                          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                            <SubmitButton data-test-id="cancel-button" onClick={this.handleCancel} >Cancel Membership</SubmitButton>
                          </Box>
                        </Container>
                      </div>
                    </Container>
                  }
                  {!this.state.isCancel &&

                    <Container maxWidth="lg" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{width: '100%'}}>
                        <Container maxWidth="lg" style={{padding: 0}}>
                          <div>
                            <p style={{ fontSize: '2.5em', fontWeight: 600, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'inter' }}>{configJSON.cancelMembershipText1}</p>
                          </div>
                          <div>

                            <p style={{ ...webStyle.lightdescription, fontSize: '1.5em', marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {configJSON.cancelMembershipText2} </p>

                          </div>
                          <div style={{ fontSize: '1.5em', color: 'white', fontWeight: 600, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {configJSON.cancelMembershipText3}
                          </div>
                          <div style={{ fontSize: '1.5em', fontWeight: 200, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                          {configJSON.cancelMembershipText4}
                          </div>
                          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px', gap: '20px', flexWrap: 'wrap' }}>
                            <SubmitButton2 onClick={this.goBack} >Keep Membership</SubmitButton2>
                            <SubmitButton3 onClick={this.openDialog} >Cancel Membership</SubmitButton3>
                          </Box>
                        </Container>
                      </div>
                    </Container>
                  }

                </Container>
                <ThemeProvider theme={theme}>
                <Dialog open={this.state.isOpen} aria-labelledby="form-dialog-title" >
                  <Typography id="form-dialogue-title" style={webStyle.dialogTitleStyle}>
                    <ErrorOutlineOutlinedIcon style={{ color: '#E82929', fontSize: 'clamp(22px, 32px, 5vw)', marginRight: '10px', }} />
                    {configJSON.cancelMembershipDialogTitle}
                  </Typography>
                  <DialogContent style={{padding: '0 2%'}}>
                    <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300 }}>
                    {configJSON.cancelMembershipDialogContent}
                    </DialogContentText>
                  </DialogContent>
                  <this.DialogActionsWrap>
                    <this.ButtonClose
                      data-test-id="login-dialog-close-btn"
                      onClick={this.closeDialoge}
                    >
                      Cancel
                    </this.ButtonClose>
                    <this.ButtonContinue
                      style={{ border: '1px solid #F4CD46' }} onClick={this.confrimCancel} data-test-id="login-dialog-continue-btn">
                      Confirm
                    </this.ButtonContinue>
                  </this.DialogActionsWrap>
                </Dialog>

                <Dialog open={this.state.confimed} aria-labelledby="form-dialog-title">
                  <Typography id="form-dialog-title" style={webStyle.dialogTitleStyle}>
                    <CheckCircleOutlineIcon style={{ color: '#008000', marginRight: '10px', fontSize: 'clamp(22px, 32px, 5vw)' }} />
                    Cancelled Successfully
                  </Typography>
                  <DialogContent style={{ paddingBottom: '70px', paddingRight: '30x', paddingLeft: '30px' }}>
                    <DialogContentText style={{ ...webStyle.dialogContentStyle, fontWeight: 300, whiteSpace: 'pre-wrap', width: '580px' }}>
                      Your membership has been cancelled successfully. You will now be logged out.
                    </DialogContentText>
                  </DialogContent>

                </Dialog>
                </ThemeProvider>

              </Container>
            </>
          )
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
    fontSize: '1.9em'
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  strongdescription: {
    fontWeight: 600,
    color: 'white' as 'white',
    fontSize: '2em',
  },
  lightdescription: {
    fontWeight: 200,
    color: 'white' as 'white',
    fontSize: '2em',
    textAlign: 'center' as const
  },
  dialoglightdescription: {
    fontWeight: 200,
    color: 'white' as 'white',
    fontSize: '1.25em'
  },
  headingStyleCreateBtn: {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '2em',
    fontWeight: 600,
    letterSpacing: '-0.72px',
    position: 'relative' as 'relative',
  },
  dialogTitleStyle: {
    fontSize: 'clamp(22px, 32px, 5vw)',
    fontWeight: 600 as 600,
    fontFamily: 'Inter, sans-serif',
    letterSpacing: '-0.408px',
    color: '#fff',
    textAlign: 'center' as 'center',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    marginBottom: '15px'
  },
  dialogContentStyle: {
    color: '#fff',
    fontFamily: 'Inter, sans-serif',
    fontSize: 'clamp(16px, 24px, 4vw)',
    letterSpacing: '-0.408px',
    textAlign: 'center' as 'center',
    marginBottom: '15px'
  },
};
// Customizable Area End
