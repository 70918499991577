import React from "react";
// Customizable Area Start
import TopNavBar from "./Header.web";
import { Box, Grid } from '@material-ui/core';
import RegistrationComponentController, { Props } from "./RegistrationComponentController";

import './DashBoard.css'
// Customizable Area End

export default class RegistrationComponent extends RegistrationComponentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation, children } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box id="childrenElement" style={{ backgroundColor: "#000000", display: "flex", flexDirection: "column", overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'none', scrollBehavior: 'smooth'}}>

        <TopNavBar {...this.props} />

        <Grid container style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Box style={{ minHeight: 'calc(100vh - 100px)' }}>
              {children}
            </Box>
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
