Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account_block/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderPassword = "Password";
exports.btnTxtLogin = "Sign up";
exports.labelRememberMe = "Remember me";
exports.labelHeader = "The reason we require login";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "Already an existing user? ";
exports.placeHolderEmail = "Email";
exports.labelTitle = "Sign up as a ";
exports.singUp = "Login";
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.loginAPiEndPoint1 = "admin/bx_block_login/logins";
exports.agreeTCtext = "I accept to the following ";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.privacyPolicyText = "Privacy Policy";
exports.tcText = "Terms and Conditions";

// Customizable Area End
