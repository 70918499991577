import React from "react";
// Customizable Area Start
import {
  Container,Button,Grid, Box,Typography,FormControlLabel, 
  RadioGroup,Radio,Checkbox,
  TextField,
  FormControl,
  FormLabel,
} from "@material-ui/core";

import "react-datepicker/dist/react-datepicker.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./customisableUserProfile.css"
import CustomTextField from "../../../components/src/CoustomTextfield.web";
import CustomDropdown from "../../../components/src/CoustomDropdown.web";
import CustomDatePicker from "../../../components/src/CoustomDatePicker.web";
import UserContext from "../../../components/src/UserProvider";
import DatePicker from "react-datepicker";
import {configJSON} from "./CustomisableUserProfilesController";
const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() - 10);
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
import moment from "moment";
import CustomDialog from "../../../components/src/CustomDailog.web";
import { flagImage } from "./assets";
import CustomeButton from "../../../components/src/CustomeButton.web";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    CustomisableUserProfiles.contextType = UserContext
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.value, field.field_type)
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };

  render() {
    // Customizable Area Start
    const { formData, errors, imageURL, isCropping,editEnable } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Container id="customisable-business-profile">
          {/* Customizable Area Start */}
          {this.state.loading && <SpinnerLoader/>}
          <Typography className="profile-headding" style={{ fontSize: '1.8em',position:'relative', }}>{this.state.editEnable || !this.state.hideButton ?"My User Profile": "Set up your User Profile"}</Typography>
          <Grid container spacing={3} className="main-container">
            <Grid item xs={12} sm={12} md={4} lg={4} className="img-container">
              {this.renderImageUi(isCropping, imageURL)}
            </Grid>
            <form onSubmit={this.handleSubmit}>
              <Grid container spacing={2} className="form-grid">
                <Grid item xs={12} sm={6} md={6} lg={6}>
                <Typography style={webstyles.header}>First Name*</Typography>
                  <CustomTextField
                    name="first_name"
                    // label="First Name*"
                    value={formData.first_name}
                    onChange={this.onHandleChange}
                    error={errors.first_name}
                    type="text"
                    dataTestId="txtInputFirstName"
                    disabled={editEnable}
                    isDisableStyle={editEnable}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography style={webstyles.header}>Last Name*</Typography>
                  <CustomTextField
                    // label="Last Name*"
                    name="last_name"
                    type="text"
                    value={formData.last_name}
                    onChange={this.onHandleChange}
                    error={errors.last_name}
                    dataTestId="txtInputLastName"
                    disabled={editEnable}
                    isDisableStyle={editEnable}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography style={webstyles.header3}>Email*</Typography>
                  <CustomTextField
                    // label="Email*"
                    istrue = {true}
                    placeholder="abc@gmail.com"
                    dataTestId="txtInputEmail"
                    value={formData.email}
                    fullWidth
                    disabled={true}
                    isDisableStyle={editEnable}
                  />
                </Grid>
               
                <Grid item xs={12} sm={6} md={6} lg={6}>
                <Typography style={webstyles.header3}>Phone Number*</Typography>
                <Grid container spacing={1} className="form-grid" style={{padding: '0 4px'}}>
                <Grid item xs={3} sm={3} md={2} lg={2} 
                style={{
                  backgroundColor: this.state.editEnable ? '#212226' : 'white',
                  color: this.state.editEnable ? '#fff' : 'black',
                  display:'flex', alignItems:'center',
                  height: '48px', marginTop: '4px',
              }}
                >
                <img src={flagImage} 
                 style={{width: '23px',
                     height: '15px',
                     marginRight: '5px'}} />
                  +44</Grid>
                <Grid item xs={9} sm={9} md={10} lg={10} style={{marginTop:'3px', padding:'1px'}}>
                <CustomTextField
                    // label="Phone Number*"
                    name="phone_number"
                    type="text"
                    value={formData.phone_number}
                    onChange={this.onHandleChange}
                    error={errors.phone_number}
                    dataTestId="txtInputPhoneNumber"
                    disabled={this.state.editEnable}
                    isDisableStyle={this.state.editEnable}
                  />
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography style={this.state.editEnable ? webstyles.header3:webstyles.header2}>Date of Birth</Typography>
                {this.state.editEnable ? 
                  <CustomTextField
                      value={formData.date_of_birth ? moment(formData.date_of_birth).format("MMMM D, YYYY") : ''}
                      disabled={this.state.editEnable}
                      isDisableStyle={this.state.editEnable}
                    /> :
                    <CustomDatePicker
                      // label="Date of Birth"
                      name="date_of_birth"
                      dataTestId="txtInputDateOfBirth"
                      value={formData.date_of_birth}
                      maxDate={this.maxDate}
                      // dateFormat="MMMM d, yyyy"
                      onChange={this.handleChange}
                      startDate={this.state.startDate}
                      isDisableStyle={this.state.editEnable}
                      
                      />}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography style={{fontSize:'1em',color:'#FFFF' ,fontFamily:'Inter',fontWeight:300,marginTop:'12px' }}>Gender</Typography>
                  <CustomDropdown
                    // label="Gender"
                    name="gender"
                    dataTestId="txtInputGender"
                    value={formData.gender}
                    onChange={(event: any) => this.onHandleChange(event)}
                    items={configJSON.data}
                    className="textfield-gender"
                    disabled={this.state.editEnable}
                    isDisableStyle={this.state.editEnable}
                    style={{marginRight:'0',}}
                  />
                </Grid>
              </Grid>
              {this.state.isRegister  ?
                (
                  <Box className="button-box">
                   {this.renderNextSaveProfileButton()}
                  </Box>
                ) : (<></>)
              }

              {!this.state.hideButton ? 
              (
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Box className="buttons" style={{display:'flex',justifyContent:'end',alignItems:'center',gap:'20px'}}>
                    <Box className="button-box">
                      <CustomeButton  data-test-id="btnNext" onclicked={this.handleEditCancel} text="Cancel" primary={false} />
                    </Box>
                    <Box className="button-box">
                     <CustomeButton  data-test-id="btnNext" type = {"submit"} text="Save" primary={true} />
            
                    </Box>
                  </Box>
                </Grid>
              )
              :(<></>)}
            </form>
            {this.state.editEnable ? 
            (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box className="button-box buttons">
                  <CustomeButton data-test-id="btnNext" onclicked = {()=>this.handleEditClicked()} text ="Edit" primary={true} />
                </Box>
              </Grid>
            ):(<></>)}
          </Grid>
           {/* Customizable End Start */}

           {
            this.state.isImageError && <CustomDialog
              open={this.state.isImageError}
              isError={true}
              actions={[
                {
                  label: "Cancel",
                  handler: this.handleCloseImageErrorDialog,
                  borderColor: "#F4CD46",
                  testId: 'dialogCloseBtn',
                  primary:false
                },
                {
                  label: "Reupload",
                  handler: this.handleChooseFile,
                  borderColor: "#F4CD46",
                  testId: 'dialogCloseBtn',
                  primary:true
                }
              ]}
              title="Failed to Upload Image"
              content="File is not supported."
            />
           }
           {
            <CustomDialog
              open={this.state.largeImageError}
              isError={true}
              actions={[
                {
                  label: "OK",
                  handler: this.handleCloseImageErrorDialog,
                  borderColor: "#F4CD46",
                  testId: 'dialogCloseBtn',
                  primary:false
                },
              ]}
              title="Failed to Upload Image"
              content={this.state.uploadImageErrorMsg}
            />
           }
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webstyles={
  header:{
    fontSize: '1em', color: '#FFFF', fontWeight: 300, marginBottom: '8px' 
  },
  header3:{
    fontSize: '1em', color: '#FFFF', fontWeight: 300, marginBottom: '8px',marginTop:'12px' 
  },
  header2: {
    fontSize: '1em', color: '#FFFF', fontWeight: 300, marginTop: '12px' 
  }
}
// Customizable Area End