Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';

exports.getTermsCondsApiEndPoint = 'terms_and_conditions/latest_record';
exports.getAllTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.createTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.getAccountGroupsApiEndPoint = 'account_groups/groups';
exports.setTermsCondsAcceptanceApiEndPoint = 'terms_and_conditions/accept_and_reject';

exports.getPrivicyPoliciesApiEndPoint = 'bx_block_privacy_policy/privacy_policy';

exports.createTermsConds = 'Create New'
exports.created = 'Created'
exports.tickAccept = 'Please Tick To Accept';
exports.updateTermsConds = 'Update';
exports.acceptedUsers = 'Accepted Users';
exports.saveTermsConds = 'Save';
exports.termsCondsUpdatedMassage = 'Terms and Conditions Updated Successfully';
exports.termsCondsList = 'Terms & Conditions List';
exports.termsConds = 'Terms and Conditions';
exports.termsCondsUserList = 'Users That Accepted List';

exports.dateFormat = "MM-DD-YYYY";
exports.termsConditionText = 
`   <span>Please read these Subscription Terms and Conditions ("Terms") carefully before subscribing to [Your Platform Name] ("the Platform"). By subscribing to the 
          Platform, you ("Subscriber") agree to be bound by these Terms.</span>

     <p>
         1. Subscription Plan and Fees:
        <ol>
          <li>1.1. The Platform offers different subscription plans with varying features and durations. The details of each plan, including applicable fees, will be displayed on the Platform.</li>
          <li>1.2. Subscriber shall select the desired subscription plan and pay the corresponding fees as per the payment options available on the Platform.</li>
        </ol>
      </p>

      <p>
          2. Billing and Payments:
        <ol>
          <li>2.1. The subscription fees shall be billed in advance, and the recurring billing cycle will depend on the chosen subscription plan.</li>
          <li>2.2. Subscriber authorizes the Platform to charge the applicable fees to the payment method provided during the subscription process.</li>
          <li>2.3. Any taxes or additional charges imposed by the relevant authorities shall be borne by the Subscriber.</li>
        </ol>
      </p>

      <p>
         3. Subscription Renewal and Cancellation:
        <ol>
          <li>3.1. Subscriptions will automatically renew at the end of each billing cycle, unless canceled by the Subscriber.</li>
          <li>3.2. Subscriber may cancel the subscription at any time before the next billing cycle. However, no refunds will be provided for the unused portion of the subscription.</li>
        </ol>
      </p>

      <p>
         4. Access and Usage:
        <ol>
          <li>4.1. Upon successful payment, the Subscriber will be granted access to the Platform as per the chosen subscription plan.</li>
          <li>4.2. The Subscriber shall use the Platform solely for lawful purposes and in compliance with these Terms and all applicable laws and regulations.</li>
          <li>4.3. Subscriber is responsible for maintaining the confidentiality of login credentials and shall not share or disclose them to any third part. </li>
        </ol>
      </p>`
  // Customizable Area End