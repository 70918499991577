import React from "react";
// Customizable Area Start
import { Avatar, Box, Button, Container, Dialog, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { ThemeProvider, createTheme, styled } from '@material-ui/core/styles';
import { QrReader } from 'react-qr-reader';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SpinnerLoader from "../../../components/src/SpinnerLoading.web";
import CustomDialog from "../../../components/src/CustomDailog.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from "moment";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
// Customizable Area End

import QrCodesController, {
    Props,
} from "./QrCodesController";

export default class QrCodes extends QrCodesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    successDialogActions = [
        {
            label: "Go to Homepage",
            handler: this.handleGoToHomepage,
            borderColor: "#F4CD46",
            testId: 'go-to-homepage-btn',
            pirmary:true
        }
    ];

    failedDialogActions = [
        {
            label: "Cancel",
            handler: this.handleFailedDialogCancelBtn,
            borderColor: "#FFF",
            testId: "cancel-btn",
            pirmary:false
        },
        {
            label: "Retry",
            handler: this.handleFailedDialogRetryBtn,
            borderColor: "#F4CD46",
            testId: 'retry-btn',
            pirmary:true
        }
    ];

    // Customizable Area End

    render() {

        return (

            <>

                {this.state.loading && <SpinnerLoader />}
                <ThemeProvider theme={theme}>
                    {!this.state.redeemed && !this.state.viewDetails &&
                        <Box className="qrCodeContainer" style={{ width: '100%', height: '100%' }}>
                            <Box style={webStyle.qrCodeContainerStyle}>
                                <this.HeadingWrapper><Container maxWidth="md">
                                    <Box
                                        style={{
                                            display: 'flex', gap: '20px', alignItems: 'center'
                                        }}>
                                        <ArrowBackIcon onClick={this.goBcak} style={{ color: '#fff', cursor: 'pointer', fontSize: '2em', position: 'relative',}} data-test-id="arrow-back-icon-btn-create" />
                                        <Typography style={webStyle.scanHeadingTextStyle}>Scan Code</Typography>
                                    </Box>

                                </Container>
                                </this.HeadingWrapper>
                                <QrReaderBoxStyle >
                                    <Box style={webStyle.qrReaderContainerStyle}>
                                        <Typography
                                            style={{ fontSize: '1.4em', fontWeight: 500, color: '#fff' }}
                                        >
                                            {this.scannerHeading()}
                                        </Typography>
                                        <Box style={webStyle.scannerContainerStyle}>
                                            {
                                            // this.state.scanningError !== "NotFoundError" &&
                                                <QrReader
                                                    onResult={(result: any, error: any) => {
                                                        if (!!result) {
                                                            this.handleScanResult(result);
                                                        }

                                                        if (!!error) {
                                                            this.handleScanError(error);
                                                        }
                                                    }}
                                                    //   style={{ width: '100%' }}
                                                    constraints={{ facingMode: "environment" }}
                                                    containerStyle={{ width: '100%', height: '100%', }}
                                                    scanDelay={1000}
                                                />
                                            }
                                            {
                                                this.state.isJoiningBusinessFailedPopup ?
                                                    <Box style={webStyle.borderBoxContainerstyle}>
                                                        <BorderBox
                                                            style={{ top: '0', left: '0', borderColor: 'red' }}
                                                        ></BorderBox>
                                                        <BorderBox
                                                            style={{ top: '0', right: '0', borderColor: 'red', rotate: '90deg' }}
                                                        ></BorderBox>
                                                        <BorderBox
                                                            style={{ bottom: '0', left: '0', borderColor: 'red', rotate: '-90deg' }}
                                                        ></BorderBox>
                                                        <BorderBox
                                                            style={{ bottom: '0', right: '0', borderColor: 'red', rotate: '180deg' }}
                                                        ></BorderBox>
                                                    </Box> :
                                                    <Box style={webStyle.borderBoxContainerstyle}>
                                                        <BorderBox
                                                            style={this.state.result ?
                                                                { top: '0', left: '0', borderColor: 'green' } :
                                                                { top: '0', left: '0', borderColor: '#fff' }}
                                                        ></BorderBox>
                                                        <BorderBox
                                                            style={this.state.result ?
                                                                { top: '0', right: '0', borderColor: 'green', rotate: '90deg' } :
                                                                { top: '0', right: '0', borderColor: '#fff', rotate: '90deg' }}
                                                        ></BorderBox>
                                                        <BorderBox
                                                            style={this.state.result ?
                                                                { bottom: '0', left: '0', borderColor: 'green', rotate: '-90deg' } :
                                                                { bottom: '0', left: '0', borderColor: '#fff', rotate: '-90deg' }}
                                                        ></BorderBox>
                                                        <BorderBox
                                                            style={this.state.result ?
                                                                { bottom: '0', right: '0', borderColor: 'green', rotate: '180deg' } :
                                                                { bottom: '0', right: '0', borderColor: '#fff', rotate: '180deg' }}
                                                        ></BorderBox>
                                                    </Box>
                                            }
                                        </Box>
                                    </Box>
                                </QrReaderBoxStyle>

                            </Box>
                        </Box>
                    }
                    {this.state.redeemed &&
                        <Box style={{ color: "#fff", fontSize: 'clamp(10px, 16px, 2vw)' }}>
                            <span style={{ marginLeft: '10%', fontWeight: 700, fontSize: '2em' }}>Subscriber</span>

                            <Box style={{ ...webStyle.flex, flexDirection: 'column', marginBottom: '30px' }}>
                                <CheckCircleIcon style={{ color: '#F4CD46', width: 'clamp(70px, 125px, 15vw)', height: 'clamp(70px, 125px, 15vw)' }} />
                                <Typography style={{ textAlign: 'center', marginBottom: '8px', color: '#F4CD46', fontSize: '3.1em', fontWeight: 700 }} >Successfully redeemed</Typography>
                                <Typography style={{ textAlign: 'center', marginBottom: '8px' }}> <b> 1 unit,</b> Redeemed at {this.currentDateAndTime()}</Typography>
                            </Box>

                            <Box style={{ ...webStyle.flex, marginBottom: '10px' }}>
                                <Avatar src={this.state.redeemedData?.photo} />
                                <Typography style={{ marginLeft: '15px', fontWeight: 600, fontSize: '2.5em' }}>
                                    {this.state.redeemedData?.full_name}
                                </Typography>
                            </Box>

                            <Typography style={{ textAlign: 'center', fontWeight: 300, fontSize: '2em', marginBottom: '8px' }}>
                                {this.state.redeemedData?.subscription_name}
                            </Typography>

                            <Box style={webStyle.buttonBoxStyle}>
                                <StyledButton
                                    data-test-id="scan-another-code-btn1"
                                    onClick={this.handleScanAnotherCodeBtn}
                                >
                                    Scan Another Code
                                </StyledButton>
                                <StyledButton2
                                    data-test-id="view-details-btn"
                                    onClick={this.handleViewDetailsBtn}
                                    style={{ border: '1px solid #F4CD46' }}
                                >
                                    View Details
                                </StyledButton2>
                            </Box>
                        </Box>
                    }
                    {this.state.viewDetails && this.state.viewDetailsData &&
                        <Box sx={webStyle.viewDetailsContainerStyle}>
                            <Box style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: 'clamp(20px, 50px, )' }}>
                                <ArrowBackIcon 
                                    onClick={this.handleBackToRedeemScreen} 
                                    style={webStyle.arrowBackIconStyle} 
                                    data-test-id="arrow-back-icon-btn" 
                                />
                                <Typography style={webStyle.headingStyle}>Subscriber Details</Typography>
                            </Box>
                            <Box style={{ color: "#fff" }}>
                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', marginBottom: '30px' }}>
                                    <Box style={{ width: 'clamp(80px, 140px, 10vw)', height: 'clamp(80px, 140px, 10vw)', borderRadius: '50%', overflow: 'hidden' }}>
                                        <img src={this.state.redeemedData?.photo} alt="" style={{ width: '100%', height: '100%' }} />
                                    </Box>
                                    <Box>
                                        <Typography style={{ fontSize: '2.5em', fontWeight: 600, textAlign: 'center' }}>{this.state.redeemedData?.full_name}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={webStyle.planTypeStyle}>{this.state.viewDetailsData.subscription.name}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={webStyle.planPriceStyle}>
                                            {`£${this.state.viewDetailsData.subscription.price}/`}
                                            <span style={{ fontSize: '0.8em', fontWeight: 200, color: '#fff' }}>month</span>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', marginBottom: '20px' }}>
                                    <Box>
                                        <Typography style={webStyle.planDescHeading}>About this Plan</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={webStyle.planDetailsStyle}>
                                            {this.state.viewDetailsData.subscription.description}
                                        </Typography>
                                    </Box>
                                </Box >
                                <Box sx={webStyle.subscriptionDescriptionContainerStyle}>
                                    <Box>
                                        <Typography style={webStyle.descHeadingStyle}>What you get</Typography>
                                        <Typography style={webStyle.descHeadingValueStyle}>
                                            {`${this.state.viewDetailsData.subscription.number_of_units} ${this.state.viewDetailsData.subscription.name_of_units} per ${this.state.viewDetailsData.subscription.per}`}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={webStyle.descHeadingStyle}>Duration</Typography>
                                        <Typography style={webStyle.descHeadingValueStyle}>
                                            {this.state.viewDetailsData.subscription.duration == 0 ? 'Unlimited' : `${this.state.viewDetailsData.subscription.duration} months`}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={webStyle.descHeadingStyle}>Pauses Allowed</Typography>
                                        <Typography style={webStyle.descHeadingValueStyle}>
                                            {this.state.viewDetailsData.subscription.pauses_allowed}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={webStyle.descHeadingStyle}>Valid Branches</Typography>
                                        <Tooltip title={this.getBranchNameList()}
                                            data-test-id="branch-name-tooltip"
                                            arrow
                                            PopperProps={{
                                                popperOptions: {
                                                    modifiers: {
                                                        offset: {
                                                            offset: '20, -15',
                                                        },
                                                    },
                                                },
                                            }}
                                            disableFocusListener
                                            style={webStyle.tooltipStyle}>
                                            <Typography style={{ ...webStyle.descHeadingValueStyle, cursor: 'pointer' }} data-test-id="valid-branch-data">
                                                {this.state.viewDetailsData.subscription.valid_branches}
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                    <Box>
                                        <Typography style={webStyle.descHeadingStyle}>Purchase Date</Typography>
                                        <Typography style={webStyle.descHeadingValueStyle}>{this.convertDateFormat(this.state.viewDetailsData?.purchase_date)}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={webStyle.descHeadingStyle}>Next Billing</Typography>
                                        <Typography style={webStyle.descHeadingValueStyle}>{this.getNextBillingDate()}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={webStyle.descHeadingStyle}>Units remaining for period</Typography>
                                        <Typography style={webStyle.descHeadingValueStyle}>
                                            {this.state.viewDetailsData.user_subscription_details.unites_remaining}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={webStyle.transactionHistoryContainerStyle}>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <Typography style={{ color: '#fff', fontSize: '1.75em', fontWeight: 600, fontFamily: 'Inter, sans-serif', letterSpacing: '-0.72px' }}
                                        >
                                            Transaction History
                                        </Typography>
                                        {this.state.expandTransactionHistory ?
                                            <ArrowDropUpIcon 
                                                style={{ color: '#fff', fontSize: '2.2em', cursor: 'pointer', }} 
                                                onClick={this.handleTranscationHistoryExpand} 
                                                data-test-id="arrow-drop-up-icon" 
                                            /> :
                                            <ArrowDropDownIcon 
                                                style={{ color: '#fff', fontSize: '2.2em', cursor: 'pointer', }} 
                                                onClick={this.handleTranscationHistoryExpand} 
                                                data-test-id="arrow-drop-down-icon" 
                                            />}
                                    </Box>
                                    <Box>
                                        <TableContainer style={{ display: 'flex', justifyContent: 'center', maxHeight: '450px' }}>
                                            <Table stickyHeader size="small" aria-label="sticky table" style={{fontSize: 'clamp(10px, 16px, 2vw)'}}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={webStyle.tableHeaderCellStyle}>Transaction</TableCell>
                                                        <TableCell style={webStyle.tableHeaderCellStyle}>Date</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.transactionHistoryDatas.map((row: any, index: number) => {
                                                        if (!this.state.expandTransactionHistory && index >= 3) {
                                                            return null;
                                                        }
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell style={webStyle.tableBodyCellStyle} scope="row">
                                                                    {`${row.transaction_type}`}
                                                                </TableCell>
                                                                <TableCell style={webStyle.tableBodyCellStyle}>
                                                                    {this.convertDateFormat(row.date)}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                                <Box style={webStyle.buttonBoxStyle}>
                                    <StyledButton
                                        onClick={this.handleScanAnotherCodeBtn}
                                        data-test-id="scan-another-code-btn2"
                                    >
                                        scan another code
                                    </StyledButton>
                                </Box>
                            </Box >
                        </Box >
                    }
                    <CustomDialog
                        open={this.state.scanningError === "NotFoundError"}
                        isError={true}
                        title="Camera Not Found"
                        content={"Camera is disabled or not available on this device"}
                    />
                    <CustomDialog
                        open={this.state.isJoiningBusinessSuccessPopup}
                        isSuccess={true}
                        actions={this.successDialogActions}
                        title="successfully joined business"
                        content={this.state.successMessage}
                        data-test-id="success-custom-dialog"
                    />
                    <CustomDialog
                        open={this.state.isJoiningBusinessFailedPopup}
                        isError={true}
                        actions={this.failedDialogActions}
                        title="Failed to Scan Code"
                        content="QR code may be invalid. Please try again."
                        data-test-id="failed-custom-dialog"
                    />
                    <CustomDialog
                        open={this.state.openRedeemDialog}
                        isError={true}
                        actions={
                            [
                                {
                                    label: "OK",
                                    handler: this.handleCheckDialogOkBtn,
                                    borderColor: "#F4CD46",
                                    testId: 'check-subs-dialog-btn'
                                }
                            ]
                        }
                        title={this.state.redeemDialogTitle}
                        content={this.state.redeemDialogMessage}
                    />
                </ThemeProvider >
            </>
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    },
    typography: {
        fontFamily: 'Inter'
    },
    overrides: {
        MuiDialog: {
            'paperWidthSm': {
                maxWidth: '694px',
                background: '#212226',
                minHeight: '220px'
            },
        },
        MuiBackdrop: {
            'root': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }
        }
    }
});

const StyledButton = styled(Button)({
    display: "flex",
    width: "269px",
    height: "56px",
    padding: "1px",
    justifyContent: "center",
    alignItems: "center",
    background: "#000",
    border: "1px solid #FFF",
    borderRadius: "70px",
    fontSize: "1em",
    fontWeight: 600,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
        background: "white",
        color: "black"
    },
    "@media(max-width: 450px)": {
        width: "250px",
        height: "50px",
    }
});
const StyledButton2 = styled(Button)({
    display: "flex",
    width: "269px",
    height: "56px",
    padding: "1px",
    justifyContent: "center",
    alignItems: "center",
    background: "#000",
    border: "1px solid #F4CD46",
    borderRadius: "70px",
    fontSize: "1em",
    fontWeight: 600,
    letterSpacing: "-0.408px",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
        background: "#F4CD46",
        color: "black"
    },
    "@media(max-width: 450px)": {
        width: "250px",
        height: "50px",
    }
});

const BorderBox = styled(Box)({
    position: "absolute",
    width: "50px",
    height: "50px",
    padding: "5px",
    background: "transparent",
    borderStyle: "solid",
    borderRadius: "5px",
    borderTop: '5px',
    borderLeft: '5px',
    borderBottom: '0',
    borderRight: '0'

});

const QrReaderBoxStyle = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    // marginBottom: '50px',
    '@media (max-width: 450px)': {
        justifyContent: 'unset',
    },
})

const SuccesfulRedeemedTypo = styled(Box)({
    color: '#F4CD46',
    margin: '10 0',
    fontWeight: 700,
    fontSize: '2.3em',
    "@media (max-width:910px)": {
        fontSize: '1.3em',
    }
})
const ButtonWraper = styled(Box)({
    margin: '20 0',
    gap: '20px',
    "@media (max-width:700px)": {
        flexDirection: "column" as "column",
    }
})

const webStyle = {
    qrCodeContainerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column' as 'column',
        gap: '50px',
        paddingLeft: '5vh',
        fontSize: 'clamp(10px, 16px, 2vw)'
    },
    buttonStyle: {
        display: "flex",
        width: "225px",
        height: "56px",
        padding: "1px",
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
        borderRadius: "50px",
        fontSize: "1rem",
        fontWeight: 600,
        letterSpacing: "-0.408px",
        color: "#111"
    },
    scanHeadingTextStyle: {
        color: "#fff",
        fontSize: "2em",
        fontWeight: 700,
        letterSpacing: "-0.408px",
    },
    qrReaderBoxStyle: {
        display: 'flex',
        justifyContent: 'center',

    },
    qrReaderContainerStyle: {
        boxSizing: 'border-box' as const,
        width: '50vw',
        // height: '50vw',
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        background: '#212226',
        alignItems: 'center',
        gap: '35px',
        position: 'relative' as 'relative',
        maxWidth: '480px',
        maxHeight: '480px',
        minWidth: '300px',
        minHeight: '300px',
        padding: '20px'
    },
    scannerContainerStyle: {
        width: '75%',
        // height: '75%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative' as 'relative'
    },
    borderBoxContainerstyle: {
        width: '100%',
        height: '100%',
        position: 'absolute' as 'absolute'
    },
    dialogTitleStyle: {
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        fontSize: '32px',
        fontWeight: 600 as 600,
        fontFamily: 'Inter, sans-serif',
        letterSpacing: '-0.408px',
        color: '#fff',
        textAlign: 'center' as 'center',
        padding: '10px',
    },
    dialogContentStyle: {
        fontSize: '24px',
        color: '#fff',
        fontFamily: 'Inter, sans-serif',
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center'
    },


    trHistoryContainerStyle: {
        display: 'flex',
        backgroundColor: 'transparent',
        gap: '2rem',
        padding: { xs: "3%", md: "35px 20%", lg: "35px 25%", xl: "35px 27%", },
        flexDirection: 'column' as 'column'
    },

    tableBCellStyle: {
        background: '#000000',
        border: 0,
        color: '#fff',
        textAlign: 'center' as 'center',
        fontSize: '16px',
        fontWeight: 200,
        letterSpacing: '-0.72px',
    },
    tableHCellStyle: {
        background: '#212226',
        border: 0,
        color: '#fff',
        textAlign: 'center' as 'center',
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: '-0.408px',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subscriptionDetailsWraper: {
        display: 'flex',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItem: 'center',
        gap: '2rem',
        flexWrap: 'wrap' as 'wrap',
    },
    descHeading: {
        color: '#FFF',
        fontFamily: 'Inter, sans-serif',
        fontSize: '1.5em',
        fontWeight: 600,
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center',
        // lineHeight: '40px',
    },
    descHeadingValue: {
        color: '#FFF',
        fontFamily: 'Inter, sans-serif',
        fontSize: '1.5em',
        fontWeight: 200,
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center'
    },
    historyContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '13em',
        background: '#212226',
        width: '40em',
        alignContent: 'center',
        alignItems: 'center',
        height: '50px',
        fontWeight: 500,
        fontSize: '1em',
        // lineHeight: '22px'
    },
    historyDetailsContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '3em',
        alignContent: 'center',
        alignItems: 'center',
        fontWeight: 200,
        fontSize: '1.5em',
        // lineHeight: '22px'
    },
    viewDetailsContainerStyle: {
        padding: { xs: "0 20px", md: "0 3rem", lg: "0 5rem", xl: "0 8rem", },
        fontSize: 'clamp(10px, 16px, 2vw)'
    },
    descHeadingValueStyle: {
        color: '#FFF',
        fontFamily: 'Inter, sans-serif',
        fontSize: '1.5em',
        fontWeight: 200,
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center'
    },
    subscriptionDescriptionContainerStyle: {
        display: 'flex',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItem: 'center',
        gap: '10px 10%',
        flexWrap: 'wrap' as 'wrap',
        padding: { xs: "20px 0", md: "35px 8%", lg: "35px 14%", xl: "35px 8rem", }
    },
    transactionHistoryContainerStyle: {
        display: 'flex',
        backgroundColor: 'transparent',
        gap: 'clamp(16px, 32px, 2vw)',
        padding: { xs: "20px", md: "35px 8%", lg: "35px 14%", xl: "35px 8rem", },
        flexDirection: 'column' as 'column'
    },
    headingStyle: {
        color: '#FFF',
        fontFamily: 'Inter, sans-serif',
        fontSize: '2em',
        fontWeight: 700,
        letterSpacing: '-0.408px',
    },
    planTypeStyle: {
        color: '#fff',
        fontSize: '2em',
        fontWeight: 300,
        fontFamily: 'Inter, sans-serif',
        letterSpacing: '-0.408px',
        textTransform: 'capitalize' as 'capitalize'
    },
    planPriceStyle: {
        color: '#fff',
        fontSize: '2em',
        fontWeight: 600,
        fontFamily: 'Inter, sans-serif',
        letterSpacing: '-0.408px',
    },
    arrowBackIconStyle: {
        color: '#FFF',
        cursor: 'pointer',
        fontSize: '2.2em'
    },
    planDescHeading: {
        color: '#fff',
        fontSize: '1.5em',
        fontWeight: 600,
        fontFamily: 'Inter, sans-serif',
        letterSpacing: '-0.72px',
    },
    tooltipStyle: {
        maxWidth: "300px",
        minHeight: "73.2px",
        fontSize: '0.9em',
        fontFamily: 'inter',
        padding: '2px',
        backgroundColor: '#212226',
        opacity: 1,
    },
    descHeadingStyle: {
        color: '#FFF',
        fontFamily: 'Inter, sans-serif',
        fontSize: '1.5em',
        fontWeight: 600,
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center',
    },
    planDetailsStyle: {
        color: '#fff',
        fontSize: '2em',
        fontWeight: 200,
        fontFamily: 'Inter, sans-serif',
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center'
    },
    tableHeaderCellStyle: {
        background: '#212226',
        border: 0,
        color: '#fff',
        textAlign: 'center' as 'center',
        fontSize: '1em',
        fontWeight: 500,
        letterSpacing: '-0.408px',
    },
    tableBodyCellStyle: {
        background: '#000000',
        border: 0,
        color: '#fff',
        textAlign: 'center' as 'center',
        fontSize: '1em',
        fontWeight: 200,
        letterSpacing: '-0.72px',
    },
    buttonBoxStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        gap: '20px',
        flexWrap: 'wrap' as 'wrap'
    }
}
// Customizable Area End
