import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Typography,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area End

import StripeSetupController, {
    Props,
} from "./StripeSetupController";

export default class StripeSetup extends StripeSetupController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Container style={{ maxWidth: '100%', marginBottom: '50px', padding: '0 6%', fontSize: 'clamp(10px, 16px, 2vw)' }}>
                    <Box>
                        <Typography style={webStyle.verifyIdentityLabel}>
                            Connect to Stripe
                        </Typography>
                        <Box sx={webStyle.identityVerifyWrapper}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={webStyle.profileLogoBoxStyle}>
                                    <CancelIcon style={{ color: '#fff', width: '60%', height: '60%' }} />
                                </Box>
                            </Box>
                            <Box>
                                <Typography style={webStyle.textLabelSecond}>
                                    Your Stripe account could not be set up. Please retry or get in touch with the Go QR team.
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                                <MuiStyledButton
                                    onClick={this.handleRetrySetup}
                                    variant="contained"
                                    data-test-id = "retryBtn"
                                >
                                    Retry
                                </MuiStyledButton>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        );
    }
}

// Customizable Area Start

const theme = createTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                width: "100%",
            },
        },
    },
});

const MuiStyledButton = styled(Button)({
    display: "flex",
    width: "225px",
    height: "56px",
    justifyContent: "center",
    alignItems: "center",
    padding: "1px",
    background: "#000",
    fontFamily: "Inter",
    border: "1px solid #F4CD46",
    borderRadius: "50px",
    fontSize: "1em",
    fontWeight: 600,
    color: "#fff",
    letterSpacing: "-0.408px",
    textTransform: "capitalize",
    "&:hover": {
        background: "#F4CD46",
        color: "#000",
    }
});

const webStyle = {
    verifyIdentityLabel: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '2em',
        fontWeight: 700,
        letterSpacing: '-0.408px',
        marginBottom: 'clamp(20px, 50px, 5vw)'
    },
    identityVerifyWrapper: {
        display: "flex",
        width: '100%',
        flexDirection: 'column',
        gap: 'clamp(20px, 50px, 5vw)'
    },
    profileLogoBoxStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '195px',
        height: '172px',
    },

    textLabelSecond: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '1.5em',
        fontWeight: 400,
        letterSpacing: '-0.408px',
        textAlign: 'center' as 'center'
    },

};
// Customizable Area End