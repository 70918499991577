Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpDeleteMethod = "DELETE";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.addBranch = '/bx_block_branch/branches';
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.branchListApiEndpoint = "bx_block_branch/joined_business_user_branches"
exports.data = [
  { value: "male", name: "Male" },
  { value: "female", name: "Female" },
  { value: "non_binary", name: "Non-binary" },
  { value: "prefer_not_to_say", name: "Prefer not to say" }
]
exports.getSubscribersListApiEndPoint = "bx_block_custom_user_subs/subscriber_subscription_list?branch_id="
exports.baseUrl = ""
exports.getBranchEndPoint =`bx_block_branch/branches`;
exports.getBranchUserEndPoint = "bx_block_branch/get_branch_users";
exports.getUserListApiEndPoint ="bx_block_branch/search_users";
exports.getBusinessDetailsEndPoint = "account_block/business";
exports.putUpdateBusinessOwnerEndPoint = "bx_block_roles_permissions/make_business_owner";
exports.putUpdateUserPermissionEndPoint = "bx_block_roles_permissions/permissions";
exports.putUpdateBranchEndPoint = "bx_block_branch/branches";
exports.deleteBranchUserEndPoint = "bx_block_branch/delete_branch_user";
exports.branchesText = "Branches";
exports.createNewBranchText ="Create New Branch";
exports.totalUsersText = "Total Users:";
exports.manageText = "Manage";
exports.getQRText = "Get QR";
exports.manageBranchText = "Manage Branch";
exports.branchNameText = "Name";
exports.addressFieldName="Address"
exports.branchUsersText = "Branch Users";
exports.editText = "Edit";
exports.backText = "Back";
exports.addUserText = "Add User";
exports.saveText = "Save";
exports.tableHeaderNameText = "Name";
exports.tableHeaderReportingText = "Reporting";
exports.tableHeaderManageBusinessText = "Manage Business";
exports.tableHeaderManageSubscriptionText = "Manage Subscriptions";
exports.tableHeaderManageSubscribersText = "Manage Subscribers";
exports.tableHeaderGeneralUserText = "General User";
exports.branchQRTitleText = "Branch";
exports.downloadPDFText = "Download pdf";
exports.makeBusinessOwnerText = "Make Business owner";
exports.editPermissionText = "Edit Permission";
exports.deleteText = "Delete";
exports.areYouSureYouWantToMakeText = "Are you sure you want to make";
exports.theBusinessOwnerText = "the Business Owner?";
exports.cancleText = "Cancel";
exports.confirmText = "Confirm";
exports.deleteUserText = "Delete User";
exports.createText = "Create";
exports.branchNameNotEmptyText = "Branch Name Can't be Empty";
exports.areYouSureYouWantToDeleteText = "Are you sure you want to delete";
exports.addText = "Add";
exports.subscribersSubscriptionPauseApiEndPoint = "bx_block_custom_user_subs/subscription_paused_by_business";
exports.subscribersSubscriptionCancelApiEndPoint = "bx_block_custom_user_subs/subscription_cancel_by_business";
exports.getIndustriesApiEndPOint = "bx_block_industry/industries"
exports.getAllBusinessApiEndPoint="account_block/business"
exports.updateBusinessProfileApiEndPoint="account_block/business"
exports.branchesMenuList = [
  {label:"1-2", value: "1-2"},
  {label:"3-5", value: "3-5"},
  {label:"6-10", value: "6-10"},
  {label:"11-20", value: "11-20"},
  {label:"21-30", value: "21-30"},
];
exports.employesNoMenuList = [
  {label:"1-10", value: "1-10"},
  {label:"11-50", value: "11-50"},
  {label:"51-200", value: "51-200"},
  {label:"201-500", value: "201-500"},
  {label:"501-1000", value: "501-1000"},
  {label:"1001-5000", value: "1001-5000"},
  {label:"5001-10000", value: "5001-10000"},
  {label:"10001+", value:"10001+"}
];
exports.subscribersNoMenuList = [
  {label:"1-10", value: "1-10"},
  {label:"11-50", value: "11-50"},
  {label:"51-200", value: "51-200"},
  {label:"201-500", value: "201-500"},
  {label:"501-1000", value: "501-1000"},
  {label:"1001-5000", value: "1001-5000"},
  {label:"5001-10000", value: "5001-10000"},
  {label:"10001+", value:"10001+"}
];
exports.businessTypesList = [
  {label:"Individual", value: "Individual"},
  {label:"Company", value: "Company"},
  {label:"Non-profit", value: "Non-profit"},
];
exports.getProfileDetailsApiEndPoint = "account_block/specific_account"
exports.subscriberText = 'Subscriber'
exports.planText = 'Plan'
exports.statusText = 'Status'
exports.noSubscriberFoundText = 'No Subscriber Found'
exports.subscriberDetailsText = 'Subscriber Details'
exports.subscriptionDetailsText = 'Subscription Details'
exports.durationText = 'Duration'
exports.pausesAllowedText = 'Pauses Allowed'
exports.validBranchesText = 'Valid Branches'
exports.purchaseDateText = 'Purchase Date'
exports.nextBillingText = 'Next Billing'
exports.unitsRemainingForPeriodText = 'Units remaining for period'
exports.transactionHistoryText = 'Transaction History'
exports.transactionText='Transaction'
exports.dateText='Date'
exports.cancelSubscriptionText=' Cancel Subscription'
exports.dialogContentText1='If you choose to cancel, Subscriber will retain complete access to their subscription until'
exports.dialogContentText2='After this date, they will no longer have access'
exports.dialogContentText3='Are you sure you want to cancel the subscription?'
exports.pauseSubscriptionText='Pause Subscription'
exports.dialogContentText4='You will immediately lose access of the subscription benefits until '
exports.dialogContentText5='and skip one month of billing.'
exports.dialogContentText6='Are you sure you want to pause the subscription?'
exports.pausedSuccessfullyText=' Paused Successfully'
exports.dialogContentText7=' Your subscription will resume on'
exports.noPausesAllowedRemainingText=' No pauses allowed remaining'
exports.cancelledSuccessfullyText='  Cancelled Successfully'
exports.dialogContentText8='Your subscription has been cancelled successfully'
exports.dialogActionText=' Close'
// Customizable Area End
