import React, { Component } from "react";

import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import NavigationOutlinedIcon from '@material-ui/icons/NavigationOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';


import CreateNewBusinessController, {
    Props,
    IField
  } from "./CreateNewBusinessController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    },
    overrides: {
        MuiDialog: {
            'paperWidthSm': {
                maxWidth: '694px',
                padding: '50px 0',
                background: '#212226'
            },
        },
        MuiBackdrop: {
            'root': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }
        }
    }
});

const  SetUpBusinessBox=styled(Box)({
    width: '400px',
    height: '280px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #fff',
    gap: '0.5rem',
    cursor: 'pointer',
    "@media (max-width: 900px)": {
        width: '300px',
        height: '200px',
    },
    "@media (max-width: 600px)": {
        width: '200px',
        height: '125px',
    }
})
const BoxTextStyle =styled(Box)({
    color: '#fff',
    textAlign: 'center' as "center",
    fontFamily: 'Inter',
    fontSize: '1.5rem',
    fontWeight: 600,
    letterSpacing: '-0.0255rem',
    "@media (max-width: 600px)": {
        fontSize: '1rem',

    }
})
const HeaderTextStyle =styled(Box)({
    color: '#fff',
    textAlign: "center" as "center",
    fontSize: '2rem',
    fontWeight: 400,
    letterSpacing: '-0.0255rem',
    maxWidth: "90%",
    width: "100%",
    margin: 'auto' as "auto",
    "@media (max-width: 600px)": {
        fontSize: '1.25em',

    }
})
// Customizable Area End

export default class SetupYourBusiness extends CreateNewBusinessController {

    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container style={{ marginBottom: '50px', maxWidth: '100%', }}>

                    <Box style={{ marginBottom: '60px' }}>
                        <HeaderTextStyle >Set up your business or join an existing one to start using Go QR</HeaderTextStyle>
                    </Box>
                    <Box style={webStyle.setupbusinessWrapper}>
                        <SetUpBusinessBox data-test-id="click1" onClick={this.goToCreateNewBusiness}>
                            <BusinessCenterOutlinedIcon style={webStyle.iconStyle} />
                            <BoxTextStyle >Create new business</BoxTextStyle>
                        </SetUpBusinessBox>
                        <SetUpBusinessBox  onClick={this.handleJoinBusiness}>
                            <NavigationOutlinedIcon style={{...webStyle.iconStyle, rotate: '45deg'}} />
                            <BoxTextStyle >Join existing business</BoxTextStyle>
                        </SetUpBusinessBox>
                    </Box>


                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}


// Customizable Area Start
const webStyle = {

    setupbusinessWrapper: {
        display: 'flex',
        justifyContent: 'center',
        gap:'2rem',
        flexWrap: 'wrap' as 'wrap',
        // textAlign: 'center' as "center",
    },


    iconStyle: {
        maxWidth: '64px', 
        height: '64px', 
        width: '100%',
        color: '#fff' 
    }
};
// Customizable Area End
