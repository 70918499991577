Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "POST";
exports.productAPiEndPoint = "/qr_codes/qr_codes";
exports.getProfileDetailsApiEndPoint = "/account_block/specific_account";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.joinBusinessApiEndPoint = "/account_block/join_business";
exports.subscriptionApiEndPoint = "/bx_block_custom_user_subs/subscription_list_subscriber?business_id="
exports.buyNowApiEndPoint = "/bx_block_stripe_integration/subscriber_subscription_payment?id="
exports.SubsDetails = "/bx_block_custom_user_subs/subscriptions/"
exports.aboutPlanText = "About this Plan"
exports.whatYouGetText = "What you get"
exports.durationText = "Duration"
exports.pausesText = "Pauses"
exports.validBranchesText = "Valid Branches"
exports.noSubscriptionText = "No Subscriptions found"
// Customizable Area End
